import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MdHowToReg, MdSecurity } from 'react-icons/md';
import { GiBoxUnpacking, GiFactory, GiStarMedal, GiHandTruck } from 'react-icons/gi';
import {
  FaTruck, FaWarehouse, FaUniversity, FaShippingFast, FaClock,
  FaSignInAlt,
  FaSignOutAlt } from 'react-icons/fa';


const faqData = [

  {
    question: 'How does Spring move out work?',
    answer:
      'You will be provided with packing supplies and labels for your items ahead of move out. On move out day, you will be asked to bring your belongings to a designated drop off location outside of your residential hall. Free handtrucks will be provided, and student movers are available for hire. Your items will be checked in with your storage coordinator, and will be safely stored for the summer.',
    icon: <FaSignOutAlt size={24} />
  },
  {
    question: 'How does Fall move in work?',
    answer:
      'Your items will be available for pickup from your designated move in location on the day of your scheduled move in appointment.',
    icon: <FaSignInAlt size={24} />
  },
  {
    question: 'How do I secure my appointment?',
    answer: 'Register online in less than 5 minutes without needing a credit card. Just provide your information and select your storage plan.',
    icon: <MdHowToReg size={24} />
  },
  {
    question: 'Does storage include movers?',
    answer: 'Student movers are available with every plan at a rate of $35 per mover, per move.',
    icon: <GiHandTruck size={24} />
  },
  {
    question: 'What items can I store?',
    answer: 'At no extra cost, you can store a wide range of items, including boxes, furniture, and other personal belongings. View our Storable Items page for a comprehensive list.',
    icon: <GiBoxUnpacking size={24} />
  }, {
    question: 'Do you offer off-campus delivery?',
    answer: 'Yes, we offer off-campus delivery for an additional fee.',
    icon: <FaShippingFast size={24} />
  },
  {
    question: 'Are my items insured?',
    answer: 'Yes, your items are insured while in possession of SummerStore. We provide insurance coverage of up to $100 per item under all of our available plans, ensuring that your items are protected against damage or loss during the storage period.',
    icon: <MdSecurity size={24} />
  },
  {
    question: 'When will my items be delivered?',
    answer: 'Your items will be delivered back to campus at the beginning of the next semester, ensuring they are ready when you return.',
    icon: <FaTruck size={24} />
  },
  {
    question: 'Where are my items stored?',
    answer: 'Your items are stored in climate-safe and secure environments provided by our partners, with insurance coverage for added protection.',
    icon: <FaWarehouse size={24} />
  },
  {
    question: 'Which universities does SummerStore provide service for?',
    answer: 'To check if we service your university, please begin the registration process on our website and select your school from the dropdown menu.',
    icon: <FaUniversity size={24} />
  },
  {
    question: 'Do you offer Early/Late move in or move out?',
    answer:
      'Yes, for an additional fee. Select from available early move in/move out appointments during registration.',
    icon: <FaClock size={24} />
  },
  {
    question: 'What makes SummerStore different from other storage companies?',
    answer: 'SummerStore focuses on the affordability of our service by creating convenient pickup and dropoff locations directly positioned outside of residential halls for dropping off and collecting student belongings. This strategy minimizes operational costs, allowing us to offer transparent and cost-effective bundled pricing plans for students.',
    icon: <GiStarMedal size={24} />
  }
];

const FAQ = () => {
  return (
    <div style={{width: {m: '80%', xs: '100%'}, display: "flex", flexDirection: "column"}}>
      
      {faqData.map((faq, index) => (
        <Accordion key={index} sx={{ mb: 2, bgcolor: "transparent", 
        borderRadius: "10px",  }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{
              "&.MuiAccordionSummary-root": {
                backgroundColor: "transparent",
                marginTop: '0 !important',
                alignItems: "unset !important",
                WebkitAlignItems: "unset !important"
              },
              "& .MuiAccordionSummary-content": {
                margin: "0 !important", // Removes the default 12px top/bottom margin
              },
              "& .MuiAccordionSummary-expandIconWrapper": {
                alignItems: "center", 
              },
              bgcolor: "transparent",
              border: "2px solid",
              borderRadiusLeftTop: "10px", 
              borderBottom: "5px solid", 
              borderColor: "primary.main",
              margin: '0 !important',
              padding: '0 !important'
            }}
          >
            <Box sx={{ backgroundColor: "primary.main", color: "white", height: "100%", display: "flex", alignItems: 'center', p: 2 }}>
              {faq.icon}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
              <Typography sx={{ ml: 2, fontSize: {m: '1.35rem', xs: '0.9rem'}, fontWeight: 'bold' }}>{faq.question}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{  backgroundColor: "primary.main", color: 'white' }}>
            <Typography sx={{ fontSize: '1.1rem' }}>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQ;
