import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Paper,
    Button,
    Grid,
    Tooltip,
    Divider
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import ContainerCustomersTable from '../../components/ContainerCustomersTable';

// Import the new CapacityBar component
import CapacityBar from '../../components/CapacityBar';

const AppointmentDateRangeDisplay = ({ universityID, containerLocationID, appointmentIDs }) => {
    const [displayText, setDisplayText] = useState(null);

    useEffect(() => {
        const loadDates = async () => {
            if (!appointmentIDs || appointmentIDs.length === 0) {
                setDisplayText(null);
                return;
            }
            try {
                const appointments = await Promise.all(
                    appointmentIDs.map(async (id) => {
                        const appointmentDocRef = doc(
                            db,
                            `universities/${universityID}/containerLocations/${containerLocationID}/appointments`,
                            id
                        );
                        const appointmentDoc = await getDoc(appointmentDocRef);
                        return appointmentDoc.exists() ? appointmentDoc.data() : null;
                    })
                );
                const validAppointments = appointments.filter((appt) => appt && appt.start);
                if (validAppointments.length === 0) {
                    setDisplayText(null);
                    return;
                }
                const dates = validAppointments.map((appt) => new Date(appt.start.seconds * 1000));
                dates.sort((a, b) => a - b);
                const options = { month: 'short', day: 'numeric' };
                const startDate = dates[0].toLocaleDateString(undefined, options);
                if (dates.length === 1) {
                    setDisplayText(startDate);
                } else {
                    const endDate = dates[dates.length - 1].toLocaleDateString(undefined, options);
                    setDisplayText(`${startDate} – ${endDate}`);
                }
            } catch (error) {
                console.error('Error loading appointment dates:', error);
                setDisplayText(null);
            }
        };

        loadDates();
    }, [universityID, containerLocationID, appointmentIDs]);

    return displayText ? (
        <Typography variant="caption" sx={{ color: 'gray', fontSize: '0.8rem' }}>
            {displayText}
        </Typography>
    ) : null;
};

const ContainerDetails = () => {
    const { containerID } = useParams();
    const navigate = useNavigate();
    const [container, setContainer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [moveInLocationName, setMoveInLocationName] = useState('N/A');
    const [moveOutLocationName, setMoveOutLocationName] = useState('N/A');

    // State for aggregated inventory volume and total items.
    const [aggregatedVolume, setAggregatedVolume] = useState(0);
    const [aggregatedItemCount, setAggregatedItemCount] = useState(0);
    // Mapping of customerId to { usedVolume, anticipatedVolume }.
    const [customerVolumes, setCustomerVolumes] = useState({});

    useEffect(() => {
        const fetchContainer = async () => {
            try {
                setLoading(true);
                const containerRef = doc(db, 'containers', containerID);
                const containerSnap = await getDoc(containerRef);
                if (containerSnap.exists()) {
                    const containerData = { id: containerSnap.id, ...containerSnap.data() };
                    setContainer(containerData);

                    // Fetch appointment location names if available.
                    if (containerData.universityId) {
                        if (containerData.moveInAppointmentLocationID) {
                            const moveInDoc = await getDoc(
                                doc(
                                    db,
                                    `universities/${containerData.universityId}/containerLocations`,
                                    containerData.moveInAppointmentLocationID
                                )
                            );
                            if (moveInDoc.exists()) {
                                setMoveInLocationName(moveInDoc.data().name);
                            }
                        }
                        if (containerData.moveOutAppointmentLocationID) {
                            const moveOutDoc = await getDoc(
                                doc(
                                    db,
                                    `universities/${containerData.universityId}/containerLocations`,
                                    containerData.moveOutAppointmentLocationID
                                )
                            );
                            if (moveOutDoc.exists()) {
                                setMoveOutLocationName(moveOutDoc.data().name);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching container details:', error);
            } finally {
                setLoading(false);
            }
        };

        if (containerID) {
            fetchContainer();
        }
    }, [containerID]);

    // (Keep the aggregated inventory useEffect as-is for your aggregated stats display)

    useEffect(() => {
        const fetchAggregatedInventory = async () => {
            if (!container) return;

            const customerIds = [
                ...(container.moveOutCustomers || []),
                ...(container.moveInCustomers || []),
                ...(container.summerCustomers || []),
            ];
            const uniqueCustomerIds = [...new Set(customerIds)];

            let totalVolume = 0;
            let totalItems = 0;
            const volumeMap = {};

            await Promise.all(
                uniqueCustomerIds.map(async (customerId) => {
                    try {
                        const inventoryCollection = collection(db, `inventoryUsers/${customerId}/items`);
                        const inventoryDocs = await getDocs(inventoryCollection);
                        const items = inventoryDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                        let usedVolume = 0;
                        items.forEach((item) => {
                            if (
                                item.dimensions &&
                                item.dimensions.width &&
                                item.dimensions.height &&
                                item.dimensions.length
                            ) {
                                const volumeCubicFeet =
                                    (item.dimensions.width *
                                        item.dimensions.height *
                                        item.dimensions.length) /
                                    1728;
                                usedVolume += volumeCubicFeet;
                                totalVolume += volumeCubicFeet;
                                totalItems++;
                            }
                        });

                        const userDoc = await getDoc(doc(db, 'users', customerId));
                        let anticipatedVolume = 0;
                        if (userDoc.exists()) {
                            const userData = userDoc.data();
                            if (userData.maxItems) {
                                anticipatedVolume = Math.max(userData.maxItems * 3 - usedVolume, 0);
                            }
                        }

                        volumeMap[customerId] = { usedVolume, anticipatedVolume };
                    } catch (error) {
                        console.error(`Error fetching inventory for customer ${customerId}:`, error);
                    }
                })
            );

            setAggregatedVolume(totalVolume);
            setAggregatedItemCount(totalItems);
            setCustomerVolumes(volumeMap);
        };

        fetchAggregatedInventory();
    }, [container]);

    // Helper function to render customer count.
    const renderCustomerCount = (label, count, maxCustomers) => (
        <Typography variant="body1">
            <strong>{label}:</strong> {count}
            <Box component="span" sx={{ fontSize: '0.8rem', color: 'gray' }}>
                {` /${maxCustomers}`}
            </Box>
        </Typography>
    );

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!container) {
        return (
            <Typography variant="h6" color="error" sx={{ mt: 4, textAlign: 'center' }}>
                Container not found.
            </Typography>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Button variant="outlined" onClick={() => navigate(-1)} sx={{ mb: 2 }}>
                Back
            </Button>

            <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                Viewing Container
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography
                        variant="subtitle2"
                        gutterBottom
                        sx={{ color: 'text.secondary', textTransform: 'uppercase' }}
                    >
                        Details
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography
                        variant="subtitle2"
                        gutterBottom
                        sx={{ color: 'text.secondary', textTransform: 'uppercase' }}
                    >
                        Space Usage
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                {/* Left Column */}
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2, mb: 2 }}>
                        <Typography variant="body1">
                            <strong>ID:</strong> {container.id}
                        </Typography>
                        {container.universityId && (
                            <Typography variant="body1">
                                <strong>University ID:</strong> {container.universityId}
                            </Typography>
                        )}
                        <Typography variant="body1">
                            <strong>Space Capacity:</strong> {container.spaceCapacity} ft³
                        </Typography>
                        <Typography variant="body1">
                            <strong>Max Customers:</strong> {container.maxCustomers}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        {renderCustomerCount(
                            'Move Out Customers',
                            container.moveOutCustomers?.length || 0,
                            container.maxCustomers
                        )}
                        {renderCustomerCount(
                            'Move In Customers',
                            container.moveInCustomers?.length || 0,
                            container.maxCustomers
                        )}
                        {renderCustomerCount(
                            'Summer Customers',
                            container.summerCustomers?.length || 0,
                            container.maxCustomers
                        )}
                    </Paper>
                    <Typography
                        variant="subtitle2"
                        gutterBottom
                        sx={{ color: 'text.secondary', textTransform: 'uppercase' }}
                    >
                        Appointment Details
                    </Typography>
                    <Paper sx={{ p: 2 }}>
                        <Box sx={{ mb: 2, p: 1, border: '1px solid #ddd', borderRadius: 2 }}>
                            <Typography variant="h6">Move Out Appointment</Typography>
                            <Typography variant="body2">
                                <strong>Location:</strong> {moveOutLocationName}
                            </Typography>
                            <AppointmentDateRangeDisplay
                                universityID={container.universityId}
                                containerLocationID={container.moveOutAppointmentLocationID}
                                appointmentIDs={container.moveOutAppointmentIDs}
                            />
                        </Box>
                        <Box sx={{ p: 1, border: '1px solid #ddd', borderRadius: 2 }}>
                            <Typography variant="h6">Move In Appointment</Typography>
                            <Typography variant="body2">
                                <strong>Location:</strong> {moveInLocationName}
                            </Typography>
                            <AppointmentDateRangeDisplay
                                universityID={container.universityId}
                                containerLocationID={container.moveInAppointmentLocationID}
                                appointmentIDs={container.moveInAppointmentIDs}
                            />
                        </Box>
                    </Paper>
                    <Paper sx={{ p: 2, mt: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Collective Inventory Usage
                        </Typography>
                        <Typography variant="body1">
                            <strong>Total Items:</strong> {aggregatedItemCount}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Total Volume:</strong> {aggregatedVolume.toFixed(2)} ft³
                        </Typography>
                    </Paper>
                </Grid>
                {/* Right Column */}
                <Grid item xs={12} md={8}>
                    <Paper sx={{ p: 2, mb: 2 }}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Space Usage by Period
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Typography variant="caption">Move Out</Typography>
                            <CapacityBar containerId={container.id} period="moveOut" />
                            <Typography variant="caption">Summer</Typography>
                            <CapacityBar containerId={container.id} period="summer" />
                            <Typography variant="caption">Move In</Typography>
                            <CapacityBar containerId={container.id} period="moveIn" />
                        </Box>
                    </Paper>
                    <Typography
                        variant="subtitle2"
                        gutterBottom
                        sx={{ color: 'text.secondary', textTransform: 'uppercase' }}
                    >
                        Customers
                    </Typography>
                    <ContainerCustomersTable container={container} collapsable />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ContainerDetails;
