import React, { useState } from 'react';
import { Box, Container, Typography, CircularProgress, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import EmailIcon from '@mui/icons-material/Email';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Helper hook to parse query parameters.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// Call the verifyEmail callable Cloud Function.
async function verifyEmail({ token, uid }) {
    const functions = getFunctions();
    const verifyEmailCallable = httpsCallable(functions, 'verifyEmail');
    const result = await verifyEmailCallable({ token, uid });
    return result.data; // result.data should contain { success, message }
}

const VerifyEmail = () => {
    const query = useQuery();
    const token = query.get('token');
    const uid = query.get('uid');
    const [status, setStatus] = useState('idle'); // 'idle', 'verifying', 'success', 'error'
    const [message, setMessage] = useState('');

    const performVerification = async () => {
        if (!token || !uid) {
            setStatus('error');
            setMessage('Missing token or user id.');
            return;
        }
        setStatus('verifying');
        try {
            const result = await verifyEmail({ token, uid });
            if (result.success) {
                setStatus('success');
                setMessage('Your email has been verified successfully!');
            } else {
                setStatus('error');
                setMessage(result.message || 'Verification failed. Please try again.');
            }
        } catch (error) {
            console.error('Verification error:', error);
            setStatus('error');
            setMessage('An error occurred during verification. Please try again.');
        }
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <NavBar />
            <Container
                sx={{
                    width: "100%",
                    mt: 8,
                    mb: 8,
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center',
                        mt: 4,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        maxWidth: 400
                    }}
                >
                    <EmailIcon sx={{ fontSize: 80, color: 'primary.main', mb: 2 }} />
                    {status === 'idle' && (
                        <>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Click the button below to verify your email.
                            </Typography>
                            <Button variant="contained" color="primary" onClick={performVerification}>
                                Verify Email
                            </Button>
                        </>
                    )}
                    {status === 'verifying' && (
                        <Box>
                            <CircularProgress />
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                Verifying your email address...
                            </Typography>
                        </Box>
                    )}
                    {status === 'success' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                Email Verified!
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {message}
                            </Typography>
                            <Button variant="contained" color="primary" href="/login" sx={{ mt: 2 }}>
                                Proceed to Login
                            </Button>
                        </>
                    )}
                    {status === 'error' && (
                        <>
                            <Typography variant="h4" gutterBottom>
                                Verification Failed
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {message}
                            </Typography>
                            <Button variant="contained" color="primary" onClick={performVerification} sx={{ mt: 2 }}>
                                Try Again
                            </Button>
                        </>
                    )}
                </Box>
            </Container>
            <Footer />
        </Box>
    );
};

export default VerifyEmail;
