import React from 'react';
import { Box, Paper, Typography, Button, Stack, Tooltip, Skeleton } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MessageIcon from '@mui/icons-material/Message';
import CallIcon from '@mui/icons-material/Call';

const AppointmentTabCoordinator = ({ coordinatorName, coordinatorEmail, coordinatorPhone, loading }) => {
    return (
        <Box>
            <Paper
                elevation={3}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    overflow: 'hidden',
                    flex: 1,
                    borderRadius: 2,
                }}
            >
                {/* Header */}
                <Box
                    sx={{
                        backgroundImage: 'url(/images/background_pattern.webp)',
                        backgroundColor: 'primary.main',
                        px: 3,
                        py: 2,
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <PersonOutlineIcon sx={{ fontSize: 48, mb: 1 }} />
                    <Typography variant="h6" gutterBottom>
                        Storage Coordinator
                    </Typography>
                </Box>

                {/* Content */}
                <Box sx={{ px: 3, py: 4, flexGrow: 1 }}>
                    {loading ? (
                        <>
                            <Skeleton variant="text" width="80%" height={30} />
                            <Skeleton variant="text" width="70%" />
                            <Skeleton variant="text" width="60%" />
                        </>
                    ) : (
                        <>
                            <Typography variant="body1" gutterBottom>
                                {coordinatorName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Email: {coordinatorEmail || 'N/A'}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Phone: {coordinatorPhone || 'N/A'}
                            </Typography>
                        </>
                    )}
                </Box>

                {/* Actions */}
                <Box sx={{ px: 3, pb: 2 }}>
                    <Stack direction="row" spacing={1} justifyContent="center">
                        <Tooltip title="Message Coordinator">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<MessageIcon />}
                                href={`mailto:${coordinatorEmail}`}
                                disabled={loading || !coordinatorEmail}
                                size="medium"
                            >
                                Message
                            </Button>
                        </Tooltip>
                        <Tooltip title="Call Coordinator">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<CallIcon />}
                                href={`tel:${coordinatorPhone}`}
                                disabled={loading || !coordinatorPhone}
                                size="medium"
                            >
                                Call
                            </Button>
                        </Tooltip>
                    </Stack>
                </Box>
            </Paper>
        </Box>
    );
};

export default AppointmentTabCoordinator;
    