import React, { useEffect, useState, useRef } from 'react';
import { List, ListItem, Typography, Paper, Divider, Box } from '@mui/material';
import { getFirestore, collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

const MessageFeed = ({ ticketID, expanded }) => {
    const [messages, setMessages] = useState([]);
    const db = getFirestore();
    const { currentUser } = useAuth();
    // Reference for the MessageFeed container
    const feedContainerRef = useRef(null);

    useEffect(() => {
        const messagesRef = collection(db, 'tickets', ticketID, 'messages');
        const q = query(messagesRef, orderBy('timestamp', 'asc'));
        const unsubscribe = onSnapshot(q, snapshot => {
            const msgs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setMessages(msgs);
        }, error => {
            console.error("Error fetching messages:", error);
        });
        return () => unsubscribe();
    }, [db, ticketID]);

    // Always scroll the feed container to the bottom internally when new messages arrive.
    useEffect(() => {
        if (feedContainerRef.current) {
            feedContainerRef.current.scrollTop = feedContainerRef.current.scrollHeight;
        }
    }, [messages]);

    // When expanded, scroll the entire page to the bottom.
    useEffect(() => {
        if (expanded) {
            window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
        }
    }, [expanded]);

    return (
        <Paper
            ref={feedContainerRef}
            sx={{
                p: 2,
                maxHeight: expanded ? 'none' : 300,
                overflowY: 'auto',
                backgroundColor: '#f9f9f9',
                transition: 'max-height 0.3s ease-in-out'
            }}
        >
            <List>
                {messages.map(message => {
                    const isCurrentUser = currentUser && message.senderID === currentUser.uid;
                    return (
                        <React.Fragment key={message.id}>
                            <ListItem
                                alignItems="flex-start"
                                sx={{
                                    display: 'flex',
                                    justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: '75%',
                                        p: 1.5,
                                        borderRadius: 2,
                                        backgroundColor: isCurrentUser ? '#d1e7fd' : '#e0e0e0',
                                    }}
                                >
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        {message.senderName || "Admin"}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        variant="caption"
                                        color="text.secondary"
                                        sx={{ display: 'block', mb: 0.5 }}
                                    >
                                        {message.timestamp?.toDate
                                            ? new Date(message.timestamp.toDate()).toLocaleString()
                                            : 'Sending...'}
                                    </Typography>
                                    <div dangerouslySetInnerHTML={{ __html: message.content }} />
                                </Box>
                            </ListItem>
                            <Divider component="li" />
                        </React.Fragment>
                    );
                })}
                {messages.length === 0 && (
                    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
                        No messages yet.
                    </Typography>
                )}
            </List>
        </Paper>
    );
};

export default MessageFeed;
