import React from 'react';
import { Box, Typography, Container, Grid, Button } from '@mui/material';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const Careers = () => {
    const jobListings = [
        {
            title: 'Mover',
            imageUrl: '/images/movers.jpg', // update with your actual image path
            pay: '$35 per move',
            description: `Work as an independent contractor with a flexible schedule! Each move takes 10–25 minutes on average.
      You can pick up as many shifts as you’d like. Perfect for students seeking quick, part-time opportunities.`
        },
        {
            title: 'Storage Coordinator',
            imageUrl: '/images/movers.jpg', // update with your actual image path
            pay: 'Competitive hourly rate',
            description: `As a Storage Coordinator (part-time employee), you'll manage the relationship between SummerStore and our customers.
      Oversee inventory, coordinate drop-offs and retrievals at on-campus storage containers, and distribute packing supplies.
      We're looking for detail-oriented individuals with excellent communication skills.`
        }
    ];

    const scrollToPositions = () => {
        const element = document.getElementById("positions");
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <NavBar />

            {/* Full-Height Hero Section */}
            <Container
                maxWidth={false}
                disableGutters
                sx={{
                    backgroundColor: 'primary.main',
                    color: 'white',
                    height: '100vh',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    py: 5,
                }}
            >
                {/* Background Pattern Overlay */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'url(/images/BackgroundPatternBoxes-01.webp)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        opacity: 0.5,
                        zIndex: 0,
                    }}
                />

                {/* Hero Text & Button */}
                <Box
                    sx={{
                        zIndex: 1,
                        textAlign: 'center',
                        p: { xs: 3, md: 6 },
                        maxWidth: '800px'
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontFamily: 'Work Sans',
                            fontWeight: 'bold',
                            mb: 3
                        }}
                    >
                        Discover Opportunities at SummerStore
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 4, fontFamily: 'Work Sans' }}>
                        Join us and be a part of the next evolution in summer storage solutions!
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={scrollToPositions}
                    >
                        View Positions
                    </Button>
                </Box>
            </Container>

            {/* Job Listings Section */}
            <Container
                id="positions"
                maxWidth="xl"
                sx={{
                    position: 'relative',
                    minHeight: '100vh',
                    py: 8,
                    px: { xs: 2, md: 4 },
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {/* Background Grid Overlay for Positions */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'url(/images/background_pattern.webp)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        opacity: 0.35,
                        zIndex: 0,
                    }}
                />
                <Grid container spacing={4} sx={{ zIndex: 1, width: '100%' }}>
                    {jobListings.map((job, index) => (
                        <Grid item xs={12} md={6} key={index} sx={{ display: 'flex' }}>
                            <Box
                                sx={{
                                    p: 4,
                                    border: '1px solid #ccc',
                                    borderRadius: 2,
                                    boxShadow: 2,
                                    width: '100%',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between'
                                }}
                            >
                                {/* Job Image */}
                                <Box sx={{ mb: 2 }}>
                                    <img
                                        src={job.imageUrl}
                                        alt={job.title}
                                        style={{ width: '100%', borderRadius: '4px' }}
                                    />
                                </Box>
                                <Typography
                                    variant="h4"
                                    sx={{ fontFamily: 'Work Sans', fontWeight: 'bold', mb: 1 }}
                                >
                                    {job.title}
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.8 }}>
                                    {job.description}
                                </Typography>
                                {/* Bottom Row: Payment above Apply Button */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography
                                        variant="h5"
                                        sx={{ fontWeight: 'bold', color: 'primary.main', mb: 2 }}
                                    >
                                        {job.pay}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        href="mailto:careers@summerstore.org"
                                        sx={{ fontSize: '1rem', py: 1.5, px: 3 }}
                                    >
                                        Apply Now
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Footer />
        </Box>
    );
};

export default Careers;
