import React, { useState, Suspense, lazy } from 'react';
import { Box, Button, Typography, Container, Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import NavigationDots from '../components/NavigationDots';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const FAQ = lazy(() => import('../components/FAQ'));
const PriceComparison = lazy(() => import('../components/PriceComparison'));

const Home = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const howItWorksSteps = [
    {
      img: '/images/Section_1.webp',
      title: '1. Registration',
      description: 'Sign up for SummerStore to secure your appointment date. It takes less than 5 minutes! No payment required for registration.',
    },
    {
      img: '/images/Section_2.webp',
      title: '2. Supplies',
      description: "Ahead of move out, we'll give you everything you'll need to store. From tape to boxes, we've got you covered. No extra cost.",
    },
    {
      img: '/images/Section_3.webp',
      title: '3. Dropoff',
      description: 'Bring your packed items to your drop off location. Movers and hand trucks will be available to help move your items!',
    },
    {
      img: '/images/Section_4.webp',
      title: '4. Storage',
      description: 'We\'ve got the rest! Your items will be stored in a climate-safe & monitored facility.',
    },
    {
      img: '/images/Section_5.webp',
      title: '5. Pickup',
      description: 'In the fall, we will safely deliver your items to the same location for you to pick up!',
    },
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? howItWorksSteps.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === howItWorksSteps.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Box sx={{ mb: 0, height: '100vh' }}> 
      <NavigationDots sections={['top', 'how-it-works', 'pricing', 'faq']} sx={{display: { xs: 'none', md: 'block' }}}/>
      <Box id="top" sx={{ backgroundColor: 'primary.main', color: 'white', height: '100vh', overflow: 'hidden', position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'url(/images/background_pattern.webp)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            opacity: 0.25,
            zIndex: 0,
          }}
        />
        <Box
          component="div"
          sx={{
            display: {m: 'none', xs: 'block'},
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'url(/images/summerstore_movers_main.jpg)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            opacity: {xs: 0.2, md: 0.10},
            zIndex: 0,
          }}
        />
        <NavBar />
        <Container disableGutters maxWidth={false} sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', ml: 0, mr: 0 }}>
          <Grid container sx={{ height: '100%', zIndex: 2 }}>
            <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' }, padding: 0, height: '100%', position: 'relative', zIndex: 1 }}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '-2px',
                    right: '-2px',
                    width: '100%',
                    height: '100%',
                    borderTop: '5px solid white',
                    borderRight: '5px solid white',
                    boxSizing: 'border-box',
                    zIndex: 3,
                  },
                }}
              >
                <Box
                  component="img"
                  src="/images/summerstore_movers_main.jpg"
                  alt="Storage Image"
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    position: 'relative',
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ textAlign: 'left', padding: { xs: '0 50px', md: '0 100px' } }}>
                
                <Typography variant="h3" sx={{ mb: 3, fontFamily: 'Work Sans', fontWeight: 'bold' }}>
                  College storage made easy & affordable.
                </Typography>
                <Typography variant="h5" sx={{ mb: 6, fontFamily: 'Work Sans' }}>
                  We pick up, store, and deliver your stuff to campus.
                </Typography>
                <Button onClick={() => navigate('/registration')} variant="contained" color="secondary" sx={{ fontSize: '16px', padding: '16px 32px', width: '100%', fontWeight: 'bold', mb: 2 }}>Register for free</Button>
                <Button onClick={() => scrollToSection('how-it-works')} variant="outlined" color="inherit" sx={{ fontSize: '16px', color: 'white',  padding: '16px 32px', width: '100%', fontWeight: 'bold' }}>Learn More</Button>
              
                <Box sx={{mt: 6, textAlign: {xs: 'center', md: 'right'}, mb: 1}}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', md: 'flex-end' } }}>
                    <CalendarMonthIcon sx={{ mr: 1, color: 'main.secondary' }} />
                    <Typography variant="h5" sx={{ fontFamily: 'Work Sans', color: 'main.secondary'}}>
                      <strong>Registration Deadline:</strong>
                    </Typography>
                  </Box>
                  <Typography variant="h5" sx={{ mt: 1, fontFamily: 'Work Sans'}}>
                    April 17th
                  </Typography>
                  </Box>
              </Box> 
            </Grid>
          </Grid>
          <Box
            sx={{
              position: 'absolute',
              bottom: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '40px',
              height: '40px',
              backgroundColor: 'white',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              zIndex: 4,
            }}
            onClick={() => scrollToSection('how-it-works')}
          >
            <KeyboardArrowDownIcon sx={{ color: 'primary.main' }} />
          </Box>
        </Container>
      </Box>

      <Suspense fallback={<div>Loading...</div>}> 
        <Container
          id="how-it-works"
          maxWidth="lg"
          sx={{
            position: 'relative',
            height: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h4" align="center" sx={{ mb: 1, fontFamily: 'Work Sans', fontWeight: 600 }}>
            How it works
          </Typography>
          <Typography variant="body1" align="center" sx={{ mb: 4, fontFamily: 'Work Sans' }}>
            Learn how our service works in a few steps.
          </Typography>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <IconButton onClick={handlePrev} sx={{ p: 1, m: 2 }}>
              <ArrowBackIosIcon />
            </IconButton>
            <Box sx={{ textAlign: 'center', p: 3, height: '100%' }}>
              <Box
                component="img"
                src={howItWorksSteps[currentIndex].img}
                alt={howItWorksSteps[currentIndex].title + ' Image'}
                sx={{ height: '35vw', width: 'auto', mb: 2 }}
              />
              <Typography variant="h6" sx={{ mb: 1, fontFamily: 'Work Sans', fontWeight: 'bold' }}>
                {howItWorksSteps[currentIndex].title}
              </Typography>
              <Typography variant="body2">
                {howItWorksSteps[currentIndex].description}
              </Typography>
            </Box>
            <IconButton onClick={handleNext} sx={{ p: 1, m: 2  }}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
            {howItWorksSteps.map((step, index) => (
              <Box key={index} sx={{ textAlign: 'center', p: 3, height: '100%' }}>
                <Box
                  component="img"
                  src={step.img}
                  alt={step.title + ' Image'}
                  sx={{ height: '12vw', width: 'auto', mb: 2 }}
                />
                <Typography variant="h6" sx={{ mb: 1, fontFamily: 'Work Sans', fontWeight: 'bold' }}>
                  {step.title}
                </Typography>
                <Typography variant="body2">
                  {step.description}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '50px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '40px',
              height: '40px',
              backgroundColor: 'primary.main',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              zIndex: 4,
            }}
            onClick={() => scrollToSection('pricing')}
          >
            <KeyboardArrowDownIcon sx={{ color: 'white' }} />
          </Box>
        </Container>
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <Box id="pricing" sx={{ display: 'flex', 
              flexDirection: { xs: 'column', md: 'row' },
              mb: 2,
              mx: 0,
              }}>
          <Box sx={{ flex: 1, backgroundColor: 'primary.main', color: 'white', py: 8, px: 2,  display: 'flex', justifyContent: 'center', alignItems: 'center', 
              backgroundImage: 'url(/images/background_pattern.webp)',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: {xs: "80vh", m: 'unset'},
              }}>
            <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
              <Typography variant="h4" sx={{ mb: 2, fontFamily: 'Work Sans', fontWeight: 'bold' }}>
                Our Pricing
              </Typography>
              <Typography variant="h5" sx={{ mb: 2 }}>
                We offer pricing plans that include packing supplies, moving services, and secure storage for 4-months <strong>for as low as $22 per item</strong>.
              </Typography>
              <Typography variant="h6" sx={{ mb: 4 }}>
                We do not charge extra for item type or size.
              </Typography>
              <Button onClick={() => navigate('/pricing')} variant="contained" color="secondary" sx={{ fontSize: '14px', padding: '16px 32px', width: '60%', fontWeight: 'bold', mb: 2 }}>
                View Plans
              </Button>
                  
            </Container>
          </Box>
          <Box sx={{ flex: 1, backgroundColor: 'white', p: {m: 2, xs: 0} }}>
            <Container maxWidth="lg">
              <Typography variant="h4" sx={{ mt: 3, mb: 2, fontFamily: 'Work Sans', color: 'primary.main' }}>
                If you store <strong>10 items </strong> with...
              </Typography>
              <Suspense fallback={<div>Loading...</div>}>
                <PriceComparison />
              </Suspense>
            </Container>
          </Box>
        </Box>
      </Suspense>

      <Container id="faq" maxWidth={false}
       sx={{ mt: 8, mb: 8, px: 8,  
       display: "flex", 
       flexDirection: "column", 
       justifyContent: "center",
       alignItems: "center",
       width: {lg: "80%", xs: "100%"}
    
       }}>
        <Suspense fallback={<div>Loading...</div>}>

          <Typography variant="h4" align="left" sx={{ mb: 3, fontWeight: 'bold' }}>
            Frequently Asked Questions
          </Typography>
          <FAQ/>
        </Suspense>
      </Container>
      <Footer id="footer" />
    </Box>
  );
};

export default Home;
