import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Tooltip,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  styled,
  TextField,
} from '@mui/material';
import {
  Edit,
  Delete,
  CalendarToday,
  MoreVert,
  Inventory2,
  ContentCopy,
  ExpandMore,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  onSnapshot,
  collectionGroup,
  getDocs,
} from 'firebase/firestore';
import { db } from '../firebase';
import ContainerTable from './ContainerTable';

// Styled component for the expand more icon with rotation
const ExpandMoreIconButton = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// Status functions
const getStatus = (parking) => {
  if (!parking.meterBagsNeeded) {
    return 'none';
  }
  if (parking.meterBags.some((bag) => !bag.requested)) {
    return 'alert';
  }
  if (parking.meterBags.some((bag) => bag.requested && !bag.reserved)) {
    return 'warning';
  }
  return 'none';
};

const getStatusColor = (status) => {
  switch (status) {
    case 'warning':
      return '#FB9D04';
    case 'alert':
      return 'red';
    default:
      return 'primary.main';
  }
};

const getStatusMessage = (status) => {
  switch (status) {
    case 'warning':
      return 'Bags requested but not reserved.';
    case 'alert':
      return 'Bags still unrequested.';
    default:
      return 'N/A';
  }
};

const ContainerLocationList = ({ universityID }) => {
  const navigate = useNavigate();
  const [containerLocations, setContainerLocations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuLocation, setMenuLocation] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [expanded, setExpanded] = useState({}); // Tracks expansion state per location
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  useEffect(() => {
    // Reference to the containerLocations collection
    const containerLocationsRef = collection(db, `universities/${universityID}/containerLocations`);

    // Set up the real-time listener for containerLocations
    const unsubscribeContainerLocations = onSnapshot(
      containerLocationsRef,
      (snapshot) => {
        const locations = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setContainerLocations(locations);
      },
      (error) => {
        console.error('Error fetching container locations:', error);
        setSnackbar({
          open: true,
          message: 'Error fetching container locations.',
          severity: 'error',
        });
      }
    );

    // Set up the real-time listener for appointments using collectionGroup
    const appointmentsRef = collectionGroup(db, 'appointments');

    const unsubscribeAppointments = onSnapshot(
      appointmentsRef,
      (snapshot) => {
        const appointments = snapshot.docs.map((doc) => {
          const appointmentData = doc.data();
          const parentLocationId = doc.ref.parent.parent.id; // Get the parent containerLocation ID
          return {
            id: doc.id,
            containerLocationId: parentLocationId,
            ...appointmentData,
          };
        });

        // Process appointments to compute counts
        const locationStats = {};

        appointments.forEach((appointment) => {
          const { containerLocationId, customers, maxCustomers } = appointment;

          if (!locationStats[containerLocationId]) {
            locationStats[containerLocationId] = {
              numberOfAppointments: 0,
              numberOfCustomers: 0,
              maxCapacity: 0,
            };
          }

          locationStats[containerLocationId].numberOfAppointments += 1;
          locationStats[containerLocationId].numberOfCustomers += Array.isArray(customers) ? customers.length : 0;
          locationStats[containerLocationId].maxCapacity += maxCustomers;
        });

        // Update containerLocations with the computed stats
        setContainerLocations((prevLocations) =>
          prevLocations.map((location) => ({
            ...location,
            numberOfAppointments: locationStats[location.id]?.numberOfAppointments || 0,
            numberOfCustomers: locationStats[location.id]?.numberOfCustomers || 0,
            maxCapacity: locationStats[location.id]?.maxCapacity || 0,
          }))
        );
      },
      (error) => {
        console.error('Error fetching appointments:', error);
        setSnackbar({
          open: true,
          message: 'Error fetching appointments.',
          severity: 'error',
        });
      }
    );

    // Cleanup listeners on unmount
    return () => {
      unsubscribeContainerLocations();
      unsubscribeAppointments();
    };
  }, [universityID]);

  // Precompute a mapping of memoized container location ID arrays
  const memoizedContainerIDs = React.useMemo(() => {
    const mapping = {};
    containerLocations.forEach((location) => {
      mapping[location.id] = [location.id];
    });
    return mapping;
  }, [containerLocations]);

  const handleMenuOpen = (event, location) => {
    event.stopPropagation(); // Prevent toggling collapse
    setAnchorEl(event.currentTarget);
    setMenuLocation(location);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuLocation(null);
  };

  const handleDelete = (location) => {
    setLocationToDelete(location);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (!locationToDelete) return;

    try {
      const locationRef = doc(db, `universities/${universityID}/containerLocations`, locationToDelete.id);
      await deleteDoc(locationRef);

      setSnackbar({
        open: true,
        message: 'Location deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting location:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete location.',
        severity: 'error',
      });
    } finally {
      setOpenConfirmDialog(false);
      setLocationToDelete(null);
      handleMenuClose();
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setLocationToDelete(null);
  };

  const handleDuplicateLocation = async (location) => {
    try {
      // Clone the location data excluding 'id'
      const { id, ...locationData } = location;

      // Modify the name to indicate duplication
      const duplicatedName = `${locationData.name} (Copy)`;

      // Add the duplicated location to Firestore
      const newLocationRef = await addDoc(
        collection(db, `universities/${universityID}/containerLocations`),
        {
          ...locationData,
          name: duplicatedName,
        }
      );

      // Fetch all appointments from the original container location
      const originalAppointmentsRef = collection(db, `universities/${universityID}/containerLocations/${id}/appointments`);
      const appointmentsSnapshot = await getDocs(originalAppointmentsRef);

      // Reference to the appointments subcollection of the duplicated location
      const duplicatedAppointmentsRef = collection(db, `universities/${universityID}/containerLocations/${newLocationRef.id}/appointments`);

      // Duplicate each appointment with an empty customers array
      const duplicatePromises = appointmentsSnapshot.docs.map(async (appointmentDoc) => {
        const appointmentData = appointmentDoc.data();
        const { customers, ...restAppointmentData } = appointmentData;
        await addDoc(duplicatedAppointmentsRef, {
          ...restAppointmentData,
          customers: [],
        });
      });

      await Promise.all(duplicatePromises);

      setSnackbar({
        open: true,
        message: 'Location and its appointments duplicated successfully!',
        severity: 'success',
      });

      handleMenuClose();
    } catch (error) {
      console.error('Error duplicating location and appointments:', error);
      setSnackbar({
        open: true,
        message: 'Failed to duplicate location.',
        severity: 'error',
      });
      handleMenuClose();
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleExpandClick = (locationId) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [locationId]: !prevExpanded[locationId],
    }));
  };

  // Handler for search input changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter container locations based on search query
  const filteredContainerLocations = containerLocations.filter((location) =>
    location.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (location.locationGroup && location.locationGroup.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <Box>
      {/* Search Bar */}
      <Box sx={{ mb: 3 }}>
        <TextField
          label="Search Locations"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search by name or group..."
          InputProps={{
            endAdornment: <Inventory2 />,
          }}
        />
      </Box>

      {/* Container Locations List */}
      {filteredContainerLocations.length > 0 ? (
        filteredContainerLocations.map((location) => {
          const status = getStatus(location.parking);
          return (
            <Paper
              sx={{
                p: 2,
                mb: 2,
                borderLeft: `4px solid`,
                borderColor: getStatusColor(status),
              }}
              key={location.id}
            >
              {/* Header Area - Click here to toggle expansion */}
              <Box
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => handleExpandClick(location.id)}
              >
                {/* Dropdown Arrow */}
                <ExpandMoreIconButton
                  expand={expanded[location.id]}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleExpandClick(location.id);
                  }}
                  aria-expanded={expanded[location.id]}
                  aria-label="show more"
                >
                  <ExpandMore />
                </ExpandMoreIconButton>

                {/* Location Name and Subtitle */}
                <Box sx={{ flexGrow: 1, ml: 1 }}>
                  <Typography variant="h6">{location.name}</Typography>
                  {location.locationGroup && (
                    <Typography variant="subtitle2" color="textSecondary">
                      {location.locationGroup}
                    </Typography>
                  )}
                </Box>

                {/* Status Display */}
                <Typography variant="body2" sx={{ color: getStatusColor(status), fontWeight: 'bold' }}>
                  {getStatusMessage(status)}
                </Typography>

                {/* Action Buttons */}
                <Box sx={{ ml: 3 }}>
                  <Tooltip title="Edit Location">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`${location.id}`);
                        handleMenuClose();
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="View Appointments">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`${location.id}/appointments`);
                      }}
                    >
                      <CalendarToday />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="View Containers">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`${location.id}/containers`);
                      }}
                    >
                      <Inventory2 />
                    </IconButton>
                  </Tooltip>

                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMenuOpen(e, location);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </Box>
              </Box>

              {/* Collapsible Section */}
              <Collapse in={expanded[location.id]} timeout="auto" unmountOnExit>
                <Box
                  sx={{ mt: 2 }}
                  onClick={(e) => e.stopPropagation()} // Prevent clicks inside from toggling collapse
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body1">Appointments:</Typography>
                      <Typography variant="body2">{location.numberOfAppointments}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body1">Customers:</Typography>
                      <Typography variant="body2">{location.numberOfCustomers}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body1">Max Capacity:</Typography>
                      <Typography variant="body2">{location.maxCapacity}</Typography>
                    </Grid>
                  </Grid>

                  {/* Render ContainerTable for this specific location using memoized container IDs */}
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Containers for {location.name}
                    </Typography>
                    <ContainerTable
                      universityID={universityID}
                      containerLocationIDs={memoizedContainerIDs[location.id]}
                      hideButtons
                    />
                  </Box>
                </Box>
              </Collapse>
            </Paper>
          );
        })
      ) : (
        <Typography variant="body1">No container locations found.</Typography>
      )}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <Box sx={{ px: 2, pt: 1 }}>
          <Typography variant="subtitle2" color="textSecondary">
            Actions
          </Typography>
        </Box>
        <MenuItem
          onClick={() => {
            navigate(`${menuLocation?.id}`);
            handleMenuClose();
          }}
        >
          <Edit sx={{ mr: 1 }} /> Edit Location
        </MenuItem>
        <MenuItem onClick={() => handleDelete(menuLocation)}>
          <Delete sx={{ mr: 1 }} /> Delete Location
        </MenuItem>
        <MenuItem onClick={() => handleDuplicateLocation(menuLocation)}>
          <ContentCopy sx={{ mr: 1 }} /> Duplicate Location
        </MenuItem>
        <Box sx={{ my: 1 }}>
          <hr style={{ border: 'none', borderTop: '1px solid #ddd', margin: 0 }} />
        </Box>
        <MenuItem
          onClick={() => {
            navigate(`/appointments/${menuLocation?.id}`);
            handleMenuClose();
          }}
        >
          <CalendarToday sx={{ mr: 1 }} /> View Appointments
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(`/containers/${menuLocation?.id}`);
            handleMenuClose();
          }}
        >
          <Inventory2 sx={{ mr: 1 }} /> View Containers
        </MenuItem>
      </Menu>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCancelDelete}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
      >
        <DialogTitle id="confirm-delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog-description">
            Are you sure you want to delete the location "<strong>{locationToDelete?.name}</strong>"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContainerLocationList;
