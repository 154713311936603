import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { db } from '../firebase';

const PageNotificationContext = createContext();

export const usePageNotifications = () => useContext(PageNotificationContext);

export const PageNotificationProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const [notifications, setNotifications] = useState({});

    useEffect(() => {
        if (!currentUser) return;

        const fetchNotifications = async () => {
            const userRef = doc(db, 'users', currentUser.uid);
            const userSnap = await getDoc(userRef);

            if (!userSnap.exists()) {
                console.log('No user data found');
                return;
            }

            const userData = userSnap.data();
            const shownNotifications = userData.shownNotifications || [];
            let notificationCounts = {};

            for (const notifId of shownNotifications) {
                const notifRef = doc(db, 'notifications', notifId);
                const notifSnap = await getDoc(notifRef);

                if (notifSnap.exists()) {
                    const notifData = notifSnap.data();
                    if (notifData.pageNotification) {
                        const page = notifData.pageNotification;
                        notificationCounts[page] = (notificationCounts[page] || 0) + 1;
                    }
                }
            }

            setNotifications(notificationCounts);
        };

        fetchNotifications();
    }, [currentUser]);

    return (
        <PageNotificationContext.Provider value={{ notifications }}>
            {children}
        </PageNotificationContext.Provider>
    );
};
