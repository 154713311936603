import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Switch,
  FormControlLabel,
  Skeleton,
  InputAdornment
} from '@mui/material';
import {
  AccountCircle,
  Payment,
  Notifications,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Lock as LockIcon
} from '@mui/icons-material';
import InputMask from 'react-input-mask';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../contexts/NotificationContext';
import { db } from '../../firebase';
import AccountBillingSettings from '../../components/AccountBillingSettings'; // adjust path as necessary

const AccountSettings = () => {
  const navigate = useNavigate();
  const functions = getFunctions();
  const { addNotification } = useNotification();
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);

  // Profile state
  const [profileData, setProfileData] = useState({
    email: '',
    phone: '',
  });

  // Security state
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  // Notifications state
  const [notificationSettings, setNotificationSettings] = useState({
    emailNotifications: true,
    smsNotifications: false,
  });

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const docRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setProfileData({
              email: data.email || '',
              phone: data.phone || '',
            });
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setDataLoading(false);
        }
      } else {
        setDataLoading(false);
      }
    };

    fetchUserData();
  }, [user]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleProfileChange = (event) => {
    setProfileData({ ...profileData, [event.target.name]: event.target.value });
  };

  const handlePasswordChange = (event) => {
    setPasswordData({ ...passwordData, [event.target.name]: event.target.value });
  };

  const handleNotificationChange = (event) => {
    setNotificationSettings({
      ...notificationSettings,
      [event.target.name]: event.target.checked
    });
  };

  const saveProfileChanges = async () => {
    if (!profileData.email.trim() || !profileData.phone.trim()) {
      addNotification({ status: 'error', message: 'Both email and phone number are required.' });
      return;
    }
    setLoading(true);
    try {
      const updateProfileFunction = httpsCallable(functions, 'updateUserProfile');
      const result = await updateProfileFunction({
        uid: user.uid,
        profileData
      });
      if (result.data.success) {
        addNotification({ status: 'success', message: 'Profile updated successfully.' });
      } else {
        addNotification({ status: 'error', message: 'Failed to update profile. Please try again.' });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      addNotification({ status: 'error', message: error.message || 'Failed to update profile. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const savePasswordChange = async () => {
    setLoading(true);
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      addNotification({ status: 'error', message: 'New passwords do not match.' });
      setLoading(false);
      return;
    }
    try {
      if (user) {
        const credential = EmailAuthProvider.credential(user.email, passwordData.currentPassword);
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, passwordData.newPassword);
        setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '' });
        addNotification({ status: 'success', message: 'Password updated successfully!' });
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      if (error.code === 'auth/wrong-password') {
        addNotification({ status: 'error', message: 'The current password is incorrect.' });
      } else if (error.code === 'auth/weak-password') {
        addNotification({ status: 'error', message: 'Password should be at least 6 characters.' });
      } else if (error.code === 'auth/requires-recent-login') {
        addNotification({ status: 'error', message: 'Please log in again and try updating the password.' });
      } else {
        addNotification({ status: 'error', message: 'Failed to update password. Please try again.' });
      }
    } finally {
      setLoading(false);
    }
  };

  const saveNotificationSettings = async () => {
    setLoading(true);
    try {
      const updateNotificationFunction = httpsCallable(functions, 'updateNotificationSettings');
      const result = await updateNotificationFunction({ uid: user.uid, notificationSettings });
      if (result.data.success) {
        addNotification({ status: 'success', message: 'Notification settings updated successfully.' });
      } else {
        addNotification({ status: 'error', message: 'Failed to update notification settings.' });
      }
    } catch (error) {
      console.error('Error updating notification settings:', error);
      addNotification({ status: 'error', message: 'Failed to update notification settings.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Account Settings
      </Typography>
      <Paper sx={{ width: '100%', mt: 2 }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab icon={<AccountCircle />} label="Profile" />
          <Tab icon={<Payment />} label="Billing" />
          <Tab icon={<Notifications />} label="Notifications" />
        </Tabs>
      </Paper>
      <Paper sx={{ p: 3, mt: 2 }}>
        {tabIndex === 0 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Profile Settings
            </Typography>
            {dataLoading ? (
              <>
                <Skeleton variant="text" width="60%" height={40} sx={{ mb: 2 }} />
                <Skeleton variant="text" width="80%" height={40} sx={{ mb: 2 }} />
                <Skeleton variant="rectangular" width="30%" height={40} />
              </>
            ) : (
              <>
                <TextField
                  label="Email Address"
                  name="email"
                  value={profileData.email}
                  onChange={handleProfileChange}
                  fullWidth
                  margin="normal"
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <InputMask
                  mask="9 (999) 999-9999"
                  value={profileData.phone}
                  onChange={handleProfileChange}
                >
                  {() => (
                    <TextField
                      label="Phone Number"
                      name="phone"
                      fullWidth
                      margin="normal"
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                </InputMask>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveProfileChanges}
                  sx={{ mt: 2 }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Save Profile'}
                </Button>
              </>
            )}

            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              Security Settings
            </Typography>
            <TextField
              label="Current Password"
              name="currentPassword"
              type="password"
              value={passwordData.currentPassword}
              onChange={handlePasswordChange}
              fullWidth
              margin="normal"
              autoComplete="new-password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="New Password"
              name="newPassword"
              type="password"
              value={passwordData.newPassword}
              onChange={handlePasswordChange}
              fullWidth
              margin="normal"
              autoComplete="new-password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label="Confirm New Password"
              name="confirmPassword"
              type="password"
              value={passwordData.confirmPassword}
              onChange={handlePasswordChange}
              fullWidth
              margin="normal"
              autoComplete="new-password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                )
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={savePasswordChange}
              sx={{ mt: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Change Password'}
            </Button>
          </Box>
        )}
        {tabIndex === 1 && (
          <AccountBillingSettings user={user} />
        )}
        {tabIndex === 2 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Notification Settings
            </Typography>
            {dataLoading ? (
              <>
                <Skeleton variant="text" width="60%" height={40} sx={{ mb: 2 }} />
                <Skeleton variant="rectangular" width="30%" height={40} sx={{ mb: 2 }} />
              </>
            ) : (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={notificationSettings.emailNotifications}
                      onChange={handleNotificationChange}
                      name="emailNotifications"
                    />
                  }
                  label="Email Notifications"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={notificationSettings.smsNotifications}
                      onChange={handleNotificationChange}
                      name="smsNotifications"
                    />
                  }
                  label="SMS Notifications"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveNotificationSettings}
                  sx={{ mt: 2 }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Save Notification Settings'}
                </Button>
              </>
            )}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default AccountSettings;
