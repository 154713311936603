import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Grid,
    Paper,
    Typography,
    IconButton,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    List,
    ListItem,
    ListItemText,
    Divider,
    Button,
    Badge,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Chip,
    Checkbox,
    Menu,
    MenuItem,
    ListItemIcon,
    Tabs,
    Tab,
    Avatar,
    Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { getFirestore, collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import IconPicker, { IconPickerItem } from 'react-icons-picker';

// Define default filters with icon color
const defaultFilters = [
    { id: 'all', name: 'All', icon: 'MdFilterList', iconColor: '#1976d2' },
    { id: 'default-unassigned', name: 'Unassigned', icon: 'MdOutlineMarkEmailRead', iconColor: '#1976d2' },
    { id: 'default-assignedToMe', name: 'Assigned to me', icon: 'MdAssignmentInd', iconColor: '#1976d2' },
    { id: 'default-archive', name: 'Archive', icon: 'MdArchive', iconColor: 'gray' },
];

// Helper function to determine chip color based on priority
const getPriorityChipColor = (priority) => {
    switch (priority.toLowerCase()) {
        case 'high':
            return 'error';
        case 'medium':
            return 'warning';
        case 'low':
            return 'success';
        default:
            return 'default';
    }
};

// Helper function to get initials from a name
const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    if (nameParts.length === 1) return nameParts[0].charAt(0).toUpperCase();
    return nameParts[0].charAt(0).toUpperCase() + nameParts[nameParts.length - 1].charAt(0).toUpperCase();
};

// Helper function to count tickets by filter (or status)
const countTicketsForFilter = (filterName, ticketsList) => {
    if (filterName === 'All') {
        return ticketsList.length;
    }
    return ticketsList.filter(ticket => ticket.tag === filterName).length;
};

// Assume the current admin's user id is known:
const adminId = "admin-uid";

const CustomerTickets = () => {
    const [tickets, setTickets] = useState([]);
    const [customTags, setCustomTags] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [searchQuery, setSearchQuery] = useState('');
    const [newTagName, setNewTagName] = useState('');
    const [newTagIcon, setNewTagIcon] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [tagToRemove, setTagToRemove] = useState(null);
    const [selectedTickets, setSelectedTickets] = useState([]);
    const [selectedTab, setSelectedTab] = useState('open');

    // Define tab mappings.
    const ticketTabs = [
        { value: 'open', label: 'Open', icon: <MarkEmailUnreadIcon />, tag: 'Unassigned' },
        { value: 'inprogress', label: 'In Progress', icon: <AssignmentIndIcon />, tag: 'Assigned to me' },
        { value: 'closed', label: 'Closed', icon: <ArchiveIcon />, tag: 'Archive' },
    ];

    const db = getFirestore();
    const navigate = useNavigate();

    // Fetch tickets from Firebase
    useEffect(() => {
        const fetchTickets = async () => {
            const ticketsRef = collection(db, 'tickets');
            const snapshot = await getDocs(ticketsRef);
            const fetchedTickets = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setTickets(fetchedTickets);
        };
        fetchTickets();
    }, [db]);

    // Fetch tags from Firebase
    useEffect(() => {
        const fetchTags = async () => {
            const tagsRef = collection(db, 'ticketTags');
            const snapshot = await getDocs(tagsRef);
            const fetchedTags = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCustomTags(fetchedTags);
        };
        fetchTags();
    }, [db]);

    const countUnreadTicketsForFilter = (filterName) => {
        if (filterName === 'All') {
            return tickets.filter(ticket => !ticket.read?.includes(adminId)).length;
        }
        return tickets.filter(ticket => ticket.tag === filterName && !ticket.read?.includes(adminId)).length;
    };

    const countUnreadTicketsForStatus = (statusTag) => {
        return tickets.filter(ticket => ticket.tag === statusTag && !ticket.read?.includes(adminId)).length;
    };

    // When clicking a ticket, mark it read (if needed) and navigate to the ticket detail page.
    const handleTicketClick = (ticketId) => {
        // Mark as read if not already.
        setTickets((prevTickets) =>
            prevTickets.map((ticket) => {
                if (ticket.id === ticketId && !ticket.read?.includes(adminId)) {
                    return { ...ticket, read: [...(ticket.read || []), adminId] };
                }
                return ticket;
            })
        );
        navigate(`${ticketId}`);
    };

    const handleToggleTicket = (ticketId) => {
        setSelectedTickets((prevSelected) =>
            prevSelected.includes(ticketId)
                ? prevSelected.filter((id) => id !== ticketId)
                : [...prevSelected, ticketId]
        );
    };

    const handleToggleAll = () => {
        const filteredTickets = tickets.filter(ticket => ticket.tag === ticketTabs.find(tab => tab.value === selectedTab)?.tag);
        if (selectedTickets.length === filteredTickets.length) {
            setSelectedTickets([]);
        } else {
            setSelectedTickets(filteredTickets.map(ticket => ticket.id));
        }
    };

    const handleArchiveTickets = () => {
        console.log('Archive tickets:', selectedTickets);
    };

    const handleAssignTickets = () => {
        console.log('Assign tickets:', selectedTickets);
    };

    const handleAddTag = async () => {
        if (!newTagName.trim() || !newTagIcon) return;
        const tag = { name: newTagName.trim(), icon: newTagIcon };
        try {
            const tagsRef = collection(db, 'ticketTags');
            const docRef = await addDoc(tagsRef, tag);
            setCustomTags(prev => [...prev, { id: docRef.id, ...tag }]);
            setNewTagName('');
            setNewTagIcon(null);
        } catch (error) {
            console.error('Error adding tag:', error);
        }
    };

    const handleRemoveTag = (tag) => {
        setTagToRemove(tag);
        setConfirmDialogOpen(true);
    };

    const confirmRemoveTag = async () => {
        if (!tagToRemove) return;
        try {
            await deleteDoc(doc(db, 'ticketTags', tagToRemove.id));
            setCustomTags(prev => prev.filter(tag => tag.id !== tagToRemove.id));
        } catch (error) {
            console.error('Error removing tag:', error);
        } finally {
            setConfirmDialogOpen(false);
            setTagToRemove(null);
        }
    };

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [menuTicketId, setMenuTicketId] = useState(null);

    const handleMenuOpen = (event, ticketId) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setMenuTicketId(ticketId);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setMenuTicketId(null);
    };

    const handleToggleReadStatus = () => {
        setTickets((prevTickets) =>
            prevTickets.map(ticket => {
                if (ticket.id === menuTicketId) {
                    const alreadyRead = ticket.read?.includes(adminId);
                    return alreadyRead
                        ? { ...ticket, read: ticket.read.filter(id => id !== adminId) }
                        : { ...ticket, read: [...(ticket.read || []), adminId] };
                }
                return ticket;
            })
        );
        handleMenuClose();
    };

    const handleArchiveTicket = () => {
        setTickets(prevTickets =>
            prevTickets.map(ticket =>
                ticket.id === menuTicketId ? { ...ticket, tag: 'Archive' } : ticket
            )
        );
        handleMenuClose();
    };

    const handleDeleteTicket = () => {
        setTickets(prevTickets => prevTickets.filter(ticket => ticket.id !== menuTicketId));
        handleMenuClose();
    };

    // Filtering based on selected tab and search query.
    const currentTabTag = ticketTabs.find(tab => tab.value === selectedTab)?.tag;
    const filteredTickets = tickets.filter(ticket => {
        if (ticket.tag !== currentTabTag) return false;
        if (searchQuery.trim() !== '') {
            const query = searchQuery.toLowerCase();
            return (
                ticket.id.toLowerCase().includes(query) ||
                ticket.title.toLowerCase().includes(query) ||
                ticket.customerName.toLowerCase().includes(query) ||
                ticket.email.toLowerCase().includes(query)
            );
        }
        return true;
    });

    const countTicketsForStatus = (statusTag) => {
        return tickets.filter(ticket => ticket.tag === statusTag).length;
    };

    const currentTicket = tickets.find(ticket => ticket.id === menuTicketId);

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                User Tickets
            </Typography>
            <Grid container spacing={2}>
                {/* Left Panel: Filters and Tag Management */}
                
                <Grid item xs={12} md={3}>
                    <Box>
                         <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', textTransform: 'uppercase' }}>
                            Filters
                        </Typography>
                        <List>
                            {defaultFilters.map(filter => (
                                <ListItem
                                    key={filter.id}
                                    button
                                    onClick={() => setSelectedFilter(filter.name)}
                                    sx={(theme) => ({
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        borderLeft: selectedFilter === filter.name ? `3px solid ${theme.palette.secondary.main}` : 'none',
                                        backgroundColor: selectedFilter === filter.name ? theme.palette.action.hover : 'inherit',
                                        mb: 0.5,
                                    })}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <IconPickerItem value={filter.icon} size={24} style={{ color: filter.iconColor }} />
                                        <ListItemText
                                            primary={
                                                <span>
                                                    {filter.name}{' '}
                                                    <span style={{ color: 'lightgray' }}>
                                                        ({countTicketsForFilter(filter.name, tickets)})
                                                    </span>
                                                </span>
                                            }
                                            sx={{ ml: 2 }}
                                        />
                                    </Box>
                                    <Badge badgeContent={countUnreadTicketsForFilter(filter.name)} color="primary" />
                                </ListItem>
                            ))}
                            <Divider sx={{ my: 1 }} />
                            {customTags.map(tag => (
                                <ListItem
                                    key={tag.id}
                                    button
                                    onClick={() => setSelectedFilter(tag.name)}
                                    sx={(theme) => ({
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        borderLeft: selectedFilter === tag.name ? `3px solid ${theme.palette.secondary.main}` : 'none',
                                        backgroundColor: selectedFilter === tag.name ? theme.palette.action.hover : 'inherit',
                                        mb: 0.5,
                                    })}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <IconPickerItem value={tag.icon} size={24} style={{ color: "gray" }} />
                                        <ListItemText
                                            primary={
                                                <span>
                                                    {tag.name}{' '}
                                                    <span style={{ color: 'lightgray' }}>
                                                        ({countTicketsForFilter(tag.name, tickets)})
                                                    </span>
                                                </span>
                                            }
                                            sx={{ ml: 2 }}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Badge badgeContent={countUnreadTicketsForFilter(tag.name)} color="primary" sx={{ mr: 1 }} />
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveTag(tag);
                                            }}
                                        >
                                            x
                                        </IconButton>
                                    </Box>
                                </ListItem>
                            ))}
                            <Divider sx={{ my: 1 }} />
                            <ListItem>
                                <TextField
                                    size="small"
                                    placeholder="New tag name"
                                    variant="outlined"
                                    value={newTagName}
                                    onChange={e => setNewTagName(e.target.value)}
                                    sx={{ mr: 1 }}
                                />
                                <IconPicker
                                    value={newTagIcon}
                                    onChange={icon => setNewTagIcon(icon)}
                                    searchable={true}
                                    placeholder="Pick an icon"
                                />
                                <IconButton color="primary" onClick={handleAddTag}>
                                    <AddIcon />
                                </IconButton>
                            </ListItem>
                        </List>
                    </Box>
                </Grid>
                {/* Right Panel: Table and Ticket List */}
                <Grid item xs={12} md={9}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 1
                            }}
                        >
                            <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', textTransform: 'uppercase' }}>
                                {selectedFilter} Tickets
                            </Typography>
                            {selectedTickets.length > 0 && (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        startIcon={<ArchiveIcon />}
                                        onClick={handleArchiveTickets}
                                    >
                                        Archive
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        startIcon={<AssignmentIndIcon />}
                                        onClick={handleAssignTickets}
                                    >
                                        Assign Ticket
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        {/* Ticket Status Tabs */}
                        <Paper sx={{ width: '100%', mb: 2 }}>
                            <Tabs
                                value={selectedTab}
                                onChange={(e, newValue) => setSelectedTab(newValue)}
                                textColor="primary"
                                indicatorColor="primary"
                                variant="fullWidth"
                            >
                                {ticketTabs.map(tab => (
                                    <Tab
                                        key={tab.value}
                                        value={tab.value}
                                        sx={{
                                            backgroundColor: selectedTab === tab.value ? 'white' : '#f0f0f0',
                                            '&.Mui-selected': { backgroundColor: 'white' },
                                            borderRadius: 1,
                                            minHeight: 48,
                                            p: 2,
                                        }}
                                        label={
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    {tab.icon}
                                                    <Typography sx={{ ml: 1 }}>
                                                        {tab.label}{' '}
                                                        <span style={{ color: 'lightgray' }}>
                                                            ({countTicketsForStatus(tab.tag)})
                                                        </span>
                                                    </Typography>
                                                </Box>
                                                <Badge badgeContent={countUnreadTicketsForStatus(tab.tag)} color="primary" sx={{ mr: 1.5 }} />
                                            </Box>
                                        }
                                    />
                                ))}
                            </Tabs>
                        </Paper>
                        <Box sx={{ mb: 2 }}>
                            <TextField
                                fullWidth
                                variant="standard"
                                placeholder="Search tickets..."
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                            />
                        </Box>
                        {/* Tickets Table */}
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={filteredTickets.length > 0 && selectedTickets.length === filteredTickets.length}
                                                indeterminate={selectedTickets.length > 0 && selectedTickets.length < filteredTickets.length}
                                                onChange={handleToggleAll}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ color: "gray" }}>Hash</TableCell>
                                        <TableCell sx={{ color: "gray" }}>Customer Name</TableCell>
                                        <TableCell sx={{ color: "gray" }}>Title</TableCell>
                                        <TableCell sx={{ color: "gray" }}>Email</TableCell>
                                        <TableCell sx={{ color: "gray" }}>Priority</TableCell>
                                        <TableCell sx={{ color: "gray" }}>Assignee</TableCell>
                                        <TableCell sx={{ color: "gray" }} align="center">
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }}>
                                    {filteredTickets.map(ticket => (
                                        <TableRow
                                            key={ticket.id}
                                            sx={{
                                                backgroundColor: ticket.read?.includes(adminId) ? 'transparent' : 'white',
                                                cursor: 'pointer',
                                                borderBottom: '1px solid #ddd',
                                            }}
                                            onClick={() => handleTicketClick(ticket.id)}
                                        >
                                            <TableCell
                                                padding="checkbox"
                                                onClick={e => e.stopPropagation()}
                                                sx={{
                                                    position: 'relative',
                                                    '&::before': ticket.read?.includes(adminId)
                                                        ? {}
                                                        : {
                                                            content: '""',
                                                            position: 'absolute',
                                                            left: 0,
                                                            top: 0,
                                                            bottom: 0,
                                                            width: '3px',
                                                            backgroundColor: 'secondary.main',
                                                        },
                                                    pl: 2,
                                                }}
                                            >
                                                <Checkbox
                                                    checked={selectedTickets.includes(ticket.id)}
                                                    onChange={() => handleToggleTicket(ticket.id)}
                                                />
                                            </TableCell>
                                            <TableCell>{ticket.id}</TableCell>
                                            <TableCell sx={{ border: 'none' }}>{ticket.customerName}</TableCell>
                                            <TableCell sx={{ border: 'none' }}>{ticket.title}</TableCell>
                                            <TableCell sx={{ border: 'none' }}>{ticket.email}</TableCell>
                                            <TableCell sx={{ border: 'none' }}>
                                                <Chip
                                                    label={ticket.priority}
                                                    color={getPriorityChipColor(ticket.priority)}
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell sx={{ border: 'none' }}>
                                                <Tooltip title={ticket.assignee}>
                                                    <Avatar sx={{ bgcolor: 'primary.main', width: 32, height: 32, fontSize: '0.8rem' }}>
                                                        {getInitials(ticket.assignee)}
                                                    </Avatar>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center" onClick={e => e.stopPropagation()}>
                                                <IconButton onClick={e => handleMenuOpen(e, ticket.id)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {filteredTickets.length === 0 && (
                            <Typography sx={{ mt: 2 }} align="center">
                                No tickets found.
                            </Typography>
                        )}
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={handleMenuClose}
                        >
                            {currentTicket && (
                                <>
                                    <MenuItem onClick={handleToggleReadStatus}>
                                        <ListItemIcon>
                                            {currentTicket.read?.includes(adminId) ? <MarkEmailUnreadIcon fontSize="small" /> : <MarkEmailReadIcon fontSize="small" />}
                                        </ListItemIcon>
                                        {currentTicket.read?.includes(adminId) ? 'Mark as Unread' : 'Mark as Read'}
                                    </MenuItem>
                                    <MenuItem onClick={handleArchiveTicket}>
                                        <ListItemIcon>
                                            <ArchiveIcon fontSize="small" />
                                        </ListItemIcon>
                                        Archive
                                    </MenuItem>
                                    <MenuItem onClick={handleDeleteTicket}>
                                        <ListItemIcon>
                                            <DeleteIcon fontSize="small" />
                                        </ListItemIcon>
                                        Delete
                                    </MenuItem>
                                </>
                            )}
                        </Menu>
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
                <DialogTitle>Confirm Remove Tag</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove the tag "{tagToRemove?.name}"? This will remove the tag from all tickets.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmRemoveTag} color="error">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CustomerTickets;
