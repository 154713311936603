import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    Divider,
    Paper,
    TextField,
    LinearProgress,
    Tooltip
} from '@mui/material';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import InvoiceDetails from '../../components/InvoiceDetails';
import PaymentForm from '../../components/PaymentForm';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAuth } from '../../contexts/AuthContext';

// Helper function to determine status color
function getStatusColor(status) {
    switch (status.toLowerCase()) {
        case 'paid':
            return 'success';
        case 'overdue':
            return 'error';
        case 'pending':
            return 'default';
        case 'on hold':
            return 'info';
        case 'due soon':
            return 'warning';
        case 'cancelled':
            return 'default';
        default:
            return 'default';
    }
}

const steps = ['1. View Details', '2. Payment', '3. Payment Confirmation'];

const InvoicePay = () => {
    const { invoiceId } = useParams();
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [invoice, setInvoice] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0); // 0: View Details, 1: Payment, 2: Payment Confirmation

    // Discount Code States
    const [showDiscountCode, setShowDiscountCode] = useState(false);
    const [discountCode, setDiscountCode] = useState('');
    const [discountError, setDiscountError] = useState('');
    const [isApplyingDiscount, setIsApplyingDiscount] = useState(false);

    // Payment processing state for the confirm payment button
    const [paymentProcessing, setPaymentProcessing] = useState(false);

    const db = getFirestore();
    const functions = getFunctions();
    const verifyDiscountCode = httpsCallable(functions, 'verifyDiscountCode');
    const fetchInvoice = httpsCallable(functions, 'fetchInvoice');

    // Create a ref for PaymentForm
    const paymentFormRef = useRef();

    useEffect(() => {
        const fetchInvoiceDetails = async () => {
            try {
                // Use the fetchInvoice cloud function to retrieve the invoice.
                const response = await fetchInvoice({ invoiceId });
                const invoiceData = response.data.invoice;

                if (invoiceData) {
                    let items = [];
                    let subtotal = 0;
                    // Use the discount from the invoice data as the initial discount
                    let discount = invoiceData.discount || 0;
                    let taxRate = invoiceData.taxRate || 0.0635; // Default tax rate

                    // Loop over each product object in the invoice.
                    for (const prod of invoiceData.products || []) {
                        const code = typeof prod === 'object' ? prod.code : prod;
                        const quantity = typeof prod === 'object' ? prod.quantity || 1 : 1;
                        const productRef = doc(db, "products", code);
                        const productSnap = await getDoc(productRef);

                        if (productSnap.exists()) {
                            let productData = productSnap.data();

                            // If the product is a Plan Change, recalc the price.
                            if (productData.productType === "Plan Change") {
                                const planFromRef = doc(db, "products", productData.planFrom);
                                const planToRef = doc(db, "products", productData.planTo);
                                const planFromSnap = await getDoc(planFromRef);
                                const planToSnap = await getDoc(planToRef);
                                const planFromPrice = planFromSnap.exists() ? (planFromSnap.data().price || 0) : 0;
                                const planToPrice = planToSnap.exists() ? (planToSnap.data().price || 0) : 0;
                                // Update the product price based on the difference.
                                productData.price = planToPrice - planFromPrice;
                            }

                            items.push({
                                name: productData.title || "Unknown Item",
                                price: productData.price || 0,
                                quantity: quantity
                            });
                            subtotal += (productData.price || 0) * quantity;
                        }
                    }

                    let taxAmount = subtotal * taxRate;
                    let totalAmount = subtotal - discount + taxAmount;

                    let dueDateStr = "No due date";
                    if (invoiceData.dueDate) {
                        dueDateStr = typeof invoiceData.dueDate.toDate === 'function'
                            ? invoiceData.dueDate.toDate().toLocaleDateString()
                            : new Date(invoiceData.dueDate).toLocaleDateString();
                    }

                    setInvoice({
                        id: invoiceId,
                        status: invoiceData.isPaid
                            ? 'Paid'
                            : invoiceData.isCancelled
                                ? 'Cancelled'
                                : invoiceData.isOverdue
                                    ? 'Overdue'
                                    : 'Due Soon',
                        dueDate: dueDateStr,
                        items: items,
                        subtotal: subtotal,
                        discount: discount,
                        tax: taxAmount,
                        total: totalAmount
                    });
                } else {
                    console.error("Invoice not found");
                }
            } catch (error) {
                console.error("Error fetching invoice details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchInvoiceDetails();
    }, [invoiceId, db, fetchInvoice]);

    // Handlers for step navigation
    const handleBack = () => {
        if (activeStep === 0) {
            navigate(-1);
        } else {
            setActiveStep((prev) => prev - 1);
        }
    };

    const handleContinue = () => {
        if (activeStep < steps.length - 1) {
            setActiveStep((prev) => prev + 1);
        } else {
            navigate('/dashboard');
        }
    };

    // This handler is triggered by PaymentForm when payment is successful.
    const handlePaymentSuccess = () => {
        setActiveStep(2);
    };

    // New handler to trigger payment from the overview panel on the Payment step.
    const handlePayNow = async () => {
        if (paymentFormRef.current && paymentFormRef.current.submitPayment) {
            setPaymentProcessing(true);
            try {
                await paymentFormRef.current.submitPayment();
            } catch (error) {
                console.error(error);
            } finally {
                setPaymentProcessing(false);
            }
        }
    };

    // Apply discount code
    const handleApplyDiscount = async () => {
        if (!discountCode.trim()) return;
        setIsApplyingDiscount(true);
        setDiscountError('');
        try {
            const result = await verifyDiscountCode({ discountCode: discountCode.trim() });
            if (result.data.success) {
                setInvoice((prev) => {
                    if (!prev) return prev;
                    const newDiscount = result.data.total;
                    return {
                        ...prev,
                        discount: newDiscount,
                        total: prev.subtotal - newDiscount + prev.tax
                    };
                });
            } else {
                setDiscountError(result.data.message || "Discount code invalid.");
            }
        } catch (error) {
            console.error('Error applying discount code:', error);
            setDiscountError('An error occurred while applying the discount code.');
        } finally {
            setIsApplyingDiscount(false);
        }
    };

    // Calculate progress percentage for LinearProgress
    const progress = ((activeStep + 1) / steps.length) * 100;

    // Left panel content based on step
    const renderLeftContent = () => {
        if (activeStep === 0) {
            return (
                <>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                        Invoice Details
                    </Typography>
                    <InvoiceDetails items={invoice.items} />
                </>
            );
        } else if (activeStep === 1) {
            return (
                <>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                        Payment Information
                    </Typography>

                    <Typography>
                        Our payment gateway is currently under construction. Please check back on April 4th. 
                    </Typography>
                    {/*<PaymentForm
                        ref={paymentFormRef}
                        amount={invoice.total.toFixed(2)} // Passing Total to the PaymentForm
                        invoiceId={invoice.id}
                        onPaymentSuccess={handlePaymentSuccess}
                        hideSubmitButton={true} // Hide internal pay button in favor of the overview panel button
                        user={currentUser}
                    />*/}
                </>
            );
        } else if (activeStep === 2) {
            return (
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        Payment Confirmation
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>
                        Your payment has been processed successfully.
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                        A confirmation email will be sent to you shortly.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3 }}
                        onClick={() => navigate('/dashboard')}
                    >
                        Back to Dashboard
                    </Button>
                </Box>
            );
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4">Invoice Payment</Typography>
            <Typography variant="subtitle1" sx={{ mb: 4 }}>
                Review your invoice details and proceed to payment.
            </Typography>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center" }}>
                <Box sx={{ width: '100%', mb: 4 }}>
                    <Box sx={{ display: 'flex', gap: 4, mb: 1, justifyContent: "space-between" }}>
                        {steps.map((label, index) => (
                            <Typography
                                key={label}
                                variant="body1"
                                fontWeight={index === activeStep ? 'bold' : 'normal'}
                                color={index === activeStep ? 'textPrimary' : 'textSecondary'}
                            >
                                {label}
                            </Typography>
                        ))}
                    </Box>
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        sx={{ width: '%', height: 8, borderRadius: 4 }}
                    />
                </Box>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : invoice ? (
                activeStep === 2 ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                        {renderLeftContent()}
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', gap: 3 }}>
                        <Box sx={{ flex: 2 }}>
                            {renderLeftContent()}
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                                Overview
                            </Typography>
                            <Paper sx={{ p: 2, border: "1px solid lightgray" }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Tooltip title="The total cost of the items before any discounts or taxes." arrow>
                                            <InfoOutlinedIcon fontSize="small" sx={{ mr: 1, color: "gray" }} />
                                        </Tooltip>
                                        <Typography>Subtotal:</Typography>
                                    </Box>
                                    <Typography>${invoice.subtotal.toFixed(2)}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Tooltip title="The discount amount applied to your subtotal." arrow>
                                            <InfoOutlinedIcon fontSize="small" sx={{ mr: 1, color: "gray" }} />
                                        </Tooltip>
                                        <Typography>Discount:</Typography>
                                    </Box>
                                    <Typography>-${invoice.discount.toFixed(2)}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Tooltip title="Taxes calculated based on the subtotal and applicable tax rate." arrow>
                                            <InfoOutlinedIcon fontSize="small" sx={{ mr: 1, color: "gray" }} />
                                        </Tooltip>
                                        <Typography>Taxes (6.35%):</Typography>
                                    </Box>
                                    <Typography>${invoice.tax.toFixed(2)}</Typography>
                                </Box>
                                <Divider sx={{ my: 2 }} />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', my: 1 }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>Total:</Typography>
                                    </Box>
                                    <Typography variant="h6" >${invoice.total.toFixed(2)}</Typography>
                                </Box>
                                <Box sx={{ mt: 3 }}>
                                    {!showDiscountCode ? (
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                <Typography variant="body1" color="text.secondary">
                                                    Yale High-Need Student Support
                                                </Typography>
                                                <Tooltip
                                                    title="Yale University is offering a special discount for high-need students: receive 50% off your invoice, up to $225. If you qualify, press 'Add' to enter your NetID and apply the discount."
                                                    arrow
                                                >
                                                    <InfoOutlinedIcon sx={{ ml: 1, color: 'text.secondary' }} />
                                                </Tooltip>
                                            </Box>
                                            <Button
                                                variant="outlined"
                                                onClick={() => setShowDiscountCode(true)}
                                                sx={{ textTransform: 'none' }}
                                            >
                                                Add
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Box sx={{ mt: 2, display: "flex", flexDirection: "row" }}>
                                                <TextField
                                                    label="Enter Yale NetID"
                                                    fullWidth
                                                    size="small"
                                                    value={discountCode}
                                                    onChange={(e) => setDiscountCode(e.target.value)}
                                                    error={!!discountError}
                                                    helperText={discountError}
                                                />
                                                <Button
                                                    variant="outlined"
                                                    onClick={handleApplyDiscount}
                                                    sx={{ textTransform: 'none', ml: 1, height: "100%" }}
                                                    disabled={isApplyingDiscount}
                                                >
                                                    {isApplyingDiscount ? <CircularProgress size={24} /> : 'Apply'}
                                                </Button>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                    <Button variant="outlined" onClick={handleBack}>
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={activeStep === 1 ? handlePayNow : handleContinue}
                                        disabled={activeStep === 1 && paymentProcessing}
                                    >
                                        {activeStep === 1 ? (
                                            paymentProcessing ? <CircularProgress size={24} color="inherit" /> : 'Confirm Payment'
                                        ) : 'Continue'}
                                    </Button>
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                )
            ) : (
                <Typography variant="h6" sx={{ mt: 3 }}>
                    Invoice not found.
                </Typography>
            )}
        </Box>
    );
};

export default InvoicePay;
