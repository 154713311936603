// MaintenancePage.js
import React from 'react';
import { LuConstruction } from 'react-icons/lu';

const MaintenancePage = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
                padding: '20px',
            }}
        >
            <LuConstruction size={120} color="#007bff" />
            <h1 style={{ marginTop: '20px' }}>Scheduled Maintenance</h1>
            <p style={{ maxWidth: '400px', marginTop: '10px', fontSize: '1.2em', color: '#333' }}>
                Our dashboard is currently undergoing scheduled maintenance. Please check back later.
            </p>
        </div>
    );
};

export default MaintenancePage;
