// src/components/Footer.js
import React, { useState } from 'react';
import { Box, Typography, Grid, Link, Container, Modal, IconButton } from '@mui/material';
import { Instagram,  Close } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import TermsPopup from './TermsPopup';

const Footer = () => {
  const { currentUser } = useAuth();

  // State for Contact Modal
  const [openContact, setOpenContact] = useState(false);
  const handleOpenContact = () => setOpenContact(true);
  const handleCloseContact = () => setOpenContact(false);

  // State for Terms/Privacy Popup
  const [popupData, setPopupData] = useState({
    open: false,
    filePath: '',
    title: ''
  });

  const handleOpenPopup = (filePath, title) => {
    setPopupData({ open: true, filePath, title });
  };

  const handleClosePopup = () => {
    setPopupData({ ...popupData, open: false });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'secondary.alternate',
          color: 'white',
          mt: 'auto', // This ensures the footer is pushed to the bottom
          pt: 8,
          pb: 3
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Typography variant="h4" gutterBottom>
                <span style={{ fontWeight: 'bold' }}>Summer</span>Store
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                We make storing your belongings easy and affordable. We pick up, store, and deliver your items back to campus.
              </Typography>
              <Box sx={{ display: 'flex',  maxWidth: '200px' }}>
                <Link href="http://instagram.com/summerstore.us/" color="inherit">
                  <Instagram sx={{ fontSize: 32 }} />
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ marginLeft: 'auto' }}>
              <Typography variant="h6" gutterBottom>Our Services</Typography>
              <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>Storage Plans</Link>
              <Link href="/pricing" color="inherit" sx={{ display: 'block', mb: 1 }}>Price Comparison</Link>
              <Link href="/#faq" color="inherit" sx={{ display: 'block', mb: 1 }}>FAQs</Link>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" gutterBottom>Company</Typography>
              <Link href="/about-us" color="inherit" sx={{ display: 'block', mb: 1 }}>About Us</Link>
              <Link
                component="button"
                onClick={handleOpenContact}
                color="inherit"
                sx={{ display: 'block', mb: 1 }}
              >
                Contact Us
              </Link>
              <Link
                component="button"
                onClick={() => handleOpenPopup('/pp.md', 'Privacy Policy')}
                color="inherit"
                sx={{ display: 'block', mb: 1 }}
              >
                Privacy Policy
              </Link>
              <Link
                component="button"
                onClick={() => handleOpenPopup('/tos.md', 'Terms of Service')}
                color="inherit"
                sx={{ display: 'block', mb: 1 }}
              >
                Terms of Service
              </Link>
            </Grid>
            {currentUser && (
              <Grid item xs={12} md={3}>
                <Typography variant="h6" gutterBottom>Quick Links</Typography>
                <Link href="/dashboard" color="inherit" sx={{ display: 'block', mb: 1 }}>Dashboard</Link>
                <Link href="/dashboard/items" color="inherit" sx={{ display: 'block', mb: 1 }}>My Items</Link>
                <Link href="/dashboard/plan" color="inherit" sx={{ display: 'block', mb: 1 }}>Change Plan</Link>
                <Link href="/dashboard/settings" color="inherit" sx={{ display: 'block', mb: 1 }}>Account Settings</Link>
                <Link href="/dashboard/inbox" color="inherit" sx={{ display: 'block', mb: 1 }}>Inbox</Link>
              </Grid>
            )}
          </Grid>
          <Box sx={{ mt: 8, borderTop: '1px solid #444', pt: 4 }}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} md={6}>
                <Typography variant="body2">
                  The entirety of this site is protected by copyright © 2025 SummerStore LCC.
                  <br />
                  2389 Main St., STE 100, Glastonbury, CT, 06033, United States
                </Typography>
              </Grid>
              {/**
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <img src="/images/Visa.jpg" alt="Visa" style={{ maxWidth: '50px', marginRight: '10px' }} />
                <img src="/images/Mastercard.jpg" alt="MasterCard" style={{ maxWidth: '50px', marginRight: '10px' }} />
                <img src="/images/Amex.jpg" alt="American Express" style={{ maxWidth: '50px', marginRight: '10px' }} />
                <img src="/images/Discover.jpg" alt="Discover" style={{ maxWidth: '50px', marginRight: '10px' }} />
                <img src="/images/ApplePay.jpg" alt="Apple Pay" style={{ maxWidth: '50px', marginRight: '10px' }} />
              </Grid>
              **/}
            </Grid>
          </Box>
        </Container>
      </Box>

      {/* Contact Us Modal */}
      <Modal
        open={openContact}
        onClose={handleCloseContact}
        aria-labelledby="contact-modal-title"
        aria-describedby="contact-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            width: { xs: '90%', sm: 400 },
            outline: 'none'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="contact-modal-title" variant="h6" component="h2">
              Our Contact Information
            </Typography>
            <IconButton onClick={handleCloseContact}>
              <Close />
            </IconButton>
          </Box>
          <Typography id="contact-modal-description" sx={{ mt: 2 }}>
            <strong>Email:</strong> support@summerstore.org<br />
            <strong>Phone:</strong> +1 (800) 564 - 2236<br />
            <strong>Address:</strong> 2389 Main St., STE 100, Glastonbury, CT, 06033, United States
          </Typography>
        </Box>
      </Modal>

      {/* Terms/Privacy Popup */}
      <TermsPopup
        open={popupData.open}
        onClose={handleClosePopup}
        filePath={popupData.filePath}
        title={popupData.title}
      />
    </>
  );
};

export default Footer;
