import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { Inbox, ShoppingCart, AccessTime, Event, Mail, MoreVert } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { format } from 'date-fns';

import UserDetailItems from '../../components/UserDetailItems';
import UserDetailInvoices from '../../components/UserDetailInvoices';
import UserDetailActivity from '../../components/UserDetailActivity';
import UserDetailAppointments from '../../components/UserDetailAppointments';

// Helper function for formatting dates
function formatInvoiceDate(dueDate) {
  if (!dueDate) return 'No due date';
  let dateObj;
  if (dueDate.toDate && typeof dueDate.toDate === 'function') {
    dateObj = dueDate.toDate();
  } else if (dueDate._seconds) {
    dateObj = new Date(dueDate._seconds * 1000);
  } else {
    dateObj = new Date(dueDate);
  }
  if (isNaN(dateObj.getTime())) {
    return 'Invalid date';
  }
  return format(dateObj, 'dd MMM yyyy');
}

const UserDetail = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('items');

  // Items
  const [inventory, setInventory] = useState([]);
  const [inventoryLoading, setInventoryLoading] = useState(false);

  // Invoices
  const [invoices, setInvoices] = useState([]);
  const [invoicesLoading, setInvoicesLoading] = useState(false);

  const navigate = useNavigate();

  // Account status & role
  const [accountStatus, setAccountStatus] = useState(true);
  const [userRole, setUserRole] = useState('');

  // Confirmation dialog states
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [pendingRole, setPendingRole] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmationError, setConfirmationError] = useState('');

  // -----------------------------
  // 1) Fetch user profile info
  // -----------------------------
  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }
    const fetchUser = async () => {
      const db = getFirestore();
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const regDate = userData.registrationDate
          ? new Date(userData.registrationDate.seconds * 1000).toLocaleDateString()
          : 'N/A';
        userData.registrationDate = regDate;
        setUser(userData);
        setAccountStatus(userData.status === 'active');
        setUserRole(userData.role || 'user');
      } else {
        console.log('No such user document!');
      }
      setLoading(false);
    };
    fetchUser();
  }, [userId]);

  // -----------------------------
  // 1a) Fetch storage plan details
  // -----------------------------
  useEffect(() => {
    if (user && user.storagePlan) {
      const fetchPlanDetails = async () => {
        try {
          const db = getFirestore();
          const planDoc = await getDoc(doc(db, 'products', user.storagePlan));
          if (planDoc.exists()) {
            setPlanDetails(planDoc.data());
          }
        } catch (error) {
          console.error('Error fetching storage plan details', error);
        }
      };
      fetchPlanDetails();
    }
  }, [user]);

  // -----------------------------
  // 2) Load inventory and invoices once on mount
  // -----------------------------
  useEffect(() => {
    if (!userId) return;
    const loadData = async () => {
      await Promise.all([fetchInventory(), fetchInvoices()]);
    };
    loadData();
  }, [userId]);

  // -----------------------------
  // 3) Fetch the customer's inventory
  // -----------------------------
  const fetchInventory = async () => {
    setInventoryLoading(true);
    const db = getFirestore();
    const inventoryCollection = collection(db, `inventoryUsers/${userId}/items`);
    const inventoryDocs = await getDocs(inventoryCollection);
    const items = inventoryDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setInventory(items);
    setInventoryLoading(false);
  };

  // -----------------------------
  // 4) Fetch the customer's invoices
  // -----------------------------
  const fetchInvoices = async () => {
    setInvoicesLoading(true);
    const db = getFirestore();
    const qInvoices = query(collection(db, 'invoices'), where('userId', '==', userId));
    const snapshot = await getDocs(qInvoices);
    const userInvoices = snapshot.docs.map(doc => {
      const invoiceData = doc.data();
      const formattedDueDate = formatInvoiceDate(invoiceData.dueDate);
      let status;
      if (invoiceData.isPaid) {
        status = 'Paid';
      } else if (invoiceData.isCancelled) {
        status = 'Cancelled';
      } else if (invoiceData.isOverdue) {
        status = 'Overdue';
      } else {
        status = 'Due Soon';
      }
      return {
        id: doc.id,
        title: invoiceData.title || 'Untitled Invoice',
        amount: invoiceData.amount || 0,
        status,
        date: formattedDueDate,
      };
    });
    setInvoices(userInvoices);
    setInvoicesLoading(false);
  };

  // -----------------------------
  // 5) Update account status and role
  // -----------------------------
  const handleStatusChange = async () => {
    const newStatus = accountStatus ? 'inactive' : 'active';
    setAccountStatus(!accountStatus);
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'users', userId), { status: newStatus });
      setUser(prevUser => ({ ...prevUser, status: newStatus }));
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // Trigger confirmation dialog when role is selected
  const handleRoleChange = (event) => {
    const newRole = event.target.value;
    setPendingRole(newRole);
    setConfirmationEmail('');
    setConfirmationError('');
    setConfirmationOpen(true);
  };

  // Called when the user confirms the role change
  const handleConfirmRoleChange = async () => {
    if (confirmationEmail !== user.email) {
      setConfirmationError("Email doesn't match. Please enter the correct user's email to confirm.");
      return;
    }
    setConfirmLoading(true);
    try {
      // Call the Cloud Function to update the user's custom claim
      const functions = getFunctions();
      const setRoleFunction = httpsCallable(functions, 'setRole');
      const result = await setRoleFunction({ uid: userId, role: pendingRole });
      console.log(result.data.message);

      // Update Firestore document to keep it in sync.
      const db = getFirestore();
      await updateDoc(doc(db, 'users', userId), { role: pendingRole });
      setUser(prevUser => ({ ...prevUser, role: pendingRole }));
      setUserRole(pendingRole);
    } catch (error) {
      console.error('Error updating role:', error);
      setConfirmationError(error.message || 'Error updating role');
    } finally {
      setConfirmLoading(false);
      setPendingRole('');
      setConfirmationOpen(false);
    }
  };

  const handleCancelRoleChange = () => {
    setPendingRole('');
    setConfirmationOpen(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }
  if (!userId || !user) {
    return <Typography>Unable to load. Try refreshing, or check user ID.</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Confirmation Dialog */}
      <Dialog open={confirmationOpen} onClose={handleCancelRoleChange}>
        <DialogTitle>Confirm Role Change</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            To confirm changing the role to <strong>{pendingRole}</strong>, please enter the user's email address:
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="User Email"
            type="email"
            fullWidth
            value={confirmationEmail}
            onChange={(e) => setConfirmationEmail(e.target.value)}
          />
          {confirmationError && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {confirmationError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelRoleChange} color="primary" disabled={confirmLoading}>
            Cancel
          </Button>
          <Button onClick={handleConfirmRoleChange} color="primary" disabled={confirmLoading}>
            {confirmLoading ? <CircularProgress size={24} /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Back button */}
      <Button variant="outlined" color="primary" onClick={() => navigate(-1)} sx={{ mb: 2 }}>
        Back
      </Button>

      {/* Page title, Message button and Triple Dot Action Icon */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          User Details
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="outlined" color="primary" startIcon={<Mail />}>
            Message
          </Button>
          <IconButton aria-label="more actions">
            <MoreVert />
          </IconButton>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {/* Left Panel - Profile Information */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Profile Information
            </Typography>
            <Box sx={{ mb: 4 }}>
              <Typography variant="body1"><strong>First Name:</strong> {user.firstName}</Typography>
              <Typography variant="body1"><strong>Last Name:</strong> {user.lastName}</Typography>
              <Typography variant="body1"><strong>Email:</strong> {user.email}</Typography>
              <Typography variant="body1"><strong>University:</strong> {user.universityId}</Typography>
              <Typography variant="body1"><strong>Registration Date:</strong> {user.registrationDate}</Typography>
              <Typography variant="body1">
                <strong>Storage Plan:</strong>{' '}
                {planDetails ? planDetails.name : user.storagePlan}
              </Typography>
              {planDetails && (
                <Typography variant="body1">
                  <strong>Max Items:</strong> {planDetails.maxItems}
                </Typography>
              )}
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1">
                <strong>Status:</strong> {accountStatus ? 'Active' : 'Inactive'}
              </Typography>
              <Switch checked={accountStatus} onChange={handleStatusChange} />
            </Box>
            <Box sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel>User Role</InputLabel>
                <Select value={userRole} onChange={handleRoleChange}>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="mover">Mover</MenuItem>
                  <MenuItem value="coordinator">Coordinator</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Paper>
        </Grid>

        {/* Right Panel - Tabs */}
        <Grid item xs={12} md={8} sx={{ flexGrow: 1 }}>
          <Paper sx={{ mb: 2, flexGrow: 1, width: '100%' }}>
            <Tabs
              value={tab}
              onChange={(event, newValue) => setTab(newValue)}
              variant="fullWidth"
              aria-label="user tabs"
            >
              <Tab icon={<Inbox />} label="Items" value="items" iconPosition="start" />
              <Tab icon={<Event />} label="Appointments" value="appointments" iconPosition="start" />
              <Tab icon={<ShoppingCart />} label="Invoices" value="invoices" iconPosition="start" />
              <Tab icon={<AccessTime />} label="Activity" value="activityHistory" iconPosition="start" />
            </Tabs>
          </Paper>

          {tab === 'items' && (
            <UserDetailItems user={user} userId={userId} inventory={inventory} inventoryLoading={inventoryLoading} />
          )}

          {tab === 'invoices' && (
            <UserDetailInvoices invoices={invoices} invoicesLoading={invoicesLoading} />
          )}

          {tab === 'activityHistory' && (
            <UserDetailActivity userId={userId} />
          )}

          {tab === 'appointments' && (
            <UserDetailAppointments userId={userId} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetail;
