import React from 'react';
import { Box } from '@mui/material';

const CustomGroupNode = ({ data, style }) => {
    return (
        <div
            style={{
                ...style,
                position: 'relative',
                boxSizing: 'border-box',
            }}
        >
            {/* Container ID label in the top left */}
            <div
                style={{
                    position: 'absolute',
                    top: 5,
                    left: 5,
                    color: 'gray',
                    fontWeight: 'bold',
                    padding: '2px 4px',
                    borderRadius: 4,
                    zIndex: 10,
                }}
            >
                Container ID:
                <span style={{ padding: '5px', color: 'lightgray' }}>
                    {data.label}
                </span>
            </div>

            {/* Render any children (nested nodes) automatically */}
            {data.children}
        </div>
    );
};

export default CustomGroupNode;
