import React, { useRef } from 'react';
import { Paper, Button } from '@mui/material';
import StarterKit from '@tiptap/starter-kit';
import {
    MenuButtonBold,
    MenuButtonItalic,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectHeading,
    RichTextEditor,
} from 'mui-tiptap';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

const MessageEditorAdmin = ({ ticketID }) => {
    const rteRef = useRef(null);
    const db = getFirestore();
    const { currentUser } = useAuth(); // Get the current user

    const handleSave = async () => {
        const htmlContent = rteRef.current?.editor?.getHTML();
        try {
            const messagesRef = collection(db, 'tickets', ticketID, 'messages');
            await addDoc(messagesRef, {
                content: htmlContent,
                senderName: 'Admin',
                senderID: currentUser ? currentUser.uid : 'admin', // Save senderID
                timestamp: serverTimestamp(),
            });
            // Optionally clear the editor after sending
            rteRef.current?.editor?.commands.setContent('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <Paper sx={{ p: 2, mt: 2 }}>
            <RichTextEditor
                ref={rteRef}
                extensions={[StarterKit]}
                content=""
                renderControls={() => (
                    <MenuControlsContainer>
                        <MenuSelectHeading />
                        <MenuDivider />
                        <MenuButtonBold />
                        <MenuButtonItalic />
                    </MenuControlsContainer>
                )}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{ mt: 1 }}
            >
                Send Message
            </Button>
        </Paper>
    );
};

export default MessageEditorAdmin;
