import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  MenuItem,
  Container,
  Fade,
  Grid,
  Alert,
  CircularProgress,
  ListSubheader,
  LinearProgress,
  Tooltip
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import AppointmentSelector from '../components/AppointmentSelector';
import { auth, db } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getDocs, collection } from 'firebase/firestore';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import TermsPopup from '../components/TermsPopup';
import { useMediaQuery, useTheme } from '@mui/material';
import { httpsCallable, getFunctions } from "firebase/functions";
import InputMask from 'react-input-mask'; // Import InputMask for the phone field
import Footer from '../components/Footer';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const steps = [     
  'Select Location',
  'Move-Out Date',
  'Move-In Date',
  'Create Account',
];

// Helper function to format a date as "Month Day" (e.g., "July 20")
const formatDeadline = (date) => {
  if (!date) return "";
  const options = { month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
};

// Helper to convert a Firebase Timestamp to a JS Date if needed
const convertTimestamp = (ts) => {
  return ts && typeof ts.toDate === 'function' ? ts.toDate() : ts;
};

const Registration = () => {
  const functions = getFunctions();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedMoveOutDate, setMoveOutDate] = useState(null);
  const [selectedMoveInDate, setMoveInDate] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState('');
  const [selectedHallId, setSelectedHallId] = useState('');
  const [selectedHallName, setSelectedHallName] = useState('');
  const [selectedMoveInLocationId, setSelectedMoveInLocationId] = useState('');
  const [selectedMoveInLocationName, setSelectedMoveInLocationName] = useState('');
  const [universities, setUniversities] = useState([]);
  const [halls, setHalls] = useState([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDialog, setOpenDialog] = useState({ terms: false, privacy: false });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '', // New phone field in form data
    password: '',
    confirmPassword: '',
    termsOfService: false,
  });
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const universitiesCollection = collection(db, 'universities');
        const universitySnapshot = await getDocs(universitiesCollection);
        const universityList = universitySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUniversities(universityList);
      } catch (err) {
        console.error("Error fetching universities:", err);
        setError("Failed to fetch universities. Please try again later.");
      }
    };

    fetchUniversities();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setError('');
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setError('');
  };

  const handleUniversityChange = async (event) => {
    const universityId = event.target.value;
    setSelectedUniversity(universityId);
    setSelectedHallId('');
    setSelectedHallName('');
    setSelectedMoveInLocationId('');
    setSelectedMoveInLocationName('');

    try {
      const hallsCollection = collection(db, `universities/${universityId}/containerLocations`);
      const hallsSnapshot = await getDocs(hallsCollection);
      const hallList = hallsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setHalls(hallList);
    } catch (err) {
      console.error("Error fetching halls:", err);
      setError("Failed to fetch container locations. Please try again later.");
      setHalls([]);
    }
  };

  const handleHallChange = (event) => {
    const hallId = event.target.value;
    setSelectedHallId(hallId);
    const hall = halls.find((h) => h.id === hallId);
    if (hall) {
      setSelectedHallName(hall.name);
    } else {
      console.error(`Hall with ID ${hallId} not found.`);
      setSelectedHallName('');
    }
  };

  const handleMoveInLocationChange = (event) => {
    const locationId = event.target.value;
    setSelectedMoveInLocationId(locationId);
    const location = halls.find((h) => h.id === locationId);
    if (location) {
      setSelectedMoveInLocationName(location.name);
    } else {
      console.error(`Location with ID ${locationId} not found.`);
      setSelectedMoveInLocationName('');
    }
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async () => {
    const { firstName, lastName, email, phone, password, confirmPassword, termsOfService } = formData;

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    const phoneNumber = parsePhoneNumberFromString(phone, 'US'); // adjust default country code as needed
    if (!phoneNumber || !phoneNumber.isValid()) {
      setError('The phone number must be a non-empty E.164 standard compliant identifier string.');
      return;
    }

    if (password.length < 8) {
      setError("Password must contain at least 8 characters");
      return;
    }

    if (firstName.length < 2 || lastName.length < 2) {
      setError("First and last name must contain at least 2 characters");
      return;
    }

    if (!termsOfService) {
      setError("You must agree to the Terms of Service and Privacy Policy to continue.");
      return;
    }

    if (!firstName || !lastName || !email || !password || !phone) {
      setError("Please fill out all required fields.");
      return;
    }

    if (!selectedMoveOutDate?.appointmentId || !selectedHallId) {
      setError("Please select a valid move-out appointment and location.");
      return;
    }

    if (!selectedMoveInDate?.appointmentId || !selectedMoveInLocationId) {
      setError("Please select a valid move-in appointment and location.");
      return;
    }

    setLoading(true);

    try {
      const registerUser = httpsCallable(functions, 'registerUser');

      console.log("RegisterUser Payload: ", {
        firstName,
        lastName,
        email,
        password,
        phoneNumber, 
        universityId: selectedUniversity,
        moveOutLocationId: selectedHallId,
        moveOutAppointmentId: selectedMoveOutDate?.appointmentId,
        moveOutIsNonComplimentary: selectedMoveOutDate?.isNonComplimentary, // flag passed
        moveInLocationId: selectedMoveInLocationId,
        moveInAppointmentId: selectedMoveInDate?.appointmentId,
        moveInIsNonComplimentary: selectedMoveInDate?.isNonComplimentary, // flag passed
      });

      const response = await registerUser({
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        phone: phone, // include phone in the cloud function payload
        universityId: selectedUniversity,
        moveOutLocationId: selectedHallId,
        moveOutAppointmentId: selectedMoveOutDate?.appointmentId,
        moveOutIsNonComplimentary: selectedMoveOutDate?.isNonComplimentary, // flag passed
        moveInLocationId: selectedMoveInLocationId,
        moveInAppointmentId: selectedMoveInDate?.appointmentId,
        moveInIsNonComplimentary: selectedMoveInDate?.isNonComplimentary, // flag passed
      });

      if (response.data.success) {
        await signInWithEmailAndPassword(auth, email, password);
        navigate('/dashboard');
        setLoading(false);
      } else {
        setError(response.data.error || "Unknown error occurred during registration.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error registering user: ", error);
      setError("Error registering user: " + error.message);
      setLoading(false);
    }
  };

  const groupHallsByLocationGroup = (halls) => {
    const grouped = halls.reduce((groups, hall) => {
      const group = hall.locationGroup || 'Other';
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(hall);
      return groups;
    }, {});

    const groupOrder = ['Old Campus', ...Object.keys(grouped).filter(g => g !== 'Old Campus')].sort();
    groupOrder.forEach(group => {
      if (grouped[group]) {
        grouped[group].sort((a, b) => a.name.localeCompare(b.name));
      }
    });

    const sortedGrouped = {};
    groupOrder.forEach(group => {
      if (grouped[group]) {
        sortedGrouped[group] = grouped[group];
      }
    });

    return sortedGrouped;
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0: {
        const groupedHalls = groupHallsByLocationGroup(halls);
        return (
          <Box sx={{ mx: 3 }}>
            <Typography variant="h5" gutterBottom>
              Select Location
            </Typography>
            <TextField
              select
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="university"
              label="Select University"
              value={selectedUniversity}
              onChange={handleUniversityChange}
            >
              {universities.map((university) => (
                <MenuItem key={university.id} value={university.id}>
                  {university.name}
                </MenuItem>
              ))}
            </TextField>
            {selectedUniversity && (
              <>
                {halls.length > 0 ? (
                  <>
                    <TextField
                      select
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="moveOutLocation"
                      label="Select Move‑Out Location"
                      value={selectedHallId}
                      onChange={handleHallChange}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: { maxHeight: 300, width: 300 },
                          },
                        },
                      }}
                    >
                      {Object.entries(groupHallsByLocationGroup(halls.filter(hall => !hall.isOffCampus))).flatMap(
                        ([groupName, hallsInGroup]) => [
                          <ListSubheader key={`header-out-${groupName}`}>{groupName}</ListSubheader>,
                          ...hallsInGroup.map((hall) => (
                            <MenuItem key={hall.id} value={hall.id}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <span>{hall.name}</span>
                              </Box>
                            </MenuItem>
                          )),
                        ]
                      )}
                    </TextField>

                    <TextField
                      select
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="moveInLocation"
                      label="Select Move-In Location"
                      value={selectedMoveInLocationId}
                      onChange={handleMoveInLocationChange}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: { maxHeight: 300, width: 300 },
                          },
                        },
                      }}
                    >
                      {Object.entries(
                        groupHallsByLocationGroup(
                          halls.filter(hall => !hall.hideForMoveIn) // filtering halls for move in
                        )
                      ).flatMap(([groupName, hallsInGroup]) => [
                        <ListSubheader key={`header-in-${groupName}`}>{groupName}</ListSubheader>,
                        ...hallsInGroup.map((hall) => (
                          <MenuItem key={hall.id} value={hall.id}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              <span>{hall.name}</span>
                              {hall.isOffCampus && (
                                <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray' }}>
                                  <span>+$150</span>
                                  <Tooltip title="Off campus moves require additional accommodations" arrow>
                                    <InfoIcon sx={{ color: 'gray', ml: 0.5, fontSize: 'medium' }} />
                                  </Tooltip>
                                </Box>
                              )}
                            </Box>
                          </MenuItem>
                        )),
                      ])}
                    </TextField>

                  </>
                ) : (
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    No container locations available for the selected university.
                  </Typography>
                )}
              </>
            )}
            <Button
              onClick={handleNext}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={!selectedHallId || !selectedMoveInLocationId}
            >
              Continue
            </Button>
          </Box>
        );
      }
      case 1: {
        const selectedUniversityData = universities.find(u => u.id === selectedUniversity);
        let moveOutDeadline = "";
        if (selectedUniversityData) {
          const earlyDeadline = convertTimestamp(selectedUniversityData.earlyRegistrationDeadline);
          const lateDeadline = convertTimestamp(selectedUniversityData.lateRegistrationDeadline);
          if (earlyDeadline && new Date() < earlyDeadline) {
            moveOutDeadline = formatDeadline(earlyDeadline);
          } else if (lateDeadline) {
            moveOutDeadline = formatDeadline(lateDeadline);
          }
        }
        return (
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 3 }}>
              <Container maxWidth="sm">
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", padding: 0 }}>
                  <Typography variant="h5" align="left">
                    Select Move-Out Date
                  </Typography>
                  <Button onClick={handleBack} variant="outlined" color="primary" sx={{ mb: 2 }}>
                    Back
                  </Button>
                </Box>
                <Box>
                  <Typography variant="subtitle2" gutterBottom align="left">
                    {moveOutDeadline && (
                      <> You can change your move‑out appointment until <strong>{moveOutDeadline}</strong> at no additional charge.</>
                    )}
                  </Typography>
                </Box>
                <AppointmentSelector
                  universityID={selectedUniversity}
                  containerLocationID={selectedHallId}
                  appointmentType="Move Out"
                  onSelect={(appointment) => {
                    // Include the isNonComplimentary flag from the appointment
                    setMoveOutDate({
                      appointmentId: appointment.id,
                      date: appointment.start,
                      isNonComplimentary: appointment.isNonComplimentaryAppointment,
                    });
                    handleNext();
                  }}
                />
              </Container>
            </Box>
          </Box>
        );
      }
      case 2: {
        const uniData = universities.find(u => u.id === selectedUniversity);
        let moveInDeadline = "July 20th"; // Fallback value
        if (uniData && uniData.moveInAppointmentEarlyDeadline) {
          const convertedDeadline = convertTimestamp(uniData.moveInAppointmentEarlyDeadline);
          moveInDeadline = formatDeadline(convertedDeadline);
        }
        return (
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 3 }}>
              <Container maxWidth="sm">
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", padding: 0 }}>
                  <Typography variant="h5" align="left">
                    Select Move-In Date
                  </Typography>
                  <Button onClick={handleBack} variant="outlined" color="primary" sx={{ mb: 2 }}>
                    Back
                  </Button>
                </Box>
                <Box>
                  <Typography variant="subtitle2" gutterBottom align="left">
                    You can change your move‑in appointment until <strong>{moveInDeadline}</strong> at no additional charge.
                  </Typography>
                </Box>
                <AppointmentSelector
                  universityID={selectedUniversity}
                  containerLocationID={selectedMoveInLocationId}
                  appointmentType="Move In"
                  onSelect={(appointment) => {
                    // Include the isNonComplimentary flag from the appointment
                    setMoveInDate({
                      appointmentId: appointment.id,
                      date: appointment.start,
                      isNonComplimentary: appointment.isNonComplimentaryAppointment,
                    });
                    handleNext();
                  }}
                />
              </Container>
            </Box>
          </Box>
        );
      }
      case 3:
        return (
          <Box sx={{ maxWidth: 400, mx: 'auto' }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 1 }}>
              Create an account
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoComplete="fname"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="University Email"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {/* New phone field with an input mask */}
            <InputMask
              mask="+9 (999) 999 99999"
              value={formData.phone}
              onChange={handleInputChange}
              name="phone"
              maskChar=""
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  defaultValue="1"
                  
                />
              )}
            </InputMask>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleInputChange}
                  
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="confirmPassword"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Move-Out Location
              </Typography>
              <Box sx={{ flexGrow: 1, mx: 1, borderBottom: '1px dotted', height: 0 }} />
              <Typography>{selectedHallName}</Typography>
              <IconButton onClick={() => setActiveStep(0)} size="small" color="primary" sx={{ ml: 1 }}>
                <EditIcon />
              </IconButton>
            </Box>
            <Box sx={{ mb: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Move-Out Date
              </Typography>
              <Box sx={{ flexGrow: 1, mx: 1, borderBottom: '1px dotted', height: 0 }} />
              <Typography>
                {selectedMoveOutDate ? selectedMoveOutDate.date.toDateString() : 'Not Selected'}
              </Typography>
              <IconButton onClick={() => setActiveStep(1)} size="small" color="primary" sx={{ ml: 1 }}>
                <EditIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Move-In Location
              </Typography>
              <Box sx={{ flexGrow: 1, mx: 1, borderBottom: '1px dotted', height: 0 }} />
              <Typography>{selectedMoveInLocationName}</Typography>
              <IconButton onClick={() => setActiveStep(0)} size="small" color="primary" sx={{ ml: 1 }}>
                <EditIcon />
              </IconButton>
            </Box>
            <Box sx={{ mb: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Move-In Date
              </Typography>
              <Box sx={{ flexGrow: 1, mx: 1, borderBottom: '1px dotted', height: 0 }} />
              <Typography>
                {selectedMoveInDate ? selectedMoveInDate.date.toDateString() : 'Not Selected'}
              </Typography>
              <IconButton onClick={() => setActiveStep(2)} size="small" color="primary" sx={{ ml: 1 }}>
                <EditIcon />
              </IconButton>
            </Box>

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="termsOfService"
                  onChange={handleInputChange}
                  checked={formData.termsOfService || false}
                />
              }
              label={
                <Box display="flex" alignItems="center">
                  <Typography variant="body2">
                    By checking this box, you agree with our{" "}
                    <Link onClick={() => setOpenDialog({ ...openDialog, terms: true })} sx={{ cursor: "pointer" }}>
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link onClick={() => setOpenDialog({ ...openDialog, privacy: true })} sx={{ cursor: "pointer" }}>
                      Privacy Policy
                    </Link>{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Box>
              }
            />

            <TermsPopup
              open={openDialog.terms}
              onClose={() => setOpenDialog({ ...openDialog, terms: false })}
              filePath="/tos.md"
              title="Terms of Service"
            />

            <TermsPopup
              open={openDialog.privacy}
              onClose={() => setOpenDialog({ ...openDialog, privacy: false })}
              filePath="/pp.md"
              title="Privacy Policy"
            />

            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Create Account"}
            </Button>
            <Button fullWidth onClick={handleBack} variant="outlined" color="primary" sx={{ mb: 2 }}>
              Back
            </Button>
            <Typography variant="body2" align="center">
              Already registered? <Link href="/login">Sign in here</Link>.
            </Typography>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  // Show partial progress on the first step by using (activeStep + 1) / steps.length
  const progress = ((activeStep + 1) / steps.length) * 100;

  return (
    <Box sx={{ mb: 0, height: '100vh', }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundImage: 'url(/images/background_pattern.webp)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed'
        }}
      >
        <NavBar />
        <Container
          maxWidth="sm"
          sx={{
            mt: 4,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          pb: 10
          }}
        >
          {isMobile ? (
            <Typography fontFamily="Work Sans" color="gray" fontWeight="700" variant="h5" gutterBottom sx={{ mb: 5 }}>
              Registration
            </Typography>
          ) : (
            <Typography fontFamily="Work Sans" color="gray" fontWeight="500" variant="h5" gutterBottom>
              Registration
            </Typography>
          )}

          {/* STEP HEADER */}
          <Box sx={{ width: '100%', mb: 1 }}>
            {isMobile ? (
              <Box textAlign="center" sx={{ mb: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
                  <Typography variant="h6" fontWeight="bold">
                    {steps[activeStep]}
                  </Typography>
                  <Typography variant="body1">
                    Step {activeStep + 1}/{steps.length}
                  </Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    width: '100%',
                    height: 8,
                    borderRadius: 4,
                  }}
                />
              </Box>
            ) : (
              <Box sx={{ width: "120%", ml: "-10%" }}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Step {activeStep + 1}/{steps.length}
                </Typography>

                <Box sx={{ display: 'flex', gap: 4, mb: 1, justifyContent: "space-between" }}>
                  {steps.map((label, index) => (
                    <Typography
                      key={label}
                      variant="body1"
                      fontWeight={index === activeStep ? 'bold' : 'normal'}
                      color={index === activeStep ? 'textPrimary' : 'textSecondary'}
                    >
                      {label}
                    </Typography>
                  ))}
                </Box>

                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    width: '100%',
                    height: 8,
                    borderRadius: 4,
                  }}
                />
              </Box>
            )}
          </Box>

          <Fade in key={activeStep}>
            <Box
              sx={{
                width: '100%',
                p: 3,
                border: 2,
                borderColor: 'lightgray',
                borderRadius: 2,
                mt: 2,
                backgroundColor: 'white',
              }}
            >
              {renderStepContent(activeStep)}
            </Box>
          </Fade>
        </Container>
      </Box>
      <Footer>
        </Footer>       
    </Box>
    
  );
};

export default Registration;
