import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  TablePagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  InputAdornment
} from '@mui/material';
import { Add, GetApp, ClearAll, Edit, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';

import UserTableDetails from '../../components/UserTableDetails';
import UserTableAppointments from '../../components/UserTableAppointments';
import UserTableFinancials from '../../components/UserTableFinancials';

const functions = getFunctions();

const UsersDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [view, setView] = useState('details'); // 'details', 'appointments', or 'financials'
  const [filterAnchors, setFilterAnchors] = useState({});
  const [filters, setFilters] = useState({});
  const [filterSearch, setFilterSearch] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Get Firestore users.
        const querySnapshot = await getDocs(collection(db, "users"));
        const firestoreUsers = querySnapshot.docs.map(doc => {
          const data = doc.data();
          const registrationDate = data.registrationDate
            ? new Date(data.registrationDate.seconds * 1000).toLocaleDateString()
            : 'N/A';
          return { id: doc.id, ...data, registrationDate, source: 'firestore' };
        });
        setUsers(firestoreUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleClickOpen = () => {
    setEditingUser(null);
    setOpen(true);
  };

  const handleEditClick = (user) => {
    navigate(`/dashboard/users/${user.id}`);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingUser(null);
  };

  const handleSave = () => {
    // Logic to save the user
    setOpen(false);
    setEditingUser(null);
  };

  const handleExport = () => {
    // Logic to export user data
  };

  // Filtering and sorting handlers (applied for details view).
  const handleFilterClick = (event, column) => {
    setFilterAnchors({ ...filterAnchors, [column]: event.currentTarget });
  };

  const handleFilterClose = (column) => {
    setFilterAnchors({ ...filterAnchors, [column]: null });
  };

  const handleFilterChange = (column, value) => {
    const columnFilters = filters[column] || [];
    const newFilters = columnFilters.includes(value)
      ? columnFilters.filter((v) => v !== value)
      : [...columnFilters, value];
    setFilters({ ...filters, [column]: newFilters });
  };

  const handleFilterSearchChange = (event) => {
    setFilterSearch(event.target.value);
  };

  const handleSelectAll = (column) => {
    const columnValues = users.map((row) => row[column])
      .filter((value, index, self) => self.indexOf(value) === index);
    setFilters({ ...filters, [column]: columnValues });
  };

  const handleDeselectAll = (column) => {
    setFilters({ ...filters, [column]: [] });
  };

  const handleSort = (column) => {
    const isAsc = sortOrder[column] === 'asc';
    setSortOrder({ [column]: isAsc ? 'desc' : 'asc' });
    const sortedUsers = [...users].sort((a, b) => {
      if (a[column] < b[column]) return isAsc ? -1 : 1;
      if (a[column] > b[column]) return isAsc ? 1 : -1;
      return 0;
    });
    setUsers(sortedUsers);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clearFilters = () => {
    setFilters({});
    setSortOrder({});
    setFilterSearch('');
  };

  const applyFilters = (data) => {
    return data.filter((row) =>
      Object.keys(filters).every((column) =>
        filters[column]?.length ? filters[column].includes(row[column]) : true
      )
    );
  };
  const filteredUsers = applyFilters(users);

  const handleViewChange = (event, nextView) => {
    if (nextView !== null) {
      setView(nextView);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, py: 3, pr: 3}}>
      <Typography variant="h4" gutterBottom>
        Users Dashboard
      </Typography>
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        sx={{ mb: 2 }}
      >
        <ToggleButton value="details">User Details</ToggleButton>
        <ToggleButton value="appointments">Appointments</ToggleButton>
        <ToggleButton value="financials">Storage Plan</ToggleButton>
      </ToggleButtonGroup>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        {view === 'details' && <Typography variant="h6">Registered Users</Typography>}
        {view === 'appointments' && <Typography variant="h6">User Appointments</Typography>}
        {view === 'financials' && <Typography variant="h6">User Storage Plans</Typography>}
        <Box>
          <Tooltip title="Export User Data">
            <IconButton onClick={handleExport}>
              <GetApp />
            </IconButton>
          </Tooltip>
          {Object.keys(filters).some(column => filters[column]?.length) && view === 'details' && (
            <Button variant="outlined" startIcon={<ClearAll />} onClick={clearFilters} sx={{ mr: 2 }}>
              Clear Filters
            </Button>
          )}
          {view === 'details' && (
            <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleClickOpen}>
              Create New User
            </Button>
          )}
        </Box>
      </Box>
      <Paper sx={{p: 2, maxWidth: "95vw"}}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <CircularProgress />
          </Box>
        ) : (
            <Box
              sx={{
                width: '100%',
                overflowX: 'auto', // allows horizontal scroll only within this Box
                WebkitOverflowScrolling: 'touch', // smooth scrolling on iOS
              }}
            >
            {view === 'details' && (
              <>
                <UserTableDetails
                  users={filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                  // Pass theme if needed; otherwise, adjust as required.
                  theme={{ palette: { action: { hover: '#f5f5f5' } } }}
                  filters={filters}
                  filterAnchors={filterAnchors}
                  filterSearch={filterSearch}
                  sortOrder={sortOrder}
                  handleFilterClick={handleFilterClick}
                  handleFilterClose={handleFilterClose}
                  handleFilterChange={handleFilterChange}
                  handleFilterSearchChange={handleFilterSearchChange}
                  handleSelectAll={handleSelectAll}
                  handleDeselectAll={handleDeselectAll}
                  handleSort={handleSort}
                  handleEditClick={handleEditClick}
                />
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  component="div"
                  count={filteredUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </>
            )}
            {view === 'appointments' && <UserTableAppointments users={users} />}
            {view === 'financials' && <UserTableFinancials users={users} />}
          </Box>
        )}
      </Paper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingUser ? 'Edit User' : 'Create New User'}</DialogTitle>
        <DialogContent>
          <TextField margin="dense" label="First Name" fullWidth defaultValue={editingUser?.firstName || ''} />
          <TextField margin="dense" label="Last Name" fullWidth defaultValue={editingUser?.lastName || ''} />
          <TextField margin="dense" label="University" fullWidth defaultValue={editingUser?.universityId || ''} />
          <TextField margin="dense" label="Role" fullWidth defaultValue={editingUser?.role || ''} />
          <TextField margin="dense" label="Email" fullWidth defaultValue={editingUser?.email || ''} />
          <TextField margin="dense" label="Registration Date" type="date" fullWidth defaultValue={editingUser?.registrationDate || ''} />
          <TextField margin="dense" label="Status" fullWidth defaultValue={editingUser?.status || ''} />
          <TextField margin="dense" label="Registration Phase" fullWidth defaultValue={editingUser?.registrationPhase || ''} />
          <TextField margin="dense" label="UID" fullWidth defaultValue={editingUser?.uid || ''} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            {editingUser ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UsersDashboard;
