import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

// A component to render the segmented capacity bar.
// Used segments (green) come first and anticipated segments (yellow) follow.
const SegmentedCapacityBar = ({ segments, totalCapacity }) => {
    // Sum the total volume used (used + anticipated) from all segments.
    const totalSegmentVolume = segments.reduce(
        (sum, seg) => sum + seg.usedVolume + seg.anticipatedVolume,
        0
    );

    return (
        <Box
            sx={{
                width: '100%',
                height: '20px',
                backgroundColor: '#e0e0e0',
                borderRadius: '4px',
                display: 'flex',
            }}
        >
            {/* Render used volume segments (green) first */}
            {segments.map(
                (segment) =>
                    segment.usedVolume > 0 && (
                        <Tooltip
                            key={`used-${segment.id}`}
                            title={`Customer ID: ${segment.id} – Used: ${segment.usedVolume.toFixed(2)} ft³`}
                        >
                            <Box
                                sx={{
                                    width: `${(segment.usedVolume / totalCapacity) * 100}%`,
                                    height: '100%',
                                    backgroundColor: '#4caf50',
                                    borderRight: '2px solid #e0e0e0',
                                    boxSizing: 'border-box',
                                }}
                            />
                        </Tooltip>
                    )
            )}
            {/* Render anticipated volume segments (yellow) next */}
            {segments.map(
                (segment) =>
                    segment.anticipatedVolume > 0 && (
                        <Tooltip
                            key={`anticipated-${segment.id}`}
                            title={`Customer ID: ${segment.id} – Anticipated: ${segment.anticipatedVolume.toFixed(
                                2
                            )} ft³`}
                        >
                            <Box
                                sx={{
                                    width: `${(segment.anticipatedVolume / totalCapacity) * 100}%`,
                                    height: '100%',
                                    backgroundColor: 'yellow',
                                    borderRight: '2px solid #e0e0e0',
                                    boxSizing: 'border-box',
                                }}
                            />
                        </Tooltip>
                    )
            )}
            {/* If there is remaining capacity, fill it */}
            {totalSegmentVolume < totalCapacity && (
                <Box
                    sx={{
                        width: `${((totalCapacity - totalSegmentVolume) / totalCapacity) * 100}%`,
                        height: '100%',
                        borderTopRightRadius: '4px',
                        borderBottomRightRadius: '4px',
                    }}
                />
            )}
        </Box>
    );
};

// A component to render a minimal capacity bar showing only total used and total anticipated volumes.
const MinimalCapacityBar = ({ segments, totalCapacity }) => {
    // Calculate total used and total anticipated volumes
    const totalUsed = segments.reduce((sum, seg) => sum + seg.usedVolume, 0);
    const totalAnticipated = segments.reduce((sum, seg) => sum + seg.anticipatedVolume, 0);
    const totalSegmentVolume = totalUsed + totalAnticipated;


    console.log("------------");
    console.log("Total used: ", totalUsed);
    console.log("Total anticipated: ", totalAnticipated);
    console.log("Total segment: ", totalSegmentVolume);
    console.log("Total capacity: ", totalCapacity);
    console.log("------------");


    return (
        <Box
            sx={{
                width: '100%',
                height: '20px',
                backgroundColor: '#e0e0e0',
                borderRadius: '4px',
                display: 'flex',
            }}
        >
            {totalUsed > 0 && (
                <Tooltip title={`Total Used: ${totalUsed.toFixed(2)} ft³`}>
                    <Box
                        sx={{
                            width: `${(totalUsed / totalCapacity) * 100}%`,
                            height: '100%',
                            backgroundColor: '#4caf50',
                            borderRight: '2px solid #e0e0e0',
                            boxSizing: 'border-box',
                        }}
                    />
                </Tooltip>
            )}
            {totalAnticipated > 0 && (
                <Tooltip title={`Total Anticipated: ${totalAnticipated.toFixed(2)} ft³`}>
                    <Box
                        sx={{
                            width: `${(totalAnticipated / totalCapacity) * 100}%`,
                            height: '100%',
                            backgroundColor: 'yellow',
                            borderRight: '2px solid #e0e0e0',
                            boxSizing: 'border-box',
                        }}
                    />
                </Tooltip>
            )}
            {totalSegmentVolume < totalCapacity && (
                <Box
                    sx={{
                        width: `${((totalCapacity - totalSegmentVolume) / totalCapacity) * 100}%`,
                        height: '100%',
                        borderTopRightRadius: '4px',
                        borderBottomRightRadius: '4px',
                    }}
                />
            )}
        </Box>
    );
};

// The CapacityBar component.
// Props:
//  - containerId: Firestore document ID for the container.
//  - period: one of "moveOut", "moveIn", or "summer"
//  - minimal: if true, renders a minimal capacity bar.
const CapacityBar = ({ containerId, period, minimal = false }) => {
    const [loading, setLoading] = useState(true);
    const [totalCapacity, setTotalCapacity] = useState(0);
    const [segments, setSegments] = useState([]); // Each segment: { id, usedVolume, anticipatedVolume }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // 1. Fetch the container document.
                const containerRef = doc(db, 'containers', containerId);
                const containerSnap = await getDoc(containerRef);
                if (!containerSnap.exists()) {
                    console.error('Container not found');
                    setLoading(false);
                    return;
                }
                const containerData = { id: containerSnap.id, ...containerSnap.data() };

                // Set the total capacity (assumed to be in ft³)
                const capacity = containerData.spaceCapacity || 0;
                setTotalCapacity(capacity);

                console.log("Container data: ", containerData);

                // 2. Get the customer IDs for the given period.
                // Expecting containerData to have arrays like moveOutCustomers, moveInCustomers, summerCustomers.
                const periodKey = `${period}Customers`;
                const customerIds = containerData[periodKey] || [];

                // 3. For each customer, fetch inventory and compute usedVolume,
                // then fetch the customer doc to get maxItems and compute anticipatedVolume.
                const segmentsData = await Promise.all(
                    customerIds.map(async (customerId) => {
                        let usedVolume = 0;
                        try {
                            // Fetch inventory for the customer.
                            const inventoryCollection = collection(db, `inventoryUsers/${customerId}/items`);
                            const inventoryDocs = await getDocs(inventoryCollection);
                            const items = inventoryDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                            items.forEach((item) => {
                                if (
                                    item.dimensions &&
                                    item.dimensions.width &&
                                    item.dimensions.height &&
                                    item.dimensions.length
                                ) {
                                    // Calculate volume in cubic feet.
                                    const volumeCubicFeet =
                                        (item.dimensions.width * item.dimensions.height * item.dimensions.length) /
                                        1728;
                                    usedVolume += volumeCubicFeet;
                                }
                            });
                        } catch (error) {
                            console.error(`Error fetching inventory for customer ${customerId}:`, error);
                        }

                        // Fetch the user document for anticipated volume info.
                        let anticipatedVolume = 0;
                        try {
                            const userDoc = await getDoc(doc(db, 'users', customerId));
                            console.log("Customer: ", customerId);
                            if (userDoc.exists()) {
                                const userData = userDoc.data();
                                if (userData.maxItems) {
                                    // Total anticipated volume is maxItems * 3.
                                    // Subtract the used volume; do not let it go below 0.
                                    anticipatedVolume = Math.max(userData.maxItems * 3 - usedVolume, 0);
                                }
                            }
                        } catch (error) {
                            console.error(`Error fetching user data for customer ${customerId}:`, error);
                        }

                        console.log("cap bar: ", );
                        return { id: customerId, usedVolume, anticipatedVolume };
                    })
                );

                setSegments(segmentsData);

                console.log("data: ", segmentsData);
            } catch (error) {
                console.error('Error fetching capacity bar data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [containerId, period]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress size={24} />
            </Box>
        );
    }

    return (
        <Box>
            {minimal ? (
                <MinimalCapacityBar segments={segments} totalCapacity={totalCapacity} />
            ) : (
                <SegmentedCapacityBar segments={segments} totalCapacity={totalCapacity} />
            )}
        </Box>
    );
};

export default CapacityBar;
