import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    InputAdornment,
    Button,
    Divider,
    Checkbox,
    ListItemText,
} from '@mui/material';
import {
    FilterAltOutlined,
    FilterAlt,
    Search,
    ArrowDropDown,
    ArrowDropUp,
    Edit,
    Delete,
} from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const UserTableAppointments = ({ users, handleEditClick }) => {
    const theme = useTheme();

    // State for appointment details loaded from Firestore
    const [appointmentData, setAppointmentData] = useState({});

    // Filtering & Sorting states
    const [filters, setFilters] = useState({});
    const [filterAnchors, setFilterAnchors] = useState({});
    const [filterSearch, setFilterSearch] = useState('');
    const [sortOrder, setSortOrder] = useState({});

    // Define columns for the table (key and label).
    const columns = [
        { key: 'customerName', label: 'Customer Name' },
        { key: 'university', label: 'University' },
        { key: 'moveOutLocation', label: 'Move Out Location' },
        { key: 'moveOutTime', label: 'Move Out Time' },
        { key: 'moveInLocation', label: 'Move In Location' },
        { key: 'moveInTime', label: 'Move In Time' },
        { key: 'containerId', label: 'Container Id' },
        { key: 'storageCoordinator', label: 'Storage Coordinator' },
        { key: 'special', label: 'Special' },
    ];

    // --- Firestore fetching helpers (same as before) ---

    // Fetch an appointment document from the containerLocations or specialAppointments subcollection.
    const fetchAppointment = async (universityId, containerLocationId, appointmentId) => {
        if (!appointmentId) return null;
        try {
            // Try containerLocations first.
            const appointmentRef = doc(
                db,
                `universities/${universityId}/containerLocations/${containerLocationId}/appointments`,
                appointmentId
            );
            const appointmentSnap = await getDoc(appointmentRef);
            if (appointmentSnap.exists()) {
                return { ...appointmentSnap.data(), isSpecial: false };
            } else {
                // If not found, check specialAppointments.
                const specialRef = doc(db, `universities/${universityId}/specialAppointments`, appointmentId);
                const specialSnap = await getDoc(specialRef);
                if (specialSnap.exists()) {
                    return { ...specialSnap.data(), isSpecial: true };
                }
            }
        } catch (error) {
            console.error('Error fetching appointment:', error);
        }
        return null;
    };

    // Helper to format a Firebase timestamp to a string like "May 6th"
    const formatDate = (timestamp) => {
        if (!timestamp?.seconds) return 'N/A';
        const date = new Date(timestamp.seconds * 1000);
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate();
        let suffix = 'th';
        if (day % 10 === 1 && day !== 11) {
            suffix = 'st';
        } else if (day % 10 === 2 && day !== 12) {
            suffix = 'nd';
        } else if (day % 10 === 3 && day !== 13) {
            suffix = 'rd';
        }
        return `${month} ${day}${suffix}`;
    };

    // Fetch container location document for a given containerLocationId.
    const fetchContainerLocation = async (universityId, containerLocationId) => {
        if (!containerLocationId) return null;
        try {
            const containerRef = doc(db, `universities/${universityId}/containerLocations`, containerLocationId);
            const containerSnap = await getDoc(containerRef);
            if (containerSnap.exists()) {
                return containerSnap.data();
            }
        } catch (error) {
            console.error('Error fetching container location:', error);
        }
        return null;
    };

    // Preload appointment data and container locations for each user.
    useEffect(() => {
        const loadAppointments = async () => {
            const newAppointments = {};
            for (const user of users) {
                const moveInAppointment = await fetchAppointment(
                    user.universityId,
                    user.moveInContainerLocationID,
                    user.moveInAppointment
                );
                const moveOutAppointment = await fetchAppointment(
                    user.universityId,
                    user.moveOutContainerLocationID,
                    user.moveOutAppointment
                );

                const moveInContainer = await fetchContainerLocation(user.universityId, user.moveInContainerLocationID);
                const moveOutContainer = await fetchContainerLocation(user.universityId, user.moveOutContainerLocationID);

                newAppointments[user.id] = {
                    moveInLocation: moveInContainer ? moveInContainer.name : 'N/A',
                    moveOutLocation: moveOutContainer ? moveOutContainer.name : 'N/A',
                    moveInTime:
                        moveInAppointment && moveInAppointment.start ? formatDate(moveInAppointment.start) : 'N/A',
                    moveOutTime:
                        moveOutAppointment && moveOutAppointment.start ? formatDate(moveOutAppointment.start) : 'N/A',
                    containerId:
                        (moveInAppointment && moveInAppointment.containerId) ||
                        (moveOutAppointment && moveOutAppointment.containerId) ||
                        'N/A',
                    storageCoordinator:
                        (moveInAppointment && moveInAppointment.storageCoordinator) ||
                        (moveOutAppointment && moveOutAppointment.storageCoordinator) ||
                        'N/A',
                    isSpecial:
                        (moveInAppointment && moveInAppointment.isSpecial) ||
                        (moveOutAppointment && moveOutAppointment.isSpecial) ||
                        false,
                };
            }
            setAppointmentData(newAppointments);
        };

        if (users.length > 0) {
            loadAppointments();
        }
    }, [users]);

    // --- Prepare combined rows (user + appointment data) ---
    const rows = users.map((user) => {
        const appointment = appointmentData[user.id] || {};
        return {
            id: user.id,
            customerName: `${user.firstName || ''} ${user.lastName || ''}`.trim() || 'N/A',
            university: user.universityId || 'N/A',
            moveOutLocation: appointment.moveOutLocation || 'N/A',
            moveOutTime: appointment.moveOutTime || 'N/A',
            moveInLocation: appointment.moveInLocation || 'N/A',
            moveInTime: appointment.moveInTime || 'N/A',
            containerId: appointment.containerId || 'N/A',
            storageCoordinator: appointment.storageCoordinator || 'N/A',
            special: appointment.isSpecial ? 'Yes' : 'No',
        };
    });

    // --- Filtering and Sorting logic ---
    const filteredRows = rows.filter((row) =>
        Object.keys(filters).every((col) => {
            if (filters[col] && filters[col].length > 0) {
                return filters[col].includes(row[col]);
            }
            return true;
        })
    );

    const sortedRows = useMemo(() => {
        let sorted = [...filteredRows];
        // Assume only one column is sorted at a time.
        const sortColumn = Object.keys(sortOrder).find((col) => sortOrder[col]);
        if (sortColumn) {
            const order = sortOrder[sortColumn];
            sorted.sort((a, b) => {
                if (a[sortColumn] < b[sortColumn]) return order === 'asc' ? -1 : 1;
                if (a[sortColumn] > b[sortColumn]) return order === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return sorted;
    }, [filteredRows, sortOrder]);

    // --- Filter Menu Handlers ---
    const handleFilterClick = (event, columnKey) => {
        setFilterAnchors((prev) => ({ ...prev, [columnKey]: event.currentTarget }));
    };

    const handleFilterClose = (columnKey) => {
        setFilterAnchors((prev) => ({ ...prev, [columnKey]: null }));
    };

    const handleFilterSearchChange = (event) => {
        setFilterSearch(event.target.value);
    };

    const handleFilterChange = (columnKey, value) => {
        setFilters((prev) => {
            const current = prev[columnKey] || [];
            let newValues;
            if (current.includes(value)) {
                newValues = current.filter((v) => v !== value);
            } else {
                newValues = [...current, value];
            }
            return { ...prev, [columnKey]: newValues };
        });
    };

    const handleSelectAll = (columnKey) => {
        const allValues = getUniqueColumnValues(columnKey);
        setFilters((prev) => ({ ...prev, [columnKey]: allValues }));
    };

    const handleDeselectAll = (columnKey) => {
        setFilters((prev) => ({ ...prev, [columnKey]: [] }));
    };

    const handleSort = (columnKey) => {
        setSortOrder((prev) => {
            const current = prev[columnKey];
            const newOrder = current === 'asc' ? 'desc' : 'asc';
            // Reset sort order for all columns, then set for the selected one.
            return { [columnKey]: newOrder };
        });
    };

    // Get unique values for a given column from the current rows.
    const getUniqueColumnValues = (columnKey) => {
        const valuesSet = new Set();
        rows.forEach((row) => {
            valuesSet.add(row[columnKey] || 'N/A');
        });
        return Array.from(valuesSet);
    };

    return (
        <TableContainer component="div" sx={{ width: '100%', overflowX: 'scroll', WebkitOverflowScrolling: 'touch' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.key}
                                sx={{ pr: 0, pl: 2, fontSize: { xs: '0.7rem', md: '0.9rem' } }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        fontWeight="bold"
                                        sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}
                                    >
                                        {column.label}
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => handleFilterClick(e, column.key)}
                                        sx={{ fontWeight: filters[column.key]?.length ? 'bold' : 'normal' }}
                                    >
                                        {filters[column.key]?.length ? (
                                            <FilterAlt fontSize="small" />
                                        ) : (
                                            <FilterAltOutlined sx={{ strokeWidth: 2 }} fontSize="small" />
                                        )}
                                    </IconButton>
                                    <Menu
                                        anchorEl={filterAnchors[column.key]}
                                        open={Boolean(filterAnchors[column.key])}
                                        onClose={() => handleFilterClose(column.key)}
                                    >
                                        <Box sx={{ p: 1 }}>
                                            <TextField
                                                variant="outlined"
                                                placeholder="Search..."
                                                fullWidth
                                                value={filterSearch}
                                                onChange={handleFilterSearchChange}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search fontSize="small" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                size="small"
                                            />
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                <Button onClick={() => handleSelectAll(column.key)} size="small">
                                                    Select All
                                                </Button>
                                                <Button onClick={() => handleDeselectAll(column.key)} size="small">
                                                    Deselect All
                                                </Button>
                                            </Box>
                                            <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                                                {getUniqueColumnValues(column.key)
                                                    .filter((value) => value.toLowerCase().includes(filterSearch.toLowerCase()))
                                                    .map((value) => (
                                                        <MenuItem key={value} onClick={() => handleFilterChange(column.key, value)}>
                                                            <Checkbox
                                                                checked={filters[column.key]?.includes(value) || false}
                                                                size="small"
                                                            />
                                                            <ListItemText
                                                                primary={value}
                                                                primaryTypographyProps={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}
                                                            />
                                                        </MenuItem>
                                                    ))}
                                            </Box>
                                            <Divider />
                                            <Button onClick={() => handleSort(column.key)} size="small">
                                                {sortOrder[column.key] === 'asc' ? (
                                                    <ArrowDropUp fontSize="small" />
                                                ) : (
                                                    <ArrowDropDown fontSize="small" />
                                                )}
                                                Sort
                                            </Button>
                                        </Box>
                                    </Menu>
                                </Box>
                            </TableCell>
                        ))}
                        <TableCell sx={{ pr: 0, pl: 2, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                            <Typography fontWeight="bold" sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                Actions
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedRows.map((row, index) => (
                        <TableRow
                            key={row.id}
                            sx={{
                                backgroundColor: index % 2 === 0 ? theme.palette.action.hover : 'inherit',
                            }}
                        >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.key}
                                    sx={{ py: 0, fontSize: { xs: '0.7rem', md: '0.9rem' } }}
                                >
                                    {row[column.key]}
                                </TableCell>
                            ))}
                            <TableCell sx={{ py: 0 }}>
                                <IconButton onClick={() => handleEditClick(row)} size="small">
                                    <Edit fontSize="small" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UserTableAppointments;
