import React from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
    Button,
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import AddBoxIcon from '@mui/icons-material/AddBox';

const CoordinatorDashboard = () => {
    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            {/* --------- OVERVIEW SECTION --------- */}
            <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ color: 'text.secondary', textTransform: 'uppercase' }}
            >
                Overview
            </Typography>
            <Grid container spacing={2}>
                {/* Left Half: Container Details */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            Container Details
                        </Typography>
                        <Typography variant="body1">
                            {/* Replace with real container details */}
                            Container ID: #12345
                            <br />
                            Location: Building A, Floor 2
                            <br />
                            Status: In Transit
                        </Typography>
                    </Paper>
                </Grid>

                {/* Right Half: Two Horizontal Outlined Icon Buttons */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<AddBoxIcon sx={{ fontSize: 28 }} />}
                            onClick={() => console.log('Add Item clicked')}
                            sx={{ height: '100%', mb: 2 }}
                        >
                            Add Item
                        </Button>
                        <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<PeopleIcon sx={{ fontSize: 28 }} />}
                            onClick={() => console.log('Check In Customer clicked')}
                            sx={{ height: '100%' }}
                        >
                            Check In Customer
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {/* --------- OPERATIONS SECTION --------- */}
            <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ color: 'text.secondary', textTransform: 'uppercase', mt: 3 }}
            >
                Operations
            </Typography>
            <Grid container spacing={2}>
                {/* Left Third: Schedule */}
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            Schedule
                        </Typography>
                        {/* Replace with real schedule data */}
                        <Typography variant="body1" paragraph>
                            10:00 AM - Check container #12345
                        </Typography>
                        <Typography variant="body1" paragraph>
                            11:30 AM - Phone call with warehouse
                        </Typography>
                        <Typography variant="body1" paragraph>
                            2:00 PM - Customer pickup
                        </Typography>
                    </Paper>
                </Grid>

                {/* Right Two-Thirds: Customer Table */}
                <Grid item xs={12} md={8}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            Customers
                        </Typography>
                        {/* Replace with a real table or MUI DataGrid */}
                        <table width="100%" style={{ borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #ccc',
                                            textAlign: 'left',
                                            paddingBottom: '8px',
                                        }}
                                    >
                                        Customer Name
                                    </th>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #ccc',
                                            textAlign: 'left',
                                            paddingBottom: '8px',
                                        }}
                                    >
                                        Status
                                    </th>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #ccc',
                                            textAlign: 'left',
                                            paddingBottom: '8px',
                                        }}
                                    >
                                        Last Check-In
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>John Doe</td>
                                    <td>Active</td>
                                    <td>09/01/2025</td>
                                </tr>
                                <tr>
                                    <td>Jane Smith</td>
                                    <td>Pending</td>
                                    <td>09/03/2025</td>
                                </tr>
                                <tr>
                                    <td>Sam Wilson</td>
                                    <td>Active</td>
                                    <td>09/05/2025</td>
                                </tr>
                            </tbody>
                        </table>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CoordinatorDashboard;
