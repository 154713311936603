import React, { Suspense, lazy } from 'react';
import { Container, Box, Typography } from '@mui/material';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import FAQ from '../components/FAQ'


const FAQPage = () => {
    return (
        <Box>
            <NavBar />
            <Container
                maxWidth="xl"
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'flex-start',
                    py: 8,
                }}
            >
                {/* FAQ Component on the left */}
                <Box sx={{ flex: 1, pr: { md: 4 } }}>
                        <Typography variant="h4" align="left" sx={{ mb: 1, fontWeight: 'bold' }}>
                            Frequently Asked Questions
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mb: 4 }}>
                            Everything you need to know about storing with SummerStore.
                        </Typography>
                        <FAQ />
                </Box>
                {/* Image on the right */}
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component="img"
                        src="/images/movers_box_tall.jpg"
                        alt="Movers Box"
                        sx={{
                            width: '100%',
                            maxWidth: 600,
                        }}
                    />
                </Box>
            </Container>
            <Footer />
        </Box>
    );
};

export default FAQPage;
