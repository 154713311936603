import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Tabs,
  Tab,
  InputAdornment,
  MenuItem,
  Switch,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { useNotification } from '../../contexts/NotificationContext';
import Search from '@mui/icons-material/Search';
import StorableItemsSearch from '../../components/StorableItemsSearch';

const loadAllConfigs = async () => {
  try {
    const itemConfigsModule = await import('../../config/items.json');
    const itemConfigs = itemConfigsModule.default || itemConfigsModule;
    if (!Array.isArray(itemConfigs)) {
      throw new TypeError('itemConfigs is not an array');
    }
    const items = {};
    itemConfigs.forEach((itemConfig) => {
      items[itemConfig.id] = itemConfig;
    });
    return items;
  } catch (error) {
    console.error('Error loading item configurations:', error);
    return {};
  }
};

const AddItem = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const db = getFirestore();
  const { addNotification } = useNotification();

  const [activeStep, setActiveStep] = useState(0);
  const [itemType, setItemType] = useState('');
  const [itemConfig, setItemConfig] = useState(null);
  const [allConfigs, setAllConfigs] = useState({});
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [dimensions, setDimensions] = useState({ length: '', width: '', height: '' });
  const [promptAnswers, setPromptAnswers] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newItemType, setNewItemType] = useState(null);
  const [newItemTypeLabel, setNewItemTypeLabel] = useState(null);
  const [activeInfo, setActiveInfo] = useState({});
  const [hasStoragePlan, setHasStoragePlan] = useState(false);

  const [itemNameError, setItemNameError] = useState(false);
  const [dimensionsError, setDimensionsError] = useState({ length: false, width: false, height: false });
  const [isSaving, setIsSaving] = useState(false);

  // "donationOption" holds the user's choice: "donate" or "store"
  const [donationOption, setDonationOption] = useState('store');

  useEffect(() => {
    const loadConfigs = async () => {
      const configs = await loadAllConfigs();
      setAllConfigs(configs);
    };
    loadConfigs();
  }, []);

  useEffect(() => {
    if (itemType) {
      const config = allConfigs[itemType];
      setItemConfig(config);
      // Autofill the item name using the config's label
      if (config && config.label) {
        setItemName(config.label);
      }
      if (config && config.lockedDimensions) {
        setDimensions(config.lockedDimensions);
      }
      if (config?.prompts) {
        const defaultAnswers = config.prompts.reduce((answers, prompt, index) => {
          if (prompt.defaultAnswer !== undefined) {
            answers[index] = prompt.defaultAnswer === 'true';
          }
          return answers;
        }, {});
        setPromptAnswers(defaultAnswers);
      }
    }
  }, [itemType, allConfigs]);

  useEffect(() => {
    const checkStoragePlan = async () => {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists() && userDoc.data().storagePlan) {
        setHasStoragePlan(true);
      } else {
        setHasStoragePlan(false);
      }
    };
    checkStoragePlan();
  }, [currentUser.uid, db]);

  const steps = useMemo(() => {
    // Base steps for adding an item.
    const baseSteps = ['Select Item Type', 'Item Details'];
    if (itemConfig?.prompts && itemConfig.prompts.length > 0) {
      baseSteps.push('Packing Instructions');
    }
    // Rename Donation step to "GradBag Donation"
    baseSteps.push('GradBag Donation');
    baseSteps.push('Confirmation');
    return baseSteps;
  }, [itemConfig]);

  const handleNext = () => {
    if (activeStep === 1 && !validateItemData()) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setItemName('');
      setItemDescription('');
      setDimensions({ length: '', width: '', height: '' });
      setPromptAnswers({});
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    navigate('/dashboard/items');
  };

  const validateItemData = () => {
    let isValid = true;

    if (!itemType) {
      addNotification({ status: 'error', message: 'Item type is required.' });
      isValid = false;
    }
    if (!itemName.trim()) {
      setItemNameError(true);
      addNotification({ status: 'error', message: 'Item name is required.' });
      isValid = false;
    } else {
      setItemNameError(false);
    }

    if (!itemConfig?.lockedDimensions) {
      let dimensionsError = { length: false, width: false, height: false };
      if (!dimensions.length || !dimensions.width || !dimensions.height) {
        addNotification({ status: 'error', message: 'Item dimensions are required.' });
        dimensionsError = {
          length: !dimensions.length,
          width: !dimensions.width,
          height: !dimensions.height,
        };
        isValid = false;
      } else if (isNaN(dimensions.length) || isNaN(dimensions.width) || isNaN(dimensions.height)) {
        addNotification({ status: 'error', message: 'Dimensions must be numbers.' });
        dimensionsError = {
          length: isNaN(dimensions.length),
          width: isNaN(dimensions.width),
          height: isNaN(dimensions.height),
        };
        isValid = false;
      }
      setDimensionsError(dimensionsError);
    }
    return isValid;
  };

  const handleSave = async () => {
    if (!validateItemData()) {
      return;
    }

    setIsSaving(true);
    try {
      const functions = getFunctions();
      const addItem = httpsCallable(functions, 'addItem');

      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const userData = userDoc.data();

      // Convert donation option to a boolean for the backend.
      const donate = donationOption === 'donate';

      console.log('addItem parameters:', {
        itemType,
        itemName,
        itemDescription,
        dimensions,
        promptAnswers,
        donate,
        userId: currentUser.uid,
        containerLocationId: userData.moveOutContainerLocationID,
      });

      const response = await addItem({
        itemType,
        itemName,
        itemDescription,
        dimensions,
        promptAnswers,
        donate,
        userId: currentUser.uid,
        containerLocationId: userData.moveOutContainerLocationID,
      });

      if (response.data.success) {
        addNotification({ status: 'success', message: 'Item added successfully!' });
        navigate('/dashboard/items');
      }
    } catch (error) {
      console.error('Error adding item:', error);
      addNotification({ status: 'error', message: error.message });
    } finally {
      setIsSaving(false);
    }
  };

  const handlePromptAnswerChange = (questionIndex, answer) => {
    setPromptAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [questionIndex]: answer };
      const prompt = itemConfig.prompts[questionIndex];
      if (prompt && prompt.next_steps && prompt.next_steps[answer]) {
        const newStep = prompt.next_steps[answer].find((step) => step.newItemType);
        if (newStep) {
          const newItemType = newStep.newItemType;
          const newItemTypeConfig = allConfigs[newItemType];
          if (newItemTypeConfig) {
            setNewItemType(newItemType);
            setNewItemTypeLabel(newItemTypeConfig.label);
            setDialogOpen(true);
            return prevAnswers;
          }
        }
      }
      return newAnswers;
    });
  };

  const handleDialogClose = (confirm) => {
    setDialogOpen(false);
    if (confirm && newItemType) {
      setItemType(newItemType);
      setNewItemType(null);
      setNewItemTypeLabel(null);
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const handleShowInfo = (id, infoType) => {
    setActiveInfo({ id, infoType });
  };

  const handleHideInfo = () => {
    setActiveInfo({});
  };

  const categories = ['All', ...new Set(Object.values(allConfigs).map((config) => config.category))];

  const filteredItems = Object.values(allConfigs).filter(
    (config) =>
      (selectedCategory === 0 || config.category === categories[selectedCategory]) &&
      config.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (selectedCategory === 0 || selectedCategory === 1) {
    const summerStoreBoxIndex = filteredItems.findIndex((config) => config.id === 'summerstore_box');
    if (summerStoreBoxIndex !== -1) {
      const [summerStoreBoxItem] = filteredItems.splice(summerStoreBoxIndex, 1);
      filteredItems.unshift(summerStoreBoxItem);
    }
  }

  const getPackingInstructions = () => {
    if (!itemConfig?.prompts) return [];
    return itemConfig.prompts.flatMap((prompt, index) => {
      const answer = promptAnswers[index];
      if (answer !== undefined && prompt.next_steps && prompt.next_steps[answer]) {
        return prompt.next_steps[answer].map((step) => step.instruction);
      }
      return [];
    });
  };

  return (
    <Box sx={{ p: 3, flexGrow: 1, width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Add Item
      </Typography>
      <Paper sx={{ p: 3 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <StorableItemsSearch
            allConfigs={allConfigs}
            handleCancel={handleCancel}
            setItemType={setItemType}
            handleNext={handleNext}
          />
        )}

        {activeStep === 1 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Item Details</Typography>
            <TextField
              label="Item Name"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
              error={itemNameError}
              helperText={itemNameError && 'Item name is required.'}
            />
            <TextField
              label="Item Description"
              value={itemDescription}
              onChange={(e) => setItemDescription(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            {itemConfig?.prompts && (
              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Important Questions
              </Typography>
            )}
            {itemConfig?.prompts?.map((prompt, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="body1">{prompt.question}</Typography>
                {prompt.type === 'boolean' && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={prompt.defaultAnswer === 'true'}
                        onChange={(e) => handlePromptAnswerChange(index, e.target.checked)}
                      />
                    }
                    label={prompt.defaultAnswer === 'true' ? 'Yes' : 'No'}
                  />
                )}
              </Box>
            ))}
            {itemConfig?.lockedDimensions ? (
              <>
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Dimensions
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                  <TextField
                    label="Length (inches)"
                    value={dimensions.length}
                    InputProps={{ readOnly: true }}
                    disabled={true}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Width (inches)"
                    value={dimensions.width}
                    InputProps={{ readOnly: true }}
                    disabled={true}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Height (inches)"
                    value={dimensions.height}
                    InputProps={{ readOnly: true }}
                    disabled={true}
                    sx={{ mb: 2 }}
                  />
                </Box>
              </>
            ) : (
              <>
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Item Dimensions
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                  <TextField
                    label="Length (inches)"
                    value={dimensions.length}
                    onChange={(e) => setDimensions({ ...dimensions, length: e.target.value })}
                    sx={{ mb: 2 }}
                    error={dimensionsError.length}
                    helperText={dimensionsError.length && 'Length is required and must be a number.'}
                  />
                  <TextField
                    label="Width (inches)"
                    value={dimensions.width}
                    onChange={(e) => setDimensions({ ...dimensions, width: e.target.value })}
                    sx={{ mb: 2 }}
                    error={dimensionsError.width}
                    helperText={dimensionsError.width && 'Width is required and must be a number.'}
                  />
                  <TextField
                    label="Height (inches)"
                    value={dimensions.height}
                    onChange={(e) => setDimensions({ ...dimensions, height: e.target.value })}
                    sx={{ mb: 2 }}
                    error={dimensionsError.height}
                    helperText={dimensionsError.height && 'Height is required and must be a number.'}
                  />
                </Box>
              </>
            )}
          </Box>
        )}

        {steps.includes('Packing Instructions') &&
          activeStep === steps.indexOf('Packing Instructions') && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Packing Instructions</Typography>
              {getPackingInstructions().map((instruction, index) => (
                <Typography key={index} variant="body1" sx={{ mb: 2 }}>
                  {instruction}
                </Typography>
              ))}
            </Box>
          )}

        {/* GradBag Donation Step */}
        {steps.includes('GradBag Donation') &&
          activeStep === steps.indexOf('GradBag Donation') && (
            <Box sx={{ mt: 5 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Typography variant="h6" gutterBottom sx={{fontWeight: 'bold'}}>
                    Would you like to donate this item?
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                  Our partner, <strong>Grad Bag</strong>, provides a more equitable transition to college by collecting, refreshing, and repackaging lightly used dorm room essentials for redistribution to incoming students in-need. 
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    Please select one of the options below:
                  </Typography>
                  <RadioGroup
                    value={donationOption}
                    onChange={(e) => setDonationOption(e.target.value)}
                  >
                    <FormControlLabel
                      value="donate"
                      control={<Radio />}
                      label="Yes, I'd like to donate this item to incoming students of need"
                    />
                    <FormControlLabel
                      value="store"
                      control={<Radio />}
                      label="No, I would like to store this item with SummerStore"
                    />
                  </RadioGroup>
                  <Typography variant="h6" sx={{mt: 2, fontWeight: "bold"}}>
                    How it works
                  </Typography>
                  <Typography>
                  On their move out day, SummerStore customers are asked to bring all of their items to a designated location outside of their residential hall for storage services. During this appointment, donated items can be brought to that same location to be marked and seperated for collection by Grad Bag.
                  </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  href="https://www.gradbag.org/"
                  target="_blank"
                  sx={{ mt: 4 }}
                >
                  Learn More
                </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box sx={{ textAlign: 'center' }}>
                    <img src="/images/gradbag_donation.jpeg" alt="GradBag Donation" style={{ width: '100%', maxWidth: '350px' }} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}

        {activeStep === steps.length - 1 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Confirmation</Typography>
            <Typography variant="body1">
              <strong>Item Type:</strong> {itemConfig?.label}
            </Typography>
            <Typography variant="body1">
              <strong>Item Name:</strong> {itemName}
            </Typography>
            <Typography variant="body1">
              <strong>Item Description:</strong> {itemDescription}
            </Typography>
            {itemConfig?.lockedDimensions && (
              <Typography variant="body1">
                <strong>Dimensions:</strong> {dimensions.length}x{dimensions.width}x{dimensions.height}
              </Typography>
            )}
            <Typography variant="body1">
              <strong>Donation Option:</strong> {donationOption === 'donate' ? 'Donate to GradBag' : 'Store Item'}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button variant="outlined" color="secondary" onClick={handleCancel} sx={{ mr: 2 }}>
            Cancel
          </Button>
          {activeStep > 0 && (
            <Button variant="outlined" onClick={handleBack} sx={{ mr: 2 }}>
              Back
            </Button>
          )}
          {activeStep === steps.length - 1 ? (
            <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaving}>
              {isSaving ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          )}
        </Box>
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will change the item type from <strong>{itemConfig?.label}</strong> to <strong>{newItemTypeLabel}</strong>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddItem;
