import React, { useEffect, useState } from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    CircularProgress,
    Box,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ContainerCustomerItemsList from './ContainerCustomerItemsList';

const ContainerCustomersTable = ({ container, onCustomerMoveClick, collapsable }) => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [expandedRows, setExpandedRows] = useState({});

    // Helper to format a single period value.
    const formatPeriod = (period) => {
        if (period === 'moveIn') return 'Move In';
        if (period === 'moveOut') return 'Move Out';
        if (period === 'summer') return 'Summer';
        return period;
    };

    // Define custom order: Move Out first, then Summer, then Move In.
    const periodOrder = { moveOut: 1, summer: 2, moveIn: 3 };

    // Format an array of periods (ensuring it’s an array) and order them.
    const formatPeriods = (periods) => {
        const periodArray = Array.isArray(periods) ? periods : [];
        const sorted = periodArray.sort((a, b) => periodOrder[a] - periodOrder[b]);
        return sorted.map((p) => formatPeriod(p)).join(', ');
    };

    useEffect(() => {
        const fetchCustomers = async () => {
            setLoading(true);
            try {
                const customersMap = {};

                // Helper to fetch customers for a given period.
                const fetchPeriod = async (periodKey, containerKey) => {
                    if (container && container[containerKey] && container[containerKey].length > 0) {
                        const results = await Promise.all(
                            container[containerKey].map(async (userId) => {
                                const userDocRef = doc(db, 'users', userId);
                                const userSnap = await getDoc(userDocRef);
                                return { id: userId, data: userSnap.data() };
                            })
                        );
                        results.forEach(({ id, data }) => {
                            if (customersMap[id]) {
                                // Ensure periods is stored as an array.
                                if (!Array.isArray(customersMap[id].periods)) {
                                    customersMap[id].periods = [customersMap[id].periods];
                                }
                                if (!customersMap[id].periods.includes(periodKey)) {
                                    customersMap[id].periods.push(periodKey);
                                }
                            } else {
                                customersMap[id] = { id, periods: [periodKey], ...data };
                            }
                        });
                    }
                };

                await fetchPeriod('moveIn', 'moveInCustomers');
                await fetchPeriod('moveOut', 'moveOutCustomers');
                await fetchPeriod('summer', 'summerCustomers'); // Assumes summerCustomers exists in container.
                setCustomers(Object.values(customersMap));
            } catch (error) {
                console.error('Error fetching customers:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCustomers();
    }, [container]);

    const handleMenuOpen = (event, customer) => {
        setAnchorEl(event.currentTarget);
        setSelectedCustomer(customer);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedCustomer(null);
    };

    const handleMenuItemClick = (action) => {
        if (onCustomerMoveClick && selectedCustomer) {
            onCustomerMoveClick(selectedCustomer, action, container.id);
        }
        handleMenuClose();
    };

    const toggleRow = (customerId) => {
        setExpandedRows((prev) => ({
            ...prev,
            [customerId]: !prev[customerId],
        }));
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!customers.length) {
        return (
            <Typography variant="body2" sx={{ p: 2 }}>
                No customers found.
            </Typography>
        );
    }

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Periods</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customers.map((user) => (
                            <React.Fragment key={user.id}>
                                <TableRow>
                                    
                                    <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{formatPeriods(user.periods)}</TableCell>
                                    <TableCell>
                                        {collapsable && (
                                            <IconButton size="small" onClick={() => toggleRow(user.id)}>
                                                <Inventory2Icon
                                                    // Optionally, add a rotation to indicate expanded state
                                                    sx={{
                                                        transform: expandedRows[user.id] ? 'rotate(90deg)' : 'rotate(0deg)',
                                                        transition: 'transform 0.2s',
                                                    }}
                                                />
                                            </IconButton>
                                        )}
                                        <IconButton onClick={(event) => handleMenuOpen(event, user)}>
                                            <MoveUpIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                {collapsable && expandedRows[user.id] && (
                                    <TableRow>
                                        <TableCell colSpan={collapsable ? 5 : 4} sx={{ backgroundColor: '#f9f9f9' }}>
                                            <ContainerCustomerItemsList
                                                customer={user}
                                                customerId={user.id}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <Typography variant="subtitle2" sx={{ pl: 2, pr: 2, pt: 1 }}>
                    Change container for:
                </Typography>
                <MenuItem onClick={() => handleMenuItemClick('moveOut')}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Move Out Period
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('summer')}>
                    <ListItemIcon>
                        <SystemUpdateAltIcon fontSize="small" />
                    </ListItemIcon>
                    Summer Period
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('moveIn')}>
                    <ListItemIcon>
                        <LoginIcon fontSize="small" />
                    </ListItemIcon>
                    Move In Period
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default ContainerCustomersTable;
