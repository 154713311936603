import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Delete,
  Edit,
  Add,
  Logout,
  Login,
  ContentCopy, // Duplicate icon
} from '@mui/icons-material';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate, useParams } from 'react-router-dom';

const AppointmentList = () => {
  const { universityID, containerLocationID } = useParams();
  const [appointments, setAppointments] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [batchEditDialogOpen, setBatchEditDialogOpen] = useState(false);
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const [universityName, setUniversityName] = useState('');
  const [containerLocationName, setContainerLocationName] = useState('');
  const navigate = useNavigate();
  const [newAppointment, setNewAppointment] = useState({
    start: '',
    end: '',
    maxCustomers: 0,
    storageCoordinator: '',
    type: 'Move In', // Default type
  });
  const [batchEditData, setBatchEditData] = useState({
    maxCustomers: 0,
    storageCoordinator: '',
    type: '',
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    const fetchNames = async () => {
      try {
        // Fetch university name
        const universityDoc = await getDoc(doc(db, 'universities', universityID));
        if (universityDoc.exists()) {
          setUniversityName(universityDoc.data().name || 'Unknown University');
        }

        // Fetch container location name
        const locationDoc = await getDoc(
          doc(db, `universities/${universityID}/containerLocations`, containerLocationID)
        );
        if (locationDoc.exists()) {
          setContainerLocationName(locationDoc.data().name || 'Unknown Location');
        }
      } catch (error) {
        console.error('Error fetching names:', error);
      }
    };

    const fetchAppointments = async () => {
      try {
        const appointmentsCollection = collection(
          db,
          `universities/${universityID}/containerLocations/${containerLocationID}/appointments`
        );
        const snapshot = await getDocs(appointmentsCollection);

        const appointmentsData = snapshot.docs.map((doc) => {
          const data = doc.data();

          // Handle Firebase Timestamp conversion
          const start = data.start?.toDate ? data.start.toDate() : new Date(data.start);
          const end = data.end?.toDate ? data.end.toDate() : new Date(data.end);

          return {
            id: doc.id,
            ...data,
            start,
            end,
          };
        });

        // Sort appointments by start date ascending
        appointmentsData.sort((a, b) => a.start - b.start);

        setAppointments(appointmentsData);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      }
    };

    fetchNames();
    fetchAppointments();
  }, [universityID, containerLocationID]);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleAddAppointment = async () => {
    try {
      const appointmentRef = await addDoc(
        collection(db, `universities/${universityID}/containerLocations/${containerLocationID}/appointments`),
        {
          ...newAppointment,
          start: new Date(newAppointment.start),
          end: new Date(newAppointment.end),
        }
      );

      const newApp = {
        id: appointmentRef.id,
        ...newAppointment,
        start: new Date(newAppointment.start),
        end: new Date(newAppointment.end),
      };

      setAppointments((prev) => {
        const updated = [...prev, newApp];
        updated.sort((a, b) => a.start - b.start); // Sort after adding
        return updated;
      });

      setSnackbar({
        open: true,
        message: 'Appointment added successfully!',
        severity: 'success',
      });
      handleCloseDialog();
    } catch (error) {
      console.error('Error adding appointment:', error);
      setSnackbar({
        open: true,
        message: 'Failed to add appointment.',
        severity: 'error',
      });
    }
  };

  const handleBatchEdit = async () => {
    try {
      // Filter out fields that have not been changed (empty strings)
      const filteredBatchEditData = Object.fromEntries(
        Object.entries(batchEditData).filter(([key, value]) => value !== '')
      );

      const updatedAppointments = appointments.map((appointment) => {
        if (selectedAppointments.includes(appointment.id)) {
          return { ...appointment, ...filteredBatchEditData };
        }
        return appointment;
      });

      await Promise.all(
        selectedAppointments.map((id) =>
          updateDoc(
            doc(
              db,
              `universities/${universityID}/containerLocations/${containerLocationID}/appointments`,
              id
            ),
            filteredBatchEditData
          )
        )
      );

      // Sort after batch edit
      updatedAppointments.sort((a, b) => a.start - b.start);

      setAppointments(updatedAppointments);
      setSelectedAppointments([]);
      setBatchEditDialogOpen(false);

      setSnackbar({
        open: true,
        message: 'Batch edit successful!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error during batch edit:', error);
      setSnackbar({
        open: true,
        message: 'Batch edit failed.',
        severity: 'error',
      });
    }
  };

  const handleDeleteAppointment = async (id) => {
    try {
      await deleteDoc(
        doc(db, `universities/${universityID}/containerLocations/${containerLocationID}/appointments`, id)
      );
      setAppointments((prev) => prev.filter((appointment) => appointment.id !== id));

      setSnackbar({
        open: true,
        message: 'Appointment deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting appointment:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete appointment.',
        severity: 'error',
      });
    }
  };

  const handleDuplicateAppointment = async (appointment) => {
    try {
      // Create a new appointment object excluding the id and title
      const { id, title, ...appointmentData } = appointment;

      const appointmentRef = await addDoc(
        collection(db, `universities/${universityID}/containerLocations/${containerLocationID}/appointments`),
        {
          ...appointmentData,
          start: new Date(appointment.start),
          end: new Date(appointment.end),
        }
      );

      const duplicatedAppointment = {
        id: appointmentRef.id,
        ...appointmentData,
        start: new Date(appointment.start),
        end: new Date(appointment.end),
      };

      setAppointments((prev) => {
        const updated = [...prev, duplicatedAppointment];
        updated.sort((a, b) => a.start - b.start); // Sort after duplicating
        return updated;
      });

      setSnackbar({
        open: true,
        message: 'Appointment duplicated successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error duplicating appointment:', error);
      setSnackbar({
        open: true,
        message: 'Failed to duplicate appointment.',
        severity: 'error',
      });
    }
  };

  const renderAppointmentTable = (appointments) => {
    // Sort the appointments by start date ascending
    const sortedAppointments = [...appointments].sort((a, b) => a.start - b.start);

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              {/* Title column removed */}
              <TableCell>Day</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Max Customers</TableCell>
              <TableCell>Storage Coordinator</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAppointments.map((appointment) => (
              <TableRow key={appointment.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAppointments.includes(appointment.id)}
                    onChange={() =>
                      setSelectedAppointments((prev) =>
                        prev.includes(appointment.id)
                          ? prev.filter((id) => id !== appointment.id)
                          : [...prev, appointment.id]
                      )
                    }
                  />
                </TableCell>
                {/* Title cell removed */}
                <TableCell>{appointment.start.toDateString()}</TableCell>
                <TableCell>
                  {appointment.start.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </TableCell>
                <TableCell>
                  {appointment.end.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </TableCell>
                <TableCell>{appointment.maxCustomers}</TableCell>
                <TableCell>{appointment.storageCoordinator}</TableCell>
                <TableCell>
                  <Tooltip title="Edit Appointment">
                    <IconButton size="small" onClick={() => navigate(`${appointment.id}`)}>
                      <Edit fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Appointment">
                    <IconButton size="small" onClick={() => handleDeleteAppointment(appointment.id)}>
                      <Delete fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Duplicate Appointment">
                    <IconButton size="small" onClick={() => handleDuplicateAppointment(appointment)}>
                      <ContentCopy fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Button onClick={() => navigate(-1)} variant="outlined" color="primary" sx={{ width: 'auto', mb: 2 }}>
        Back
      </Button>

      <Typography variant="h4" sx={{ mb: 1 }}>
        Manage Appointments
      </Typography>
      <Typography variant="subtitle2" gutterBottom sx={{ color: 'gray', mb: 2 }}>
        Showing <strong>{appointments.length}</strong> appointments for <strong>{containerLocationName}</strong>
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          sx={{ mb: 2 }}
        >
          <Tab
            label="Move Out Appointments"
            icon={<Logout />}
            iconPosition="start" // Icon on the left
          />
          <Tab
            label="Move In Appointments"
            icon={<Login />}
            iconPosition="start" // Icon on the left
          />
        </Tabs>

        <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
          <Button
            variant="contained"
            size="small"
            sx={{ height: '100%' }}
            startIcon={<Add />}
            onClick={handleOpenDialog}
          >
            Add Appointment
          </Button>
          {selectedAppointments.length > 0 && (
            <Button
              variant="outlined"
              startIcon={<Edit />}
              onClick={() => setBatchEditDialogOpen(true)}
            >
              Batch Edit
            </Button>
          )}
        </Box>
      </Box>

      {/* Render Sorted Appointments Based on Selected Tab */}
      {selectedTab === 1 &&
        renderAppointmentTable(appointments.filter((app) => app.type === 'Move In'))}
      {selectedTab === 0 &&
        renderAppointmentTable(appointments.filter((app) => app.type === 'Move Out'))}

      {/* Add Appointment Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add Appointment</DialogTitle>
        <DialogContent>
          {/* Removed Title TextField */}
          <TextField
            fullWidth
            label="Start Time"
            type="datetime-local"
            margin="dense"
            InputLabelProps={{ shrink: true }}
            value={newAppointment.start}
            onChange={(e) => setNewAppointment({ ...newAppointment, start: e.target.value })}
          />
          <TextField
            fullWidth
            label="End Time"
            type="datetime-local"
            margin="dense"
            InputLabelProps={{ shrink: true }}
            value={newAppointment.end}
            onChange={(e) => setNewAppointment({ ...newAppointment, end: e.target.value })}
          />
          <TextField
            fullWidth
            label="Max Customers"
            type="number"
            margin="dense"
            value={newAppointment.maxCustomers}
            onChange={(e) =>
              setNewAppointment({ ...newAppointment, maxCustomers: parseInt(e.target.value, 10) })
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Storage Coordinator</InputLabel>
            <Select
              value={newAppointment.storageCoordinator}
              onChange={(e) =>
                setNewAppointment({ ...newAppointment, storageCoordinator: e.target.value })
              }
              label="Storage Coordinator"
            >
              <MenuItem value="Coordinator A">Coordinator A</MenuItem>
              <MenuItem value="Coordinator B">Coordinator B</MenuItem>
              <MenuItem value="Coordinator C">Coordinator C</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Type</InputLabel>
            <Select
              value={newAppointment.type}
              onChange={(e) => setNewAppointment({ ...newAppointment, type: e.target.value })}
              label="Type"
            >
              <MenuItem value="Move In">Move In</MenuItem>
              <MenuItem value="Move Out">Move Out</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddAppointment} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Batch Edit Dialog */}
      <Dialog
        open={batchEditDialogOpen}
        onClose={() => setBatchEditDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Batch Edit Appointments</DialogTitle>
        <DialogContent>
          {/* Removed Title TextField */}
          <TextField
            fullWidth
            label="Max Customers"
            type="number"
            margin="dense"
            onChange={(e) =>
              setBatchEditData((prev) => ({
                ...prev,
                maxCustomers: parseInt(e.target.value, 10) || prev.maxCustomers,
              }))
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Storage Coordinator</InputLabel>
            <Select
              value={batchEditData.storageCoordinator}
              onChange={(e) =>
                setBatchEditData((prev) => ({
                  ...prev,
                  storageCoordinator: e.target.value || prev.storageCoordinator,
                }))
              }
              label="Storage Coordinator"
            >
              <MenuItem value="Coordinator A">Coordinator A</MenuItem>
              <MenuItem value="Coordinator B">Coordinator B</MenuItem>
              <MenuItem value="Coordinator C">Coordinator C</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBatchEditDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleBatchEdit} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AppointmentList;
