import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, Button, Tab, Tabs, Skeleton, Link } from '@mui/material';
import { Check, Inventory2, LocalOfferRounded } from '@mui/icons-material';
import PriceComparison from '../components/PriceComparison';
import { useNavigate } from 'react-router-dom';

const plans = [
  {
    id: '0001',
    title: '10 Items',
    subtitle: 'Boxes, Furniture, or Electronics',
    price: '$250',
    registrationFee: '',
    perItemPrice: '$20',
    image: '/images/Plan1-01.webp',
    features: [
      '4 months of storage for up to 10 items',
      'Need more? Add items for $30 each.',
      'Boxes & Tape',
      '$100 insurance per item',
      'Any sized items. Terms apply.',
      'Student movers for $35',
    ],
  },
  {
    id: '0002',
    title: '20 Items',
    subtitle: 'Boxes, Furniture, or Electronics',
    price: '$450',
    registrationFee: '',
    perItemPrice: '$20',
    image: '/images/Plan2-01.webp',
    features: [
      '4 months of storage for up to 20 items',
      'Need more? Add items for $30 each.',
      'Boxes & Tape',
      '$100 insurance per item',
      'Any sized items. Terms apply.',
      'Student movers for $35',
    ],
  },
  {
    id: '0003',
    title: '30 Items',
    subtitle: 'Boxes, Furniture, or Electronics',
    price: '$650',
    registrationFee: '',
    perItemPrice: '$20',
    image: '/images/Plan3-01.webp',
    features: [
      '4 months of storage for up to 30 items',
      'Need more? Add items for $30 each.',
      'Boxes & Tape',
      '$100 insurance per item',
      'Any sized items. Terms apply.',
      'Student movers for $35',
    ],
  },
  {
    id: '0004',
    title: 'Per Item',
    subtitle: 'Pay for each item individually.',
    price: '$30',
    registrationFee: '+$50 Registration Fee',
    image: '/images/Plan4-01.webp',
    features: [
      '4 months of storage per item',
      'Boxes & Tape',
      'Optional item insurance',
      'Any sized items. Terms apply.',
      'Student movers for $35',
    ],
  },
];

const StoragePlan = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Header with three columns: Left (Title), Center (Tabs), Right (Storable Items) */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
          flexWrap: 'wrap',
        }}
      >
        {/* Left: Page Title and Subtitle */}
        <Box
          sx={{
            flex: { xs: '100%', md: '33%' },
            textAlign: { xs: 'center', md: 'left' },
            mb: { xs: 2, md: 0 },
            order: { xs: 0, md: 0 },
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Storage Plans
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Choose a plan that suits your storage needs.
          </Typography>
        </Box>

        {/* Right: Storable Items description */}
        <Box
          sx={{
            flex: { xs: '100%', md: '33%' },
            textAlign: { xs: 'center', md: 'right' },
            mb: { xs: 2, md: 0 },
            order: { xs: 1, md: 2 },
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Storable Items
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Boxes, fridges, furniture, and more!
          </Typography>
          <Link
            href="/storable-items"
            underline="hover"
            sx={{ mt: 1, display: 'inline-block' }}
          >
            View all storable items
          </Link>
        </Box>

        {/* Center: Tabs */}
        <Box
          sx={{
            flex: { xs: '100%', md: '33%' },
            textAlign: 'center',
            mb: { xs: 2, md: 0 },
            order: { xs: 2, md: 1 },
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Storage Plan Tabs"
            centered
          >
            <Tab icon={<Inventory2 />} label="Available Plans" />
            <Tab icon={<LocalOfferRounded />} label="Price Comparison" />
          </Tabs>
        </Box>
      </Box>

      {/* Main content below the header */}
      {tabIndex === 0 && (
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
          {loading ? (
            <Grid container spacing={3} maxWidth="xl" sx={{ mt: 1 }}>
              {Array.from(new Array(4)).map((_, index) => (
                <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
                  <Skeleton variant="rectangular" height={350} sx={{ borderRadius: 2 }} />
                  <Skeleton variant="text" sx={{ mt: 1 }} />
                  <Skeleton variant="text" width="60%" />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container spacing={3} maxWidth="xl" sx={{ mt: 1 }}>
              {plans.map((plan) => {
                const isPerItem = plan.title === 'Per Item';
                const priceValue = parseFloat(plan.price.replace('$', ''));
                const numberOfItems = isPerItem ? 1 : parseInt(plan.title.split(' ')[0], 10);
                const monthlyPrice = isPerItem
                  ? (parseFloat(plan.price.replace('$', '')) / 4).toFixed(2)
                  : (priceValue / numberOfItems / 4).toFixed(2);

                return (
                  <Grid item xs={12} sm={6} md={6} lg={3} key={plan.id}>
                    <Paper
                      elevation={3}
                      sx={{
                        mx: { xs: 2, md: 0 },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        borderRadius: 2,
                        borderColor: 'primary.main',
                        borderWidth: 1,
                        backgroundColor: 'none',
                        borderStyle: 'solid',
                        position: 'relative',
                        height: '100%',
                        overflow: 'hidden',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: isPerItem ? 'transparent' : 'primary.main',
                          width: '100%',
                          pt: 3,
                          color: isPerItem ? 'unset' : 'white',
                          backgroundImage: 'url(/images/background_pattern.webp)',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                        }}
                      >
                        <Typography variant="h5" sx={{ fontWeight: '1000' }}>
                          {plan.title}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: isPerItem ? 'gray' : 'lightgray',
                            mb: 1,
                          }}
                        >
                          {plan.subtitle}
                        </Typography>
                        <Box component="img" src={plan.image} alt={plan.title} sx={{ height: 150, mb: 2 }} />
                      </Box>
                      <Box sx={{ p: 2, px: 3, width: '100%' }}>
                        <Box sx={{ width: '100%', textAlign: 'left', mb: 3 }}>
                          {isPerItem ? (
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                $50
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  display: 'block',
                                  pl: 1,
                                  color: 'text.secondary',
                                }}
                              >
                                + $30/item
                              </Typography>
                            </Box>
                          ) : (
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                              {plan.price}
                            </Typography>
                          )}
                          <Typography variant="body1" sx={{ color: 'gray', mt: 0.5 }}>
                            (Approx. <strong>${monthlyPrice}</strong> per item, per month.)
                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          sx={{
                            mt: 'auto',
                            mb: 3,
                            p: 1.5,
                            fontWeight: 'bold',
                          }}
                          onClick={() => navigate('/registration')}
                        >
                          Get Started
                        </Button>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: '100%',
                          }}
                        >
                          <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                            <strong>Includes all of the following:</strong>
                          </Typography>
                          {plan.features.map((feature, idx) => (
                            <Box key={idx} sx={{ display: 'flex', flexDirection: 'row', color: 'text.secondary' }}>
                              <Check sx={{ height: '75%', pr: 1 }} />
                              <Typography variant="body2" sx={{ mb: 2 }}>
                                {feature}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Box>
      )}
      {tabIndex === 1 && (
        <Box sx={{ mt: 2 }}>
          {loading ? (
            <>
              <Skeleton variant="text" width="80%" height={40} sx={{ mb: 2, mx: 'auto' }} />
              <Skeleton variant="rectangular" width="100%" height={300} />
            </>
          ) : (
            <>
              <Typography
                variant="h4"
                sx={{
                  mb: 2,
                  fontFamily: 'Work Sans',
                  color: 'primary.main',
                  mt: 2,
                }}
              >
                If you store <strong>10 items </strong> with...
              </Typography>
              <PriceComparison />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default StoragePlan;
