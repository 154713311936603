import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Popover,
  Paper,
  List,
  ListItem,
  ButtonGroup,
  Button,
  Badge,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import AlarmIcon from '@mui/icons-material/Alarm';
import WarningIcon from '@mui/icons-material/Warning';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { IconPickerItem } from 'react-icons-picker';

const Header = ({ menuOpen, setMenuOpen, toggleSidebar, sidebarOpen, breadcrumb }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const { currentUser, logout, userClaims } = useAuth();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const notificationOpen = Boolean(notificationAnchorEl);

  // Fetch notifications from Firebase
  const fetchNotifications = async (userId) => {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      console.log('No user data found');
      return [];
    }

    const userData = userSnap.data();
    const shownNotifications = userData.notifications || [];

    const notifications = [];
    for (const notification of shownNotifications) {
      const notifRef = doc(db, 'notifications', notification.messageId);
      const notifSnap = await getDoc(notifRef);
      if (notifSnap.exists()) {
        notifications.push({ id: notifSnap.id, ...notifSnap.data() });
      }
    }

    return notifications;
  };

  useEffect(() => {
    const loadNotifications = async () => {
      if (currentUser?.uid) {
        const notifs = await fetchNotifications(currentUser.uid);
        setNotifications(notifs);
      }
    };

    loadNotifications();
  }, [currentUser]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();
    await logout();
    navigate('/login');
  };

  const handleSettings = async () => {
    handleClose();
    navigate('settings');
  };

  // Updated: getIcon now looks for webIcon; if absent, it falls back to type-based icons.
  const getIcon = (notification) => {
    if (notification.webIcon) {
      return <IconPickerItem value={notification.webIcon} size={24} />;
    }
    switch (notification.type) {
      case 'mail':
        return <MailIcon sx={{ color: 'white' }} />;
      case 'alarm':
        return <AlarmIcon sx={{ color: 'white' }} />;
      case 'warning':
        return <WarningIcon sx={{ color: 'white' }} />;
      default:
        return <NotificationsIcon sx={{ color: 'white' }} />;
    }
  };

  // Updated: getAvatarColor now checks for webPrimaryColor
  const getAvatarColor = (notification) => {
    if (notification.webPrimaryColor) {
      return notification.webPrimaryColor;
    }
    switch (notification.type) {
      case 'mail':
        return '#007bff';
      case 'alarm':
        return '#ff9800';
      case 'warning':
        return '#f44336';
      default:
        return '#757575';
    }
  };

  const firstName = userClaims.firstName || 'User';
  const lastName = userClaims.lastName || '';
  const profileInitial = firstName.charAt(0);
  const fullName = `${firstName} ${lastName || ''}`.trim();
  const email = currentUser?.email || '';

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: { md: 'transparent', xs: 'primary.main' },
          boxShadow: 'none',
          color: { md: 'black', xs: 'white' },
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', pt: { xs: 1 }, pb: { xs: 2 } }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              <IconButton onClick={toggleSidebar} sx={{ color: { md: 'black', xs: 'white' }, mt: { xs: 2 }, pl: 1, pr: 2 }}>
                <MenuIcon />
              </IconButton>
              <Link to="/" style={{ height: '18px' }}>
                <Box
                  component="img"
                  src="/images/SummerStore-Logo.webp"
                  alt="SummerStore Logo"
                  sx={{
                    display: { md: 'none', xs: 'block' },
                    height: '15px',
                    width: 'auto',
                    mt: { md: 3, xs: '17px' },
                    pl: 2,
                  }}
                />
              </Link>
            </Box>
            <Typography variant="h6" sx={{ ml: 2, display: { md: 'block', xs: 'none' }, mt: 2 }}>
              {breadcrumb}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: { xs: 2 } }}>
            {/* Notification Bell with Badge */}
            <IconButton onClick={handleNotificationClick} sx={{ ml: 2, color: { xs: 'white', md: 'gray' } }}>
              <Badge badgeContent={notifications.length > 0 ? notifications.length : null} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            {/* Notification Popover */}
            <Popover
              anchorEl={notificationAnchorEl}
              open={notificationOpen}
              onClose={handleNotificationClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Paper sx={{ p: 2, minWidth: 400 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">Notifications</Typography>
                  <IconButton size="small" onClick={handleNotificationClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                {notifications.length ? (
                  <List>
                    {notifications.map((notification, index) => (
                      <React.Fragment key={notification.id}>
                        <ListItem sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar sx={{ bgcolor: getAvatarColor(notification), mr: 2 }}>
                            {getIcon(notification)}
                          </Avatar>
                          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', flexDirection: "column" }}>
                              <Typography sx={{ fontSize: '0.875rem', whiteSpace: 'normal' }}>
                                {notification.webTitle}
                              </Typography>
                              <Typography sx={{ fontSize: '0.800rem', whiteSpace: 'normal', color: "gray" }}>
                                {notification.webText}
                              </Typography>
                              <Typography variant="caption" color="textSecondary" sx={{ ml: 2 }}>
                                {notification.time}
                              </Typography>
                            </Box>
                            {notification.actions && (
                              <ButtonGroup variant="outlined" size="small" sx={{ mt: 1 }}>
                                {notification.actions.map((action, idx) => (
                                  <Button key={idx}>{action}</Button>
                                ))}
                              </ButtonGroup>
                            )}
                          </Box>
                        </ListItem>
                        {index !== notifications.length - 1 && <Divider />}
                      </React.Fragment>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    You're all caught up!
                  </Typography>
                )}
              </Paper>
            </Popover>

            <IconButton onClick={handleClick} sx={{ ml: 2 }}>
              <Avatar sx={{ bgcolor: '#007bff' }}>{profileInitial}</Avatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 1,
                sx: {
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: 'auto',
                    mr: 'auto',
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <Box sx={{ padding: 2, textAlign: 'center' }}>
                <Avatar sx={{ bgcolor: '#007bff', margin: '0 auto' }}>{profileInitial}</Avatar>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  {fullName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {email}
                </Typography>
              </Box>
              <Divider />
              <MenuItem onClick={handleSettings}>
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="body2">Account Settings</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="body2">Sign out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
