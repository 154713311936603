import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    TextField,
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    Divider
} from '@mui/material';
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaCcDiscover } from 'react-icons/fa';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LockIcon from '@mui/icons-material/Lock';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputMask from 'react-input-mask';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNotification } from '../contexts/NotificationContext';

const AccountBillingSettings = ({ user }) => {
    const functions = getFunctions();
    const { addNotification } = useNotification();
    const [loading, setLoading] = useState(true);
    const [savedCards, setSavedCards] = useState([]);
    const [customerProfileId, setCustomerProfileId] = useState(null);
    // State for tracking which card is currently being deleted.
    const [deletingCardId, setDeletingCardId] = useState(null);

    // State for adding a new payment method.
    const [showAddForm, setShowAddForm] = useState(false);
    const [newPaymentData, setNewPaymentData] = useState({
        cardNumber: '',
        cardHolder: '',
        expiry: '',
        cvv: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        firstName: '',
        lastName: '',
        phone: ''
    });

    // Error state for new payment fields.
    const [errors, setErrors] = useState({
        cardNumber: '',
        cardHolder: '',
        expiry: '',
        cvv: '',
        address: '',
        city: '',
        state: '',
        zip: ''
    });

    // Fetch saved payment methods when component mounts.
    useEffect(() => {
        const fetchPaymentMethods = async () => {
            if (!user?.uid) {
                setLoading(false);
                return;
            }
            try {
                const userDocRef = doc(db, 'users', user.uid);
                const docSnap = await getDoc(userDocRef);
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    if (userData.authorizeNetID) {
                        setCustomerProfileId(userData.authorizeNetID);
                        const getAuthorizeNetProfile = httpsCallable(functions, 'getAuthorizeNetProfile');
                        const result = await getAuthorizeNetProfile({ customerId: userData.authorizeNetID });
                        if (result.data?.profiles?.length > 0) {
                            setSavedCards(result.data.profiles);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching payment methods:', error);
                addNotification({ message: 'Error fetching payment methods.', status: 'error' });
            } finally {
                setLoading(false);
            }
        };

        fetchPaymentMethods();
    }, [user, functions, addNotification]);

    // Helper: Return the proper card icon
    const getCardIcon = (cardType) => {
        switch (cardType?.toLowerCase()) {
            case 'visa':
                return <FaCcVisa size={24} />;
            case 'mastercard':
                return <FaCcMastercard size={24} />;
            case 'amex':
            case 'american express':
                return <FaCcAmex size={24} />;
            case 'discover':
                return <FaCcDiscover size={24} />;
            default:
                return <CreditCardIcon fontSize="medium" />;
        }
    };

    // Update new payment data when input changes.
    const handleNewPaymentInputChange = (e) => {
        setNewPaymentData({ ...newPaymentData, [e.target.name]: e.target.value });
        // Clear the error for this field
        setErrors({ ...errors, [e.target.name]: '' });
    };

    // Validate new payment fields.
    const validateNewPaymentFields = () => {
        let valid = true;
        const newErrors = {
            cardNumber: '',
            cardHolder: '',
            expiry: '',
            cvv: '',
            address: '',
            city: '',
            state: '',
            zip: ''
        };

        const cleanedCardNumber = newPaymentData.cardNumber.replace(/\s+/g, '');
        if (!/^\d{16}$/.test(cleanedCardNumber)) {
            newErrors.cardNumber = 'Card number must be 16 digits.';
            addNotification({ message: newErrors.cardNumber, status: 'error' });
            valid = false;
        }

        if (!newPaymentData.cardHolder.trim()) {
            newErrors.cardHolder = 'Cardholder name is required.';
            addNotification({ message: newErrors.cardHolder, status: 'error' });
            valid = false;
        }

        const [expMonth, expYear] = newPaymentData.expiry.split('/');
        if (!expMonth || !expYear || !/^\d{2}$/.test(expMonth) || !/^\d{2}$/.test(expYear)) {
            newErrors.expiry = 'Expiry must be in MM/YY format.';
            addNotification({ message: newErrors.expiry, status: 'error' });
            valid = false;
        } else {
            const monthNum = parseInt(expMonth, 10);
            if (monthNum < 1 || monthNum > 12) {
                newErrors.expiry = 'Expiry month must be between 01 and 12.';
                addNotification({ message: newErrors.expiry, status: 'error' });
                valid = false;
            }
        }

        if (!/^\d{3}$/.test(newPaymentData.cvv)) {
            newErrors.cvv = 'CVV must be 3 digits.';
            addNotification({ message: newErrors.cvv, status: 'error' });
            valid = false;
        }

        if (!newPaymentData.address.trim()) {
            newErrors.address = 'Billing address is required.';
            addNotification({ message: newErrors.address, status: 'error' });
            valid = false;
        }

        if (!newPaymentData.city.trim()) {
            newErrors.city = 'Billing city is required.';
            addNotification({ message: newErrors.city, status: 'error' });
            valid = false;
        }

        if (!newPaymentData.state.trim()) {
            newErrors.state = 'Billing state is required.';
            addNotification({ message: newErrors.state, status: 'error' });
            valid = false;
        }

        if (!newPaymentData.zip.trim()) {
            newErrors.zip = 'Billing zip is required.';
            addNotification({ message: newErrors.zip, status: 'error' });
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    // ----- Delete Payment Method -----
    const handleDelete = async (paymentProfileId) => {
        if (!customerProfileId) {
            addNotification({ message: 'Customer profile not found.', status: 'error' });
            return;
        }
        setDeletingCardId(paymentProfileId);
        try {
            const deletePaymentMethod = httpsCallable(functions, 'deletePaymentMethod');
            const result = await deletePaymentMethod({
                customerProfileId,
                customerPaymentProfileId: paymentProfileId
            });
            if (result.data.success) {
                addNotification({ message: 'Payment method deleted successfully.', status: 'success' });
                setSavedCards(savedCards.filter(card => card.customerPaymentProfileId !== paymentProfileId));
            } else {
                addNotification({ message: 'Failed to delete payment method.', status: 'error' });
            }
        } catch (error) {
            console.error('Error deleting payment method:', error);
            addNotification({ message: 'Error deleting payment method.', status: 'error' });
        } finally {
            setDeletingCardId(null);
        }
    };

    // ----- Add New Payment Method -----
    const handleAddNewPayment = async () => {
        if (!customerProfileId) {
            addNotification({ message: 'Customer profile not found.', status: 'error' });
            return;
        }

        if (!validateNewPaymentFields()) {
            return;
        }

        // In a production environment, integrate Accept.js to generate a secure token.
        // For demonstration, we use a dummy token.
        const dummyToken = "DUMMY_TOKEN";
        try {
            const savePaymentProfile = httpsCallable(functions, 'savePaymentProfile');
            const result = await savePaymentProfile({
                token: dummyToken,
                cardHolder: newPaymentData.cardHolder,
                email: user.email,
                address: newPaymentData.address,
                city: newPaymentData.city,
                state: newPaymentData.state,
                zip: newPaymentData.zip,
                firstName: newPaymentData.firstName,
                lastName: newPaymentData.lastName,
                phone: newPaymentData.phone
            });
            if (result.data.success) {
                addNotification({ message: 'Payment method added successfully.', status: 'success' });
                setShowAddForm(false);
                setNewPaymentData({
                    cardNumber: '',
                    cardHolder: '',
                    expiry: '',
                    cvv: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    firstName: '',
                    lastName: '',
                    phone: ''
                });
                setErrors({
                    cardNumber: '',
                    cardHolder: '',
                    expiry: '',
                    cvv: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: ''
                });
                // Re-fetch saved cards
                const getAuthorizeNetProfile = httpsCallable(functions, 'getAuthorizeNetProfile');
                const res = await getAuthorizeNetProfile({ customerId: customerProfileId });
                if (res.data?.profiles?.length > 0) {
                    setSavedCards(res.data.profiles);
                }
            } else {
                addNotification({ message: 'Failed to add payment method.', status: 'error' });
            }
        } catch (error) {
            console.error('Error adding payment method:', error);
            addNotification({ message: 'Error adding payment method.', status: 'error' });
        }
    };

    if (loading) {
        return (
            <Box sx={{ p: 3, textAlign: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
                Billing Settings
            </Typography>

            <Typography variant="subtitle2" sx={{ mb: 1, color: "gray", textTransform: "uppercase" }} gutterBottom>
                Saved Payment Methods:
            </Typography>
            {savedCards.length === 0 ? (
                <Typography>No saved payment methods.</Typography>
            ) : (
                savedCards.map((card) => {
                    const cardType = card.payment?.creditCard?.cardType || 'Unknown';
                    const maskedNumber = card.payment?.creditCard?.cardNumber || '••••';
                    return (
                        <Paper key={card.customerPaymentProfileId} sx={{ p: 2, mb: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ mr: 2 }}>{getCardIcon(cardType)}</Box>
                                <Box>
                                    <Typography variant="subtitle1">
                                        {cardType} ending in {maskedNumber.slice(-4)}
                                    </Typography>
                                    {card.cardHolder && (
                                        <Typography variant="body2">Cardholder: {card.cardHolder}</Typography>
                                    )}
                                </Box>
                                <Box sx={{ ml: 'auto' }}>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => handleDelete(card.customerPaymentProfileId)}
                                        disabled={deletingCardId === card.customerPaymentProfileId}
                                    >
                                        {deletingCardId === card.customerPaymentProfileId ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            'Delete'
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </Paper>
                    );
                })
            )}

            {/* Section for adding a new payment method */}
            <Box sx={{ mt: 4 }}>
                {!showAddForm ? (
                    <Button variant="contained" onClick={() => setShowAddForm(true)}>
                        Add New Payment Method
                    </Button>
                ) : (
                    <Paper sx={{ p: 3, mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Add New Payment Method
                        </Typography>
                        <InputMask
                            mask="9999 9999 9999 9999"
                            value={newPaymentData.cardNumber}
                            onChange={handleNewPaymentInputChange}
                            maskChar=""
                        >
                            {(inputProps) => (
                                <TextField
                                    {...inputProps}
                                    label="Card Number"
                                    name="cardNumber"
                                    fullWidth
                                    margin="normal"
                                    error={!!errors.cardNumber}
                                    helperText={errors.cardNumber}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CreditCardIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                        </InputMask>
                        <TextField
                            label="Cardholder Name"
                            name="cardHolder"
                            value={newPaymentData.cardHolder}
                            onChange={handleNewPaymentInputChange}
                            fullWidth
                            margin="normal"
                            error={!!errors.cardHolder}
                            helperText={errors.cardHolder}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputMask
                                    mask="99/99"
                                    value={newPaymentData.expiry}
                                    onChange={handleNewPaymentInputChange}
                                    maskChar=""
                                >
                                    {(inputProps) => (
                                        <TextField
                                            {...inputProps}
                                            label="Expiry Date (MM/YY)"
                                            name="expiry"
                                            fullWidth
                                            margin="normal"
                                            error={!!errors.expiry}
                                            helperText={errors.expiry}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <CalendarTodayIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                            <Grid item xs={6}>
                                <InputMask
                                    mask="999"
                                    value={newPaymentData.cvv}
                                    onChange={handleNewPaymentInputChange}
                                    maskChar=""
                                >
                                    {(inputProps) => (
                                        <TextField
                                            {...inputProps}
                                            label="CVV"
                                            name="cvv"
                                            type="password"
                                            fullWidth
                                            margin="normal"
                                            error={!!errors.cvv}
                                            helperText={errors.cvv}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                        </Grid>
                        <TextField
                            label="Address"
                            name="address"
                            value={newPaymentData.address}
                            onChange={handleNewPaymentInputChange}
                            fullWidth
                            margin="normal"
                            error={!!errors.address}
                            helperText={errors.address}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="City"
                                    name="city"
                                    value={newPaymentData.city}
                                    onChange={handleNewPaymentInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!errors.city}
                                    helperText={errors.city}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="State"
                                    name="state"
                                    value={newPaymentData.state}
                                    onChange={handleNewPaymentInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!errors.state}
                                    helperText={errors.state}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <InputMask
                                    mask="99999"
                                    value={newPaymentData.zip}
                                    onChange={handleNewPaymentInputChange}
                                    maskChar=""
                                >
                                    {(inputProps) => (
                                        <TextField
                                            {...inputProps}
                                            label="Zip"
                                            name="zip"
                                            fullWidth
                                            margin="normal"
                                            error={!!errors.zip}
                                            helperText={errors.zip}
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                        </Grid>
                        {/* Optional fields */}
                        <TextField
                            label="First Name"
                            name="firstName"
                            value={newPaymentData.firstName}
                            onChange={handleNewPaymentInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Last Name"
                            name="lastName"
                            value={newPaymentData.lastName}
                            onChange={handleNewPaymentInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Phone"
                            name="phone"
                            value={newPaymentData.phone}
                            onChange={handleNewPaymentInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                            <Button variant="contained" color="primary" onClick={handleAddNewPayment}>
                                Save Payment Method
                            </Button>
                            <Button variant="text" onClick={() => setShowAddForm(false)}>
                                Cancel
                            </Button>
                        </Box>
                    </Paper>
                )}

                <Box
                    sx={{
                        mt: 4,
                        p: 2,
                        backgroundColor: 'grey.100',
                        borderRadius: 1,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <InfoOutlinedIcon sx={{ color: 'grey.600', mr: 1 }} />
                    <Typography variant="caption" color="text.secondary">
                        Payment methods are securely processed by <strong>Authorize.net</strong>. We do not store your full payment information on our servers.
                        For more information, please see our Privacy Policy.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default AccountBillingSettings;
