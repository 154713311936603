import React, { useEffect, useState } from 'react';
import { Box, Typography, Tab, Tabs, Paper, CircularProgress } from '@mui/material';
import { Logout, Login } from '@mui/icons-material';

import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

import AppointmentTab from '../../components/AppointmentTab'; // New simplified component

const Appointments = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [userData, setUserData] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(true);

  const auth = getAuth();
  const user = auth.currentUser;

  // Fetch user document on mount
  useEffect(() => {
    if (!user) return;
    const fetchUserDoc = async () => {
      setLoadingUserData(true);
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userDocRef);
        if (userSnap.exists()) {
          setUserData(userSnap.data());
        }
      } catch (error) {
        console.error('Error fetching user document:', error);
      } finally {
        setLoadingUserData(false);
      }
    };
    fetchUserDoc();
  }, [user]);

  const handleTabChange = (event, newIndex) => setTabIndex(newIndex);

  if (!user) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6">Please log in to view appointments.</Typography>
      </Box>
    );
  }

  if (loadingUserData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Appointments
      </Typography>
      <Typography variant="body1" gutterBottom>
        Manage your scheduled appointments below.
      </Typography>

      <Paper sx={{ width: '100%', mt: 2 }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab icon={<Logout />} label="Move Out" />
          <Tab icon={<Login />} label="Move In" />
        </Tabs>
      </Paper>

      {/* Move Out Tab */}
      {tabIndex === 0 && (
        <Box sx={{ mt: 3 }}>
          <AppointmentTab user={userData} userId={user.uid} appointmentType="Move Out" />
        </Box>
      )}

      {/* Move In Tab */}
      {tabIndex === 1 && (
        <Box sx={{ mt: 3 }}>
          <AppointmentTab user={userData} userId={user.uid} appointmentType="Move In" />
        </Box>
      )}
    </Box>
  );
};

export default Appointments;
