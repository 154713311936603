import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Skeleton, Fade, Tooltip, IconButton } from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const AppointmentSelector = ({
  universityID,
  appointmentType,
  containerLocationID,
  onSelect,
  disableConfirm = false, // New prop to hide the confirm button
}) => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  // State for controlling the calendar's active start date
  const [activeStartDate, setActiveStartDate] = useState(null);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        // Get appointments from the main appointments collection
        const appointmentCollection = collection(db, `universities/${universityID}/specialAppointments`);
        const snapshot = await getDocs(appointmentCollection);
        let fetchedAppointments = snapshot.docs.map((doc) => ({
          id: doc.id,
          start: doc.data().start.toDate(),
          end: doc.data().end.toDate(),
          type: doc.data().type,
          category: doc.data().category, // might be undefined for complimentary appointments
          cost: doc.data().cost,
        }));

        // Filter to only special appointments
        fetchedAppointments = fetchedAppointments.filter((appointment) =>
          appointment.category === 'Early' || appointment.category === 'Late'
        );

        // If containerLocationID is provided, fetch complimentary appointments from that container location
        if (containerLocationID) {
          const compCollection = collection(
            db,
            `universities/${universityID}/containerLocations/${containerLocationID}/appointments`
          );
          const compSnapshot = await getDocs(compCollection);
          const compAppointments = compSnapshot.docs.map((doc) => ({
            id: doc.id,
            start: doc.data().start.toDate(),
            end: doc.data().end.toDate(),
            type: doc.data().type,
            category: doc.data().category,
            cost: doc.data().cost,
          }));

          // You could filter or merge as needed. In this case, we merge them.
          fetchedAppointments = [...fetchedAppointments, ...compAppointments];
        }

        // Further filter by appointmentType if provided (e.g., "Move In" or "Move Out")
        if (appointmentType) {
          console.log("Appointment type ", appointmentType);
          fetchedAppointments = fetchedAppointments.filter(
            (appointment) => appointment.type === appointmentType
          );
        }

        setAppointments(fetchedAppointments);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, [universityID, appointmentType, containerLocationID]);

  // Set the activeStartDate once appointments are loaded.
  useEffect(() => {
    if (appointments.length > 0 && !activeStartDate) {
      // Complimentary appointments (no category)
      const complimentary = appointments.filter(app => !app.category);
      const firstDate =
        complimentary.length > 0
          ? new Date(Math.min(...complimentary.map(app => app.start.getTime())))
          : new Date(Math.min(...appointments.map(app => app.start.getTime())));
      setActiveStartDate(firstDate);
    }
  }, [appointments, activeStartDate]);

  // Compute previous and next month dates relative to activeStartDate.
  const previousMonthDate = activeStartDate ? new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() - 1, 1) : null;
  const nextMonthDate = activeStartDate ? new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() + 1, 1) : null;

  const hasPrevious = previousMonthDate && appointments.some(app =>
    app.start.getFullYear() === previousMonthDate.getFullYear() &&
    app.start.getMonth() === previousMonthDate.getMonth()
  );
  const hasNext = nextMonthDate && appointments.some(app =>
    app.start.getFullYear() === nextMonthDate.getFullYear() &&
    app.start.getMonth() === nextMonthDate.getMonth()
  );

  const firstAppointmentMonth = activeStartDate
    ? activeStartDate.toLocaleString('default', { month: 'long', year: 'numeric' }).toUpperCase()
    : '';

  // Helper to determine if an appointment is non-complimentary (i.e. special)
  const isNonComplimentaryAppointment = (appointment) => {
    return appointment.category === 'Early' || appointment.category === 'Late';
  };

  // Modified: immediately call onSelect when disableConfirm is true
  const handleSelectDate = (date) => {
    setSelectedDate(date);
    if (disableConfirm) {
      const selectedAppointment = appointments.find(
        (appointment) => appointment.start.toDateString() === date.toDateString()
      );
      if (selectedAppointment && onSelect) {
        onSelect({
          ...selectedAppointment,
          isNonComplimentaryAppointment: isNonComplimentaryAppointment(selectedAppointment),
        });
      }
    }
  };

  // Only used when disableConfirm is false
  const handleConfirmDate = () => {
    if (selectedDate) {
      const selectedAppointment = appointments.find(
        (appointment) => appointment.start.toDateString() === selectedDate.toDateString()
      );
      if (selectedAppointment && onSelect) {
        onSelect({
          ...selectedAppointment,
          isNonComplimentaryAppointment: isNonComplimentaryAppointment(selectedAppointment),
        });
      }
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          padding: '20px',
          maxWidth: '900px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Skeleton variant="text" width={200} height={30} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width="100%" height={350} />
      </Box>
    );
  }

  return (
    <Fade in={!loading} timeout={500}>
      <Box
        sx={{
          padding: 1,
          maxWidth: '900px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <style>{`
          abbr[title] {
            text-decoration: none;
          }
          .react-calendar {
            border: none;
            position: relative;
          }
          .react-calendar__tile {
            border-radius: 25%;
            height: 50px !important;
            width: 50px !important;
            margin: 4px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
          }
          .react-calendar__tile--selected {
            background: none !important;
          }
          .react-calendar__tile--disabled {
            pointer-events: none;
            background-color: white !important;
            color: #ccc !important;
          }
          .tile-complimentary {
            background-color: #b0cfff !important;
            color: black;
          }
          .tile-complimentary-selected {
            background-color: #244A83 !important;
            color: white;
          }
          .tile-special {
            background-color: #ffcc99 !important;
            color: black;
          }
          .tile-special-selected {
            background-color: #ffa500 !important;
            color: black;
          }
          /* Price label - hidden by default */
          .tile-label {
            position: absolute;
            bottom: 4px;
            width: 100%;
            font-size: 10px;
            color: gray;
            text-align: center;
            opacity: 0;
            transition: opacity 0.3s ease;
          }
          /* Show label on hover or when selected */
          .react-calendar__tile:hover .tile-label,
          .tile-special-selected .tile-label,
          .tile-complimentary-selected .tile-label {
            opacity: 1;
          }
          .react-calendar__navigation {
            display: none;
          }
          .react-calendar__month-view__days {
            margin-top: 20px;
          }
        `}</style>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2, width: '100%' }}>
          <IconButton
            onClick={() => setActiveStartDate(previousMonthDate)}
            disabled={!hasPrevious}
            sx={{ visibility: hasPrevious ? 'visible' : 'hidden' }}
          >
            <ArrowBackIos fontSize="small" />
          </IconButton>
          <Typography
            variant="subtitle1"
            align="center"
            gutterBottom
            sx={{ fontSize: '14px', fontWeight: 'bold', color: 'gray' }}
          >
            {firstAppointmentMonth}
          </Typography>
          <IconButton
            onClick={() => setActiveStartDate(nextMonthDate)}
            disabled={!hasNext}
            sx={{ visibility: hasNext ? 'visible' : 'hidden' }}
          >
            <ArrowForwardIos fontSize="small" />
          </IconButton>
        </Box>

        <Calendar
          value={selectedDate}
          onChange={handleSelectDate}
          activeStartDate={activeStartDate || new Date()}
          tileClassName={({ date, view }) => {
            if (view !== 'month') return '';
            const appointmentForDate = appointments.find(
              (appointment) => appointment.start.toDateString() === date.toDateString()
            );
            if (appointmentForDate) {
              const isSpecial = isNonComplimentaryAppointment(appointmentForDate);
              if (selectedDate && selectedDate.toDateString() === date.toDateString()) {
                return isSpecial ? 'tile-special-selected' : 'tile-complimentary-selected';
              }
              return isSpecial ? 'tile-special' : 'tile-complimentary';
            }
            return 'react-calendar__tile--disabled';
          }}
          tileContent={({ date, view }) => {
            if (view !== 'month') return null;
            const appointmentForDate = appointments.find(
              (appointment) => appointment.start.toDateString() === date.toDateString()
            );
            if (appointmentForDate && isNonComplimentaryAppointment(appointmentForDate)) {
              return (
                <Tooltip
                  title="This date falls outside of our normal moving operations, but we are able to make special accommodations for an additional fee."
                  arrow
                >
                  <div className="tile-label">
                    {`+$${appointmentForDate.cost}`}
                  </div>
                </Tooltip>
              );
            }
            return null;
          }}
          tileDisabled={({ date }) => {
            return !appointments.some(
              (appointment) => appointment.start.toDateString() === date.toDateString()
            );
          }}
          showNeighboringMonth={false}
        />

        {/* Render Confirm Date button only if disableConfirm is false */}
        {!disableConfirm && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleConfirmDate}
            disabled={!selectedDate}
            sx={{ mt: 2 }}
          >
            Confirm Date
          </Button>
        )}

        {/* Color Key */}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <Box sx={{ width: 16, height: 16, backgroundColor: '#b0cfff', borderRadius: '4px', mr: 1 }} />
            <Typography variant="caption">Complimentary Date</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 16, height: 16, backgroundColor: '#ffcc99', borderRadius: '4px', mr: 1 }} />
            <Typography variant="caption">Special Date</Typography>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default AppointmentSelector;
