// MaintenanceContext.js
import React, { createContext, useState, useContext } from 'react';

const MaintenanceContext = createContext();

export const MaintenanceProvider = ({ children }) => {
    const [maintenanceMode, setMaintenanceMode] = useState(false);

    const toggleMaintenanceMode = () => {
        setMaintenanceMode(prev => !prev);
    };

    return (
        <MaintenanceContext.Provider value={{ maintenanceMode, toggleMaintenanceMode }}>
            {children}
        </MaintenanceContext.Provider>
    );
};

export const useMaintenance = () => useContext(MaintenanceContext);
