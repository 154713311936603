import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip,
    Stack,
    CircularProgress,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AppointmentSelector from './AppointmentSelector';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNotification } from '../contexts/NotificationContext';

const AppointmentTabDate = ({
    userId,
    currentAppointment,
    appointmentType,
    universityID,
    containerLocationID,
    onAppointmentChange,
    loading,
}) => {
    // step = 0: dialog closed; 1: appointment selection; 2: confirmation
    const [step, setStep] = useState(0);
    const [newAppointment, setNewAppointment] = useState(currentAppointment);
    const [confirming, setConfirming] = useState(false);
    const { addNotification } = useNotification();

    // Update local state when currentAppointment prop changes.
    useEffect(() => {
        setNewAppointment(currentAppointment);
    }, [currentAppointment]);

    const handleOpen = () => {
        setNewAppointment(currentAppointment);
        setStep(1);
    };

    const handleClose = () => {
        setStep(0);
    };

    // Formats the appointment data for display.
    const formatAppointment = (appointment) => {
        if (!appointment || !appointment.start || !appointment.end) return 'N/A';
        const start = appointment.start.toDate ? appointment.start.toDate() : new Date(appointment.start);
        const end = appointment.end.toDate ? appointment.end.toDate() : new Date(appointment.end);
        const day = start.toLocaleDateString(undefined, { weekday: 'long', month: 'long', day: 'numeric' });
        const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
        const startTime = start.toLocaleTimeString(undefined, timeOptions);
        const endTime = end.toLocaleTimeString(undefined, timeOptions);
        return (
            <>
                <Typography variant="body2">Day: {day}</Typography>
                <Typography variant="body2">Time: {startTime} to {endTime}</Typography>
            </>
        );
    };

    // When the final confirm button is clicked, run updateAppointment cloud function.
    const handleConfirm = async () => {
        if (!newAppointment) return;
        setConfirming(true);
        const functions = getFunctions();
        const updateAppointmentCallable = httpsCallable(functions, 'updateAppointment');
        try {
            await updateAppointmentCallable({
                userId,
                universityId: universityID,
                containerLocationId: containerLocationID,
                appointmentId: newAppointment.id,
                // Pass along the special appointment flag and the appointment type.
                isNonComplimentary: newAppointment.isNonComplimentaryAppointment,
                appointmentType: newAppointment.type,
            });
            onAppointmentChange(newAppointment);
            addNotification({ message: 'Appointment successfully updated.', status: 'success' });
            setConfirming(false);
            setStep(0);
        } catch (error) {
            console.error('Error updating appointment:', error);
            addNotification({ message: error.message || 'Error updating appointment.', status: 'error' });
            setConfirming(false);
        }
    };

    return (
        <Box>
            <Paper
                elevation={3}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    overflow: 'hidden',
                    flex: 1,
                    borderRadius: 2,
                }}
            >
                {/* Header with a large calendar icon */}
                <Box
                    sx={{
                        backgroundImage: 'url(/images/background_pattern.webp)',
                        backgroundColor: 'primary.main',
                        px: 3,
                        py: 2,
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <CalendarMonthIcon sx={{ fontSize: 48, mb: 1 }} />
                    <Typography variant="h6" gutterBottom>
                        Appointment Day
                    </Typography>
                </Box>
                {/* Content */}
                <Box sx={{ px: 3, py: 4, flexGrow: 1 }}>
                    {loading ? (
                        <Typography variant="body1">Loading appointment...</Typography>
                    ) : currentAppointment ? (
                        <>
                            {formatAppointment(currentAppointment)}
                        </>
                    ) : (
                        <Typography>No appointment scheduled yet.</Typography>
                    )}
                </Box>
                {/* Actions */}
                <Box sx={{ px: 3, pb: 2 }}>
                    <Stack direction="row" spacing={1} justifyContent="center">
                        <Tooltip title={currentAppointment ? 'Update Appointment' : 'Schedule Appointment'}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={handleOpen}
                                size="medium"
                                disabled={loading}
                            >
                                {currentAppointment ? 'Update' : 'Schedule'}
                            </Button>
                        </Tooltip>
                    </Stack>
                </Box>
            </Paper>
            {/* Multi-step dialog */}
            <Dialog open={step !== 0} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>
                    {step === 1
                        ? currentAppointment
                            ? 'Update Appointment'
                            : 'Schedule Appointment'
                        : 'Confirm Appointment Update'}
                </DialogTitle>
                <DialogContent>
                    {step === 1 && (
                        <Box sx={{ mt: 2 }}>
                            <AppointmentSelector
                                appointmentType={appointmentType}
                                universityID={universityID}
                                containerLocationID={containerLocationID}
                                onSelect={(appointment) => setNewAppointment(appointment)}
                                disableConfirm
                            />
                        </Box>
                    )}
                    {step === 2 && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1" gutterBottom>
                                Please confirm the following appointment:
                            </Typography>
                            {newAppointment ? formatAppointment(newAppointment) : <Typography>N/A</Typography>}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    {step === 1 && (
                        <>
                            <Tooltip title="Cancel">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    startIcon={<CancelIcon />}
                                    onClick={handleClose}
                                    size="medium"
                                >
                                    Cancel
                                </Button>
                            </Tooltip>
                            <Tooltip title="Next">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveIcon />}
                                    onClick={() => setStep(2)}
                                    size="medium"
                                    disabled={!newAppointment}
                                >
                                    Next
                                </Button>
                            </Tooltip>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <Tooltip title="Back">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    startIcon={<CancelIcon />}
                                    onClick={() => setStep(1)}
                                    size="medium"
                                >
                                    Back
                                </Button>
                            </Tooltip>
                            <Tooltip title="Confirm">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={confirming ? <CircularProgress size={20} /> : <SaveIcon />}
                                    onClick={handleConfirm}
                                    size="medium"
                                    disabled={confirming}
                                >
                                    {confirming ? 'Processing...' : 'Confirm'}
                                </Button>
                            </Tooltip>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AppointmentTabDate;
