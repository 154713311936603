import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Chip,
    Button,
    CircularProgress,
    Divider
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import InvoiceDetails from '../../components/InvoiceDetails';

// Helper function to determine status color
function getStatusColor(status) {
    switch (status.toLowerCase()) {
        case 'paid': return 'success';
        case 'overdue': return 'error';
        case 'pending': return 'default';
        case 'on hold': return 'info';
        case 'due soon': return 'warning';
        case 'cancelled': return 'default';
        default: return 'default';
    }
}

const InvoiceDetailsPage = () => {
    const { invoiceId } = useParams();
    const navigate = useNavigate();
    const [invoice, setInvoice] = useState(null);
    const [loading, setLoading] = useState(true);
    const db = getFirestore();
    const functions = getFunctions();

    useEffect(() => {
        const fetchInvoiceDetails = async () => {
            try {
                // Call the fetchInvoice cloud function
                const fetchInvoice = httpsCallable(functions, 'fetchInvoice');
                const response = await fetchInvoice({ invoiceId });
                const invoiceData = response.data.invoice;

                if (invoiceData) {
                    let items = [];
                    let subtotal = 0;
                    let discount = invoiceData.discount || 0;
                    let taxRate = invoiceData.taxRate || 0.0635; // 6.35% tax

                    // Loop over each product object in the invoice.
                    // New invoice structure is expected to have a similar products array.
                    for (const prod of invoiceData.products || []) {
                        // For backward compatibility, check if prod is an object or a string.
                        const code = typeof prod === 'object' ? (prod.code || prod.id) : prod;
                        const quantity = typeof prod === 'object' ? prod.quantity || 1 : 1;

                        // Fetch product details from Firestore.
                        const productRef = doc(db, "products", code);
                        const productSnap = await getDoc(productRef);

                        if (productSnap.exists()) {
                            const productData = productSnap.data();

                            let itemPrice = productData.price || 0;

                            // Check if the productType is "Plan Change".
                            if (productData.productType === "Plan Change") {
                                // Fetch the product details for planFrom and planTo.
                                const planFromRef = doc(db, "products", productData.planFrom);
                                const planToRef = doc(db, "products", productData.planTo);
                                const planFromSnap = await getDoc(planFromRef);
                                const planToSnap = await getDoc(planToRef);

                                const planFromPrice = planFromSnap.exists() ? (planFromSnap.data().price || 0) : 0;
                                const planToPrice = planToSnap.exists() ? (planToSnap.data().price || 0) : 0;

                                // Calculate the price as the difference.
                                itemPrice = planToPrice - planFromPrice;
                            }

                            items.push({
                                name: productData.title || "Unknown Item",
                                price: itemPrice,
                                quantity: quantity
                            });
                            subtotal += itemPrice * quantity;
                        }
                    }

                    let taxAmount = subtotal * taxRate;
                    let totalAmount = subtotal - discount + taxAmount;

                    setInvoice({
                        id: invoiceId,
                        status: invoiceData.isPaid
                            ? 'Paid'
                            : invoiceData.isCancelled
                                ? 'Cancelled'
                                : invoiceData.isOverdue
                                    ? 'Overdue'
                                    : 'Due Soon',
                        dueDate: invoiceData.dueDate
                            ? (invoiceData.dueDate.toDate ? invoiceData.dueDate.toDate().toLocaleDateString() : new Date(invoiceData.dueDate).toLocaleDateString())
                            : "No due date",
                        items: items,
                        subtotal: subtotal,
                        discount: discount,
                        tax: taxAmount,
                        total: totalAmount,
                        paymentDetails: invoiceData.paymentDetails || null
                    });
                } else {
                    console.error("Invoice not found.");
                }
            } catch (error) {
                console.error("Error fetching invoice details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchInvoiceDetails();
    }, [invoiceId, db, functions]);

    return (
        <Box sx={{ p: 3 }}>
            <Button
                onClick={() => navigate(-1)}
                variant="outlined"
                color="primary"
                startIcon={<ArrowBack />}
                sx={{ mb: 2 }}
            >
                Back
            </Button>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : invoice ? (
                <>
                    {/* Header */}
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h4" gutterBottom>
                            Invoice Details
                        </Typography>
                    </Box>

                    {/* Invoice Status & Due Date */}
                    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", mb: 2, gap: 2 }}>
                        <Typography variant="subtitle1">
                            Due Date: <strong>{invoice.dueDate}</strong>
                        </Typography>
                        <Typography variant="subtitle1">
                            Status: <Chip label={invoice.status} color={getStatusColor(invoice.status)} size="small" />
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: "gray" }}>
                            Invoice Code: {invoice.id}
                        </Typography>
                    </Box>

                    <Divider sx={{ mb: 2 }} />

                    {/* Invoice Items */}
                    <InvoiceDetails items={invoice.items} />

                    <Divider sx={{ my: 3 }} />

                    {/* Price Summary */}
                    <Box
                        sx={{
                            p: 2,
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            backgroundColor: '#f9f9f9',
                            mb: 3
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Overview
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Typography>Subtotal:</Typography>
                            <Typography>${invoice.subtotal.toFixed(2)}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Typography>Discounts:</Typography>
                            <Typography>-${invoice.discount.toFixed(2)}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Typography>Estimated Taxes (6.35%):</Typography>
                            <Typography>${invoice.tax.toFixed(2)}</Typography>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                            <Typography>Total:</Typography>
                            <Typography>${invoice.total.toFixed(2)}</Typography>
                        </Box>
                    </Box>

                    {/* Payment Information Section */}
                    {invoice.paymentDetails && (
                        <Box
                            sx={{
                                p: 2,
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#fff'
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                Payment Information
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                {invoice.paymentDetails.transactionId && (
                                    <Typography variant="body1">
                                        Transaction ID: <strong>{invoice.paymentDetails.transactionId}</strong>
                                    </Typography>
                                )}
                                {invoice.paymentDetails.cardLast4 && (
                                    <Typography variant="body1">
                                        Card Ending: <strong>**** {invoice.paymentDetails.cardLast4}</strong>
                                    </Typography>
                                )}
                                {invoice.paymentDetails.billingInfo && (
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            Billing Information:
                                        </Typography>
                                        <Typography variant="body2">
                                            {invoice.paymentDetails.billingInfo.firstName} {invoice.paymentDetails.billingInfo.lastName}
                                        </Typography>
                                        <Typography variant="body2">
                                            {invoice.paymentDetails.billingInfo.address}
                                        </Typography>
                                        <Typography variant="body2">
                                            {invoice.paymentDetails.billingInfo.city}, {invoice.paymentDetails.billingInfo.state} {invoice.paymentDetails.billingInfo.zip}
                                        </Typography>
                                        {invoice.paymentDetails.billingInfo.phone && (
                                            <Typography variant="body2">
                                                Phone: {invoice.paymentDetails.billingInfo.phone}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                                {invoice.paymentDetails.timePaid && (
                                    <Typography variant="body1">
                                        Time Paid:{' '}
                                        <strong>
                                            {new Date(invoice.paymentDetails.timePaid.seconds * 1000).toLocaleString()}
                                        </strong>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )}
                </>
            ) : (
                <Typography variant="h6" sx={{ mt: 3 }}>
                    Invoice not found.
                </Typography>
            )}
        </Box>
    );
};

export default InvoiceDetailsPage;
