import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, ListSubheader } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const ContainerLocationDropdownSelect = ({
    universityID,
    selectedValue,
    onChange,
    label = 'Select Container Location',
    ...rest
}) => {
    const [containerLocations, setContainerLocations] = useState([]);

    // Fetch container locations for the given universityID.
    useEffect(() => {
        const fetchContainerLocations = async () => {
            try {
                const colRef = collection(db, `universities/${universityID}/containerLocations`);
                const snapshot = await getDocs(colRef);
                const locations = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setContainerLocations(locations);
            } catch (error) {
                console.error('Error fetching container locations:', error);
            }
        };

        if (universityID) {
            fetchContainerLocations();
        }
    }, [universityID]);

    // Group container locations by their locationGroup.
    const groupContainerLocations = (locations) => {
        const grouped = locations.reduce((acc, loc) => {
            const group = loc.locationGroup || 'Other';
            if (!acc[group]) {
                acc[group] = [];
            }
            acc[group].push(loc);
            return acc;
        }, {});

        // Order groups so that "Other" comes last.
        const groupNames = Object.keys(grouped).sort((a, b) => {
            if (a === 'Other') return 1;
            if (b === 'Other') return -1;
            return a.localeCompare(b);
        });

        // Sort locations alphabetically within each group.
        groupNames.forEach((group) => {
            grouped[group].sort((a, b) => a.name.localeCompare(b.name));
        });

        return { grouped, groupNames };
    };

    const { grouped, groupNames } = groupContainerLocations(containerLocations);

    return (
        <TextField
            select
            label={label}
            value={selectedValue}
            onChange={onChange}
            fullWidth
            {...rest}
        >
            {groupNames.reduce((acc, groupName) => {
                acc.push(
                    <ListSubheader key={`header-${groupName}`}>
                        {groupName}
                    </ListSubheader>
                );
                acc.push(
                    ...grouped[groupName].map((loc) => (
                        <MenuItem key={loc.id} value={loc.id}>
                            {loc.name}
                        </MenuItem>
                    ))
                );
                return acc;
            }, [])}
        </TextField>
    );
};

export default ContainerLocationDropdownSelect;
