import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Skeleton } from '@mui/material';
import AppointmentTabLocation from './AppointmentTabLocation';
import AppointmentTabDate from './AppointmentTabDate';
import AppointmentTabCoordinator from './AppointmentTabCoordinator';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const AppointmentTab = ({ user, userId, appointmentType, disableConfirm = false }) => {
    const [loadingDetails, setLoadingDetails] = useState(true);
    const [appointmentDetails, setAppointmentDetails] = useState(null);
    const [locationName, setLocationName] = useState('');
    const [coordinatorName, setCoordinatorName] = useState('');
    const [coordinatorEmail, setCoordinatorEmail] = useState('');
    const [coordinatorPhone, setCoordinatorPhone] = useState('');
    const [universityId, setUniversityId] = useState('');
    const [containerLocationID, setContainerLocationID] = useState('');

    // Helper: fetch appointment document given an appointment ID.
    const subscribeToAppointmentDetails = (apptId, uniId, containerLocId) => {
        let unsubscribe;
        // First, try the containerLocations subcollection.
        const containerRef = doc(db, 'universities', uniId, 'containerLocations', containerLocId, 'appointments', apptId);
        unsubscribe = onSnapshot(
            containerRef,
            (docSnap) => {
                if (docSnap.exists()) {
                    setAppointmentDetails({ id: docSnap.id, ...docSnap.data() });
                    setLoadingDetails(false);
                } else {
                    // If not found in containerLocations, try specialAppointments.
                    const specialRef = doc(db, 'universities', uniId, 'specialAppointments', apptId);
                    unsubscribe = onSnapshot(
                        specialRef,
                        (docSnapSpecial) => {
                            if (docSnapSpecial.exists()) {
                                setAppointmentDetails({ id: docSnapSpecial.id, ...docSnapSpecial.data() });
                            } else {
                                setAppointmentDetails(null);
                            }
                            setLoadingDetails(false);
                        },
                        (error) => {
                            console.error("Error listening to special appointment:", error);
                            setLoadingDetails(false);
                        }
                    );
                }
            },
            (error) => {
                console.error("Error listening to appointment:", error);
                setLoadingDetails(false);
            }
        );
        return unsubscribe;
    };

    // Live subscription for current appointment.
    useEffect(() => {
        let unsubscribe;
        if (user) {
            setUniversityId(user.universityId || '');
            let apptId;
            if (appointmentType === 'Move Out') {
                setContainerLocationID(user.moveOutContainerLocationID || '');
                apptId = user.moveOutAppointment; // Use the appointment ID field.
                setCoordinatorName('Coordinator Unassigned');
                setCoordinatorEmail('email@summmerstore.org');
                setCoordinatorPhone('+1 (---) --- ----');
            } else {
                setContainerLocationID(user.moveInContainerLocationID || '');
                apptId = user.moveInAppointment; // Use the appointment ID field.
                setCoordinatorName('Coordinator Unassigned');
                setCoordinatorEmail('email@summmerstore.org');
                setCoordinatorPhone('+1 (---) --- ----');
            }
            if (apptId && user.universityId && (appointmentType === 'Move Out'
                ? user.moveOutContainerLocationID
                : user.moveInContainerLocationID)) {
                unsubscribe = subscribeToAppointmentDetails(apptId, user.universityId, appointmentType === 'Move Out' ? user.moveOutContainerLocationID : user.moveInContainerLocationID);
            } else {
                setAppointmentDetails(null);
                setLoadingDetails(false);
            }
        }
        return () => {
            if (unsubscribe) unsubscribe();
        };
    }, [user, appointmentType]);

    // Fetch the container location's name.
    useEffect(() => {
        async function fetchContainerLocationName() {
            if (!universityId || !containerLocationID) return;
            try {
                const containerLocationRef = doc(db, 'universities', universityId, 'containerLocations', containerLocationID);
                const containerLocationSnap = await getDoc(containerLocationRef);
                if (containerLocationSnap.exists()) {
                    setLocationName(containerLocationSnap.data().name);
                } else {
                    console.error("No container location document found");
                }
            } catch (error) {
                console.error("Error fetching container location:", error);
            }
        }
        fetchContainerLocationName();
    }, [universityId, containerLocationID]);

    // Handlers to update location and appointment details.
    const handleLocationChange = (newLoc) => {
        setLocationName(newLoc);
    };

    const handleAppointmentChange = (newAppt) => {
        setAppointmentDetails(newAppt);
    };

    return (
        <Box sx={{ p: 3, flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
                Appointments
            </Typography>
            <Typography variant="body1" gutterBottom>
                Manage your scheduled appointments below.
            </Typography>
            {loadingDetails ? (
                // Show three skeleton columns
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Skeleton variant="rectangular" width="100%" height={200} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Skeleton variant="rectangular" width="100%" height={200} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Skeleton variant="rectangular" width="100%" height={200} />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <AppointmentTabLocation
                            userId={userId}
                            appointmentType={appointmentType}
                            currentLocation={locationName}
                            containerLocationID={containerLocationID}
                            universityID={universityId}
                            onLocationChange={handleLocationChange}
                            loading={loadingDetails}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <AppointmentTabDate
                            appointmentType={appointmentType}
                            userId={userId}
                            currentAppointment={appointmentDetails}
                            universityID={universityId}
                            containerLocationID={containerLocationID}
                            onAppointmentChange={handleAppointmentChange}
                            disableConfirm={disableConfirm}
                            loading={loadingDetails}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <AppointmentTabCoordinator
                            userId={userId}
                            coordinatorName={coordinatorName}
                            coordinatorEmail={coordinatorEmail}
                            coordinatorPhone={coordinatorPhone}
                            loading={loadingDetails}
                        />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default AppointmentTab;
