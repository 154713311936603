// src/pages/Pricing.js
import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import StoragePlan from './StoragePlan'; // Adjust the import path if necessary

const Pricing = () => {
  return (
    <Box sx={{mb: 0, height: '100vh' }}>
      <NavBar />
      <Container maxWidth="xl" sx={{ mt: 8, mb: 8 }}>

        <StoragePlan />
      </Container>
      <Footer />
    </Box>
  );
};

export default Pricing;
