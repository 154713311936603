import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip,
    Stack,
    CircularProgress,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ContainerLocationDropdownSelect from './ContainerLocationDropdownSelect';
import AppointmentSelector from './AppointmentSelector';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNotification } from '../contexts/NotificationContext';
import { Navigate } from 'react-router-dom';

const AppointmentTabLocation = ({
    userId,
    currentLocation,
    currentAppointment,
    universityID,
    containerLocationID, // may be undefined
    appointmentType,
    loading,
    disableConfirm,
}) => {
    const [step, setStep] = useState(0);
    // newLocation defaults to containerLocationID or empty string.
    const [newLocation, setNewLocation] = useState(containerLocationID || "");
    const [newContainerLocationID, setNewContainerLocationID] = useState(containerLocationID || "")
    const [newAppointment, setNewAppointment] = useState(currentAppointment);
    const [newLocationName, setNewLocationName] = useState('');
    const [locationName, setLocationName] = useState(currentLocation || "N/A");
    const [confirming, setConfirming] = useState(false);
    const { addNotification } = useNotification();

    const openDialog = () => {
        // Reset newLocation using containerLocationID or empty string.
        setNewLocation(newContainerLocationID || "");
        setNewAppointment(newAppointment);
        setStep(1);
    };

    const closeDialog = () => {
        setStep(0);
    };

    // Fetch the container location's name from Firestore whenever newLocation changes.
    useEffect(() => {
        const fetchLocationName = async () => {
            if (newLocation && universityID) {
                try {
                    const locationDocRef = doc(db, 'universities', universityID, 'containerLocations', newLocation);
                    const locationSnap = await getDoc(locationDocRef);
                    if (locationSnap.exists()) {
                        setNewLocationName(locationSnap.data().name);
                    } else {
                        setNewLocationName('');
                    }
                } catch (error) {
                    console.error('Error fetching location name:', error);
                    setNewLocationName('');
                }
            } else {
                setNewLocationName('');
            }
        };
        fetchLocationName();
    }, [newLocation, universityID]);

    // When the customer confirms the update, call the cloud function updateAppointment.
    const handleConfirm = async () => {
        if (!newLocation || !newAppointment) return;

        setConfirming(true);
        const functions = getFunctions();
        const updateAppointmentCallable = httpsCallable(functions, 'updateAppointment');
        try {
            await updateAppointmentCallable({
                userId,
                universityId: universityID,
                containerLocationId: newLocation,
                appointmentId: newAppointment.id,
                // Pass along the flag and the appointment type.
                isNonComplimentary: newAppointment.isNonComplimentaryAppointment,
                appointmentType: newAppointment.type,
            });
            addNotification({ message: 'Appointment successfully updated.', status: 'success' });
            setLocationName(newLocationName);
            setConfirming(false);
            closeDialog();

        } catch (error) {
            addNotification({ message: error.message || 'Error updating appointment.', status: 'error' });
            setConfirming(false);
        }
    };

    return (
        <Box>
            <Paper
                elevation={3}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    overflow: 'hidden',
                    flex: 1,
                    borderRadius: 2,
                }}
            >
                {/* Header with a large location icon */}
                <Box
                    sx={{
                        backgroundImage: 'url(/images/background_pattern.webp)',
                        backgroundColor: 'primary.main',
                        px: 3,
                        py: 2,
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <LocationOnIcon sx={{ fontSize: 48, mb: 1 }} />
                    <Typography variant="h6" gutterBottom>
                        Appointment Location
                    </Typography>
                </Box>
                {/* Content */}
                <Box sx={{ px: 3, py: 4, flexGrow: 1 }}>
                    {loading ? (
                        <Typography variant="body1">Loading appointment...</Typography>
                    ) : (
                        <Typography variant="body1" gutterBottom>
                            {locationName || 'N/A'}
                        </Typography>
                    )}
                </Box>
                {/* Actions */}
                <Box sx={{ px: 3, pb: 2 }}>
                    <Stack direction="row" spacing={1} justifyContent="center">
                        <Tooltip title="Edit Location">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={openDialog}
                                size="medium"
                                disabled={loading}
                            >
                                Edit
                            </Button>
                        </Tooltip>
                    </Stack>
                </Box>
            </Paper>

            {/* Multi-step Update Dialog */}
            <Dialog open={step > 0} onClose={closeDialog} fullWidth maxWidth="sm">
                <DialogTitle>
                    {step === 1 && 'Step 1: Update Appointment Location'}
                    {step === 2 && 'Step 2: Set Appointment Day'}
                    {step === 3 && 'Step 3: Confirm Changes'}
                </DialogTitle>
                <DialogContent>
                    {step === 1 && (
                        <Box sx={{ mt: 2 }}>
                            <ContainerLocationDropdownSelect
                                universityID={universityID}
                                selectedValue={newLocation}
                                onChange={(e) => setNewLocation(e.target.value)}
                                label="College"
                                sx={{ mb: 2 }}
                            />
                        </Box>
                    )}
                    {step === 2 && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1" gutterBottom>
                                Please select a new appointment day/time:
                            </Typography>
                            {/* Pass newLocation as containerLocationID */}
                            <AppointmentSelector
                                appointmentType={appointmentType}
                                universityID={universityID}
                                containerLocationID={newLocation}
                                onSelect={(appointment) => setNewAppointment(appointment)}
                                disableConfirm
                            />
                        </Box>
                    )}
                    {step === 3 && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1" gutterBottom>
                                Please confirm your changes:
                            </Typography>
                            <Typography variant="body2">
                                New Location: {newLocationName || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                                New Appointment:{' '}
                                {newAppointment && newAppointment.start
                                    ? newAppointment.start.toDate
                                        ? `${newAppointment.start.toDate().toLocaleString()}`
                                        : `${new Date(newAppointment.start).toLocaleString()}`
                                    : 'N/A'}
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    {step > 1 && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<CancelIcon />}
                            onClick={() => setStep(step - 1)}
                            size="medium"
                        >
                            Back
                        </Button>
                    )}
                    {step === 1 && (
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={() => setStep(2)}
                            size="medium"
                            disabled={!newLocation || newLocation === (containerLocationID || "")}
                        >
                            Next
                        </Button>
                    )}
                    {step === 2 && (
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={confirming ? <CircularProgress size={20} /> : <SaveIcon />}
                            onClick={disableConfirm ? handleConfirm : () => setStep(3)}
                            size="medium"
                            disabled={!newAppointment || confirming}
                        >
                            {confirming ? 'Processing...' : 'Next'}
                        </Button>
                    )}
                    {step === 3 && !disableConfirm && (
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={confirming ? <CircularProgress size={20} /> : <SaveIcon />}
                            onClick={handleConfirm}
                            size="medium"
                            disabled={confirming}
                        >
                            {confirming ? 'Processing...' : 'Confirm'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AppointmentTabLocation;
