import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    IconButton,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tabs,
    Tab,
    Tooltip,
    Snackbar,
    Alert,
} from '@mui/material';
import { Delete, Edit, Add, Logout, Login, ContentCopy } from '@mui/icons-material';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, getDoc, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate, useParams } from 'react-router-dom';

// Helper function to format a Date into a string for datetime-local input
const formatDateToInput = (date) => {
    const pad = (num) => String(num).padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const SpecialMoves = () => {
    const { universityID } = useParams();
    const navigate = useNavigate();

    const [appointments, setAppointments] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [batchEditDialogOpen, setBatchEditDialogOpen] = useState(false);
    const [selectedAppointments, setSelectedAppointments] = useState([]);
    const [universityName, setUniversityName] = useState('');
    const [newAppointment, setNewAppointment] = useState({
        start: '',
        end: '',
        maxCustomers: 0,
        storageCoordinator: '',
        type: 'Move In',      // "Move In" or "Move Out"
        category: 'Early',    // "Early", "Late", or "Off-Campus"
        productCode: '',      // This will be set via the dropdown.
    });
    const [editingAppointment, setEditingAppointment] = useState(null);
    const [batchEditData, setBatchEditData] = useState({
        maxCustomers: 0,
        storageCoordinator: '',
        type: '',
        category: '',
        productCode: '',
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });

    // States for product options for each dialog
    const [newProductOptions, setNewProductOptions] = useState([]);
    const [editProductOptions, setEditProductOptions] = useState([]);
    const [batchProductOptions, setBatchProductOptions] = useState([]);

    useEffect(() => {
        const fetchUniversityName = async () => {
            try {
                const universityDoc = await getDoc(doc(db, 'universities', universityID));
                if (universityDoc.exists()) {
                    setUniversityName(universityDoc.data().name || 'Unknown University');
                }
            } catch (error) {
                console.error('Error fetching university name:', error);
            }
        };

        const fetchAppointments = async () => {
            try {
                // Pulling from specialAppointments instead of appointments
                const appointmentsCollection = collection(db, `universities/${universityID}/specialAppointments`);
                const snapshot = await getDocs(appointmentsCollection);
                const appointmentsData = snapshot.docs.map((docSnap) => {
                    const data = docSnap.data();
                    const start = data.start?.toDate ? data.start.toDate() : new Date(data.start);
                    const end = data.end?.toDate ? data.end.toDate() : new Date(data.end);
                    return {
                        id: docSnap.id,
                        ...data,
                        start,
                        end,
                    };
                });
                // Sort appointments by start date ascending
                appointmentsData.sort((a, b) => a.start - b.start);
                setAppointments(appointmentsData);
            } catch (error) {
                console.error('Error fetching special move appointments:', error);
            }
        };

        fetchUniversityName();
        fetchAppointments();
    }, [universityID]);

    // --- Fetch product options for New Appointment ---
    useEffect(() => {
        if (newAppointment.type && newAppointment.category) {
            const fetchProducts = async () => {
                try {
                    const productsQuery = query(
                        collection(db, 'products'),
                        where('productType', '==', 'Non-Complimentary Move'),
                        where('type', '==', newAppointment.type),
                        where('category', '==', newAppointment.category)
                    );
                    const snapshot = await getDocs(productsQuery);
                    const products = snapshot.docs.map((doc) => ({
                        code: doc.data().code || doc.id,
                        ...doc.data(),
                    }));
                    setNewProductOptions(products);
                } catch (error) {
                    console.error('Error fetching product options:', error);
                    setNewProductOptions([]);
                }
            };
            fetchProducts();
        } else {
            setNewProductOptions([]);
        }
    }, [newAppointment.type, newAppointment.category]);

    // --- Fetch product options for Editing Appointment ---
    useEffect(() => {
        if (editingAppointment && editingAppointment.type && editingAppointment.category) {
            const fetchProducts = async () => {
                try {
                    const productsQuery = query(
                        collection(db, 'products'),
                        where('productType', '==', 'Non-Complimentary Move'),
                        where('type', '==', editingAppointment.type),
                        where('category', '==', editingAppointment.category)
                    );

                    console.log("Editing appointment type: ", editingAppointment.type);
                    console.log('Editing appointment category: ', editingAppointment.category);

                    const snapshot = await getDocs(productsQuery);
                    const products = snapshot.docs.map((doc) => ({
                        code: doc.data().code || doc.id,
                        ...doc.data(),
                    }));

                    console.log("Products: ", products);
                    setEditProductOptions(products);
                } catch (error) {
                    console.error('Error fetching product options for editing:', error);
                    setEditProductOptions([]);
                }
            };
            fetchProducts();
        } else {
            setEditProductOptions([]);
        }
    }, [editingAppointment?.type, editingAppointment?.category]);

    // --- Fetch product options for Batch Edit (if needed) ---
    useEffect(() => {
        if (batchEditData.type && batchEditData.category) {
            const fetchProducts = async () => {
                try {
                    const productsQuery = query(
                        collection(db, 'products'),
                        where('productType', '==', 'Non-Complimentary Move'),
                        where('type', '==', batchEditData.type),
                        where('category', '==', batchEditData.category)
                    );
                    const snapshot = await getDocs(productsQuery);
                    const products = snapshot.docs.map((doc) => ({
                        code: doc.data().code || doc.id,
                        ...doc.data(),
                    }));
                    setBatchProductOptions(products);
                } catch (error) {
                    console.error('Error fetching product options for batch edit:', error);
                    setBatchProductOptions([]);
                }
            };
            fetchProducts();
        } else {
            setBatchProductOptions([]);
        }
    }, [batchEditData.type, batchEditData.category]);

    // --- Dialog state handlers ---
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleOpenEditDialog = (appointment) => {
        // Format dates for the datetime-local input fields
        const formattedAppointment = {
            ...appointment,
            start: formatDateToInput(appointment.start),
            end: formatDateToInput(appointment.end),
        };
        setEditingAppointment(formattedAppointment);
        setEditDialogOpen(true);
    };

    const handleCloseEditDialog = () => {
        setEditingAppointment(null);
        setEditDialogOpen(false);
    };

    // --- CRUD Functions ---
    const handleAddAppointment = async () => {
        try {
            const appointmentRef = await addDoc(
                collection(db, `universities/${universityID}/specialAppointments`),
                {
                    ...newAppointment,
                    start: new Date(newAppointment.start),
                    end: new Date(newAppointment.end),
                }
            );
            const newApp = {
                id: appointmentRef.id,
                ...newAppointment,
                start: new Date(newAppointment.start),
                end: new Date(newAppointment.end),
            };
            setAppointments((prev) => {
                const updated = [...prev, newApp];
                updated.sort((a, b) => a.start - b.start);
                return updated;
            });
            setSnackbar({
                open: true,
                message: 'Special move appointment added successfully!',
                severity: 'success',
            });
            handleCloseDialog();
        } catch (error) {
            console.error('Error adding special move appointment:', error);
            setSnackbar({
                open: true,
                message: 'Failed to add special move appointment.',
                severity: 'error',
            });
        }
    };

    const handleUpdateAppointment = async () => {
        try {
            // Remove the "id" property so it doesn't get saved back to Firestore.
            const { id, ...updatedData } = editingAppointment;
            updatedData.start = new Date(editingAppointment.start);
            updatedData.end = new Date(editingAppointment.end);
            await updateDoc(
                doc(db, `universities/${universityID}/specialAppointments`, id),
                updatedData
            );
            setAppointments((prev) =>
                prev
                    .map((app) =>
                        app.id === id ? { ...updatedData, id } : app
                    )
                    .sort((a, b) => a.start - b.start)
            );
            setSnackbar({
                open: true,
                message: 'Appointment updated successfully!',
                severity: 'success',
            });
            handleCloseEditDialog();
        } catch (error) {
            console.error('Error updating appointment:', error);
            setSnackbar({
                open: true,
                message: 'Failed to update appointment.',
                severity: 'error',
            });
        }
    };


    const handleBatchEdit = async () => {
        try {
            const filteredBatchEditData = Object.fromEntries(
                Object.entries(batchEditData).filter(([key, value]) => value !== '')
            );
            const updatedAppointments = appointments.map((appointment) => {
                if (selectedAppointments.includes(appointment.id)) {
                    return { ...appointment, ...filteredBatchEditData };
                }
                return appointment;
            });
            await Promise.all(
                selectedAppointments.map((id) =>
                    updateDoc(doc(db, `universities/${universityID}/specialAppointments`, id), filteredBatchEditData)
                )
            );
            updatedAppointments.sort((a, b) => a.start - b.start);
            setAppointments(updatedAppointments);
            setSelectedAppointments([]);
            setBatchEditDialogOpen(false);
            setSnackbar({
                open: true,
                message: 'Batch edit successful!',
                severity: 'success',
            });
        } catch (error) {
            console.error('Error during batch edit:', error);
            setSnackbar({
                open: true,
                message: 'Batch edit failed.',
                severity: 'error',
            });
        }
    };

    const handleDeleteAppointment = async (id) => {
        try {
            await deleteDoc(doc(db, `universities/${universityID}/specialAppointments`, id));
            setAppointments((prev) => prev.filter((appointment) => appointment.id !== id));
            setSnackbar({
                open: true,
                message: 'Appointment deleted successfully!',
                severity: 'success',
            });
        } catch (error) {
            console.error('Error deleting appointment:', error);
            setSnackbar({
                open: true,
                message: 'Failed to delete appointment.',
                severity: 'error',
            });
        }
    };

    const handleDuplicateAppointment = async (appointment) => {
        try {
            const { id, title, ...appointmentData } = appointment;
            const appointmentRef = await addDoc(
                collection(db, `universities/${universityID}/specialAppointments`),
                {
                    ...appointmentData,
                    start: new Date(appointment.start),
                    end: new Date(appointment.end),
                }
            );
            const duplicatedAppointment = {
                id: appointmentRef.id,
                ...appointmentData,
                start: new Date(appointment.start),
                end: new Date(appointment.end),
            };
            setAppointments((prev) => {
                const updated = [...prev, duplicatedAppointment];
                updated.sort((a, b) => a.start - b.start);
                return updated;
            });
            setSnackbar({
                open: true,
                message: 'Appointment duplicated successfully!',
                severity: 'success',
            });
        } catch (error) {
            console.error('Error duplicating appointment:', error);
            setSnackbar({
                open: true,
                message: 'Failed to duplicate appointment.',
                severity: 'error',
            });
        }
    };

    // In the table, replace "Cost" with "Product Code"
    const renderAppointmentTable = (appointments) => {
        const filteredAppointments = appointments.filter((app) => app.category);
        const sortedAppointments = [...filteredAppointments].sort((a, b) => a.start - b.start);
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" />
                            <TableCell>Day</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Start Time</TableCell>
                            <TableCell>End Time</TableCell>
                            <TableCell>Max Customers</TableCell>
                            <TableCell>Storage Coordinator</TableCell>
                            <TableCell>Product Code</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedAppointments.map((appointment) => (
                            <TableRow key={appointment.id}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedAppointments.includes(appointment.id)}
                                        onChange={() =>
                                            setSelectedAppointments((prev) =>
                                                prev.includes(appointment.id)
                                                    ? prev.filter((id) => id !== appointment.id)
                                                    : [...prev, appointment.id]
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell>{appointment.start.toDateString()}</TableCell>
                                <TableCell>{appointment.category}</TableCell>
                                <TableCell>{appointment.type}</TableCell>
                                <TableCell>
                                    {appointment.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </TableCell>
                                <TableCell>
                                    {appointment.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </TableCell>
                                <TableCell>{appointment.maxCustomers}</TableCell>
                                <TableCell>{appointment.storageCoordinator}</TableCell>
                                <TableCell>{appointment.productCode || '-'}</TableCell>
                                <TableCell>
                                    <Tooltip title="Edit Appointment">
                                        <IconButton size="small" onClick={() => handleOpenEditDialog(appointment)}>
                                            <Edit fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete Appointment">
                                        <IconButton size="small" onClick={() => handleDeleteAppointment(appointment.id)}>
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Duplicate Appointment">
                                        <IconButton size="small" onClick={() => handleDuplicateAppointment(appointment)}>
                                            <ContentCopy fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box sx={{ p: 3 }}>
            <Button onClick={() => navigate(-1)} variant="outlined" color="primary" sx={{ width: 'auto', mb: 2 }}>
                Back
            </Button>

            <Typography variant="h4" sx={{ mb: 1 }}>
                Manage Special Move Appointments
            </Typography>
            <Typography variant="subtitle2" gutterBottom sx={{ color: 'gray', mb: 2 }}>
                Showing <strong>{appointments.filter(app => app.category).length}</strong> appointments for <strong>{universityName}</strong>
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', mb: 2 }}>
                <Tabs
                    value={selectedTab}
                    onChange={(e, newValue) => setSelectedTab(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Move Out Appointments" icon={<Logout />} iconPosition="start" />
                    <Tab label="Move In Appointments" icon={<Login />} iconPosition="start" />
                </Tabs>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button variant="contained" size="small" sx={{ height: '100%' }} startIcon={<Add />} onClick={handleOpenDialog}>
                        Add Appointment
                    </Button>
                    {selectedAppointments.length > 0 && (
                        <Button variant="outlined" startIcon={<Edit />} onClick={() => setBatchEditDialogOpen(true)}>
                            Batch Edit
                        </Button>
                    )}
                </Box>
            </Box>

            {selectedTab === 1 &&
                renderAppointmentTable(appointments.filter((app) => app.type === 'Move In'))}
            {selectedTab === 0 &&
                renderAppointmentTable(appointments.filter((app) => app.type === 'Move Out'))}

            {/* Add Appointment Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Add Special Move Appointment</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Start Time"
                        type="datetime-local"
                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        value={newAppointment.start}
                        onChange={(e) => setNewAppointment({ ...newAppointment, start: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        label="End Time"
                        type="datetime-local"
                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        value={newAppointment.end}
                        onChange={(e) => setNewAppointment({ ...newAppointment, end: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        label="Max Customers"
                        type="number"
                        margin="dense"
                        value={newAppointment.maxCustomers}
                        onChange={(e) =>
                            setNewAppointment({ ...newAppointment, maxCustomers: parseInt(e.target.value, 10) })
                        }
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Storage Coordinator</InputLabel>
                        <Select
                            value={newAppointment.storageCoordinator}
                            onChange={(e) =>
                                setNewAppointment({ ...newAppointment, storageCoordinator: e.target.value })
                            }
                            label="Storage Coordinator"
                        >
                            <MenuItem value="Coordinator A">Coordinator A</MenuItem>
                            <MenuItem value="Coordinator B">Coordinator B</MenuItem>
                            <MenuItem value="Coordinator C">Coordinator C</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Type</InputLabel>
                        <Select
                            value={newAppointment.type}
                            onChange={(e) =>
                                setNewAppointment({ ...newAppointment, type: e.target.value, productCode: '' })
                            }
                            label="Type"
                        >
                            <MenuItem value="Move In">Move In</MenuItem>
                            <MenuItem value="Move Out">Move Out</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Category</InputLabel>
                        <Select
                            value={newAppointment.category}
                            onChange={(e) =>
                                setNewAppointment({ ...newAppointment, category: e.target.value, productCode: '' })
                            }
                            label="Category"
                        >
                            <MenuItem value="Early">Early</MenuItem>
                            <MenuItem value="Late">Late</MenuItem>
                            <MenuItem value="Off-Campus">Off-Campus</MenuItem>
                        </Select>
                    </FormControl>
                    {/* Show product code dropdown only when type and category are selected */}
                    {newAppointment.type && newAppointment.category && newProductOptions.length > 0 && (
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Product Code</InputLabel>
                            <Select
                                value={newAppointment.productCode}
                                onChange={(e) =>
                                    setNewAppointment({ ...newAppointment, productCode: e.target.value })
                                }
                                label="Product Code"
                            >
                                {newProductOptions.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                        {option.label} ({option.code})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddAppointment} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Appointment Dialog */}
            <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
                <DialogTitle>Edit Special Move Appointment</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Start Time"
                        type="datetime-local"
                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        value={editingAppointment?.start || ''}
                        onChange={(e) =>
                            setEditingAppointment({ ...editingAppointment, start: e.target.value })
                        }
                    />
                    <TextField
                        fullWidth
                        label="End Time"
                        type="datetime-local"
                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        value={editingAppointment?.end || ''}
                        onChange={(e) =>
                            setEditingAppointment({ ...editingAppointment, end: e.target.value })
                        }
                    />
                    <TextField
                        fullWidth
                        label="Max Customers"
                        type="number"
                        margin="dense"
                        value={editingAppointment?.maxCustomers || ''}
                        onChange={(e) =>
                            setEditingAppointment({
                                ...editingAppointment,
                                maxCustomers: parseInt(e.target.value, 10),
                            })
                        }
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Storage Coordinator</InputLabel>
                        <Select
                            value={editingAppointment?.storageCoordinator || ''}
                            onChange={(e) =>
                                setEditingAppointment({
                                    ...editingAppointment,
                                    storageCoordinator: e.target.value,
                                })
                            }
                            label="Storage Coordinator"
                        >
                            <MenuItem value="Coordinator A">Coordinator A</MenuItem>
                            <MenuItem value="Coordinator B">Coordinator B</MenuItem>
                            <MenuItem value="Coordinator C">Coordinator C</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Type</InputLabel>
                        <Select
                            value={editingAppointment?.type || ''}
                            onChange={(e) =>
                                setEditingAppointment({ ...editingAppointment, type: e.target.value, productCode: '' })
                            }
                            label="Type"
                        >
                            <MenuItem value="Move In">Move In</MenuItem>
                            <MenuItem value="Move Out">Move Out</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Category</InputLabel>
                        <Select
                            value={editingAppointment?.category || ''}
                            onChange={(e) =>
                                setEditingAppointment({ ...editingAppointment, category: e.target.value, productCode: '' })
                            }
                            label="Category"
                        >
                            <MenuItem value="Early">Early</MenuItem>
                            <MenuItem value="Late">Late</MenuItem>
                            <MenuItem value="Off-Campus">Off-Campus</MenuItem>
                        </Select>
                    </FormControl>
                    {editingAppointment?.type && editingAppointment?.category && editProductOptions.length > 0 && (
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Product Code</InputLabel>
                            <Select
                                value={editingAppointment?.productCode || ''}
                                onChange={(e) =>
                                    setEditingAppointment({ ...editingAppointment, productCode: e.target.value })
                                }
                                label="Product Code"
                            >
                                {editProductOptions.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                        {option.label} ({option.code})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateAppointment} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Batch Edit Dialog */}
            <Dialog
                open={batchEditDialogOpen}
                onClose={() => setBatchEditDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Batch Edit Appointments</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Max Customers"
                        type="number"
                        margin="dense"
                        onChange={(e) =>
                            setBatchEditData((prev) => ({
                                ...prev,
                                maxCustomers: parseInt(e.target.value, 10) || prev.maxCustomers,
                            }))
                        }
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Storage Coordinator</InputLabel>
                        <Select
                            value={batchEditData.storageCoordinator}
                            onChange={(e) =>
                                setBatchEditData((prev) => ({
                                    ...prev,
                                    storageCoordinator: e.target.value || prev.storageCoordinator,
                                }))
                            }
                            label="Storage Coordinator"
                        >
                            <MenuItem value="Coordinator A">Coordinator A</MenuItem>
                            <MenuItem value="Coordinator B">Coordinator B</MenuItem>
                            <MenuItem value="Coordinator C">Coordinator C</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Type</InputLabel>
                        <Select
                            value={batchEditData.type}
                            onChange={(e) =>
                                setBatchEditData((prev) => ({
                                    ...prev,
                                    type: e.target.value || prev.type,
                                }))
                            }
                            label="Type"
                        >
                            <MenuItem value="Move In">Move In</MenuItem>
                            <MenuItem value="Move Out">Move Out</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Category</InputLabel>
                        <Select
                            value={batchEditData.category}
                            onChange={(e) =>
                                setBatchEditData((prev) => ({
                                    ...prev,
                                    category: e.target.value || prev.category,
                                }))
                            }
                            label="Category"
                        >
                            <MenuItem value="Early">Early</MenuItem>
                            <MenuItem value="Late">Late</MenuItem>
                            <MenuItem value="Off-Campus">Off-Campus</MenuItem>
                        </Select>
                    </FormControl>
                    {batchEditData.type && batchEditData.category && batchProductOptions.length > 0 && (
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Product Code</InputLabel>
                            <Select
                                value={batchEditData.productCode}
                                onChange={(e) =>
                                    setBatchEditData((prev) => ({
                                        ...prev,
                                        productCode: e.target.value,
                                    }))
                                }
                                label="Product Code"
                            >
                                {batchProductOptions.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                        {option.label} ({option.code})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBatchEditDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleBatchEdit} color="primary">
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SpecialMoves;
