import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import EditItemAdmin from './EditItemAdmin'; 

const UserDetailItems = ({ user, userId, inventory, inventoryLoading }) => {
    const [maxItems, setMaxItems] = useState(null);
    const [editItemId, setEditItemId] = useState(null);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const db = getFirestore();
                const productDoc = await getDoc(doc(db, 'products', user.storagePlan));
                if (productDoc.exists()) {
                    const data = productDoc.data();
                    console.log("Max Items!", data.maxItems);
                    setMaxItems(data.maxItems);
                } else {
                    console.log("Doesn't exist!");
                }
            } catch (error) {
                console.error('Error fetching product details', error);
            }
        };

        if (user?.storagePlan) {
            fetchProductDetails();
        }
    }, [user]);

    if (inventoryLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '200px',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
    if (inventory.length === 0) {
        return (
            <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography>No inventory found for this user.</Typography>
            </Box>
        );
    }

    return (
        <Paper sx={{ p: 2 }}>
            <Typography
                variant="h6"
                sx={{
                    mb: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <span>User Inventory</span>
                {maxItems !== null && <span>{inventory.length} / {maxItems}</span>}
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: 50, height: 50 }}>Icon</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Dimensions</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inventory.map((item, index) => (
                            <TableRow key={item.id}>
                                <TableCell
                                    sx={{
                                        backgroundColor: 'primary.main',
                                        width: 50,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderTopLeftRadius: index === 0 ? 4 : 0,
                                        borderBottomLeftRadius: index === inventory.length - 1 ? 4 : 0,
                                    }}
                                >
                                    <img
                                        src={`/images/items/${item.type.toLowerCase().replace(/\s+/g, '-')}.webp`}
                                        alt={item.type}
                                        style={{ width: 25, height: 25 }}
                                    />
                                </TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>
                                    {item.dimensions
                                        ? `${item.dimensions.height}x${item.dimensions.length}x${item.dimensions.width}`
                                        : '—'}
                                </TableCell>
                                <TableCell>{item.type || '—'}</TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => setEditItemId(item.id)} sx={{p: 0, my: 0}}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => {/*    */ }} sx={{ p: 0, my: 0 }}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Render the EditItemAdmin dialog when an item is selected */}
            {editItemId && (
                <EditItemAdmin
                    userId={userId}
                    open={Boolean(editItemId)}
                    onClose={() => setEditItemId(null)}
                    itemId={editItemId}
                />
            )}
        </Paper>
    );
};

export default UserDetailItems;









