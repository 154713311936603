import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  ListItemIcon,
  Badge,
  useMediaQuery,
  IconButton,
  Divider,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PaymentsIcon from '@mui/icons-material/Payments';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PeopleIcon from '@mui/icons-material/People';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SchoolIcon from '@mui/icons-material/School';
import CategoryIcon from '@mui/icons-material/Category';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { FaPeopleCarry } from "react-icons/fa";
import FeedbackIcon from '@mui/icons-material/FeedbackOutlined';
import HelpIcon from '@mui/icons-material/HelpCenterTwoTone';
import NotificationsNone from '@mui/icons-material/NotificationsNone';
import { useAuth } from '../contexts/AuthContext';
import { usePageNotifications } from '../contexts/PageNotificationContext';

/* USER MENU */
const userMenuSections = [
  {
    section: 'Main',
    items: [
      { text: 'Dashboard', path: '/dashboard', icon: <DashboardIcon />, key: 'dashboard' },
      { text: 'My Items', path: '/dashboard/items', icon: <InventoryIcon />, key: 'items' },
      { text: 'Appointments', path: '/dashboard/appointments', icon: <CalendarMonthIcon />, key: 'appointments' },
    ],
  },
  {
    section: 'Services',
    items: [
      { text: 'Storage Plan', path: '/dashboard/plan', icon: <StorageIcon />, key: 'plan' },
      { text: 'Movers', path: '/dashboard/movers', icon: <FaPeopleCarry />, key: 'plan' },
    ],
  },
  {
    section: 'Account',
    items: [
      { text: 'Payments', path: '/dashboard/payments', icon: <PaymentsIcon />, key: 'payments' },
      { text: 'Settings', path: '/dashboard/settings', icon: <SettingsIcon />, key: 'settings' },
      { text: 'Support Center', path: '/dashboard/support', icon: <SupportAgentIcon />, key: 'support' },
    ],
  },
];

/* ADMIN MENU */
const adminMenuSections = [
  {
    section: 'Analytics',
    items: [
      { text: 'Dashboard', path: '/dashboard', icon: <DashboardIcon /> },
      { text: 'System Monitoring', path: '/dashboard/system-monitoring', icon: <AssessmentIcon /> },
      { text: 'Activity Feed', path: '/dashboard/activity-feed', icon: <ListAltIcon /> },
    ],
  },
  {
    section: 'Logistics',
    items: [
      { text: 'Containers', path: '/dashboard/containers', icon: <InventoryIcon /> },
      { text: 'Special Moves', path: '/dashboard/special-moves', icon: <LocalShippingIcon /> },
    ],
  },
  {
    section: 'Management',
    items: [
      { text: 'Customers', path: '/dashboard/users', icon: <PeopleIcon /> },
      { text: 'Universities', path: '/dashboard/universities', icon: <SchoolIcon /> },
      { text: 'Notifications', path: '/dashboard/notifications', icon: <NotificationsNone /> },
    ],
  },
  {
    section: 'Sales',
    items: [
      { text: 'Products', path: '/dashboard/products', icon: <CategoryIcon /> },
      { text: 'Sales History', path: '/dashboard/sales-dashboard', icon: <AttachMoneyIcon /> },
    ],
  },
  {
    section: 'Support',
    items: [
      { text: 'Tickets', path: '/dashboard/tickets', icon: <SupportAgentIcon /> },
      { text: 'Customer Feedback', path: '/dashboard/customer-feedback', icon: <FeedbackIcon /> },
      { text: 'Help Center', path: '/dashboard/help-center', icon: <HelpIcon /> },
    ],
  },
];

/* COORDINATOR MENU */
const coordinatorMenuSections = [
  {
    section: 'Overview',
    items: [
      { text: 'Dashboard', path: '/dashboard', icon: <DashboardIcon />, key: 'dashboard' },
      { text: 'Schedule', path: '/dashboard/schedule', icon: <CalendarMonthIcon />, key: 'schedule' },
    ],
  },
  {
    section: 'Management',
    items: [
      { text: 'Customers', path: '/dashboard/customers', icon: <PeopleIcon />, key: 'customers' },
      { text: 'Movers', path: '/dashboard/movers', icon: <LocalShippingIcon />, key: 'movers' },
    ],
  },
];

/* MOVER MENU */
const moverMenuSections = [
  {
    section: 'Overview',
    items: [
      { text: 'Dashboard', path: '/dashboard', icon: <DashboardIcon />, key: 'dashboard' },
      { text: 'My Moves', path: '/dashboard/my-moves', icon: <LocalShippingIcon />, key: 'my-moves' },
    ],
  },
];

const Sidebar = ({ open, toggleSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userClaims } = useAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { notifications } = usePageNotifications();

  const handleMenuItemClick = (path) => {
    navigate(path);
    if (isMobile) {
      toggleSidebar();
    }
  };

  const getMenuSections = () => {
    if (userClaims.role === 'admin') return adminMenuSections;
    if (userClaims.role === 'coordinator') return coordinatorMenuSections;
    if (userClaims.role === 'mover') return moverMenuSections;
    return userMenuSections; // Default: user menu
  };

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor="left"
      open={open}
      onClose={isMobile ? toggleSidebar : undefined}
      ModalProps={{ keepMounted: true }}
    >
      <Box
        sx={{
          width: { md: 240, xs: '100vw' },
          backgroundColor: 'secondary.alternate',
          minHeight: '100vh',
          overflowY: 'auto',
          pb: 2, // Extra padding so the background extends a bit beyond the last button
        }}
      >
        {/* Sidebar Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', pt: 2, pl: 1 }}>
          <IconButton
            onClick={toggleSidebar}
            sx={{ display: { md: 'none', xs: 'block' }, color: 'white', mt: 2, pl: 2, pr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/" sx={{ height: '18px' }}>
            <Box
              component="img"
              src="/images/SummerStore-Logo.webp"
              alt="SummerStore Logo"
              sx={{ height: '15px', width: 'auto', mt: { md: 3, xs: '17px' }, pl: 2 }}
            />
          </Link>
        </Box>

        {/* Welcome Message */}
        <Typography
          variant="subtitle2"
          sx={{ color: 'white', p: 2, mt: 2, textAlign: 'left', width: '100%' }}
        >
          Welcome, <span style={{ fontWeight: 'bold' }}>{userClaims.firstName || 'User'}!</span>
        </Typography>

        {/* Dynamic Menu Sections */}
        <List>
          {getMenuSections().map((section, index) => (
            <React.Fragment key={section.section}>
              <Typography
                variant="subtitle2"
                sx={{ color: 'white', pl: 3, mt: index === 0 ? 0 : 2, mb: 1 }}
              >
                {section.section}
              </Typography>
              {section.items.map((item) => (
                <ListItem
                  button
                  key={item.text}
                  onClick={() => handleMenuItemClick(item.path)}
                  sx={{
                    color: 'white',
                    backgroundColor: location.pathname === item.path ? '#244A83' : 'inherit',
                    '&:hover': { backgroundColor: '#244A83' },
                    pl: 3,
                    borderLeft: location.pathname === item.path ? '5px solid #3B88EA' : 'none',
                  }}
                >
                  <ListItemIcon sx={{ color: 'white' }}>
                    {notifications[item.key] ? (
                      <Badge badgeContent={notifications[item.key]} color="error">
                        {item.icon}
                      </Badge>
                    ) : (
                      item.icon
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      fontWeight: location.pathname === item.path ? 'bold' : 'normal',
                    }}
                  />
                </ListItem>
              ))}
              {index < getMenuSections().length - 1 && (
                <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', my: 1 }} />
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default React.memo(Sidebar);
