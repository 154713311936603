// NotificationTemplatesPopup.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

const NotificationTemplatesPopup = ({
    open,
    onClose,
    onTemplateSelect,  // Callback that passes the selected template back to parent
    currentSubject, 
    currentBody
}) => {
    const [templates, setTemplates] = useState([]);
    const [newTemplateName, setNewTemplateName] = useState('');
    const [loadingTemplates, setLoadingTemplates] = useState(false);

    const fetchTemplates = async () => {
        setLoadingTemplates(true);
        try {
            const querySnapshot = await getDocs(collection(db, 'emailTemplates'));
            const templatesData = [];
            querySnapshot.forEach((docSnap) => {
                templatesData.push({ id: docSnap.id, ...docSnap.data() });
            });
            setTemplates(templatesData);
        } catch (err) {
            console.error('Error fetching templates: ', err);
        }
        setLoadingTemplates(false);
    };

    useEffect(() => {
        if (open) {
            fetchTemplates();
        }
    }, [open]);

    const handleSaveAsTemplate = async () => {
        if (!newTemplateName) return;
        try {
            const payload = {
                name: newTemplateName,
                // In this popup, you might decide not to send the current subject/body.
                // Alternatively, pass them as props.
                subject: currentSubject,
                body: currentBody
            };
            await addDoc(collection(db, 'emailTemplates'), payload);
            setNewTemplateName('');
            fetchTemplates();
        } catch (err) {
            console.error('Error saving template: ', err);
        }
    };

    const handleDeleteTemplate = async (templateId) => {
        try {
            await deleteDoc(doc(db, 'emailTemplates', templateId));
            fetchTemplates();
        } catch (err) {
            console.error('Error deleting template: ', err);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Email Templates</DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Save new template</Typography>
                    <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                        <TextField
                            label="Template Name"
                            value={newTemplateName}
                            onChange={(e) => setNewTemplateName(e.target.value)}
                            fullWidth
                        />
                        <Button variant="contained" onClick={handleSaveAsTemplate}>
                            Save
                        </Button>
                    </Box>
                </Box>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Available Templates
                </Typography>
                {loadingTemplates ? (
                    <Typography>Loading templates...</Typography>
                ) : (
                    <List>
                        {templates.map((template) => (
                            <ListItem
                                button
                                key={template.id}
                                onClick={() => onTemplateSelect(template)}
                            >
                                <ListItemText
                                    primary={template.name}
                                    secondary={template.subject}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteTemplate(template.id);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default NotificationTemplatesPopup;
