import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Paper,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    OutlinedInput,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import {
    ReactFlow,
    useNodesState,
    useEdgesState,
    addEdge,
    MiniMap,
    Controls,
    Background,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import CustomContainerNode from './CustomContainerNode';
import CustomGroupNode from './CustomGroupNode';

const ContainerFlowGraph = () => {
    const { universityID } = useParams();
    const navigate = useNavigate();

    // State management hooks for nodes/edges.
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [containers, setContainers] = useState([]);
    const [loading, setLoading] = useState(true);
    // State for tracking the sizes of each container's child nodes.
    const [containerSizes, setContainerSizes] = useState({});
    // State for storing appointment location names mapped by location ID.
    const [appointmentLocations, setAppointmentLocations] = useState({});

    // New state for filtering.
    const [filterType, setFilterType] = useState('none'); // 'none' or 'location'
    const [selectedLocations, setSelectedLocations] = useState([]);

    // Layout constants.
    const periodX = useMemo(() => ({ moveOut: 0, summer: 450, moveIn: 900 }), []);
    const yStart = 50;
    const gap = 30; // Constant vertical gap between groups (in addition to group height)
    const padding = 50;

    // Memoize node types.
    const nodeTypes = useMemo(
        () => ({
            customContainerNode: CustomContainerNode,
            customGroupNode: CustomGroupNode,
        }),
        []
    );

    // Callback for child nodes to report their rendered sizes.
    const reportSize = useCallback((containerId, period, width, height) => {
        setContainerSizes((prev) => {
            const currentSize = prev[containerId]?.[period];
            if (currentSize && currentSize.width === width && currentSize.height === height) {
                return prev;
            }
            return {
                ...prev,
                [containerId]: {
                    ...prev[containerId],
                    [period]: { width, height },
                },
            };
        });
    }, []);

    // Fetch containers for the given universityID.
    useEffect(() => {
        const fetchContainers = async () => {
            setLoading(true);
            try {
                const containersRef = collection(db, 'containers');
                const q = query(containersRef, where('universityId', '==', universityID));
                const snapshot = await getDocs(q);
                const containersData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setContainers(containersData);
            } catch (error) {
                console.error('Error fetching containers: ', error);
            } finally {
                setLoading(false);
            }
        };

        if (universityID) {
            fetchContainers();
        }
    }, [universityID]);

    // Batch fetch appointment location names for all containers.
    useEffect(() => {
        const fetchAppointmentLocations = async () => {
            if (containers.length === 0) return;
            // Gather unique location IDs from both moveOut and moveIn.
            const locationIDs = new Set();
            containers.forEach((container) => {
                if (container.moveOutAppointmentLocationID) {
                    locationIDs.add(container.moveOutAppointmentLocationID);
                }
                if (container.moveInAppointmentLocationID) {
                    locationIDs.add(container.moveInAppointmentLocationID);
                }
            });
            const idsArray = Array.from(locationIDs);
            if (idsArray.length === 0) {
                setAppointmentLocations({});
                return;
            }
            try {
                // Query the containerLocations collection.
                const containerLocationsRef = collection(
                    db,
                    `universities/${universityID}/containerLocations`
                );
                // Firestore allows querying by document ID using __name__.
                const q = query(containerLocationsRef, where('__name__', 'in', idsArray));
                const snapshot = await getDocs(q);
                const mapping = {};
                snapshot.forEach((doc) => {
                    mapping[doc.id] = doc.data().name;
                });
                setAppointmentLocations(mapping);
            } catch (error) {
                console.error('Error fetching appointment locations: ', error);
            }
        };

        fetchAppointmentLocations();
    }, [containers, universityID]);

    // Helper to compute a group's height.
    // (computed height || 120) + 2 * padding.
    const computeGroupHeight = useCallback(
        (containerId) => {
            const groupSizes = containerSizes[containerId] || {};
            const periods = ['moveOut', 'summer', 'moveIn'];
            const computedGroupHeight = Math.max(
                ...periods.map((period) => (groupSizes[period] ? groupSizes[period].height : 0))
            );
            return (computedGroupHeight || 120) + 2 * padding;
        },
        [containerSizes, padding]
    );

    // Build nodes and aggregated edges.
    useEffect(() => {
        // First, filter the containers if needed.
        const filteredContainers = containers.filter((container) => {
            if (filterType !== 'location' || selectedLocations.length === 0) {
                return true;
            }

            // Get appointment names for each period.
            const moveOutName =
                container.moveOutAppointmentLocationID
                    ? appointmentLocations[container.moveOutAppointmentLocationID] || 'No Location'
                    : 'No Location';
            const moveInName =
                container.moveInAppointmentLocationID
                    ? appointmentLocations[container.moveInAppointmentLocationID] || 'No Location'
                    : 'No Location';
            const summerName = 'Storage Facility';

            // Check that there is at least one customer for the period and that the location matches.
            const moveOutValid =
                container.moveOutCustomers?.length > 0 && selectedLocations.includes(moveOutName);
            const summerValid =
                container.summerCustomers?.length > 0 && selectedLocations.includes(summerName);
            const moveInValid =
                container.moveInCustomers?.length > 0 && selectedLocations.includes(moveInName);

            return moveOutValid || summerValid || moveInValid;
        });

        const newNodes = [];
        const periods = ['moveOut', 'summer', 'moveIn'];

        // Use the filtered containers when building nodes.
        filteredContainers.forEach((container, index) => {
            const groupSizes = containerSizes[container.id] || {};
            const computedGroupWidth = Math.max(
                ...periods.map((period) =>
                    groupSizes[period] ? periodX[period] + groupSizes[period].width : periodX[period]
                )
            );
            const computedGroupHeight = Math.max(
                ...periods.map((period) => (groupSizes[period] ? groupSizes[period].height : 0))
            );
            const groupWidth = (computedGroupWidth || 1000) + 2 * padding;
            const groupHeight = (computedGroupHeight || 120) + 2 * padding;

            newNodes.push({
                id: container.id,
                type: 'customGroupNode',
                data: {
                    label: container.id,
                    // Removed onReorganize functionality.
                },
                position: { x: 0, y: yStart + index * (gap + 220) },
                style: {
                    width: groupWidth,
                    height: groupHeight,
                    border: '1px solid #999',
                },
            });

            periods.forEach((period) => {
                const customers = container[`${period}Customers`] || [];
                let appointmentLocationName = '';
                if (period === 'summer') {
                    appointmentLocationName = 'Storage Facility';
                } else if (period === 'moveOut') {
                    appointmentLocationName = container.moveOutAppointmentLocationID
                        ? appointmentLocations[container.moveOutAppointmentLocationID] || 'No Location'
                        : 'No Location';
                } else if (period === 'moveIn') {
                    appointmentLocationName = container.moveInAppointmentLocationID
                        ? appointmentLocations[container.moveInAppointmentLocationID] || 'No Location'
                        : 'No Location';
                }

                newNodes.push({
                    id: `${container.id}-${period}`,
                    type: 'customContainerNode',
                    parentId: container.id,
                    data: {
                        containerId: container.id,
                        period,
                        customerCount: customers.length,
                        reportSize,
                        appointmentLocationName,
                    },
                    position: { x: periodX[period] + padding, y: padding },
                    extent: 'parent',
                    sourcePosition: period === 'moveOut' || period === 'summer' ? 'right' : undefined,
                    targetPosition: period === 'summer' || period === 'moveIn' ? 'left' : undefined,
                });
            });
        });

        // Build lookup mappings.
        const summerMapping = {};
        const moveInMapping = {};
        filteredContainers.forEach((container) => {
            (container.summerCustomers || []).forEach((customerId) => {
                summerMapping[customerId] = container.id;
            });
            (container.moveInCustomers || []).forEach((customerId) => {
                moveInMapping[customerId] = container.id;
            });
        });

        // Aggregate movement.
        const aggregatedMoveOutToSummer = {};
        filteredContainers.forEach((container) => {
            const sourceId = container.id;
            (container.moveOutCustomers || []).forEach((customerId) => {
                const targetContainerId = summerMapping[customerId];
                if (targetContainerId) {
                    const key = `${sourceId}-${targetContainerId}`;
                    aggregatedMoveOutToSummer[key] =
                        (aggregatedMoveOutToSummer[key] || 0) + 1;
                }
            });
        });

        const aggregatedSummerToMoveIn = {};
        filteredContainers.forEach((container) => {
            const sourceId = container.id;
            (container.summerCustomers || []).forEach((customerId) => {
                const targetContainerId = moveInMapping[customerId];
                if (targetContainerId) {
                    const key = `${sourceId}-${targetContainerId}`;
                    aggregatedSummerToMoveIn[key] =
                        (aggregatedSummerToMoveIn[key] || 0) + 1;
                }
            });
        });

        const newEdges = [];

        Object.entries(aggregatedMoveOutToSummer).forEach(([key]) => {
            const [sourceContainerId, targetContainerId] = key.split('-');
            newEdges.push({
                id: `edge-${sourceContainerId}-moveOut-to-${targetContainerId}-summer`,
                source: `${sourceContainerId}-moveOut`,
                target: `${targetContainerId}-summer`,
                animated: true,
                sourceHandle: 'source',
                targetHandle: 'target',
                markerEnd: { type: 'arrowclosed' },
            });
        });

        Object.entries(aggregatedSummerToMoveIn).forEach(([key]) => {
            const [sourceContainerId, targetContainerId] = key.split('-');
            newEdges.push({
                id: `edge-${sourceContainerId}-summer-to-${targetContainerId}-moveIn`,
                source: `${sourceContainerId}-summer`,
                target: `${targetContainerId}-moveIn`,
                animated: true,
                sourceHandle: 'source',
                targetHandle: 'target',
                markerEnd: { type: 'arrowclosed' },
            });
        });

        filteredContainers.forEach((container) => {
            newEdges.push({
                id: `internal-${container.id}-moveOut-to-summer`,
                source: `${container.id}-moveOut`,
                target: `${container.id}-summer`,
                animated: false,
                style: { stroke: 'lightgray', strokeWidth: 2 },
                sourceHandle: 'source',
                targetHandle: 'target',
            });
            newEdges.push({
                id: `internal-${container.id}-summer-to-moveIn`,
                source: `${container.id}-summer`,
                target: `${container.id}-moveIn`,
                animated: false,
                style: { stroke: 'lightgray', strokeWidth: 2 },
                sourceHandle: 'source',
                targetHandle: 'target',
            });
        });

        setNodes(newNodes);
        setEdges(newEdges);
    }, [
        containers,
        containerSizes,
        appointmentLocations,
        periodX,
        yStart,
        gap,
        reportSize,
        padding,
        computeGroupHeight,
        filterType,
        selectedLocations,
    ]);

    const onConnect = useCallback(
        (connection) =>
            setEdges((eds) =>
                addEdge(
                    {
                        ...connection,
                        animated: true,
                        sourceHandle: 'source',
                        targetHandle: 'target',
                        markerEnd: { type: 'arrowclosed' },
                    },
                    eds
                )
            ),
        [setEdges]
    );

    return (
        <Box style={{ height: '80vh', mx: 4 }}>
            <Button onClick={() => navigate(-1)} variant="outlined" color="primary" sx={{ width: 'auto', mb: 1 }}>
                Back
            </Button>
            {/* Header with title and filter controls */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="h6">Viewing Container Flow</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl size="small" sx={{ mr: 2, minWidth: 120 }}>
                        <InputLabel id="filter-label">Filter by</InputLabel>
                        <Select
                            value={filterType}
                            onChange={(e) => setFilterType(e.target.value)}
                            labelId="filter-label"
                            input={<OutlinedInput label="Filter By" />}
                        >
                            <MenuItem value="location">Location</MenuItem>
                            <MenuItem value="none">None</MenuItem>
                        </Select>
                    </FormControl>
                    {filterType === 'location' && (
                        <FormControl size="small" sx={{ minWidth: 200 }}>
                            <InputLabel id="location-filter-label">Select Location</InputLabel>
                            <Select
                                labelId="location-filter-label"
                                multiple
                                value={selectedLocations}
                                onChange={(e) => setSelectedLocations(e.target.value)}
                                input={<OutlinedInput label="Select Location" />}
                                renderValue={(selected) =>
                                    selected.length > 0 ? `${selected.length} selected` : ''
                                }
                            >
                                {/* Include Storage Facility as an option */}
                                <MenuItem value="Storage Facility">Storage Facility</MenuItem>
                                {/* Use appointmentLocations values */}
                                {Object.values(appointmentLocations).map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Box>
            </Box>
            <Paper style={{ height: '90%', border: '1px solid #ddd' }}>
                {loading ? (
                    <div style={{ padding: 20 }}>Loading containers...</div>
                ) : (
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        nodeTypes={nodeTypes}
                        fitView
                    >
                        <MiniMap />
                        <Controls />
                        <Background color="#aaa" gap={16} />
                    </ReactFlow>
                )}
            </Paper>
        </Box>
    );
};

export default ContainerFlowGraph;
