import React, { useState, useMemo, memo } from 'react';
import {
    Box,
    Typography,
    Paper,
    TextField,
    Button,
    Tabs,
    Tab,
    Grid,
    InputAdornment,
    Tooltip,
    Select,
    MenuItem,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import AutoAwesome from '@mui/icons-material/AutoAwesome';

// A memoized component for rendering each item card
const ItemCard = memo(({ config, active, onShowInfo, onSelect, onHideInfo }) => (
    <Grid item xs={12} sm={12} md={12} lg={4} key={config.id}>
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: '1px solid #ccc',
                overflow: 'hidden',
                transition: 'all 0.3s ease-in-out',
                position: 'relative',
                width: '100%',
            }}
        >
            {/* Tooltip for summerstore_box */}
            {config.id === 'summerstore_box' && (
                <Tooltip title="Includes a complimentary cardboard box">
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 12,
                            right: 12,
                            zIndex: 10,
                        }}
                    >
                        <AutoAwesome sx={{ color: '#fff' }} />
                    </Box>
                </Tooltip>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    transform: active ? 'translateX(-100%)' : 'translateX(0)',
                    transition: 'transform 0.3s ease-in-out',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'primary.main',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        backgroundImage: 'url(/images/background_pattern.webp)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        py: 3,
                    }}
                >
                    <img src={config.icon} alt={config.label} style={{ width: 80, height: 80 }} />
                </Box>
                <Typography variant="h6" sx={{ mt: 1, fontWeight: 'bold' }}>
                    {config.label}
                </Typography>
                <Box sx={{ display: 'flex', mt: 1, mb: 2 }}>
                    <Button
                        variant="outlined"
                        sx={{ m: 1 }}
                        onClick={() => onShowInfo(config.id, 'maxDimensions')}
                    >
                        {config.lockedDimensions ? 'Dimensions' : 'Max Dimensions'}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ m: 1 }}
                        onClick={() => onSelect(config.id)}
                    >
                        Select
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#fff',
                    transform: active ? 'translateX(0)' : 'translateX(100%)',
                    transition: 'transform 0.3s ease-in-out',
                    padding: 2,
                }}
            >
                <Typography variant="h6" sx={{ mt: 1 }}>
                    {config.lockedDimensions ? 'Dimensions' : 'Maximum Dimensions'}
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                    {config.lockedDimensions
                        ? `${config.lockedDimensions.length} x ${config.lockedDimensions.width} x ${config.lockedDimensions.height}`
                        : config.maxDimensions}
                </Typography>
                <Button variant="contained" onClick={onHideInfo} sx={{ mt: 2 }}>
                    Back
                </Button>
            </Box>


        </Paper>
    </Grid>
));

const StorableItemsSearch = ({ allConfigs, handleCancel, setItemType, handleNext }) => {
    // Internal state for filters and search
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [activeInfo, setActiveInfo] = useState({});

    // Compute unique categories and create a mapping: category => items
    const { categories, categorizedItems } = useMemo(() => {
        const catSet = new Set();
        const mapping = { All: [] };
        Object.values(allConfigs).forEach((config) => {
            mapping.All.push(config);
            catSet.add(config.category);
            if (!mapping[config.category]) {
                mapping[config.category] = [];
            }
            mapping[config.category].push(config);
        });
        return { categories: ['All', ...catSet], categorizedItems: mapping };
    }, [allConfigs]);

    // Compute filtered items based on selected category and search term
    const filteredItems = useMemo(() => {
        // If "All" is selected, use all items; otherwise, use items for that category.
        const baseItems =
            selectedCategory === 0
                ? categorizedItems.All
                : categorizedItems[categories[selectedCategory]] || [];
        // Apply search filter if searchTerm is provided
        let items = searchTerm
            ? baseItems.filter((config) =>
                config.label.toLowerCase().includes(searchTerm.toLowerCase())
            )
            : baseItems.slice();

        // If "All" or first category (after "All") is selected, prioritize summerstore_box
        if (selectedCategory === 0 || selectedCategory === 1) {
            const index = items.findIndex((config) => config.id === 'summerstore_box');
            if (index > -1) {
                const [summerstore] = items.splice(index, 1);
                items.unshift(summerstore);
            }
        }
        return items;
    }, [categorizedItems, selectedCategory, searchTerm, categories]);

    // Handlers for internal state
    const handleCategoryChange = (event, newValue) => {
        setSelectedCategory(newValue);
    };

    const handleDropdownChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleShowInfo = (id, infoType) => {
        setActiveInfo({ id, infoType });
    };

    const handleHideInfo = () => {
        setActiveInfo({});
    };

    const handleSelect = (id) => {
        setItemType(id);
        handleNext();
    };

    // Determine screen size
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
                Browse Items
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                }}
            >
                {(isSmallScreen || isMediumScreen || isLargeScreen) ? (
                    // Render a dropdown for categories on small screens
                    <Select
                        value={selectedCategory}
                        onChange={handleDropdownChange}
                        sx={{ minWidth: 150 }}
                    >
                        {categories.map((category, index) => (
                            <MenuItem key={category} value={index}>
                                {category}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    // Render tabs for larger screens
                    <Tabs value={selectedCategory} onChange={handleCategoryChange}>
                        {categories.map((category) => (
                            <Tab key={category} label={category} />
                        ))}
                    </Tabs>
                )}
                <Box>
                    <Button variant="outlined" onClick={handleCancel} sx={{ mr: 2 }}>
                        Cancel
                    </Button>
                </Box>
            </Box>
            <TextField
                variant="outlined"
                placeholder="Search for an item..."
                fullWidth
                margin="normal"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Grid container spacing={2}>
                {filteredItems.map((config) => (
                    <ItemCard
                        key={config.id}
                        config={config}
                        active={activeInfo.id === config.id}
                        onShowInfo={handleShowInfo}
                        onHideInfo={handleHideInfo}
                        onSelect={handleSelect}
                    />
                ))}
            </Grid>
        </Box>
    );
};

export default StorableItemsSearch;
