import React from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Menu,
    TextField,
    InputAdornment,
    Button,
    Divider,
    Checkbox,
    ListItemText
} from '@mui/material';
import {
    FilterAltOutlined,
    FilterAlt,
    Search,
    ArrowDropDown,
    ArrowDropUp,
    Edit,
    Delete
} from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';

const UserTableDetails = ({
    users,
    theme,
    filters,
    filterAnchors,
    filterSearch,
    sortOrder,
    handleFilterClick,
    handleFilterClose,
    handleFilterChange,
    handleFilterSearchChange,
    handleSelectAll,
    handleDeselectAll,
    handleSort,
    handleEditClick,
    handleDeleteFirebaseUser
}) => {
    return (
        <TableContainer 

            component="div"
        sx={{
            width: '100%',
            overflowX: 'scroll',
            WebkitOverflowScrolling: 'touch', // enables momentum scrolling on iOS
        }}>
            <Table size="small" >
                <TableHead>
                    <TableRow>
                        {['firstName', 'lastName', 'universityId', 'role', 'email', 'registrationDate', 'status', 'id'].map((column) => (
                            <TableCell
                                key={column}
                                sx={{
                                    pr: 0,
                                    pl: 2,
                                    fontSize: { xs: '0.7rem', md: '0.9rem' }
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        fontWeight="bold"
                                        sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}
                                    >
                                        {column.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => handleFilterClick(e, column)}
                                        sx={{ fontWeight: filters[column]?.length ? 'bold' : 'normal' }}
                                    >
                                        {filters[column]?.length ? (
                                            <FilterAlt fontSize="small" />
                                        ) : (
                                            <FilterAltOutlined sx={{ strokeWidth: 2 }} fontSize="small" />
                                        )}
                                    </IconButton>
                                    <Menu
                                        anchorEl={filterAnchors[column]}
                                        open={Boolean(filterAnchors[column])}
                                        onClose={() => handleFilterClose(column)}
                                    >
                                        <Box sx={{ p: 1 }}>
                                            <TextField
                                                variant="outlined"
                                                placeholder="Search..."
                                                fullWidth
                                                value={filterSearch}
                                                onChange={handleFilterSearchChange}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search fontSize="small" />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                size="small"
                                            />
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                <Button onClick={() => handleSelectAll(column)} size="small">
                                                    Select All
                                                </Button>
                                                <Button onClick={() => handleDeselectAll(column)} size="small">
                                                    Deselect All
                                                </Button>
                                            </Box>
                                            <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                                                {users
                                                    .map((row) => {
                                                        const value = row[column];
                                                        return (value || 'N/A').toString();
                                                    })
                                                    .filter((value, index, self) => self.indexOf(value) === index)
                                                    .filter((value) =>
                                                        value.toLowerCase().includes(filterSearch.toLowerCase())
                                                    )
                                                    .map((value) => (
                                                        <MenuItem key={value} onClick={() => handleFilterChange(column, value)}>
                                                            <Checkbox checked={filters[column]?.includes(value) || false} size="small" />
                                                            <ListItemText
                                                                primary={value}
                                                                primaryTypographyProps={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}
                                                            />
                                                        </MenuItem>
                                                    ))}
                                            </Box>
                                            <Divider />
                                            <Button onClick={() => handleSort(column)} size="small">
                                                {sortOrder[column] === 'asc' ? (
                                                    <ArrowDropUp fontSize="small" />
                                                ) : (
                                                    <ArrowDropDown fontSize="small" />
                                                )}
                                                Sort
                                            </Button>
                                        </Box>
                                    </Menu>
                                </Box>
                            </TableCell>
                        ))}
                        <TableCell sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user, index) =>
                        user.source === 'auth' ? (
                            <TableRow
                                key={user.id}
                                sx={{ backgroundColor: index % 2 === 0 ? theme.palette.action.hover : 'inherit' }}
                            >
                                <TableCell colSpan={3} sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                    {user.displayName}
                                </TableCell>
                                <TableCell colSpan={5} sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                    {user.email}
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleDeleteFirebaseUser(user.id)} size="small">
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow
                                key={user.id}
                                sx={{ backgroundColor: index % 2 === 0 ? theme.palette.action.hover : 'inherit' }}
                            >
                                <TableCell sx={{ py: 0, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                    {user.firstName || 'N/A'}
                                </TableCell>
                                <TableCell sx={{ py: 0, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                    {user.lastName || 'N/A'}
                                </TableCell>
                                <TableCell sx={{ py: 0, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                    {user.universityId || 'N/A'}
                                </TableCell>
                                <TableCell sx={{ py: 0, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                    {user.role || 'N/A'}
                                </TableCell>
                                <TableCell sx={{ py: 0, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                    {user.email || 'N/A'}
                                </TableCell>
                                <TableCell sx={{ py: 0, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                    {user.registrationDate || 'N/A'}
                                </TableCell>
                                <TableCell sx={{ py: 0, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                    {user.status || 'N/A'}
                                </TableCell>
                                <TableCell sx={{ py: 0, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                    {user.id || 'N/A'}
                                </TableCell>
                                <TableCell sx={{ py: 0 }}>
                                    <IconButton onClick={() => handleEditClick(user)} size="small">
                                        <Edit fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UserTableDetails;
