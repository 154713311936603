import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Paper,
    Tabs,
    Tab,
    IconButton,
    Switch,
    Typography,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Button,
    Divider,
    Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ChatIcon from '@mui/icons-material/Chat';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ListIcon from '@mui/icons-material/List';
import PaymentIcon from '@mui/icons-material/Payment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenter from '@mui/icons-material/BusinessCenter';
import { MailOutline, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import MessageFeed from '../../components/MessageFeed';
import MessageEditorAdmin from '../../components/MessageEditorAdmin';

const TicketDetailPage = () => {
    const { ticketID } = useParams();
    const navigate = useNavigate();

    // Local state for mode, tab selection, pane collapse and closed status.
    const [mode, setMode] = useState('ticket'); // 'ticket', 'user', 'faq'
    const [tabIndex, setTabIndex] = useState(0);
    const [isClosed, setIsClosed] = useState(false);
    const [isLeftPaneCollapsed, setIsLeftPaneCollapsed] = useState(false);
    // New state to control MessageFeed expansion
    const [isFeedExpanded, setIsFeedExpanded] = useState(false);

    // State for ticket document and customer document
    const [ticket, setTicket] = useState(null);
    const [customer, setCustomer] = useState(null);

    const db = getFirestore();

    // Fetch the ticket document based on the ticketID from URL params.
    useEffect(() => {
        const fetchTicket = async () => {
            try {
                const ticketRef = doc(db, 'tickets', ticketID);
                const ticketSnap = await getDoc(ticketRef);
                if (ticketSnap.exists()) {
                    setTicket({ id: ticketSnap.id, ...ticketSnap.data() });
                } else {
                    console.error('No such ticket!');
                }
            } catch (error) {
                console.error('Error fetching ticket:', error);
            }
        };
        fetchTicket();
    }, [db, ticketID]);

    // Once we have the ticket and its customerID, fetch the customer document.
    useEffect(() => {
        if (ticket && ticket.customerID) {
            const fetchCustomer = async () => {
                try {
                    const customerRef = doc(db, 'users', ticket.customerID);
                    const customerSnap = await getDoc(customerRef);
                    if (customerSnap.exists()) {
                        setCustomer({ id: customerSnap.id, ...customerSnap.data() });
                    } else {
                        console.error('No such customer!');
                    }
                } catch (error) {
                    console.error('Error fetching customer:', error);
                }
            };
            fetchCustomer();
        }
    }, [db, ticket]);

    // Define tab labels and icons based on the selected mode
    const getTabsForMode = () => {
        if (mode === 'ticket') {
            return [
                { label: 'Messages', icon: <ChatIcon fontSize="small" /> },
                { label: 'Notes', icon: <StickyNote2Icon fontSize="small" /> },
            ];
        }
        if (mode === 'user') {
            return [
                { label: 'Items', icon: <ListIcon fontSize="small" /> },
                { label: 'Payments', icon: <PaymentIcon fontSize="small" /> },
                { label: 'Appointments', icon: <EventNoteIcon fontSize="small" /> },
            ];
        }
        if (mode === 'faq') {
            return [
                { label: 'Account', icon: <AccountCircleIcon fontSize="small" /> },
                { label: 'Payments', icon: <PaymentIcon fontSize="small" /> },
                { label: 'Supplies', icon: <BusinessCenterIcon fontSize="small" /> },
                { label: 'Moving', icon: <LocalShippingIcon fontSize="small" /> },
                { label: 'Storage', icon: <BusinessCenter fontSize="small" /> },
                { label: 'Other', icon: <MoreHorizIcon fontSize="small" /> },
            ];
        }
        return [];
    };

    const tabs = getTabsForMode();

    const handleModeChange = (newMode) => {
        setMode(newMode);
        setTabIndex(0); // reset the tab index when changing modes
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Button
                onClick={() => navigate(-1)}
                variant="outlined"
                sx={{ mb: 2 }}
            >
                Back
            </Button>

            <Typography variant="h4" gutterBottom>
                Viewing Ticket
            </Typography>

            <Grid container spacing={2}>
                {/* Left Panel */}
                <Grid item xs={isLeftPaneCollapsed ? 1 : 4}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 1,
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{ color: 'text.secondary', textTransform: 'uppercase' }}
                            >
                                Options
                            </Typography>
                            <IconButton
                                onClick={() => setIsLeftPaneCollapsed(!isLeftPaneCollapsed)}
                                size="small"
                            >
                                {isLeftPaneCollapsed ? <ChevronRight /> : <ChevronLeft />}
                            </IconButton>
                        </Box>

                        {/* Mode Selector */}
                        <Paper>
                            <List sx={{ p: 0 }}>
                                {[
                                    { mode: 'ticket', label: 'Ticket', icon: <MailOutline /> },
                                    { mode: 'user', label: 'User', icon: <PersonIcon /> },
                                    { mode: 'faq', label: 'FAQ', icon: <HelpOutlineIcon /> },
                                ].map((item) => (
                                    <ListItem
                                        key={item.mode}
                                        disablePadding
                                        sx={{
                                            width: '100%',
                                            borderLeft: mode === item.mode ? '3px solid blue' : 'none',
                                            alignItems: "center"
                                        }}
                                    >
                                        <ListItemButton
                                            onClick={() => handleModeChange(item.mode)}
                                            selected={mode === item.mode}
                                            sx={{ width: '100%', p: isLeftPaneCollapsed ? 1 : 2 }}
                                        >
                                            {item.icon}
                                            <ListItemText primary={item.label} sx={{ ml: 1, mr: 1 }} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>

                        {/* Customer Information (hidden when collapsed) */}
                        {!isLeftPaneCollapsed && customer && (
                            <Box>
                                <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    sx={{ color: 'text.secondary', textTransform: 'uppercase', mt: 2 }}
                                >
                                    Customer Details
                                </Typography>
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant="body2">
                                        Name: {customer.firstName} {customer.lastName}
                                    </Typography>
                                    <Typography variant="body2">
                                        Max Items: {customer.storagePlan}
                                    </Typography>
                                    <Typography variant="body2">
                                        Email: {customer.email}
                                    </Typography>
                                </Paper>
                            </Box>
                        )}

                        <Divider sx={{ my: 2 }} />

                        {/* Additional controls */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isLeftPaneCollapsed ? 'column' : 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 1,
                            }}
                        >
                            <IconButton color="primary" aria-label="close ticket">
                                <CloseIcon />
                            </IconButton>
                            <IconButton color="primary" aria-label="assign ticket">
                                <AssignmentIndIcon />
                            </IconButton>
                            {!isLeftPaneCollapsed && (
                                <>
                                    <Typography variant="body2">Toggle Info</Typography>
                                    <Switch
                                        checked={isClosed}
                                        onChange={(e) => setIsClosed(e.target.checked)}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                </Grid>

                {/* Right Panel */}
                <Grid item xs={isLeftPaneCollapsed ? 11 : 8}>
                    <Box>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{ color: 'text.secondary', textTransform: 'uppercase' }}
                        >
                            Ticket Details
                        </Typography>

                        {/* Tabbed Content */}
                        <Paper sx={{ width: '100%' }}>
                            <Tabs
                                value={tabIndex}
                                onChange={handleTabChange}
                                textColor="primary"
                                indicatorColor="primary"
                                variant="scrollable"
                            >
                                {tabs.map((tab, index) => (
                                    <Tab
                                        key={index}
                                        label={
                                            <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                                                {tab.icon}
                                                <Typography sx={{ ml: 0.5 }}>{tab.label}</Typography>
                                            </Box>
                                        }
                                    />
                                ))}
                            </Tabs>
                        </Paper>

                        <Paper sx={{ mt: 2 }}>
                            {mode === 'ticket' && (
                                <>
                                    {tabIndex === 0 && (
                                        <Box>
                                            <MessageFeed ticketID={ticketID} expanded={isFeedExpanded} />
                                            <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
                                                <Chip
                                                    label={isFeedExpanded ? "Collapse Feed" : "Expand Feed"}
                                                    onClick={() => setIsFeedExpanded(prev => !prev)}
                                                    clickable
                                                />
                                            </Box>
                                            <MessageEditorAdmin ticketID={ticketID} />
                                        </Box>
                                    )}
                                    {tabIndex === 1 && (
                                        <Box>
                                            <Typography variant="subtitle1">Notes</Typography>
                                            <Typography variant="body2">[Notes content]</Typography>
                                        </Box>
                                    )}
                                </>
                            )}
                            {mode === 'user' && (
                                <>
                                    {tabIndex === 0 && (
                                        <Box>
                                            <Typography variant="subtitle1">Items</Typography>
                                            <Typography variant="body2">[Items content]</Typography>
                                        </Box>
                                    )}
                                    {tabIndex === 1 && (
                                        <Box>
                                            <Typography variant="subtitle1">Payments</Typography>
                                            <Typography variant="body2">[Payments content]</Typography>
                                        </Box>
                                    )}
                                    {tabIndex === 2 && (
                                        <Box>
                                            <Typography variant="subtitle1">Appointments</Typography>
                                            <Typography variant="body2">[Appointments content]</Typography>
                                        </Box>
                                    )}
                                </>
                            )}
                            {mode === 'faq' && (
                                <Box>
                                    <Typography variant="subtitle1">
                                        FAQ - {tabs[tabIndex].label}
                                    </Typography>
                                    <Typography variant="body2">
                                        [FAQ content for {tabs[tabIndex].label}]
                                    </Typography>
                                </Box>
                            )}
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TicketDetailPage;
