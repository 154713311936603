import React, { useState, useMemo, useEffect } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    InputAdornment,
    Button,
    Divider,
    Checkbox,
    ListItemText,
} from '@mui/material';
import {
    FilterAltOutlined,
    FilterAlt,
    Search,
    ArrowDropDown,
    ArrowDropUp,
    Edit,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
// Import Firebase Firestore methods and your configured db instance
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // adjust the import path as needed

const UserDetailFinancials = ({ users, handleEditClick }) => {
    const theme = useTheme();

    // Firebase products mapping state: { productCode: { title: '...' } }
    const [products, setProducts] = useState({});

    // Fetch products from Firestore on component mount
    useEffect(() => {
        async function fetchProducts() {
            try {
                const productsSnapshot = await getDocs(collection(db, 'products'));
                const productsMap = {};
                productsSnapshot.forEach((doc) => {
                    productsMap[doc.id] = doc.data();
                });
                setProducts(productsMap);
            } catch (error) {
                console.error('Error fetching products: ', error);
            }
        }
        fetchProducts();
    }, []);

    // Filtering and Sorting state
    const [filters, setFilters] = useState({});
    const [filterAnchors, setFilterAnchors] = useState({});
    const [filterSearch, setFilterSearch] = useState('');
    const [sortOrder, setSortOrder] = useState({});

    // Define columns and their labels.
    const columns = [
        { key: 'firstName', label: 'First Name' },
        { key: 'lastName', label: 'First Name' },
        { key: 'email', label: 'Email' },
        { key: 'selectedStoragePlan', label: 'Selected Storage Plan' },
        { key: 'paidStoragePlan', label: 'Paid Storage Plan' },
        { key: 'numberOfStorableItems', label: 'Number Of Storable Items' },
        { key: 'openInvoices', label: 'Open Invoices' },
        { key: 'paidInvoices', label: 'Paid Invoices' },
        { key: 'overdueInvoices', label: 'Overdue Invoices' },
        { key: 'totalPaid', label: 'Total Paid' },
        { key: 'totalRefunded', label: 'Total Refunded' },
    ];

    // Prepare rows from users data.
    // Note: user.selectedPlan and user.paidPlan are product codes.
    // If a matching product exists, its title is used; otherwise, a fallback is provided.
    const rows = users.map((user) => ({
        id: user.id,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email || 'N/A',
        university: user.universityId || 'N/A',
        selectedStoragePlan: user.storagePlan
            ? products[user.storagePlan]
                ? products[user.storagePlan].internalName
                : user.storagePlan
            : 'N/A',
        paidStoragePlan: user.paidPlan
            ? products[user.paidPlan]
                ? products[user.paidPlan].internalName
                : user.paidPlan
            : 'N/A',
        numberOfStorableItems: user.numberOfStorableItems || 'N/A',
        openInvoices: user.openInvoices || 'N/A',
        paidInvoices: user.paidInvoices || 'N/A',
        overdueInvoices: user.overdueInvoices || 'N/A',
        totalPaid: user.totalPaid || 'N/A',
        totalRefunded: user.totalRefunded || 'N/A',
    }));

    // Filtering: Only include rows matching all active filters.
    const filteredRows = rows.filter((row) =>
        Object.keys(filters).every((col) => {
            if (filters[col] && filters[col].length > 0) {
                return filters[col].includes(row[col]);
            }
            return true;
        })
    );

    // Sorting: Sort based on one active column (if any).
    const sortedRows = useMemo(() => {
        let sorted = [...filteredRows];
        const sortColumn = Object.keys(sortOrder).find((col) => sortOrder[col]);
        if (sortColumn) {
            const order = sortOrder[sortColumn];
            sorted.sort((a, b) => {
                if (a[sortColumn] < b[sortColumn]) return order === 'asc' ? -1 : 1;
                if (a[sortColumn] > b[sortColumn]) return order === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return sorted;
    }, [filteredRows, sortOrder]);

    // --- Filter Menu Handlers ---
    const handleFilterClick = (event, columnKey) => {
        setFilterAnchors((prev) => ({ ...prev, [columnKey]: event.currentTarget }));
    };

    const handleFilterClose = (columnKey) => {
        setFilterAnchors((prev) => ({ ...prev, [columnKey]: null }));
    };

    const handleFilterSearchChange = (event) => {
        setFilterSearch(event.target.value);
    };

    const handleFilterChange = (columnKey, value) => {
        setFilters((prev) => {
            const current = prev[columnKey] || [];
            let newValues;
            if (current.includes(value)) {
                newValues = current.filter((v) => v !== value);
            } else {
                newValues = [...current, value];
            }
            return { ...prev, [columnKey]: newValues };
        });
    };

    const handleSelectAll = (columnKey) => {
        const allValues = getUniqueColumnValues(columnKey);
        setFilters((prev) => ({ ...prev, [columnKey]: allValues }));
    };

    const handleDeselectAll = (columnKey) => {
        setFilters((prev) => ({ ...prev, [columnKey]: [] }));
    };

    const handleSort = (columnKey) => {
        setSortOrder((prev) => {
            const current = prev[columnKey];
            const newOrder = current === 'asc' ? 'desc' : 'asc';
            return { [columnKey]: newOrder };
        });
    };

    // Get unique values for a column
    const getUniqueColumnValues = (columnKey) => {
        const valuesSet = new Set();
        rows.forEach((row) => {
            valuesSet.add(row[columnKey] || 'N/A');
        });
        return Array.from(valuesSet);
    };

    return (
        <TableContainer component="div" sx={{ width: '100%', overflowX: 'scroll', WebkitOverflowScrolling: 'touch' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.key} sx={{ pr: 0, pl: 2, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography fontWeight="bold" sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                        {column.label}
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => handleFilterClick(e, column.key)}
                                        sx={{ fontWeight: filters[column.key]?.length ? 'bold' : 'normal' }}
                                    >
                                        {filters[column.key]?.length ? (
                                            <FilterAlt fontSize="small" />
                                        ) : (
                                            <FilterAltOutlined sx={{ strokeWidth: 2 }} fontSize="small" />
                                        )}
                                    </IconButton>
                                    <Menu
                                        anchorEl={filterAnchors[column.key]}
                                        open={Boolean(filterAnchors[column.key])}
                                        onClose={() => handleFilterClose(column.key)}
                                    >
                                        <Box sx={{ p: 1 }}>
                                            <TextField
                                                variant="outlined"
                                                placeholder="Search..."
                                                fullWidth
                                                value={filterSearch}
                                                onChange={handleFilterSearchChange}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search fontSize="small" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                size="small"
                                            />
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                <Button onClick={() => handleSelectAll(column.key)} size="small">
                                                    Select All
                                                </Button>
                                                <Button onClick={() => handleDeselectAll(column.key)} size="small">
                                                    Deselect All
                                                </Button>
                                            </Box>
                                            <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                                                {getUniqueColumnValues(column.key)
                                                    .filter((value) => value.toLowerCase().includes(filterSearch.toLowerCase()))
                                                    .map((value) => (
                                                        <MenuItem key={value} onClick={() => handleFilterChange(column.key, value)}>
                                                            <Checkbox checked={filters[column.key]?.includes(value) || false} size="small" />
                                                            <ListItemText
                                                                primary={value}
                                                                primaryTypographyProps={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}
                                                            />
                                                        </MenuItem>
                                                    ))}
                                            </Box>
                                            <Divider />
                                            <Button onClick={() => handleSort(column.key)} size="small">
                                                {sortOrder[column.key] === 'asc' ? (
                                                    <ArrowDropUp fontSize="small" />
                                                ) : (
                                                    <ArrowDropDown fontSize="small" />
                                                )}
                                                Sort
                                            </Button>
                                        </Box>
                                    </Menu>
                                </Box>
                            </TableCell>
                        ))}
                        {/* Actions Column */}
                        <TableCell sx={{ pr: 0, pl: 2, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                            <Typography fontWeight="bold" sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                Actions
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedRows.map((row, index) => (
                        <TableRow
                            key={row.id}
                            sx={{
                                backgroundColor: index % 2 === 0 ? theme.palette.action.hover : 'inherit',
                            }}
                        >
                            {columns.map((column) => (
                                <TableCell key={column.key} sx={{ py: 0, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                    {row[column.key]}
                                </TableCell>
                            ))}
                            {/* Only the Edit button is provided */}
                            <TableCell sx={{ py: 0 }}>
                                <IconButton onClick={() => handleEditClick(row)} size="small">
                                    <Edit fontSize="small" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UserDetailFinancials;
