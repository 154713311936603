import React, { useEffect, useState } from 'react';
import { doc, onSnapshot, collection, query, where } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Skeleton,
} from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { IconPickerItem } from 'react-icons-picker';
import { db } from '../firebase';

const NotificationsCarousel = ({ loading }) => {
  const [notifications, setNotifications] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openLearnMore, setOpenLearnMore] = useState(false);

  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  useEffect(() => {
    if (!userId) return;

    // Listen to changes on the user document
    const userRef = doc(db, 'users', userId);
    const unsubscribeUser = onSnapshot(userRef, (userSnap) => {
      if (!userSnap.exists()) {
        console.log('No user data found');
        setNotifications([]);
        return;
      }
      const userData = userSnap.data();
      const shownNotifications = userData.notifications || [];

      // If there are no notifications, clear out state
      if (shownNotifications.length === 0) {
        setNotifications([]);
        return;
      }

      // Map to extract the notification document IDs
      const notificationIds = shownNotifications.map((notif) => notif.messageId);

      // Create a query on the notifications collection with a "where in" filter
      const notificationsQuery = query(
        collection(db, 'notifications'),
        where('__name__', 'in', notificationIds)
      );

      // Listen to the notifications matching the query in real-time
      const unsubscribeNotifications = onSnapshot(notificationsQuery, (querySnapshot) => {
        const notifs = [];
        querySnapshot.forEach((doc) => {
          notifs.push({ id: doc.id, ...doc.data() });
        });
        // Optionally, sort notifications in the order of the shownNotifications array
        notifs.sort((a, b) =>
          notificationIds.indexOf(a.id) - notificationIds.indexOf(b.id)
        );
        setNotifications(notifs);
      });

      // Clean up the notifications listener when the user doc changes
      return () => {
        unsubscribeNotifications();
      };
    });

    return () => {
      unsubscribeUser();
    };
  }, [userId]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? notifications.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === notifications.length - 1 ? 0 : prevIndex + 1
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 3,
          borderRadius: 2,
          border: '1px solid lightgray',
          backgroundColor: '#f5f5f5',
        }}
      >
        <Skeleton variant="circular" width={60} height={60} />
        <Skeleton variant="text" width="60%" height={30} sx={{ mt: 2 }} />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="40%" />
      </Box>
    );
  }

  const currentNotification = notifications[currentIndex];

  if (!currentNotification) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          border: '1px solid darkgray',
          borderRadius: 2,
          p: 2,
        }}
      >
        <Box sx={{ mt: 3, mb: 3 }}>
          <NotificationsOffIcon sx={{ fontSize: 60, color: 'lightgray' }} />
          <Typography variant="h6" color="gray" sx={{ mt: 1 }}>
            No Notifications
          </Typography>
          <Typography variant="body2" color="gray">
            You're all caught up!
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          p: 3,
          borderRadius: 2,
          border: `3px solid ${currentNotification.webPrimaryColor || '#000'}`,
          backgroundColor: currentNotification.webBackgroundColor || '#f5f5f5',
          color: currentNotification.webTextColor || '#000',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <IconPickerItem
          value={currentNotification.webIcon || 'FaUsers'}
          size={60}
          color={currentNotification.webPrimaryColor || '#000'}
        />
        <Typography variant="h6" sx={{ mt: 2 }}>
          {currentNotification.webTitle}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {currentNotification.webText}
        </Typography>

        {currentNotification.showLearnMore && (
          <Button variant="outlined" sx={{ mt: 2 }} onClick={() => setOpenLearnMore(true)}>
            Learn More
          </Button>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
          <IconButton onClick={handlePrevious}>
            <ArrowBack />
          </IconButton>
          <Typography variant="caption" sx={{ mx: 2 }}>
            {currentIndex + 1} of {notifications.length}
          </Typography>
          <IconButton onClick={handleNext}>
            <ArrowForward />
          </IconButton>
        </Box>
      </Box>

      <Dialog open={openLearnMore} onClose={() => setOpenLearnMore(false)}>
        <DialogTitle>{currentNotification.webTitle}</DialogTitle>
        <DialogContent>
          <Typography>
            {currentNotification.learnMoreLink || 'No additional information available.'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLearnMore(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationsCarousel;
