import React from 'react';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    CircularProgress,
    Chip,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// Helper to determine chip color for invoice status
function getStatusColor(status) {
    switch (status.toLowerCase()) {
        case 'paid':
            return 'success';
        case 'overdue':
            return 'error';
        case 'pending':
            return 'default';
        case 'on hold':
            return 'info';
        case 'due soon':
            return 'warning';
        case 'cancelled':
            return 'default';
        default:
            return 'default';
    }
}

const UserDetailInvoices = ({ invoices, invoicesLoading }) => {
    const navigate = useNavigate();

    if (invoicesLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                <CircularProgress />
            </Box>
        );
    }

    const duePayments = invoices.filter(inv => {
        const status = inv.status.toLowerCase();
        return status !== 'paid' && status !== 'cancelled';
    });

    const pastPayments = invoices.filter(inv => {
        const status = inv.status.toLowerCase();
        return status === 'paid' || status === 'cancelled';
    });

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Invoices
            </Typography>

            {/* Due Payments Section */}
            <Box sx={{ mt: 2 }}>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ color: 'text.secondary', textTransform: 'uppercase', mb: 2 }}
                >
                    Due Payments
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Due Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {duePayments.map(invoice => (
                                <TableRow key={invoice.id}>
                                    <TableCell>{invoice.title}</TableCell>
                                    <TableCell>${invoice.amount.toFixed(2)}</TableCell>
                                    <TableCell>{invoice.date}</TableCell>
                                    <TableCell>
                                        <Chip label={invoice.status} color={getStatusColor(invoice.status)} size="small" />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={<Visibility />}
                                            onClick={() => navigate(`invoice/details/${invoice.id}`)}
                                        >
                                            View Details
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Past Payments Section */}
            <Box sx={{ mt: 4 }}>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ color: 'text.secondary', textTransform: 'uppercase', mb: 2 }}
                >
                    Past Payments
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pastPayments.map(invoice => (
                                <TableRow key={invoice.id}>
                                    <TableCell>{invoice.title}</TableCell>
                                    <TableCell>${invoice.amount.toFixed(2)}</TableCell>
                                    <TableCell>
                                        <Chip label={invoice.status} color={getStatusColor(invoice.status)} size="small" />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={<Visibility />}
                                            onClick={() => navigate(`invoice/details/${invoice.id}`)}
                                        >
                                            View Details
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default UserDetailInvoices;
