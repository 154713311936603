import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Paper,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Divider,
    IconButton,
    Collapse,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import {
    Visibility as PageViewIcon,
    TimerOff as TimedOutIcon,
    TouchApp as ButtonClickIcon,
    ExitToApp as LogoutIcon,
    Login as LoginIcon,
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { format } from 'date-fns';

// -----------------------------------------------------------------------------
//  Helper function: pick an icon for each activity type
// -----------------------------------------------------------------------------
const getActivityIcon = (activityType) => {
    switch (activityType) {
        case 'page_view':
            return <PageViewIcon />;
        case 'timed_out':
            return <TimedOutIcon />;
        case 'button_click':
            return <ButtonClickIcon />;
        case 'login':
            return <LoginIcon />;
        case 'logout':
            return <LogoutIcon />;
        default:
            return <Avatar>{activityType?.charAt(0)?.toUpperCase() || 'A'}</Avatar>;
    }
};

// -----------------------------------------------------------------------------
//  Helper function: group array items by a key
// -----------------------------------------------------------------------------
const groupBy = (array, keyGetter) => {
    const map = new Map();
    array.forEach((item) => {
        const key = keyGetter(item) || 'Unknown';
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
};

// -----------------------------------------------------------------------------
//  Main component
// -----------------------------------------------------------------------------
const UserDetailActivity = ({ userId }) => {
    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedActivity, setExpandedActivity] = useState(null); // tracks expanded individual activity
    const [expandedSessions, setExpandedSessions] = useState({}); // tracks expanded session groups

    // New dropdown state for sorting and filtering:
    const [sortOption, setSortOption] = useState("session"); // "session" or "activityType"
    const [filterActivityType, setFilterActivityType] = useState("all");

    // ---------------------------------------------------------------------------
    //  Fetch activity data
    // ---------------------------------------------------------------------------
    useEffect(() => {
        if (!userId) {
            setLoading(false);
            return;
        }
        const fetchActivity = async () => {
            try {
                const db = getFirestore();
                const historyRef = collection(db, 'analytics', 'users', 'activityData', userId, 'history');
                const snapshot = await getDocs(historyRef);

                const data = snapshot.docs.map((doc) => {
                    const docData = doc.data();
                    let parsedTimestamp = null;
                    if (docData.timestamp) {
                        parsedTimestamp = new Date(docData.timestamp);
                    }
                    return {
                        id: doc.id,
                        ...docData,
                        parsedTimestamp,
                    };
                });

                // Sort by descending timestamp (most recent first)
                data.sort((a, b) => (b.parsedTimestamp - a.parsedTimestamp));
                setActivityData(data);
            } catch (error) {
                console.error('Error fetching activity data:', error);
            }
            setLoading(false);
        };

        fetchActivity();
    }, [userId]);

    // ---------------------------------------------------------------------------
    //  Handlers for individual and session expansion
    // ---------------------------------------------------------------------------
    const toggleActivityExpansion = (activityId) => {
        setExpandedActivity((prev) => (prev === activityId ? null : activityId));
    };

    const toggleSessionExpansion = (sessionId) => {
        setExpandedSessions((prev) => ({
            ...prev,
            [sessionId]: !prev[sessionId],
        }));
    };

    // ---------------------------------------------------------------------------
    //  Handlers for dropdown changes
    // ---------------------------------------------------------------------------
    const handleSortChange = (event) => {
        setSortOption(event.target.value);
    };

    const handleFilterChange = (event) => {
        setFilterActivityType(event.target.value);
    };

    // ---------------------------------------------------------------------------
    //  Filter and group the data
    // ---------------------------------------------------------------------------
    const activityTypes = Array.from(
        new Set(activityData.map((activity) => activity.activityType).filter(Boolean))
    );

    const filteredData =
        filterActivityType === "all"
            ? activityData
            : activityData.filter((activity) => activity.activityType === filterActivityType);

    let groupedData = new Map();
    if (sortOption === "session") {
        groupedData = groupBy(filteredData, (item) => item.sessionId);
    } else if (sortOption === "activityType") {
        groupedData = groupBy(filteredData, (item) => item.activityType);
    }

    // ---------------------------------------------------------------------------
    //  Render Loading / Empty States
    // ---------------------------------------------------------------------------
    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (activityData.length === 0) {
        return (
            <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography>No activity found for this user.</Typography>
            </Box>
        );
    }

    // ---------------------------------------------------------------------------
    //  Main Render
    // ---------------------------------------------------------------------------
    return (
        <Paper sx={{ p: 3 }}>
            {/* Dropdown menus */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <FormControl variant="outlined" size="small">
                    <InputLabel>Sort By</InputLabel>
                    <Select label="Sort By" value={sortOption} onChange={handleSortChange}>
                        <MenuItem value="session">Session</MenuItem>
                        <MenuItem value="activityType">Activity Type</MenuItem>
                    </Select>
                </FormControl>

                <FormControl variant="outlined" size="small">
                    <InputLabel>Filter by Activity Type</InputLabel>
                    <Select
                        label="Filter by Activity Type"
                        value={filterActivityType}
                        onChange={handleFilterChange}
                    >
                        <MenuItem value="all">All</MenuItem>
                        {activityTypes.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {/* Render grouped activity */}
            {sortOption === 'session' ? (
                // Render sessions as collapsible menus
                [...groupedData.entries()].map(([sessionId, activities]) => {
                    // Compute session start and end times from the group's timestamps
                    const timestamps = activities
                        .map((a) => a.parsedTimestamp)
                        .filter(Boolean);
                    const startTime = timestamps.length > 0 ? new Date(Math.min(...timestamps)) : null;
                    const endTime = timestamps.length > 0 ? new Date(Math.max(...timestamps)) : null;

                    return (
                        <Box key={sessionId} sx={{ mb: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                            <ListItem button onClick={() => toggleSessionExpansion(sessionId)}>
                                <ListItemText
                                    primary={`Session: ${sessionId || 'Unknown'}`}
                                    secondary={
                                        startTime && endTime
                                            ? `Start: ${format(startTime, 'yyyy-MM-dd HH:mm:ss')} | End: ${format(
                                                endTime,
                                                'yyyy-MM-dd HH:mm:ss'
                                            )}`
                                            : 'No timestamp available'
                                    }
                                />
                                <IconButton>
                                    {expandedSessions[sessionId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </ListItem>
                            <Collapse in={expandedSessions[sessionId]} timeout="auto" unmountOnExit>
                                <List sx={{ bgcolor: 'background.paper' }}>
                                    {activities.map((activity) => {
                                        const { id, activityType, description, path, timestamp, parsedTimestamp } = activity;
                                        const primaryText = description || activityType || '—';
                                        const secondaryText = parsedTimestamp
                                            ? format(parsedTimestamp, 'yyyy-MM-dd HH:mm:ss')
                                            : 'No timestamp';

                                        return (
                                            <React.Fragment key={id}>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar>
                                                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                                                            {getActivityIcon(activityType)}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={primaryText} secondary={secondaryText} />
                                                    <Tooltip title={expandedActivity === id ? 'Hide Details' : 'Show Details'}>
                                                        <IconButton onClick={() => toggleActivityExpansion(id)}>
                                                            {expandedActivity === id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItem>
                                                <Collapse in={expandedActivity === id} timeout="auto" unmountOnExit>
                                                    <TableContainer sx={{ my: 1 }}>
                                                        <Table size="small">
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
                                                                    <TableCell>{activityType || '—'}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                                                                    <TableCell>{description || '—'}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: 'bold' }}>Path</TableCell>
                                                                    <TableCell>{path || '—'}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: 'bold' }}>Session</TableCell>
                                                                    <TableCell>{sessionId || '—'}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: 'bold' }}>Timestamp</TableCell>
                                                                    <TableCell>{timestamp || '—'}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Collapse>
                                                <Divider variant="inset" component="li" />
                                            </React.Fragment>
                                        );
                                    })}
                                </List>
                            </Collapse>
                        </Box>
                    );
                })
            ) : (
                // Render grouping by activity type (as before)
                [...groupedData.entries()].map(([groupKey, activities]) => (
                    <Box key={groupKey} sx={{ mb: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                            Activity Type: {groupKey}
                        </Typography>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {activities.map((activity) => {
                                const { id, activityType, description, path, sessionId, timestamp, parsedTimestamp } = activity;
                                const primaryText = description || activityType || '—';
                                const secondaryText = parsedTimestamp
                                    ? format(parsedTimestamp, 'yyyy-MM-dd HH:mm:ss')
                                    : 'No timestamp';

                                return (
                                    <React.Fragment key={id}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar sx={{ bgcolor: 'primary.main' }}>
                                                    {getActivityIcon(activityType)}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={primaryText} secondary={secondaryText} />
                                            <Tooltip title={expandedActivity === id ? 'Hide Details' : 'Show Details'}>
                                                <IconButton onClick={() => toggleActivityExpansion(id)}>
                                                    {expandedActivity === id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </Tooltip>
                                        </ListItem>
                                        <Collapse in={expandedActivity === id} timeout="auto" unmountOnExit>
                                            <TableContainer sx={{ my: 1 }}>
                                                <Table size="small">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
                                                            <TableCell>{activityType || '—'}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                                                            <TableCell>{description || '—'}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }}>Path</TableCell>
                                                            <TableCell>{path || '—'}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }}>Session</TableCell>
                                                            <TableCell>{sessionId || '—'}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }}>Timestamp</TableCell>
                                                            <TableCell>{timestamp || '—'}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Collapse>
                                        <Divider variant="inset" component="li" />
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    </Box>
                ))
            )}
        </Paper>
    );
};

export default UserDetailActivity;
 