import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Skeleton,
  Fade,
  CircularProgress,
  Link,
} from '@mui/material';
import { ReceiptLong, CreditCardOff, ChangeCircle, Check } from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PriceComparison from '../../components/PriceComparison';
import { useNotification } from '../../contexts/NotificationContext';

const plansVisual = {
  '0001': {
    title: '10 Items',
    subtitle: 'Boxes, Furniture, or Electronics',
    price: '$250',
    registrationFee: '',
    perItemPrice: '$20',
    image: '/images/Plan1-01.webp',
    features: [
      '4 months of storage for up to 10 items',
      'Need more? Add items for $30 each.',
      'Boxes & Tape',
      '$100 insurance per item',
      'Any sized items. Terms apply.',
      'Student movers for $35',
    ],
  },
  '0002': {
    title: '20 Items',
    subtitle: 'Boxes, Furniture, or Electronics',
    price: '$450',
    registrationFee: '',
    perItemPrice: '$20',
    image: '/images/Plan2-01.webp',
    features: [
      '4 months of storage for up to 20 items',
      'Need more? Add items for $30 each.',
      'Boxes & Tape',
      '$100 insurance per item',
      'Any sized items. Terms apply.',
      'Student movers for $35',
    ],
  },
  '0003': {
    title: '30 Items',
    subtitle: 'Boxes, Furniture, or Electronics',
    price: '$650',
    registrationFee: '',
    perItemPrice: '$20',
    image: '/images/Plan3-01.webp',
    features: [
      '4 months of storage for up to 30 items',
      'Need more? Add items for $30 each.',
      'Boxes & Tape',
      '$100 insurance per item',
      'Any sized items. Terms apply.',
      'Student movers for $35',
    ],
  },
  '0004': {
    title: 'Per Item',
    subtitle: 'Pay for each item individually.',
    price: '$30', // Displayed price when base plan is paid
    registrationFee: '+$50 Registration Fee',
    image: '/images/Plan4-01.webp',
    features: [
      '4 months of storage per item',
      'Boxes & Tape',
      'Optional item insurance',
      'Any sized items. Terms apply.',
      'Student movers for $35',
    ],
  },
};

const StoragePlanPrivate = () => {
  const { currentUser } = useAuth();
  const db = getFirestore();
  const { addNotification } = useNotification();

  // Tab / Plan / Loading States
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  // Confirmation Dialog & Step States
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [planToConfirm, setPlanToConfirm] = useState(null);
  const [confirmStep, setConfirmStep] = useState(0);
  const [isUpgrade, setIsUpgrade] = useState(false);
  // New state: whether this is an "Add Item" action
  const [isAddItem, setIsAddItem] = useState(false);

  const [openAddItemsDialog, setOpenAddItemsDialog] = useState(false);
  const [newAdditionalItems, setNewAdditionalItems] = useState(0);
  const [paidAdditionalItems, setPaidAdditionalItems] = useState(0);
  const [additionalItems, setAdditionalItems] = useState(0);
  const [isConfirmingAddItems, setIsConfirmingAddItems] = useState(false);

  // New state for Cancel Confirmation Dialog
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [planToCancel, setPlanToCancel] = useState(null);

  // New state: number of items for plan 0004
  const [itemCount, setItemCount] = useState(1);

  // New state: product code of the paid base plan (empty string if not paid)
  const [paidBasePlan, setPaidBasePlan] = useState('');

  // New state: store fetched plan change products (upgrade options)
  const [planChangeProducts, setPlanChangeProducts] = useState([]);

  // Deadline States
  const [isEarlyValid, setIsEarlyValid] = useState(true);
  const [activeDeadline, setActiveDeadline] = useState('');

  // ------------------- Fetch Plans -------------------
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setLoading(true);
        const productIds = ['0001', '0002', '0003', '0004'];
        const productsList = [];
        for (const productId of productIds) {
          const productDoc = await getDoc(doc(db, 'products', productId));
          if (productDoc.exists()) {
            productsList.push({ id: productId, ...productDoc.data() });
          }
        }
        setPlans(productsList);
      } catch (error) {
        console.error('Error fetching plans:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPlans();
  }, [db]);

  // ------------------- Fetch Plan Change Products via Query -------------------
  useEffect(() => {
    const fetchPlanChanges = async () => {
      try {
        const planChangeQuery = query(
          collection(db, 'products'),
          where('productType', '==', 'Plan Change')
        );
        const querySnapshot = await getDocs(planChangeQuery);
        const changes = [];
        querySnapshot.forEach((docSnap) => {
          // Push an object containing the document ID as code along with the rest of the data
          changes.push({ code: docSnap.id, ...docSnap.data() });
        });
        setPlanChangeProducts(changes);
        console.log("Plan changes: ", changes);
      } catch (error) {
        console.error('Error fetching plan changes:', error);
      }
    };
    fetchPlanChanges();
  }, [db]);

  // ------------------- Fetch User & University Deadlines -------------------
  useEffect(() => {
    const fetchUserAndDeadline = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setSelectedPlan(userData.storagePlan || '');
          // Set paidBasePlan from user data (empty string if not paid)
          setPaidBasePlan(userData.paidBasePlan || '');

          setPaidAdditionalItems(userData.paidAdditionalItems || 0);
          setAdditionalItems(userData.additionalItems || 0);
          setNewAdditionalItems(userData.additionalItems || 0);

          if (userData.universityId) {
            const uniRef = doc(db, 'universities', userData.universityId);
            const uniSnap = await getDoc(uniRef);
            if (uniSnap.exists()) {
              const { earlyRegistrationDeadline, lateRegistrationDeadline } = uniSnap.data();
              const now = new Date();
              const earlyDate = earlyRegistrationDeadline?.toDate?.();
              const lateDate = lateRegistrationDeadline?.toDate?.();
              if (earlyDate && earlyDate > now) {
                setIsEarlyValid(true);
                setActiveDeadline(
                  earlyDate.toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })
                );
              } else if (lateDate) {
                setIsEarlyValid(false);
                setActiveDeadline(
                  lateDate.toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })
                );
              } else {
                setActiveDeadline('');
                setIsEarlyValid(false);
              }
            }
          } else {
            console.log('No "university" field on the user doc.');
          }
        }
      } catch (error) {
        console.error('Error fetching selected plan or deadlines:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserAndDeadline();
  }, [currentUser, db]);

  // ------------------- Compute Upgrade Cost -------------------
  const computedUpgradeCost =
    selectedPlan && planToConfirm
      ? (
        parseFloat(plansVisual[planToConfirm].price.replace('$', '')) -
        parseFloat(plansVisual[selectedPlan].price.replace('$', ''))
      ).toFixed(2)
      : null;

  // ------------------- Handle Tab Change -------------------
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  // ------------------- Open Confirmation Dialog -------------------
  const openConfirmationDialog = (planId, upgrade = false) => {
    if (!currentUser) {
      return addNotification({
        status: 'error',
        message: 'You must be signed in to set a storage plan.',
      });
    }
    setPlanToConfirm(planId);
    setIsUpgrade(upgrade);
    // For the per-item plan, if a base plan is paid, set Add Item mode.
    if (planId === '0004' && paidBasePlan) {
      setIsAddItem(true);
      setConfirmStep(0); // Two-step flow: Item Count, Plan Summary
    } else {
      setIsAddItem(false);
      setConfirmStep(0);
    }
    setConfirmDialogOpen(true);
    if (planId === '0004') {
      setItemCount(1);
    }
  };

  // ------------------- Cancel Dialog -------------------
  const handleCancelConfirmation = () => {
    setConfirmDialogOpen(false);
  };

  // ------------------- Confirm & Cancel Plan (Final Step) -------------------
  const handleConfirmPlan = async () => {
    if (!planToConfirm || !currentUser) return;
    setConfirmDialogOpen(false);
    setLoading(true);
    try {
      const functions = getFunctions();
      if (isAddItem) {
        const addExtraItemsFn = httpsCallable(functions, 'addExtraItems');
        await addExtraItemsFn({
          uid: currentUser.uid,
          itemCount,
        });
        addNotification({
          status: 'success',
          message: 'Additional items added successfully!',
        });
      } else if (isUpgrade) {
        // Look up the upgrade product from your fetched planChangeProducts.
        const upgradeProduct = planChangeProducts.find(
          (pc) => pc.planFrom === (paidBasePlan || selectedPlan) && pc.planTo === planToConfirm
        );

        // If the upgrade product isn't found, notify the user or handle the error.
        if (!upgradeProduct || !upgradeProduct.code) {
          addNotification({
            status: 'error',
            message: 'Upgrade product not found. Please contact support.',
          });
          setLoading(false);
          return;
        }

        const upgradeStoragePlanFn = httpsCallable(functions, 'upgradeStoragePlan');

        console.log("Upgrade product: ", upgradeProduct.code);

        await upgradeStoragePlanFn({
          storagePlan: planToConfirm,
          planChangeProductCode: upgradeProduct.code,
        });

        setSelectedPlan(planToConfirm);
        addNotification({
          status: 'success',
          message: 'Storage plan upgraded successfully!',
        });
      }
     else {
        const setStoragePlanFn = httpsCallable(functions, 'setStoragePlan');
        await setStoragePlanFn({
          uid: currentUser.uid,
          storagePlan: planToConfirm,
          ...(planToConfirm === '0004' && { itemCount }),
        });
        setSelectedPlan(planToConfirm);
        addNotification({
          status: 'success',
          message: 'Storage plan updated successfully!',
        });
      }
    } catch (error) {
      console.error('Error processing your request:', error);
      addNotification({
        status: 'error',
        message: error.message || 'An error occurred while processing your request.',
      });
    } finally {
      setLoading(false);
      setPlanToConfirm(null);
      setIsUpgrade(false);
      setIsAddItem(false);
    }
  };

  // Handlers to open/close the dialog:
  const handleOpenAddItemsDialog = () => setOpenAddItemsDialog(true);
  const handleCloseAddItemsDialog = () => setOpenAddItemsDialog(false);

  // Handlers for plus and minus buttons:
  const handleDecrease = () => {
    setNewAdditionalItems(prev => Math.max(paidAdditionalItems, prev - 1));
  };

  const handleIncrease = () => {
    setNewAdditionalItems(prev => prev + 1);
  };

  // Handler for confirming the update:
  const handleConfirmAddItems = async () => {
    // Calculate the delta relative to the current additionalItems value.
    const delta = newAdditionalItems - (additionalItems || 0);
    setIsConfirmingAddItems(true);
    try {
      const functions = getFunctions();
      const addExtraItemsFn = httpsCallable(functions, "addExtraItems");
      const result = await addExtraItemsFn({ delta });
      addNotification({
        status: "success",
        message: result.data.message || "Additional items updated successfully!",
      });
      // Update local state with new value.
      setAdditionalItems(result.data.additionalItems);
      handleCloseAddItemsDialog();
    } catch (error) {
      console.error("Error updating additional items:", error);
      addNotification({
        status: "error",
        message: error.message || "Error updating additional items.",
      });
    } finally {
      setIsConfirmingAddItems(false);
    }
  };

  // Open the cancel confirmation dialog
  const openCancelDialog = (planId) => {
    setPlanToCancel(planId);
    setCancelDialogOpen(true);
  };

  // Close the cancel confirmation dialog without cancelling
  const handleCancelCancelPlan = () => {
    setCancelDialogOpen(false);
    setPlanToCancel(null);
  };

  // When user confirms cancel in the dialog, run the cloud function
  const handleConfirmCancelPlan = async () => {
    setCancelDialogOpen(false);
    setLoading(true);
    try {
      const functions = getFunctions();
      const cancelPlanFn = httpsCallable(functions, 'cancelSelectedPlan');
      await cancelPlanFn({ uid: currentUser.uid, planId: planToCancel });
      addNotification({
        status: 'success',
        message: 'Your storage plan has been cancelled and any open invoices have been removed.',
      });
      // Clear selected and paid plan states.
      setSelectedPlan(paidBasePlan ? paidBasePlan : "");
    } catch (error) {
      console.error('Error cancelling storage plan:', error);
      addNotification({
        status: 'error',
        message: error.message || 'An error occurred while cancelling your storage plan.',
      });
    } finally {
      setLoading(false);
      setPlanToCancel(null);
    }
  };



  // ------------------- Next / Back Step -------------------
  const handleNextStep = () => {
    setConfirmStep((prev) => prev + 1);
  };

  const handleBackStep = () => {
    setConfirmStep((prev) => prev - 1);
  };

  // ------------------- Compute Confirmation Steps -------------------
  const currentSteps = isUpgrade
    ? ['Upgrade Summary']
    : isAddItem
      ? ['Item Count', 'Plan Summary']
      : planToConfirm === '0004'
        ? ['Additional Info', 'Item Count', 'Plan Summary']
        : ['Additional Info', 'Plan Summary'];

  // ------------------- Helper: Check for Upgrade Option -------------------
  // Uses the paidBasePlan if available; otherwise falls back to selectedPlan.
  const upgradeProductExists = (plan) => {
    const fromPlan = paidBasePlan || selectedPlan;
    if (fromPlan && fromPlan !== plan.id) {
      return planChangeProducts.some(
        (pc) => pc.planFrom === fromPlan && pc.planTo === plan.id
      );
    }
    return false;
  };

  // ------------------- Determine Button Text & Disabled State -------------------
  const getButtonProps = (plan) => {
    const isPerItem = plan.id === '0004';

    // 1. If this plan is currently selected, always show "Selected" (or "Paid & Selected" if it is the paid plan)
    if (selectedPlan === plan.id) {
      if (plan.id === paidBasePlan) {
        return { buttonText: 'Paid & Selected', buttonDisabled: true };
      }
      return { buttonText: 'Awaiting Payment', buttonDisabled: true };
    }
    // 3. If an upgrade option exists (using paidBasePlan if available)
    if (upgradeProductExists(plan)) {
      return { buttonText: 'Upgrade Plan', buttonDisabled: false };
    }
    // 4. If a base plan is paid and this plan is not selected, then lock it
    if (paidBasePlan && selectedPlan !== plan.id && paidBasePlan === plan.id) {
      return { buttonText: 'Paid', buttonDisabled: true };
    }

    if (paidBasePlan) {
      return { buttonText: 'Downgrade Unavailable', buttonDisabled: true };
    }

    // 5. Otherwise (no base plan paid yet), default to "Get Started"
    return { buttonText: 'Get Started', buttonDisabled: false };
  };

  return (
    <Box sx={{ p: 2, height: '100%' }}>
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ flex: { xs: '100%', md: '50%' }, textAlign: { xs: 'center', md: 'left' }, mb: { xs: 2, md: 0 } }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Storage Plans
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Choose a plan that suits your storage needs.
          </Typography>
        </Box>
        <Box sx={{ flex: { xs: '100%', md: '50%' }, textAlign: { xs: 'center', md: 'right' } }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Storable Items
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Boxes, Furniture, or Electronics
          </Typography>
          <Link href="/storable-items" underline="hover" sx={{ mt: 1, display: 'inline-block' }}>
            View all storable items
          </Link>
        </Box>
      </Box>

      {/* Plans Grid */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        {loading ? (
          <Grid container spacing={3} maxWidth="xl" sx={{ mt: 1 }}>
            {Array.from(new Array(4)).map((_, index) => (
              <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
                <Skeleton variant="rectangular" height={350} sx={{ borderRadius: 2 }} />
                <Skeleton variant="text" sx={{ mt: 1 }} />
                <Skeleton variant="text" width="60%" />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Fade in={!loading} timeout={1200}>
            <Grid container spacing={3} maxWidth="xl" sx={{ mt: 1 }}>
              {plans.map((plan) => {
                const isPerItem = plan.id === '0004';
                let upgradeCost = null;
                let upgradeProduct = null;
                if (selectedPlan && selectedPlan !== plan.id) {
                  upgradeProduct = planChangeProducts.find(
                    (pc) => pc.planFrom === (paidBasePlan || selectedPlan) && pc.planTo === plan.id
                  );
                  if (upgradeProduct && plansVisual[paidBasePlan || selectedPlan] && plansVisual[plan.id]) {
                    const fromPrice = parseFloat(plansVisual[paidBasePlan || selectedPlan].price.replace('$', ''));
                    const toPrice = parseFloat(plansVisual[plan.id].price.replace('$', ''));
                    upgradeCost = (toPrice - fromPrice).toFixed(2);
                  }
                }
                const { buttonText, buttonDisabled } = getButtonProps(plan);
                const basePriceDisplay = isPerItem ? (
                  paidBasePlan ? (
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      $30
                    </Typography>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                        $50
                      </Typography>
                      <Typography variant="h6" sx={{ display: 'block', pl: 1, color: 'text.secondary' }}>
                        + $30/item
                      </Typography>
                    </Box>
                  )
                ) : (
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {plansVisual[plan.id].price}
                  </Typography>
                );
                const effectivePriceDisplay = upgradeProduct ? (
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    ${upgradeCost}
                  </Typography>
                ) : (
                  basePriceDisplay
                );
                return (
                  <Grid item xs={12} sm={6} md={6} lg={3} key={plan.id}>
                    <Paper
                      elevation={3}
                      sx={{
                        mx: { xs: 2, md: 0 },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        borderRadius: 2,
                        borderColor: 'primary.main',
                        borderWidth: selectedPlan === plan.id ? 2 : 1,
                        backgroundColor: 'none',
                        borderStyle: 'solid',
                        height: '100%',
                        overflow: 'hidden',
                      }}
                    >
                      {/* HEADER */}
                      <Box
                        sx={{
                          backgroundColor: isPerItem ? 'transparent' : 'primary.main',
                          width: '100%',
                          pt: 3,
                          color: isPerItem ? 'unset' : 'white',
                          backgroundImage: isPerItem ? 'none' : 'url(/images/background_pattern.webp)',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                        }}
                      >
                        <Typography variant="h5" sx={{ fontWeight: '1000' }}>
                          {plansVisual[plan.id].title}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: isPerItem ? 'gray' : 'lightgray', mb: 1 }}>
                          {plansVisual[plan.id].subtitle}
                        </Typography>
                        <Box component="img" src={plansVisual[plan.id].image} alt={plansVisual[plan.id].title} sx={{ height: 150, mb: 2 }} />
                      </Box>
                      {/* BODY */}
                      <Box sx={{ p: 2, px: 3, width: '100%' }}>
                        <Box sx={{ width: '100%', textAlign: 'left', mb: 3 }}>
                          {effectivePriceDisplay}
                          {upgradeProduct && (
                            <Typography variant="body1" sx={{ color: 'gray', mt: 0.5 }}>
                              Upgrade from <strong>{plansVisual[upgradeProduct.planFrom]?.title}</strong> to{' '}
                              <strong>{plansVisual[upgradeProduct.planTo]?.title}</strong>.
                            </Typography>
                          )}
                          {!isPerItem && !upgradeProduct && (
                            <Typography variant="body1" sx={{ color: 'gray', mt: 0.5 }}>
                              Approx.{' '}
                              <strong>
                                {(
                                  parseFloat(plansVisual[plan.id].price.replace('$', '')) /
                                  parseInt(plansVisual[plan.id].title.split(' ')[0], 10) /
                                  4
                                ).toFixed(2)}
                              </strong>{' '}
                              per item, per month.
                            </Typography>
                          )}
                        </Box>
                        <Button
                          variant="contained"
                          disabled={buttonDisabled}
                          color="secondary"
                          fullWidth
                          sx={{ mt: 'auto', mb: 3, p: 1.5, fontWeight: 'bold' }}
                          onClick={() => openConfirmationDialog(plan.id, !!upgradeProduct)}
                        >
                          {buttonText}
                        </Button>
                        {/* Conditionally render the red outlined Cancel button */}
                        {selectedPlan === plan.id && selectedPlan !== paidBasePlan && (
                          <Button
                            variant="outlined"
                            color="error"
                            fullWidth
                            sx={{ mb: 3, p: 1.5, fontWeight: 'bold' }}
                            onClick={() => openCancelDialog(plan.id)}
                          >
                            Cancel Plan
                          </Button>
                        )}
                        {paidBasePlan && plan.id === paidBasePlan && (
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            sx={{ mb: 3, p: 1.5, fontWeight: 'bold' }}
                            onClick={handleOpenAddItemsDialog}
                          >
                            Add / Edit Items
                          </Button>
                        )}
                        {selectedPlan && plan.id === selectedPlan && !paidBasePlan && (
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled="true"
                            fullWidth
                            sx={{ mb: 3, p: 1.5, fontWeight: 'bold' }}
                          >
                            Add Items
                          </Button>
                        )}
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                          <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                            <strong>Includes all of the following:</strong>
                          </Typography>
                          {plansVisual[plan.id].features.map((feature, idx) => (
                            <Box key={idx} sx={{ display: 'flex', flexDirection: 'row', color: 'text.secondary' }}>
                              <Check sx={{ height: '75%', pr: 1 }} />
                              <Typography variant="body2" sx={{ mb: 2 }}>
                                {feature}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Fade>
        )}
      </Box>

      {/* CONFIRMATION DIALOG */}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancelConfirmation}
        fullWidth
        maxWidth="md"
        PaperProps={{ sx: { transition: 'width 0.5s ease, height 0.5s ease' } }}
      >
        <DialogTitle>
          {isUpgrade
            ? 'Confirm Your Plan Upgrade'
            : isAddItem
              ? 'Add Additional Items'
              : 'Confirm Your Plan Selection'}
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={confirmStep} sx={{ mb: 2 }}>
            {currentSteps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* --- UPGRADE FLOW --- */}
          {isUpgrade && confirmStep === 0 && selectedPlan && planToConfirm && (
            <Box>
              <Typography variant="body1" paragraph align="center">
                You are upgrading from <strong>{plansVisual[selectedPlan]?.title}</strong> to{' '}
                <strong>{plansVisual[planToConfirm]?.title}</strong>.
              </Typography>
              <Typography variant="body1" paragraph align="center">
                Additional upgrade cost: <strong>${computedUpgradeCost}</strong>.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: 2,
                  mb: 3,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    backgroundColor: '#f5f5f5',
                    textAlign: 'center',
                    p: 2,
                    minWidth: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ReceiptLong sx={{ fontSize: 40, mb: 1, color: 'primary.main' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    Payment Due
                  </Typography>
                  <Typography variant="body2">
                    A new invoice will include the upgrade cost.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    backgroundColor: '#f5f5f5',
                    textAlign: 'center',
                    p: 2,
                    minWidth: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CreditCardOff sx={{ fontSize: 40, mb: 1, color: 'primary.main' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    Not Auto-Charged
                  </Typography>
                  <Typography variant="body2">
                    Pay by {activeDeadline || 'the deadline'}.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    backgroundColor: '#f5f5f5',
                    textAlign: 'center',
                    p: 2,
                    minWidth: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ChangeCircle sx={{ fontSize: 40, mb: 1, color: 'primary.main' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    Change Anytime
                  </Typography>
                  <Typography variant="body2">
                    Free changes until {activeDeadline || 'the deadline'}.
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {/* --- NON-UPGRADE FLOW (Additional Info) --- */}
          {!isUpgrade && !isAddItem && confirmStep === 0 && (
            <Box>
              <Typography variant="body1" paragraph align="center">
                Please review your plan selection below.{' '}
                {activeDeadline ? (
                  isEarlyValid ? (
                    <strong>Early Registration</strong>
                  ) : (
                    <>
                      The <strong>early registration deadline</strong> has passed.
                    </>
                  )
                ) : (
                  <span style={{ color: 'red' }}>
                    No valid future registration deadline found. Please contact support.
                  </span>
                )}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: 2,
                  mb: 3,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    backgroundColor: '#f5f5f5',
                    textAlign: 'center',
                    p: 2,
                    minWidth: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ReceiptLong sx={{ fontSize: 40, mb: 1, color: 'primary.main' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    Payment Due
                  </Typography>
                  <Typography variant="body2">
                    A new invoice will be created for your chosen plan.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    backgroundColor: '#f5f5f5',
                    textAlign: 'center',
                    p: 2,
                    minWidth: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CreditCardOff sx={{ fontSize: 40, mb: 1, color: 'primary.main' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    Not Auto-Charged
                  </Typography>
                  <Typography variant="body2">
                    Pay by {activeDeadline || 'the deadline'}.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    backgroundColor: '#f5f5f5',
                    textAlign: 'center',
                    p: 2,
                    minWidth: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ChangeCircle sx={{ fontSize: 40, mb: 1, color: 'primary.main' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    Change Anytime
                  </Typography>
                  <Typography variant="body2">
                    Free changes until {activeDeadline || 'the deadline'}.
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {/* --- ITEM COUNT INPUT --- */}
          {/* Render only for the per-item plan (0004) */}
          {!isUpgrade && planToConfirm === '0004' && confirmStep === (isAddItem ? 0 : 1) && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                How many items would you like to store?
              </Typography>
              <TextField
                type="number"
                label="Number of Items"
                value={itemCount}
                onChange={(e) => setItemCount(Math.max(1, parseInt(e.target.value) || 1))}
                inputProps={{ min: 1 }}
                sx={{ width: '50%' }}
              />
            </Box>
          )}

          {/* --- FINAL SUMMARY --- */}
          {confirmStep === currentSteps.length - 1 && (
            <>
              {isUpgrade ? (
                // Upgrade Summary: show upgrade cost and plan details (no item count)
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {plansVisual[planToConfirm] && (
                    <Paper
                      sx={{
                        borderRadius: 2,
                        border: '2px solid',
                        borderColor: 'primary.main',
                        textAlign: 'center',
                        mb: 2,
                        mt: 2,
                        width: '75%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          backgroundColor: 'primary.main',
                          p: 2,
                          color: 'white',
                          backgroundImage: 'url(/images/background_pattern.webp)',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                        }}
                      >
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                            {plansVisual[planToConfirm]?.title}
                          </Typography>
                          <Typography variant="subtitle1" sx={{ color: 'lightgray' }}>
                            {plansVisual[planToConfirm]?.subtitle}
                          </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'right' }}>
                          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Upgrade Cost: ${computedUpgradeCost}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ textAlign: 'left', p: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                          Included Features:
                        </Typography>
                        <ul style={{ margin: 0, paddingLeft: '1rem' }}>
                          {plansVisual[planToConfirm]?.features.map((feature, idx) => (
                            <li key={idx}>
                              <Typography variant="body2">{feature}</Typography>
                            </li>
                          ))}
                        </ul>
                      </Box>
                    </Paper>
                  )}
                  {activeDeadline ? (
                    isEarlyValid ? (
                      <Typography variant="body1" paragraph align="center">
                        After confirming, your upgrade cost will be due by <strong>{activeDeadline} at 11:59pm</strong>.
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="body1" paragraph>
                          The <strong>early registration deadline</strong> has passed.
                          You can still upgrade until the <strong>late registration deadline</strong> on <strong>{activeDeadline}</strong> for an additional <strong>$75</strong> cost.
                        </Typography>
                        <Typography variant="body2" paragraph>
                          <em>Note:</em> Upgrades and appointment changes during late registration are not free.
                        </Typography>
                      </>
                    )
                  ) : (
                    <Typography variant="body1" paragraph color="error">
                      No valid future registration deadline found. Please contact support.
                    </Typography>
                  )}
                </Box>
              ) : planToConfirm === '0004' ? (
                isAddItem ? (
                  // Summary for "Add Item" action: exclude registration fee
                  <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Paper
                      sx={{
                        borderRadius: 2,
                        border: '2px solid',
                        borderColor: 'primary.main',
                        textAlign: 'center',
                        mb: 2,
                        mt: 2,
                        p: 2,
                      }}
                    >
                      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {plansVisual[planToConfirm]?.title || 'Plan Title'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'gray', mb: 1 }}>
                        {plansVisual[planToConfirm]?.subtitle || ''}
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 1 }}>
                        Item Price: <strong>$30</strong> per item
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 1 }}>
                        Number of Items: <strong>{itemCount}</strong>
                      </Typography>
                      {(() => {
                        const perItem = 30;
                        const total = perItem * itemCount;
                        return (
                          <Typography variant="h6" sx={{ mt: 2 }}>
                            Total: <strong>${total}</strong>
                          </Typography>
                        );
                      })()}
                    </Paper>
                    <Typography variant="body2">
                      By confirming, your payment of the total amount will be due by{' '}
                      <strong>{activeDeadline ? activeDeadline + ' at 11:59pm' : 'the deadline'}</strong>.
                    </Typography>
                  </Box>
                ) : (
                  // Summary for new per-item plan selection (includes registration fee)
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {plansVisual[planToConfirm] && (
                      <Paper
                        sx={{
                          borderRadius: 2,
                          border: '2px solid',
                          borderColor: 'primary.main',
                          textAlign: 'center',
                          mb: 2,
                          mt: 2,
                          width: '75%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: 'primary.main',
                            p: 2,
                            color: 'white',
                            backgroundImage: 'url(/images/background_pattern.webp)',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                          }}
                        >
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                              {plansVisual[planToConfirm]?.title}
                            </Typography>
                            <Typography variant="subtitle1" sx={{ color: 'lightgray' }}>
                              {plansVisual[planToConfirm]?.subtitle}
                            </Typography>
                          </Box>
                          <Box sx={{ textAlign: 'right' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                              {plansVisual[planToConfirm]?.price}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'white', mb: 2 }}>
                              {plansVisual[planToConfirm]?.registrationFee}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ textAlign: 'left', p: 2 }}>
                          <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                            Included Features:
                          </Typography>
                          <ul style={{ margin: 0, paddingLeft: '1rem' }}>
                            {plansVisual[planToConfirm]?.features.map((feature, idx) => (
                              <li key={idx}>
                                <Typography variant="body2">{feature}</Typography>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </Paper>
                    )}
                    {activeDeadline ? (
                      isEarlyValid ? (
                        <Typography variant="body1" paragraph align="center">
                          After confirming, your payment of <strong>{plansVisual[planToConfirm]?.price}</strong> will be due by{' '}
                          <strong>{activeDeadline} at 11:59pm</strong>.
                        </Typography>
                      ) : (
                        <>
                          <Typography variant="body1" paragraph>
                            The <strong>early registration deadline</strong> has passed.
                            You can still register until the <strong>late registration deadline</strong> on{' '}
                            <strong>{activeDeadline}</strong> for an additional <strong>$75</strong> cost.
                          </Typography>
                          <Typography variant="body2" paragraph>
                            <em>Note:</em> Plan transfers and appointment changes during late registration are not free.
                          </Typography>
                        </>
                      )
                    ) : (
                      <Typography variant="body1" paragraph color="error">
                        No valid future registration deadline found. Please contact support.
                      </Typography>
                    )}
                  </Box>
                )
              ) : (
                // Summary for non per-item, non-upgrade plans
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {plansVisual[planToConfirm] && (
                    <Paper
                      sx={{
                        borderRadius: 2,
                        border: '2px solid',
                        borderColor: 'primary.main',
                        textAlign: 'center',
                        mb: 2,
                        mt: 2,
                        width: '75%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          backgroundColor: 'primary.main',
                          p: 2,
                          color: 'white',
                          backgroundImage: 'url(/images/background_pattern.webp)',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                        }}
                      >
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                            {plansVisual[planToConfirm]?.title}
                          </Typography>
                          <Typography variant="subtitle1" sx={{ color: 'lightgray' }}>
                            {plansVisual[planToConfirm]?.subtitle}
                          </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'right' }}>
                          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            {plansVisual[planToConfirm]?.price}
                          </Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                            {plansVisual[planToConfirm]?.registrationFee}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ textAlign: 'left', p: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                          Included Features:
                        </Typography>
                        <ul style={{ margin: 0, paddingLeft: '1rem' }}>
                          {plansVisual[planToConfirm]?.features.map((feature, idx) => (
                            <li key={idx}>
                              <Typography variant="body2">{feature}</Typography>
                            </li>
                          ))}
                        </ul>
                      </Box>
                    </Paper>
                  )}
                  {activeDeadline ? (
                    isEarlyValid ? (
                      <Typography variant="body1" paragraph align="center">
                        After confirming, your payment of <strong>{plansVisual[planToConfirm]?.price}</strong> will be due by{' '}
                        <strong>{activeDeadline} at 11:59pm</strong>.
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="body1" paragraph>
                          The <strong>early registration deadline</strong> has passed.
                          You can still register until the <strong>late registration deadline</strong> on{' '}
                          <strong>{activeDeadline}</strong> for an additional <strong>$75</strong> cost.
                        </Typography>
                        <Typography variant="body2" paragraph>
                          <em>Note:</em> Plan transfers and appointment changes during late registration are not free.
                        </Typography>
                      </>
                    )
                  ) : (
                    <Typography variant="body1" paragraph color="error">
                      No valid future registration deadline found. Please contact support.
                    </Typography>
                  )}
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation} color="inherit">
            Cancel
          </Button>
          {confirmStep > 0 && (
            <Button onClick={handleBackStep} color="inherit">
              Back
            </Button>
          )}
          {confirmStep < currentSteps.length - 1 ? (
            <Button onClick={handleNextStep} variant="contained" color="primary">
              Continue
            </Button>
          ) : (
            <Button onClick={handleConfirmPlan} variant="contained" color="primary">
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* CANCEL DIALOG */}
      <Dialog
        open={cancelDialogOpen}
        onClose={handleCancelCancelPlan}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Cancel Storage Plan</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to cancel your selected storage plan? This action will also remove any open invoices associated with the plan.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCancelPlan} color="inherit">
            No
          </Button>
          <Button onClick={handleConfirmCancelPlan} variant="contained" color="error">
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* ADD ITEMS DIALOG */}
      <Dialog
        open={openAddItemsDialog}
        onClose={handleCloseAddItemsDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Update Additional Items</DialogTitle>
        <DialogContent>
          <Typography variant="h6" align="center" gutterBottom sx={{mb: 2}}>
            How many additional items would you like to store?
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="outlined"
              onClick={handleDecrease}
              disabled={paidAdditionalItems >= newAdditionalItems}
            >
              –
            </Button>
            <Typography variant="h6" sx={{ mx: 2 }}>
              {newAdditionalItems}
            </Typography>
            <Button variant="outlined" onClick={handleIncrease}>
              +
            </Button>
          </Box>
          {paidAdditionalItems > 0 && (
            <Typography variant="subtitle2" align="center" sx={{ mt: 1 }}>
              You have already paid for {paidAdditionalItems} additional items.
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseAddItemsDialog} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleConfirmAddItems} variant="contained" color="primary" 
          disabled={
            isConfirmingAddItems || (
            paidAdditionalItems == 
            additionalItems && newAdditionalItems == additionalItems)
            }>
            {isConfirmingAddItems ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Confirm"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StoragePlanPrivate;
