import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    Snackbar,
    Alert,
    Tooltip,
    IconButton,
} from '@mui/material';
import { Edit, Delete, ContentCopy, Add } from '@mui/icons-material';
import { collection, getDocs, doc, getDoc, deleteDoc, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate, useParams } from 'react-router-dom';

const AppointmentSelectorDialog = ({
    open,
    onClose,
    universityID,
    containerLocationID,
    initialSelected = []
}) => {
    const [appointments, setAppointments] = useState([]);
    const [selectedAppointments, setSelectedAppointments] = useState(initialSelected);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        // Fetch available appointments for the given container location.
        const fetchAppointments = async () => {
            try {
                const appointmentsCollection = collection(
                    db,
                    `universities/${universityID}/containerLocations/${containerLocationID}/appointments`
                );
                const snapshot = await getDocs(appointmentsCollection);
                const appointmentsData = snapshot.docs.map((docSnap) => {
                    const data = docSnap.data();
                    const start = data.start?.toDate ? data.start.toDate() : new Date(data.start);
                    const end = data.end?.toDate ? data.end.toDate() : new Date(data.end);
                    return { id: docSnap.id, ...data, start, end };
                });
                // Sort appointments by start date
                appointmentsData.sort((a, b) => a.start - b.start);
                setAppointments(appointmentsData);
            } catch (error) {
                console.error('Error fetching appointments:', error);
            }
        };
        fetchAppointments();
    }, [universityID, containerLocationID]);

    const handleToggle = (appointmentID) => {
        setSelectedAppointments((prev) =>
            prev.includes(appointmentID)
                ? prev.filter((id) => id !== appointmentID)
                : [...prev, appointmentID]
        );
    };

    const handleSave = () => {
        onClose(selectedAppointments);
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Dialog open={open} onClose={() => onClose(initialSelected)} maxWidth="md" fullWidth>
            <DialogTitle>Select Appointments</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox"></TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Day</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                                <TableCell>Max Customers</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {appointments.map((appointment) => (
                                <TableRow key={appointment.id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedAppointments.includes(appointment.id)}
                                            onChange={() => handleToggle(appointment.id)}
                                        />
                                    </TableCell>
                                    <TableCell>{appointment.title}</TableCell>
                                    <TableCell>{appointment.start.toDateString()}</TableCell>
                                    <TableCell>
                                        {appointment.start.toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        {appointment.end.toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </TableCell>
                                    <TableCell>{appointment.maxCustomers}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(initialSelected)} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save Selection
                </Button>
            </DialogActions>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default AppointmentSelectorDialog;
