import React from 'react';
import { Container, Paper, Typography, Box, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FeedbackIcon from '@mui/icons-material/Feedback';
import AddIcon from '@mui/icons-material/Add';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { useNavigate } from 'react-router-dom';

const Support = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Support
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <SupportAgentIcon sx={{ mr: 1 }} />
            Support Tickets
          </Typography>
          <Typography variant="body1" sx={{mb: 1}}>
            Our ticket system is currently undergoing construction.
            If you have any issues or need assistance, please feel free reach out to us at support@summerstore.org or call us at +1 (800) 564 - 2236. 
          </Typography> 
          <List>
            
            {/* <ListItem button onClick={() => navigate('/dashboard/support/create-ticket')}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Submit a New Ticket" />
            </ListItem>*/}
          </List>
        </Paper>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <FeedbackIcon sx={{ mr: 1 }} />
            Customer Feedback
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            We value your feedback. Please share your thoughts and suggestions to help us improve our services.
          </Typography>
          <List>
            <ListItem button>
              <ListItemIcon>
                <FeedbackOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Submit Feedback" />
            </ListItem>
          </List>
        </Paper>
      </Box>

      {/* The Help Center section has been removed. */}
    </Container>
  );
};

export default Support;
