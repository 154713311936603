import React, { useRef, useLayoutEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Handle } from '@xyflow/react';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CloseIcon from '@mui/icons-material/Close';
import MemoizedCapacityBar from './MemoizedCapacityBar';

const CustomContainerNode = ({ data }) => {
    const { period, containerId, customerCount, reportSize, appointmentLocationName } = data;
    const isPlaceholder = customerCount === 0;
    const ref = useRef(null);

    // Helper function to format period names.
    const formatPeriod = (period) => {
        switch (period) {
            case 'moveOut':
                return 'Move Out';
            case 'moveIn':
                return 'Move In';
            case 'summer':
                return 'Summer';
            default:
                return period;
        }
    };

    useLayoutEffect(() => {
        if (ref.current && reportSize) {
            const { offsetWidth, offsetHeight } = ref.current;
            reportSize(containerId, period, offsetWidth, offsetHeight);
        }
    }, [containerId, period, reportSize]);

    let PeriodIcon;
    if (period === 'moveOut') {
        PeriodIcon = LogoutIcon;
    } else if (period === 'moveIn') {
        PeriodIcon = LoginIcon;
    } else if (period === 'summer') {
        PeriodIcon = SystemUpdateAltIcon;
    }

    if (isPlaceholder) {
        return (
            <Box
                ref={ref}
                sx={{
                    padding: 2,
                    border: '1px solid #ddd',
                    borderRadius: 4,
                    backgroundColor: 'rgba(211,211,211,0.5)',
                    width: 220,
                    height: 100,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                <CloseIcon fontSize="large" />
                <Typography variant="caption" color="gray" sx={{ marginTop: 1 }}>
                    Not needed for {formatPeriod(period)}
                </Typography>
                <Handle
                    type="target"
                    position="left"
                    id="target"
                    style={{
                        background: '#555',
                        width: 10,
                        height: 10,
                        position: 'absolute',
                        left: -5,
                        top: '50%',
                    }}
                />
                <Handle
                    type="source"
                    position="right"
                    id="source"
                    style={{
                        background: '#555',
                        width: 10,
                        height: 10,
                        position: 'absolute',
                        right: -5,
                        top: '50%',
                    }}
                />
            </Box>
        );
    }

    return (
        <Box
            ref={ref}
            sx={{
                padding: 2,
                border: '1px solid #ddd',
                borderRadius: 4,
                backgroundColor: '#fff',
                width: 220,
                position: 'relative',
            }}
        >
            <Handle
                type="target"
                position="left"
                id="target"
                style={{ background: '#555', width: 10, height: 10 }}
            />

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 1,
                }}
            >
                {PeriodIcon && <PeriodIcon style={{ color: 'gray' }} />}
                <Typography
                    variant="body2"
                    sx={{
                        marginLeft: 1,
                        color: appointmentLocationName === 'No Location' ? 'red' : 'inherit',
                    }}
                >
                    {appointmentLocationName}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                    <PersonIcon />
                    <Typography variant="body2" sx={{ marginLeft: 0.5 }}>
                        {customerCount}
                    </Typography>
                </Box>
            </Box>

            <MemoizedCapacityBar containerId={containerId} period={period} minimal={true} />

            <Handle
                type="source"
                position="right"
                id="source"
                style={{ background: '#555', width: 10, height: 10 }}
            />
        </Box>
    );
};

export default CustomContainerNode;
