import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const comparisonData = [
  {
    feature: '4-Months Storage',
    summerStore: true,
    storageScholars: true,
    dormRoomMovers: true,
  },
  {
    feature: 'Movers',
    summerStore: true,
    storageScholars: true,
    dormRoomMovers: true,
  },
  {
    feature: 'Delivery',
    summerStore: true,
    storageScholars: true,
    dormRoomMovers: true,
  },
  {
    feature: 'Packing Supplies',
    summerStore: true,
    storageScholars: true,
    dormRoomMovers: true,
  },
  {
    feature: 'Insurance',
    summerStore: true,
    storageScholars: true,
    dormRoomMovers: true,
  },
  {
    feature: 'No Hidden Costs',
    summerStore: true,
    storageScholars: false,
    dormRoomMovers: false,
  },
  {
    feature: 'Per Item Cost',
    summerStore: '$25',
    storageScholars: '$88 to $134',
    dormRoomMovers: '$85 to $126',
  },
  {
    feature: 'Total Costs',
    summerStore: '$250',
    storageScholars: '$880+',
    dormRoomMovers: '$850+',
  },
];

const renderCheck = (included) => (
  included ? <CheckIcon style={{ color: '#4CAF50' }} /> : <CloseIcon style={{ color: '#F44336' }} />
);

const specialFeatures = ['Per Item Cost']; // List of features that need the special rendering

const renderSpecialCell = (value) => (
  <Box>
    <Typography variant="body2" component="div" sx={{  fontSize: '1.0rem' }}>
      {value.includes('/') ? value.split('/')[0] : value}
    </Typography>
    <Typography variant="caption" sx={{ display: 'block' }}>
      Per Item
    </Typography>
  </Box>
);

const PriceComparison = () => {
  return (
    <Box sx={{ mt: 3, backgroundColor: "none" }}>
      <TableContainer boxShadow={false} component={Box} sx={{ mt: 3, boxShadow: 'none', backgroundColor: "none" }}>
        <Table sx={{backgroundColor: "none" }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#053D62', color: 'white', fontWeight: 'bold',  borderTopLeftRadius: 8, borderTopRightRadius: 8  }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box height={{xs: 12, m: 20}} component="img" src='/images/SummerStore-Logo.webp' alt="SummerStore Logo" />
                </Box>
              </TableCell>
              <TableCell align="center">Storage Scholars</TableCell>
              <TableCell align="center" sx={{display: { xs: 'none', md: 'block' } }}>Dorm Room Movers</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {comparisonData.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row" sx={{ fontWeight: '800' }}>
                  {row.feature}
                </TableCell>
                <TableCell align="center" sx={{
                  backgroundColor: '#053D62',
                  color: 'white',
                  fontSize: {m: '2.5rem', s: '2.5rem'},
                  fontWeight: 'bold',
                  ...(index === comparisonData.length - 1 && { borderBottomLeftRadius: 8, borderBottomRightRadius: 8 })
                }}>
                  {specialFeatures.includes(row.feature) ? renderSpecialCell(row.summerStore) : typeof row.summerStore === 'boolean' ? renderCheck(row.summerStore) : row.summerStore}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: '1.0rem', display: { xs: 'none', md: 'block' } }}>
                  {specialFeatures.includes(row.feature) ? renderSpecialCell(row.storageScholars) : typeof row.storageScholars === 'boolean' ? renderCheck(row.storageScholars) : row.storageScholars}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: '1.0rem'}}>
                  {specialFeatures.includes(row.feature) ? renderSpecialCell(row.dormRoomMovers) : typeof row.dormRoomMovers === 'boolean' ? renderCheck(row.dormRoomMovers) : row.dormRoomMovers}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PriceComparison;
