import React, { useEffect, useState } from 'react';
import { Box, Typography, Tab, Tabs, Paper, CircularProgress } from '@mui/material';
import { Logout, Login } from '@mui/icons-material';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import AppointmentTab from './AppointmentTab';

const UserDetailAppointments = ({ userId }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [userData, setUserData] = useState(null);
    const [loadingUserData, setLoadingUserData] = useState(true);

    // Move Out states
    const [moveOutAppointmentDetails, setMoveOutAppointmentDetails] = useState(null);
    const [loadingMoveOutDetails, setLoadingMoveOutDetails] = useState(false);
    const [showMoveOutSelector, setShowMoveOutSelector] = useState(false);
    const [moveOutColleges, setMoveOutColleges] = useState([]);
    const [moveOutLocationName, setMoveOutLocationName] = useState('');
    const [moveOutEntryway, setMoveOutEntryway] = useState('');
    const [moveOutEntryways, setMoveOutEntryways] = useState([]);
    const [editingMoveOutLocation, setEditingMoveOutLocation] = useState(false);

    // Move In states
    const [moveInAppointmentDetails, setMoveInAppointmentDetails] = useState(null);
    const [loadingMoveInDetails, setLoadingMoveInDetails] = useState(false);
    const [showMoveInSelector, setShowMoveInSelector] = useState(false);
    const [moveInColleges, setMoveInColleges] = useState([]);
    const [moveInLocationName, setMoveInLocationName] = useState('');
    const [moveInEntryway, setMoveInEntryway] = useState('');
    const [moveInEntryways, setMoveInEntryways] = useState([]);
    const [editingMoveInLocation, setEditingMoveInLocation] = useState(false);

    // -----------------------------
    // 1. Fetch user document for the given userId
    // -----------------------------
    useEffect(() => {
        if (!userId) return;
        const fetchUserDoc = async () => {
            setLoadingUserData(true);
            try {
                const userDocRef = doc(db, 'users', userId);
                const userSnap = await getDoc(userDocRef);
                if (userSnap.exists()) {
                    const data = userSnap.data();
                    setUserData(data);
                    // No longer initializing location names from the user document
                }
            } catch (error) {
                console.error('Error fetching user document:', error);
            } finally {
                setLoadingUserData(false);
            }
        };
        fetchUserDoc();
    }, [userId]);

    // -----------------------------
    // 1a. Fetch Move Out Location Name based on container ID
    // -----------------------------
    useEffect(() => {
        if (userData && userData.universityId && userData.moveOutContainerLocationID) {
            const fetchMoveOutLocationName = async () => {
                try {
                    const locationDocRef = doc(
                        db,
                        'universities',
                        userData.universityId,
                        'containerLocations',
                        userData.moveOutContainerLocationID
                    );
                    const locationSnap = await getDoc(locationDocRef);
                    if (locationSnap.exists()) {
                        setMoveOutLocationName(locationSnap.data().name);
                    }
                } catch (error) {
                    console.error('Error fetching Move Out location name:', error);
                }
            };
            fetchMoveOutLocationName();
        }
    }, [userData]);

    // -----------------------------
    // 1b. Fetch Move In Location Name based on container ID
    // -----------------------------
    useEffect(() => {
        if (userData && userData.universityId && userData.moveInContainerLocationID) {
            const fetchMoveInLocationName = async () => {
                try {
                    const locationDocRef = doc(
                        db,
                        'universities',
                        userData.universityId,
                        'containerLocations',
                        userData.moveInContainerLocationID
                    );
                    const locationSnap = await getDoc(locationDocRef);
                    if (locationSnap.exists()) {
                        setMoveInLocationName(locationSnap.data().name);
                    }
                } catch (error) {
                    console.error('Error fetching Move In location name:', error);
                }
            };
            fetchMoveInLocationName();
        }
    }, [userData]);

    // -----------------------------
    // 2. Fetch Move Out appointment details
    // -----------------------------
    useEffect(() => {
        if (
            !userData ||
            !userData.universityId ||
            !userData.moveOutAppointment ||
            !userData.moveOutContainerLocationID
        ) {
            setMoveOutAppointmentDetails(null);
            return;
        }
        const fetchMoveOutDetails = async () => {
            setLoadingMoveOutDetails(true);
            try {
                const apptDocRef = doc(
                    db,
                    'universities',
                    userData.universityId,
                    'containerLocations',
                    userData.moveOutContainerLocationID,
                    'appointments',
                    userData.moveOutAppointment
                );
                const apptSnap = await getDoc(apptDocRef);
                if (apptSnap.exists()) {
                    setMoveOutAppointmentDetails(apptSnap.data());
                }
            } catch (error) {
                console.error('Error fetching Move Out appointment:', error);
            } finally {
                setLoadingMoveOutDetails(false);
            }
        };
        fetchMoveOutDetails();
    }, [userData]);

    // -----------------------------
    // 3. Fetch Move In appointment details
    // -----------------------------
    useEffect(() => {
        if (
            !userData ||
            !userData.universityId ||
            !userData.moveInAppointment ||
            !userData.moveInContainerLocationID
        ) {
            setMoveInAppointmentDetails(null);
            return;
        }
        const fetchMoveInDetails = async () => {
            setLoadingMoveInDetails(true);
            try {
                const apptDocRef = doc(
                    db,
                    'universities',
                    userData.universityId,
                    'containerLocations',
                    userData.moveInContainerLocationID,
                    'appointments',
                    userData.moveInAppointment
                );
                const apptSnap = await getDoc(apptDocRef);
                if (apptSnap.exists()) {
                    setMoveInAppointmentDetails(apptSnap.data());
                }
            } catch (error) {
                console.error('Error fetching Move In appointment:', error);
            } finally {
                setLoadingMoveInDetails(false);
            }
        };
        fetchMoveInDetails();
    }, [userData]);

    // -----------------------------
    // 4. Fetch container locations for selection
    // -----------------------------
    useEffect(() => {
        if (!userData?.universityId) return;
        const fetchColleges = async () => {
            try {
                const collegesCollection = collection(
                    db,
                    `universities/${userData.universityId}/containerLocations`
                );
                const snapshot = await getDocs(collegesCollection);
                const collegeList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setMoveOutColleges(collegeList);
                setMoveInColleges(collegeList);
            } catch (error) {
                console.error('Error fetching container locations:', error);
            }
        };
        fetchColleges();
    }, [userData?.universityId]);

    // -----------------------------
    // 5. Appointment selection handlers
    // -----------------------------
    const handleSelectMoveOutAppointment = async (selectedAppointment) => {
        try {
            const userDocRef = doc(db, 'users', userId);
            await updateDoc(userDocRef, { moveOutAppointment: selectedAppointment.id });
            setUserData(prev => ({ ...prev, moveOutAppointment: selectedAppointment.id }));
            setShowMoveOutSelector(false);
        } catch (error) {
            console.error('Error updating Move Out appointment:', error);
        }
    };

    const handleSelectMoveInAppointment = async (selectedAppointment) => {
        try {
            const userDocRef = doc(db, 'users', userId);
            await updateDoc(userDocRef, { moveInAppointment: selectedAppointment.id });
            setUserData(prev => ({ ...prev, moveInAppointment: selectedAppointment.id }));
            setShowMoveInSelector(false);
        } catch (error) {
            console.error('Error updating Move In appointment:', error);
        }
    };

    // -----------------------------
    // 6. Handle tab switching
    // -----------------------------
    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    if (loadingUserData) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!userData) {
        return <Typography variant="body1">Unable to load user appointment details.</Typography>;
    }

    return (
        <Box sx={{ p: 3, flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
                Appointments
            </Typography>
            <Paper sx={{ width: '100%', mt: 2 }}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab icon={<Logout />} label="Move Out" />
                    <Tab icon={<Login />} label="Move In" />
                </Tabs>
            </Paper>

            {/* ================= MOVE OUT TAB ================= */}
            {tabIndex === 0 && (
                <Box sx={{ mt: 3 }}>
                    <AppointmentTab
                        tabTitle="Move Out Appointment"
                        instructionsTitle="Move Out Instructions"
                        coordinatorName="Johnee Doe" // Replace with dynamic info if needed
                        loadingDetails={loadingMoveOutDetails}
                        appointmentDetails={moveOutAppointmentDetails}
                        onSelectAppointment={handleSelectMoveOutAppointment}
                        onShowSelector={() => setShowMoveOutSelector(true)}
                        showSelector={showMoveOutSelector}
                        onHideSelector={() => setShowMoveOutSelector(false)}
                        universityID={userData?.universityId}
                        containerLocationID={userData?.moveOutContainerLocationID}
                        locationName={moveOutLocationName}
                        colleges={moveOutColleges}
                        editingLocation={editingMoveOutLocation}
                        onEditLocation={() => setEditingMoveOutLocation(true)}
                        onCancelLocation={() => setEditingMoveOutLocation(false)}
                        onSaveLocation={async () => {
                            try {
                                const chosenCollegeDoc = moveOutColleges.find(c => c.name === moveOutLocationName);
                                if (!chosenCollegeDoc) return;
                                const userDocRef = doc(db, 'users', userId);
                                await updateDoc(userDocRef, {
                                    moveOutContainerLocationID: chosenCollegeDoc.id,
                                    moveOutLocationName: chosenCollegeDoc.name,
                                    moveOutEntryway,
                                });
                                setUserData(prev => ({
                                    ...prev,
                                    moveOutContainerLocationID: chosenCollegeDoc.id,
                                }));
                                setMoveOutLocationName(chosenCollegeDoc.name);
                                setEditingMoveOutLocation(false);
                            } catch (error) {
                                console.error('Error updating Move Out location:', error);
                            }
                        }}
                        onCollegeChange={(e) => {
                            const newLocationName = e.target.value;
                            setMoveOutLocationName(newLocationName);
                            setMoveOutEntryway('');
                            const collegeDoc = moveOutColleges.find(c => c.name === newLocationName);
                            setMoveOutEntryways(collegeDoc?.servicedEntryways || []);
                        }}
                        onEntrywayChange={(e) => setMoveOutEntryway(e.target.value)}
                    />
                </Box>
            )}

            {/* ================= MOVE IN TAB ================= */}
            {tabIndex === 1 && (
                <Box sx={{ mt: 3 }}>
                    <AppointmentTab
                        tabTitle="Move In Appointment"
                        instructionsTitle="Move In Instructions"
                        coordinatorName="Jane Smith" // Replace with dynamic info if needed
                        loadingDetails={loadingMoveInDetails}
                        appointmentDetails={moveInAppointmentDetails}
                        onSelectAppointment={handleSelectMoveInAppointment}
                        onShowSelector={() => setShowMoveInSelector(true)}
                        showSelector={showMoveInSelector}
                        onHideSelector={() => setShowMoveInSelector(false)}
                        universityID={userData?.universityId}
                        containerLocationID={userData?.moveInContainerLocationID}
                        locationName={moveInLocationName}
                        locationEntryway={moveInEntryway}
                        colleges={moveInColleges}
                        editingLocation={editingMoveInLocation}
                        onEditLocation={() => setEditingMoveInLocation(true)}
                        onCancelLocation={() => setEditingMoveInLocation(false)}
                        onSaveLocation={async () => {
                            try {
                                const chosenCollegeDoc = moveInColleges.find(c => c.name === moveInLocationName);
                                if (!chosenCollegeDoc) return;
                                const userDocRef = doc(db, 'users', userId);
                                await updateDoc(userDocRef, {
                                    moveInContainerLocationID: chosenCollegeDoc.id,
                                    moveInLocationName: chosenCollegeDoc.name,
                                    moveInEntryway,
                                });
                                setUserData(prev => ({
                                    ...prev,
                                    moveInContainerLocationID: chosenCollegeDoc.id,
                                }));
                                setMoveInLocationName(chosenCollegeDoc.name);
                                setEditingMoveInLocation(false);
                            } catch (error) {
                                console.error('Error updating Move In location:', error);
                            }
                        }}
                        onCollegeChange={(e) => {
                            const newLocationName = e.target.value;
                            setMoveInLocationName(newLocationName);
                            setMoveInEntryway('');
                            const collegeDoc = moveInColleges.find(c => c.name === newLocationName);
                            setMoveInEntryways(collegeDoc?.servicedEntryways || []);
                        }}
                        onEntrywayChange={(e) => setMoveInEntryway(e.target.value)}
                    />
                </Box>
            )}
        </Box>
    );
};

export default UserDetailAppointments;
