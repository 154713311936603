// File: src/components/NotificationEditor.jsx
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
} from '@mui/material';
import PropTypes from 'prop-types';

// Import your existing editors
import NotificationEditorDetails from '../../components/NotificationEditorDetails';
import NotificationEditorWeb from '../../components/NotificationEditorWeb';
import NotificationEditorText from '../../components/NotificationEditorText';
import NotificationEditorEmail from '../../components/NotificationEditorEmail';
import NotificationEditorScheduler from '../../components/NotificationEditorScheduler';

import { useParams, useNavigate } from 'react-router-dom';
import {
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
  collection,
  deleteDoc,
  getDocs,
} from 'firebase/firestore';
import { db } from '../../firebase';

// Import Material-UI Icons
import InfoIcon from '@mui/icons-material/Info';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WebIcon from '@mui/icons-material/Public'; // Using Public as a web icon
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';

import { isEqual } from 'lodash';

const NotificationEditor = () => {
  const { notificationId } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);

  // =======================
  // State for Details Tab
  // =======================
  const [detailName, setDetailName] = useState('');
  const [detailDescription, setDetailDescription] = useState('');
  const [detailIcon, setDetailIcon] = useState('');

  // Type flags
  const [detailHasWeb, setDetailHasWeb] = useState(false);
  const [detailHasSms, setDetailHasSms] = useState(false);
  const [detailHasEmail, setDetailHasEmail] = useState(false);

  // =======================
  // State for Scheduler Tab
  // =======================
  const [schedules, setSchedules] = useState([]);
  // State for selected cancel triggers for this notification.
  const [cancelTriggers, setCancelTriggers] = useState([]);

  // =======================
  // State for Email Tab
  // =======================
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [emailComponentsMap, setEmailComponentsMap] = useState({});

  // =======================
  // State for Web Tab
  // =======================
  const [webTitle, setWebTitle] = useState('');
  const [webIcon, setWebIcon] = useState('');
  const [webPrimaryColor, setWebPrimaryColor] = useState('#000000');
  const [webBackgroundColor, setWebBackgroundColor] = useState('#ffffff');
  const [webTextColor, setWebTextColor] = useState('#000000');
  const [webText, setWebText] = useState('');
  const [webButtons, setWebButtons] = useState([]);

  // =======================
  // State for SMS Tab
  // =======================
  const [smsMessage, setSmsMessage] = useState('');

  // =======================
  // Available Triggers State
  // =======================
  const [availableTriggers, setAvailableTriggers] = useState([]);

  // =======================
  // Available Cancel Triggers State
  // =======================
  const [availableCancelTriggers, setAvailableCancelTriggers] = useState([]);

  // =======================
  // Original Data for Change Detection
  // =======================
  const [originalData, setOriginalData] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  // =======================
  // Confirmation Dialog State
  // =======================
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  // =======================
  // Snackbar State for Notifications
  // =======================
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success' | 'error' | 'warning' | 'info'
  });

  // =======================
  // Fetch Notification Details and Initialize `originalData`
  // =======================
  useEffect(() => {
    const fetchDetails = async () => {
      if (notificationId) {
        const docRef = doc(db, 'notifications', notificationId);
        const unsubscribe = onSnapshot(
          docRef,
          async (docSnap) => {
            if (docSnap.exists()) {
              const data = docSnap.data();
              setDetailName(data.detailName || '');
              setDetailDescription(data.detailDescription || '');
              setDetailIcon(data.detailIcon || '');
              setDetailHasWeb(data.detailHasWeb || false);
              setDetailHasSms(data.detailHasSms || false);
              setDetailHasEmail(data.detailHasEmail || false);
              setSchedules(data.schedules || []);
              setCancelTriggers(data.cancelTriggers || []);

              // Load email data directly from the notification document
              setEmailSubject(data.emailSubject || '');
              setEmailBody(data.emailBody || '');

              // Load Web data directly from the notification document
              if (data.detailHasWeb) {
                setWebTitle(data.webTitle || '');
                setWebIcon(data.webIcon || '');
                setWebPrimaryColor(data.webPrimaryColor || '#000000');
                setWebBackgroundColor(data.webBackgroundColor || '#ffffff');
                setWebTextColor(data.webTextColor || '#000000');
                setWebText(data.webText || '');
                setWebButtons(data.webButtons || []);
              }

              // Load SMS
              if (data.detailHasSms) {
                const smsDocRef = doc(db, 'textNotifications', notificationId);
                const smsSnap = await getDoc(smsDocRef);
                if (smsSnap.exists()) {
                  const smsData = smsSnap.data();
                  setSmsMessage(smsData.message || '');
                }
              }

              // Load Email Components Map if email is enabled
              if (data.detailHasEmail) {
                const emailComponentsSnapshot = await getDocs(collection(db, 'emailComponents'));
                const mapping = {};
                emailComponentsSnapshot.forEach((docSnap) => {
                  const compData = docSnap.data();
                  mapping[compData.name.trim()] = compData.body;
                });
                setEmailComponentsMap(mapping);
              }

              // Initialize original data using fields from the main document
              setOriginalData({
                detailName: data.detailName || '',
                detailDescription: data.detailDescription || '',
                detailIcon: data.detailIcon || '',
                detailHasWeb: data.detailHasWeb || false,
                detailHasSms: data.detailHasSms || false,
                detailHasEmail: data.detailHasEmail || false,
                schedules: data.schedules || [],
                cancelTriggers: data.cancelTriggers || [],
                emailSubject: data.emailSubject || '',
                emailBody: data.emailBody || '',
                webTitle: data.webTitle || '',
                webIcon: data.webIcon || '',
                webPrimaryColor: data.webPrimaryColor || '#000000',
                webBackgroundColor: data.webBackgroundColor || '#ffffff',
                webTextColor: data.webTextColor || '#000000',
                webText: data.webText || '',
                webButtons: data.webButtons || [],
                smsMessage: data.smsMessage || '',
              });
              console.log("Original data: ", {
                detailName: data.detailName || '',
                detailDescription: data.detailDescription || '',
                detailIcon: data.detailIcon || '',
                detailHasWeb: data.detailHasWeb || false,
                detailHasSms: data.detailHasSms || false,
                detailHasEmail: data.detailHasEmail || false,
                schedules: data.schedules || [],
                cancelTriggers: data.cancelTriggers || [],
                emailSubject: data.emailSubject || '',
                emailBody: data.emailBody || '',
                webTitle: data.webTitle || '',
                webIcon: data.webIcon || '',
                webPrimaryColor: data.webPrimaryColor || '#000000',
                webBackgroundColor: data.webBackgroundColor || '#ffffff',
                webTextColor: data.webTextColor || '#000000',
                webText: data.webText || '',
                webButtons: data.webButtons || [],
                smsMessage: data.smsMessage || '',
              });
            } else {
              console.warn('Notification does not exist.');
            }
            setLoading(false);
          },
          (error) => {
            console.error('Error fetching notification details:', error);
            setLoading(false);
          }
        );

        // Cleanup listener on unmount
        return () => unsubscribe();
      } else {
        // For a new notification, initialize originalData with empty states
        setOriginalData({
          detailName: '',
          detailDescription: '',
          detailIcon: '',
          detailHasWeb: false,
          detailHasSms: false,
          detailHasEmail: false,
          schedules: [],
          cancelTriggers: [],
          emailSubject: '',
          emailBody: '',
          webTitle: '',
          webIcon: '',
          webPrimaryColor: '#000000',
          webBackgroundColor: '#ffffff',
          webTextColor: '#000000',
          webText: '',
          webButtons: [],
          smsMessage: '',
        });
        setLoading(false);
      }
    };

    fetchDetails();
  }, [notificationId]);

  // =======================
  // Fetch Available Triggers
  // =======================
  useEffect(() => {
    const fetchTriggers = async () => {
      try {
        const triggerSnapshot = await getDocs(collection(db, 'triggers'));
        const triggersList = triggerSnapshot.docs.map((doc) => doc.data());
        setAvailableTriggers(triggersList);
      } catch (error) {
        console.error('Error fetching triggers:', error);
      }
    };

    fetchTriggers();
  }, []);

  // =======================
  // Fetch Available Cancel Triggers
  // =======================
  useEffect(() => {
    const fetchCancelTriggers = async () => {
      try {
        const cancelTriggerSnapshot = await getDocs(collection(db, 'cancelTriggers'));
        const cancelTriggersList = cancelTriggerSnapshot.docs.map((doc) => doc.data());
        setAvailableCancelTriggers(cancelTriggersList);
      } catch (error) {
        console.error('Error fetching cancel triggers:', error);
      }
    };

    fetchCancelTriggers();
  }, []);

  // =======================
  // Determine if there are unsaved changes
  // =======================
  useEffect(() => {
    if (!originalData) {
      setIsDirty(false);
      return;
    }

    const hasChanges = () => {

      console.log("New Cancel triggers: ", cancelTriggers);
      console.log("Og Cancel Triggers: ", originalData.cancelTriggers);
      return (
        detailName !== originalData.detailName ||
        detailDescription !== originalData.detailDescription ||
        detailIcon !== originalData.detailIcon ||
        detailHasWeb !== originalData.detailHasWeb ||
        detailHasSms !== originalData.detailHasSms ||
        detailHasEmail !== originalData.detailHasEmail ||
        !isEqual(schedules, originalData.schedules) ||
        !isEqual(cancelTriggers, originalData.cancelTriggers) ||
        emailSubject !== originalData.emailSubject ||
        emailBody !== originalData.emailBody ||
        webTitle !== originalData.webTitle ||
        webIcon !== originalData.webIcon ||
        webPrimaryColor !== originalData.webPrimaryColor ||
        webBackgroundColor !== originalData.webBackgroundColor ||
        webTextColor !== originalData.webTextColor ||
        webText !== originalData.webText ||
        !isEqual(webButtons, originalData.webButtons) ||
        smsMessage !== originalData.smsMessage
      );
    };

    setIsDirty(hasChanges());
  }, [
    detailName,
    detailDescription,
    detailIcon,
    detailHasWeb,
    detailHasSms,
    detailHasEmail,
    schedules,
    cancelTriggers, 
    emailSubject,
    emailBody,
    webTitle,
    webIcon,
    webPrimaryColor,
    webBackgroundColor,
    webTextColor,
    webText,
    webButtons,
    smsMessage,
    originalData,
  ]);


  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Define tabs based on enabled notification types with icons
  const tabs = [
    {
      label: 'Details',
      icon: <InfoIcon />,
      component: (
        <NotificationEditorDetails
          detailName={detailName}
          setDetailName={setDetailName}
          detailDescription={detailDescription}
          setDetailDescription={setDetailDescription}
          detailIcon={detailIcon}
          setDetailIcon={setDetailIcon}
          detailHasWeb={detailHasWeb}
          setDetailHasWeb={setDetailHasWeb}
          detailHasSms={detailHasSms}
          setDetailHasSms={setDetailHasSms}
          detailHasEmail={detailHasEmail}
          setDetailHasEmail={setDetailHasEmail}
        />
      ),
    },
  ];

  // Always include the Scheduler tab, passing cancelTriggers state and availableCancelTriggers.
  tabs.push({
    label: 'Scheduler',
    icon: <ScheduleIcon />,
    component: (
      <NotificationEditorScheduler
        schedules={schedules}
        setSchedules={setSchedules}
        cancelTriggers={cancelTriggers}
        setCancelTriggers={setCancelTriggers}
        availableTriggers={availableTriggers}
        availableCancelTriggers={availableCancelTriggers}
      />
    ),
  });

  if (detailHasWeb) {
    tabs.push({
      label: 'Web Notification',
      icon: <WebIcon />,
      component: (
        <NotificationEditorWeb
          webTitle={webTitle}
          setWebTitle={setWebTitle}
          webIcon={webIcon}
          setWebIcon={setWebIcon}
          webPrimaryColor={webPrimaryColor}
          setWebPrimaryColor={setWebPrimaryColor}
          webBackgroundColor={webBackgroundColor}
          setWebBackgroundColor={setWebBackgroundColor}
          webTextColor={webTextColor}
          setWebTextColor={setWebTextColor}
          webText={webText}
          setWebText={setWebText}
          webButtons={webButtons}
          setWebButtons={setWebButtons}
        />
      ),
    });
  }

  if (detailHasSms) {
    tabs.push({
      label: 'SMS Notification',
      icon: <SmsIcon />,
      component: (
        <NotificationEditorText
          smsMessage={smsMessage}
          setSmsMessage={setSmsMessage}
        />
      ),
    });
  }

  if (detailHasEmail) {
    tabs.push({
      label: 'Email Notification',
      icon: <EmailIcon />,
      component: (
        <NotificationEditorEmail
          emailSubject={emailSubject}
          setEmailSubject={setEmailSubject}
          emailBody={emailBody}
          setEmailBody={setEmailBody}
          emailComponentsMap={emailComponentsMap}
          setEmailComponentsMap={setEmailComponentsMap}
        />
      ),
    });
  }

  // Adjust activeTab if necessary when tabs change
  useEffect(() => {
    if (activeTab >= tabs.length) {
      setActiveTab(tabs.length - 1);
    }
  }, [tabs.length, activeTab]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // ===========================
  // Universal Save Function
  // ===========================
  const handleSaveAll = async () => {
    try {
      // 1. Save Details (including web fields and email fields directly)
      const detailsData = {
        detailName: detailName.trim(),
        detailDescription: detailDescription.trim(),
        detailIcon: detailIcon.trim(),
        detailHasWeb,
        detailHasSms,
        detailHasEmail,
        schedules,
        cancelTriggers,
        webTitle: webTitle.trim(),
        webIcon: webIcon.trim(),
        webPrimaryColor,
        webBackgroundColor,
        webTextColor,
        webText: webText.trim(),
        webButtons,
        // Save SMS field for consistency
        smsMessage: smsMessage.trim(),
        // Save Email fields directly to the notification document
        emailSubject: emailSubject.trim(),
        emailBody: emailBody.trim(),
      };

      if (!notificationId) {
        // Creating a new notification
        const newDocRef = doc(collection(db, 'notifications'));
        await setDoc(newDocRef, detailsData);

        // Reset originalData to current data
        setOriginalData({
          ...detailsData,
        });

        setSnackbar({
          open: true,
          message: 'Notification created successfully!',
          severity: 'success',
        });

        navigate(`dashboard/notifications/edit/${newDocRef.id}/details`);
      } else {
        // Updating an existing notification
        const detailsDocRef = doc(db, 'notifications', notificationId);
        await updateDoc(detailsDocRef, detailsData);

        // Delete associated SMS document if the type is disabled
        if (!detailHasSms && originalData.detailHasSms) {
          await deleteDoc(doc(db, 'textNotifications', notificationId)).catch((err) => {
            if (err.code !== 'not-found') {
              console.error('Error deleting SMS notification:', err);
            }
          });
        }

        // Reset originalData to current data
        setOriginalData({
          ...detailsData,
        });

        setSnackbar({
          open: true,
          message: 'Notification updated successfully!',
          severity: 'success',
        });

        navigate(-1); // Go back to the previous page
      }
    } catch (error) {
      console.error('Error saving notification:', error);
      setSnackbar({
        open: true,
        message: 'Failed to save notification. Please try again.',
        severity: 'error',
      });
    }
  };

  // ===========================
  // Back Button with Unsaved Changes Warning
  // ===========================
  const handleBackButtonClick = () => {
    if (isDirty) {
      setConfirmDialogOpen(true);
    } else {
      navigate(-1);
    }
  };

  const handleConfirmLeave = () => {
    setConfirmDialogOpen(false);
    navigate(-1);
  };

  const handleCancelLeave = () => {
    setConfirmDialogOpen(false);
  };

  // ===========================
  // Handle Snackbar Close
  // ===========================
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 3 }}>
      {/* Back Button */}
      <Button onClick={handleBackButtonClick} variant="outlined" color="primary" sx={{ mb: 2 }}>
        Back
      </Button>

      <Typography variant="h5" sx={{ mb: 2 }}>
        Notification Editor
      </Typography>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Notification Editor Tabs"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            icon={tab.icon}
            iconPosition="start"
          />
        ))}
      </Tabs>

      <Box sx={{ mt: 3 }}>
        {tabs[activeTab]?.component}
      </Box>

      {/* Universal Save Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
        <Button variant="contained" color="primary" onClick={handleSaveAll} disabled={!isDirty}>
          Save All
        </Button>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={confirmDialogOpen} onClose={handleCancelLeave}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have unsaved changes. Are you sure you want to leave without saving?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLeave} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLeave} color="error">
            Leave Without Saving
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NotificationEditor;
