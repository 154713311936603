import React, { useEffect, useState, memo } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    TablePagination,
    Tooltip,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Collapse,
    TextField,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
    collection,
    query,
    where,
    getDocs,
    getDoc,
    doc,
    addDoc,
    updateDoc,
    arrayUnion,
    arrayRemove,
} from 'firebase/firestore';
import { db } from '../firebase';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContainerLocationDropdownSelect from './ContainerLocationDropdownSelect';
import ContainerCustomersTable from './ContainerCustomersTable';
import { useNotification } from '../contexts/NotificationContext';
import AppointmentSelectorDialog from './AppointmentSelectorDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AccountTreeOutlined } from '@mui/icons-material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';

// Import the new CapacityBar component (which takes containerId and period)
import CapacityBar from './CapacityBar';

// Helper function to fetch a single appointment document.
const fetchAppointment = async (universityID, containerLocationID, appointmentID) => {
    const appointmentDocRef = doc(
        db,
        `universities/${universityID}/containerLocations/${containerLocationID}/appointments/${appointmentID}`
    );
    const appointmentDoc = await getDoc(appointmentDocRef);
    return appointmentDoc.exists() ? appointmentDoc.data() : null;
};

// New component to display a date or range of dates from an array of appointment IDs.
const AppointmentDateRangeDisplay = ({ universityID, containerLocationID, appointmentIDs }) => {
    const [displayText, setDisplayText] = useState(null);

    useEffect(() => {
        const loadDates = async () => {
            if (!appointmentIDs || appointmentIDs.length === 0) {
                setDisplayText(null);
                return;
            }
            try {
                // Fetch all appointment documents.
                const appointments = await Promise.all(
                    appointmentIDs.map((id) => fetchAppointment(universityID, containerLocationID, id))
                );
                // Filter out any missing appointments.
                const validAppointments = appointments.filter((appt) => appt && appt.start);
                if (validAppointments.length === 0) {
                    setDisplayText(null);
                    return;
                }
                // Convert Firestore timestamps to Date objects.
                const dates = validAppointments.map((appt) => new Date(appt.start.seconds * 1000));
                // Sort dates in ascending order.
                dates.sort((a, b) => a - b);

                // Format the earliest and latest dates.
                const options = { month: 'short', day: 'numeric' };
                const startDate = dates[0].toLocaleDateString(undefined, options);
                if (dates.length === 1) {
                    setDisplayText(startDate);
                } else {
                    const endDate = dates[dates.length - 1].toLocaleDateString(undefined, options);
                    setDisplayText(`${startDate} – ${endDate}`);
                }
            } catch (error) {
                console.error('Error loading appointment dates:', error);
                setDisplayText(null);
            }
        };
        loadDates();
    }, [universityID, containerLocationID, appointmentIDs]);

    return displayText ? (
        <Typography variant="caption" sx={{ color: 'gray', fontSize: '0.65rem' }}>
            {displayText}
        </Typography>
    ) : null;
};


// Memoized component for each container row.   
const ContainerRow = memo(({
    container,
    locationMap,
    expanded,
    toggleExpand,
    openEditLocationDialog,
    handleMoveCustomerToContainer,
    selectedCustomer,
    selectedCustomerPrevContainerId,
    handleCustomerMoveClick,
    universityID  // Needed for fetching appointment dates.
}) => {
    const moveInCount = container.moveInCustomers?.length || 0;
    const moveOutCount = container.moveOutCustomers?.length || 0;
    const summerCount = container.summerCustomers?.length || 0;
    const maxCustomers = container.maxCustomers || 0;

    const navigate = useNavigate();

    // Prepare an array of customer count items.
    const customerCounts = [];
    if (moveOutCount > 0) {
        customerCounts.push({ label: 'Move Out', count: moveOutCount });
    }
    if (summerCount > 0) {
        customerCounts.push({ label: 'Summer', count: summerCount });
    }
    if (moveInCount > 0) {
        customerCounts.push({ label: 'Move In', count: moveInCount });
    }

    // Copy container ID to clipboard.
    const handleCopy = () => {
        navigator.clipboard.writeText(container.id);
    };

    return (
        <React.Fragment key={container.id}>
            <TableRow>
                <TableCell>
                    <IconButton onClick={() => toggleExpand(container.id)}>
                        <ExpandMoreIcon
                            sx={{
                                transform: expanded[container.id] ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.2s',
                            }}
                        />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <IconButton onClick={handleCopy}>
                        <ContentCopyIcon />
                    </IconButton>
                </TableCell>
                {/* Display Move Out Appointment */}
                <TableCell sx={{ position: 'relative' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box flexGrow={1}>
                                {locationMap[container.moveOutAppointmentLocationID] || 'N/A'}
                            </Box>
                            <IconButton
                                size="small"
                                onClick={() =>
                                    openEditLocationDialog(
                                        container.id,
                                        'moveOut',
                                        container.moveOutAppointmentLocationID,
                                        container.moveOutAppointmentIDs && container.moveOutAppointmentIDs[0]
                                    )
                                }
                                sx={{ color: 'lightgray' }}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Box>
                        <AppointmentDateRangeDisplay
                            universityID={universityID}
                            containerLocationID={container.moveOutAppointmentLocationID}
                            appointmentIDs={container.moveOutAppointmentIDs}
                        />
                    </Box>
                </TableCell>
                {/* Display Move In Appointment */}
                <TableCell sx={{ position: 'relative' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box flexGrow={1}>
                                {locationMap[container.moveInAppointmentLocationID] || 'N/A'}
                            </Box>
                            <IconButton
                                size="small"
                                onClick={() =>
                                    openEditLocationDialog(
                                        container.id,
                                        'moveIn',
                                        container.moveInAppointmentLocationID,
                                        container.moveInAppointmentIDs && container.moveInAppointmentIDs[0]
                                    )
                                }
                                sx={{ color: 'lightgray' }}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Box>
                        <AppointmentDateRangeDisplay
                            universityID={universityID}
                            containerLocationID={container.moveInAppointmentLocationID}
                            appointmentIDs={container.moveInAppointmentIDs}
                        />
                    </Box>
                </TableCell>
                {/* SPACE USED COLUMN: Replace the old SpaceUsedBars with new CapacityBar components */}
                <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <CapacityBar containerId={container.id} period="moveOut" minimal />
                        <CapacityBar containerId={container.id} period="summer" minimal />
                        <CapacityBar containerId={container.id} period="moveIn" minimal />
                    </Box>
                </TableCell>
                <TableCell>
                    {customerCounts.length > 0 ? (
                        customerCounts.map((item) => (
                            <Box key={item.label} display="flex" justifyContent="space-between" mb={0.5}>
                                <Typography variant="caption" align="left">
                                    {item.label}:
                                </Typography>
                                <Typography variant="caption" align="right">
                                    {item.count}
                                    <Box component="span" sx={{ color: 'gray', fontSize: '0.65rem' }}>
                                        {` /${maxCustomers}`}
                                    </Box>
                                </Typography>
                            </Box>
                        ))
                    ) : (
                        <Typography variant="caption" sx={{ color: 'lightgray' }}>
                            None
                        </Typography>
                    )}
                </TableCell>
                <TableCell>
                    {/* Eyeball view icon */}
                    <Tooltip title="Container Details">
                        <IconButton onClick={() => navigate(`/dashboard/containers/container/${container.id}`)}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View Container X-Ray">
                        <IconButton onClick={() => navigate(`/dashboard/containers/container/${container.id}/xray`)}>
                            <ViewInArIcon />
                        </IconButton>
                    </Tooltip>
                    {/* 3-dot menu icon */}
                    <Tooltip title="More Options">
                        <IconButton>
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip>
                    {selectedCustomer && container.id !== selectedCustomerPrevContainerId && (
                        <Tooltip title="Move Customer to this Container">
                            <IconButton
                                onClick={() => handleMoveCustomerToContainer(container.id)}
                                sx={{ color: 'secondary.main' }}
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={expanded[container.id]} timeout="auto" unmountOnExit>
                        <ContainerCustomersTable container={container} onCustomerMoveClick={handleCustomerMoveClick} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
});

const ContainerTable = ({ universityID, containerLocationIDs, hideButtons }) => {
    const [containers, setContainers] = useState([]);
    const [locationMap, setLocationMap] = useState({});
    const [containerLocations, setContainerLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [refresh, setRefresh] = useState(0);

    // Add Container Dialog state (using appointment arrays)
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [moveInContainerLocation, setMoveInContainerLocation] = useState('');
    const [moveInAppointmentIDs, setMoveInAppointmentIDs] = useState([]);
    const [moveOutContainerLocation, setMoveOutContainerLocation] = useState('');
    const [moveOutAppointmentIDs, setMoveOutAppointmentIDs] = useState([]);

    // New state for Appointment Selector dialog (for add or edit)
    const [openAppointmentSelector, setOpenAppointmentSelector] = useState(false);
    const [appointmentSelectorType, setAppointmentSelectorType] = useState(''); // "moveIn", "moveOut", or "edit"

    // Edit Dialog state
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editingContainerId, setEditingContainerId] = useState(null);
    const [editingField, setEditingField] = useState('');
    const [newLocation, setNewLocation] = useState('');
    // Instead of a single appointment, use an array for editing.
    const [editingAppointmentIDs, setEditingAppointmentIDs] = useState([]);
    // Optionally store fetched appointment details (from the first appointment).
    const [editingAppointmentData, setEditingAppointmentData] = useState(null);

    const [expanded, setExpanded] = useState({});

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedCustomerType, setSelectedCustomerType] = useState(null);
    const [selectedCustomerPrevContainerId, setSelectedCustomerPrevContainerId] = useState(null);

    const { addNotification } = useNotification();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const colRef = collection(db, `universities/${universityID}/containerLocations`);
                const snapshot = await getDocs(colRef);
                const tempLocationMap = {};
                const allContainerLocations = [];
                snapshot.docs.forEach((docSnap) => {
                    const data = docSnap.data();
                    tempLocationMap[docSnap.id] = data.name;
                    allContainerLocations.push({
                        id: docSnap.id,
                        name: data.name,
                        locationGroup: data.locationGroup,
                    });
                });
                setLocationMap(tempLocationMap);
                setContainerLocations(allContainerLocations);

                const containersRef = collection(db, 'containers');
                const qMoveOut = query(
                    containersRef,
                    where('moveOutAppointmentLocationID', 'in', containerLocationIDs)
                );
                const qMoveIn = query(
                    containersRef,
                    where('moveInAppointmentLocationID', 'in', containerLocationIDs)
                );

                const [snapshotMoveOut, snapshotMoveIn] = await Promise.all([
                    getDocs(qMoveOut),
                    getDocs(qMoveIn),
                ]);

                const containerMap = new Map();
                snapshotMoveOut.forEach((docSnap) => {
                    containerMap.set(docSnap.id, { id: docSnap.id, ...docSnap.data() });
                });
                snapshotMoveIn.forEach((docSnap) => {
                    containerMap.set(docSnap.id, { id: docSnap.id, ...docSnap.data() });
                });
                setContainers(Array.from(containerMap.values()));
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (universityID && containerLocationIDs && containerLocationIDs.length > 0) {
            fetchData();
        }
    }, [universityID, containerLocationIDs, refresh]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setMoveInContainerLocation('');
        setMoveInAppointmentIDs([]);
        setMoveOutContainerLocation('');
        setMoveOutAppointmentIDs([]);
    };

    const handleAddContainer = async () => {
        try {
            await addDoc(collection(db, 'containers'), {
                moveInAppointmentLocationID: moveInContainerLocation,
                moveInAppointmentIDs,
                moveOutAppointmentLocationID: moveOutContainerLocation,
                moveOutAppointmentIDs,
                spaceReserved: 0,
                spaceCapacity: 100,
                moveInCustomers: [],
                moveOutCustomers: [],
                summerCustomers: [],
                maxCustomers: 10,
                universityId: universityID,
            });
            setRefresh((prev) => prev + 1);
            handleCloseAddDialog();
        } catch (error) {
            console.error('Error adding container:', error);
        }
    };

    // Open the Appointment Selector dialog for add mode.
    const openSelectorForType = (type) => {
        setAppointmentSelectorType(type);
        setOpenAppointmentSelector(true);
    };

    // Callback from AppointmentSelectorDialog for Add mode.
    const handleAppointmentSelectorClose = (selectedIDs) => {
        if (appointmentSelectorType === 'moveIn') {
            setMoveInAppointmentIDs(selectedIDs);
        } else if (appointmentSelectorType === 'moveOut') {
            setMoveOutAppointmentIDs(selectedIDs);
        }
        setOpenAppointmentSelector(false);
        setAppointmentSelectorType('');
    };

    // Open the edit dialog and load initial data.
    const openEditLocationDialog = async (containerId, field, currentLocation, currentAppointmentID) => {
        setEditingContainerId(containerId);
        setEditingField(field);
        setNewLocation(currentLocation);
        // For editing, load the full array from the container.
        if (field === 'moveIn') {
            const container = containers.find((c) => c.id === containerId);
            setEditingAppointmentIDs(container?.moveInAppointmentIDs || []);
        } else if (field === 'moveOut') {
            const container = containers.find((c) => c.id === containerId);
            setEditingAppointmentIDs(container?.moveOutAppointmentIDs || []);
        }
        // Optionally, fetch details from the first appointment.
        if (currentLocation && currentAppointmentID) {
            const appointment = await fetchAppointment(universityID, currentLocation, currentAppointmentID);
            setEditingAppointmentData(appointment);
        } else {
            setEditingAppointmentData(null);
        }
        setOpenEditDialog(true);
    };

    // Callback from AppointmentSelectorDialog for Edit mode.
    const handleEditAppointmentSelectorClose = (selectedIDs) => {
        setEditingAppointmentIDs(selectedIDs);
        setOpenAppointmentSelector(false);
        setAppointmentSelectorType('');
    };

    const handleSaveLocationChange = async () => {
        const fieldMapping = {
            moveIn: 'moveInAppointmentLocationID',
            moveOut: 'moveOutAppointmentLocationID',
            summer: 'summerAppointmentLocationID',
        };
        const appointmentFieldMapping = {
            moveIn: 'moveInAppointmentIDs',
            moveOut: 'moveOutAppointmentIDs',
            summer: 'summerAppointmentIDs',
        };

        try {
            const containerRef = doc(db, 'containers', editingContainerId);
            await updateDoc(containerRef, {
                [fieldMapping[editingField]]: newLocation,
                [appointmentFieldMapping[editingField]]: editingAppointmentIDs,
            });
            setContainers((prevContainers) =>
                prevContainers.map((container) =>
                    container.id === editingContainerId
                        ? {
                            ...container,
                            [fieldMapping[editingField]]: newLocation,
                            [appointmentFieldMapping[editingField]]: editingAppointmentIDs,
                        }
                        : container
                )
            );
        } catch (error) {
            console.error(`Error updating ${editingField} appointments:`, error);
        } finally {
            setOpenEditDialog(false);
            setEditingContainerId(null);
            setEditingField('');
            setNewLocation('');
            setEditingAppointmentIDs([]);
            setEditingAppointmentData(null);
        }
    };

    const handleCustomerMoveClick = (customer, type, previousContainerId) => {
        setSelectedCustomer(customer);
        setSelectedCustomerType(type);
        setSelectedCustomerPrevContainerId(previousContainerId);
    };

    const handleMoveCustomerToContainer = async (containerId) => {
        if (!selectedCustomer) return;

        if (containerId === selectedCustomerPrevContainerId) {
            addNotification({
                status: 'info',
                message: 'Customer is already in the selected container.',
            });
            return;
        }

        let field;
        if (selectedCustomerType === 'moveIn') {
            field = 'moveInCustomers';
        } else if (selectedCustomerType === 'moveOut') {
            field = 'moveOutCustomers';
        } else if (selectedCustomerType === 'summer') {
            field = 'summerCustomers';
        }

        setContainers((prevContainers) =>
            prevContainers.map((container) => {
                if (container.id === containerId) {
                    return {
                        ...container,
                        [field]: [...(container[field] || []), selectedCustomer.id],
                    };
                } else if (container.id === selectedCustomerPrevContainerId) {
                    return {
                        ...container,
                        [field]: container[field]?.filter((id) => id !== selectedCustomer.id),
                    };
                }
                return container;
            })
        );

        try {
            const containerRef = doc(db, 'containers', containerId);
            await updateDoc(containerRef, {
                [field]: arrayUnion(selectedCustomer.id),
            });
            if (selectedCustomerPrevContainerId) {
                const prevContainerRef = doc(db, 'containers', selectedCustomerPrevContainerId);
                await updateDoc(prevContainerRef, {
                    [field]: arrayRemove(selectedCustomer.id),
                });
            }
            addNotification({
                status: 'success',
                message: 'Customer successfully moved between containers.',
            });
        } catch (error) {
            console.error('Error moving customer:', error);
            addNotification({
                status: 'error',
                message: 'Error moving customer between containers.',
            });
        } finally {
            setSelectedCustomer(null);
            setSelectedCustomerType(null);
            setSelectedCustomerPrevContainerId(null);
        }
    };

    const toggleExpand = (containerId) => {
        setExpanded((prev) => ({ ...prev, [containerId]: !prev[containerId] }));
    };

    const getMoveTypeText = () => {
        if (selectedCustomerType === 'moveIn') return 'move in';
        if (selectedCustomerType === 'moveOut') return 'move out';
        if (selectedCustomerType === 'summer') return 'summer container';
        return '';
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', spacing: 2 }}>
                    <Typography variant="h6">Containers</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', spacing: 2, alignItems: 'center' }}>
                    {selectedCustomer && (
                        <>
                            <Typography variant="body1">
                                {`Changing ${getMoveTypeText()} container for: ${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
                            </Typography>
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={{ mx: 2 }}
                                onClick={() => {
                                    setSelectedCustomer(null);
                                    setSelectedCustomerType(null);
                                    setSelectedCustomerPrevContainerId(null);
                                }}
                            >
                                Cancel
                            </Button>
                        </>
                    )}
                    <Box display={hideButtons ? 'none' : 'block'}>
                        <Button
                            variant="outlined"
                            color="primary"
                            component={Link}
                            to={`/dashboard/containers/university/graph/${universityID}`}
                            startIcon={<AccountTreeOutlined />}
                            sx={{ ml: 2, mr: 2 }}
                        >
                            View Flow
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleOpenAddDialog}
                            startIcon={<AddCircleOutline />}>
                            Add Container
                        </Button>

                    </Box>
                </Box>
            </Box>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Paper sx={{ p: 2 }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                        <TableCell sx={{ width: '1%', whiteSpace: 'nowrap' }} />
                                        <TableCell sx={{ width: '1%', whiteSpace: 'nowrap' }}>Container ID</TableCell>

                                    <TableCell>Move Out Appointment</TableCell>
                                    <TableCell>Move In Appointment</TableCell>
                                    <TableCell>Space Used</TableCell>
                                    <TableCell>Customers</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {containers
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((container) => (
                                        <ContainerRow
                                            key={container.id}
                                            container={container}
                                            locationMap={locationMap}
                                            expanded={expanded}
                                            toggleExpand={toggleExpand}
                                            openEditLocationDialog={openEditLocationDialog}
                                            handleMoveCustomerToContainer={handleMoveCustomerToContainer}
                                            selectedCustomer={selectedCustomer}
                                            selectedCustomerPrevContainerId={selectedCustomerPrevContainerId}
                                            handleCustomerMoveClick={handleCustomerMoveClick}
                                            universityID={universityID}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={containers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </Paper>
            )}

            {/* Add Container Dialog */}
            <Dialog open={openAddDialog} onClose={handleCloseAddDialog} fullWidth maxWidth="sm">
                <DialogTitle>Add Container</DialogTitle>
                <DialogContent>
                    {/* For Move In Appointment */}
                    <ContainerLocationDropdownSelect
                        universityID={universityID}
                        selectedValue={moveInContainerLocation}
                        onChange={(e) => {
                            setMoveInContainerLocation(e.target.value);
                            setMoveInAppointmentIDs([]);
                        }}
                        label="Move In Appointment Location"
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        sx={{ mb: 2 }}
                        onClick={() => openSelectorForType('moveIn')}
                        disabled={!moveInContainerLocation}
                    >
                        Select Move In Appointments
                    </Button>
                    {moveInAppointmentIDs.length > 0 && (
                        <AppointmentDateRangeDisplay
                            universityID={universityID}
                            containerLocationID={moveInContainerLocation}
                            appointmentIDs={moveInAppointmentIDs}
                        />
                    )}
                    {/* For Move Out Appointment */}
                    <ContainerLocationDropdownSelect
                        universityID={universityID}
                        selectedValue={moveOutContainerLocation}
                        onChange={(e) => {
                            setMoveOutContainerLocation(e.target.value);
                            setMoveOutAppointmentIDs([]);
                        }}
                        label="Move Out Appointment Location"
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        sx={{ mb: 2 }}
                        onClick={() => openSelectorForType('moveOut')}
                        disabled={!moveOutContainerLocation}
                    >
                        Select Move Out Appointments
                    </Button>
                    {moveOutAppointmentIDs.length > 0 && (
                        <AppointmentDateRangeDisplay
                            universityID={universityID}
                            containerLocationID={moveOutContainerLocation}
                            appointmentIDs={moveOutAppointmentIDs}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleAddContainer}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Appointment Dialog */}
            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    {editingField === 'moveIn'
                        ? 'Edit Move In Appointment'
                        : editingField === 'moveOut'
                            ? 'Edit Move Out Appointment'
                            : 'Edit Summer Appointment'}
                </DialogTitle>
                <DialogContent>
                    <ContainerLocationDropdownSelect
                        universityID={universityID}
                        selectedValue={newLocation}
                        onChange={(e) => setNewLocation(e.target.value)}
                        label="Appointment Location"
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        sx={{ mb: 2 }}
                        onClick={() => {
                            // Open Appointment Selector for edit mode.
                            setAppointmentSelectorType('edit');
                            setOpenAppointmentSelector(true);
                        }}
                        disabled={!newLocation}
                    >
                        Select Appointments
                    </Button>
                    {editingAppointmentIDs.length > 0 && (
                        <AppointmentDateRangeDisplay
                            universityID={universityID}
                            containerLocationID={newLocation}
                            appointmentIDs={editingAppointmentIDs}
                        />
                    )}
                    {editingAppointmentData && (
                        <Box sx={{ p: 1, backgroundColor: '#f5f5f5', borderRadius: 1, mt: 1 }}>
                            <Typography variant="caption">
                                Appointment Date:{' '}
                                {editingAppointmentData.start
                                    ? new Date(editingAppointmentData.start.seconds * 1000).toLocaleDateString()
                                    : 'N/A'}
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleSaveLocationChange}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Appointment Selector Dialog */}
            <AppointmentSelectorDialog
                open={openAppointmentSelector}
                onClose={
                    appointmentSelectorType === 'edit'
                        ? handleEditAppointmentSelectorClose
                        : handleAppointmentSelectorClose
                }
                universityID={universityID}
                containerLocationID={
                    appointmentSelectorType === 'moveIn'
                        ? moveInContainerLocation
                        : appointmentSelectorType === 'moveOut'
                            ? moveOutContainerLocation
                            : newLocation
                }
                initialSelected={
                    appointmentSelectorType === 'edit'
                        ? editingAppointmentIDs
                        : appointmentSelectorType === 'moveIn'
                            ? moveInAppointmentIDs
                            : moveOutAppointmentIDs
                }
            />
        </Box>
    );
};

export default ContainerTable;
