import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { ArrowForward } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  Grid,
  Tabs,
  Tab,
  Button,
  Skeleton,
} from '@mui/material';
import { Search } from '@mui/icons-material';

// Function to load all item configurations
const loadAllConfigs = async () => {
  try {
    const itemConfigsModule = await import('../config/items.json');
    const itemConfigs = itemConfigsModule.default || itemConfigsModule;

    if (!Array.isArray(itemConfigs)) {
      throw new TypeError('itemConfigs is not an array');
    }

    const items = {};
    itemConfigs.forEach((itemConfig) => {
      items[itemConfig.id] = itemConfig;
    });

    return items;
  } catch (error) {
    console.error('Error loading item configurations:', error);
    return {};
  }
};

const StorableItems = () => {
  const [allConfigs, setAllConfigs] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeInfo, setActiveInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConfigs = async () => {
      const configs = await loadAllConfigs();
      setAllConfigs(configs);
      setLoading(false);
    };

    fetchConfigs();
  }, []);

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const handleShowInfo = (id, infoType) => {
    setActiveInfo({ id, infoType });
  };

  const handleHideInfo = () => {
    setActiveInfo({});
  };

  // Create categories from the config data (if available)
  const categories = ['All', ...new Set(Object.values(allConfigs).map((config) => config.category))];

  // Filter items based on selected category and search term
  const filteredItems = Object.values(allConfigs).filter(
    (config) =>
      (selectedCategory === 0 || config.category === categories[selectedCategory]) &&
      config.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // For the "All" category, move "summerstore_box" to the front
  if (selectedCategory === 0) {
    const summerStoreBoxIndex = filteredItems.findIndex((config) => config.id === 'summerstore_box');
    if (summerStoreBoxIndex !== -1) {
      const [summerStoreBoxItem] = filteredItems.splice(summerStoreBoxIndex, 1);
      filteredItems.unshift(summerStoreBoxItem);
    }
  }

  // Display skeletons while loading
  if (loading) {
    return (
      <Box sx={{ mb: 0, height: '100vh', width: '100%' }}>
        <NavBar />
        <Box sx={{ p: { md: 8, xs: 1 }, flexGrow: 1 }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              px: { xs: 3 },
              fontWeight: 'bold',
              mt: { xs: 4 },
              fontSize: { xs: '1.9rem' },
              textAlign: { md: 'center', xs: 'left' },
            }}
          >
            Storable Items
          </Typography>
          <Typography variant="body1" sx={{ px: { xs: 3 }, textAlign: { md: 'center', xs: 'left' } }}>
            We are able to store any of the following item types at no additional cost to you.
          </Typography>
          <Box sx={{ my: 3, mx: { xs: 3, md: 'auto' } }}>
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Box>
          <Grid container spacing={2}>
            {Array.from(new Array(6)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rectangular" width="100%" height={200} />
                <Skeleton variant="text" width="80%" height={30} sx={{ mt: 1 }} />
                <Skeleton variant="rectangular" width="60%" height={30} sx={{ mt: 1 }} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Footer id="footer" />
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 0, minHeight: '100%', width: '100%' }}>
      <NavBar />
      <Box sx={{ p: { md: 8, xs: 1 }, flexGrow: 1 }}>
        <Grid container spacing={2}>
          {/* Left Column: Storable Items */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                px: { xs: 3 },
                mt: { xs: 4 },
                textAlign: 'left',
              }}
            >
              Storable Items
            </Typography>
            <Typography variant="body1" sx={{ px: { xs: 3 }, textAlign: 'left' }}>
              We are able to store any of the following item types at no additional cost to you.
            </Typography>
          </Grid>

          {/* Right Column: Request form info */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column', 
              justifyContent: 'center',
              textAlign: 'right',
              px: { xs: 3 },
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                mt: { xs: 4 },
                px: { xs: 3 },
                textAlign: {xs: "left", md: "right"}
              }}
            >
              Don't see your item on this list?
            </Typography>
            <Link to="/dashboard/items" style={{ textDecoration: 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'right',
                  justifyContent: {xs: "flex-start", md: "flex-end"},
                  px: { xs: 3 }, 
                  color: 'primary.main',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                <Typography variant="body1" sx={{ mr: 1 }}>
                  Request an item
                </Typography>
                <ArrowForward fontSize="small" />
              </Box>
            </Link>
          </Grid>
        </Grid>

        {/* Main Content: Search, Tabs, and Items */}
        <Box sx={{ p: 3, mt: 1 }}>
          <Box sx={{ mt: { md: 2, xs: 1 } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Tabs value={selectedCategory} onChange={handleCategoryChange}>
                {categories.map((category, index) => (
                  <Tab key={category} label={category} />
                ))}
              </Tabs>
            </Box>
            <TextField
              variant="outlined"
              placeholder="Search for an item..."
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Grid container spacing={2}>
              {filteredItems.map((config) => (
                <Grid item xs={12} sm={6} md={4} key={config.id}>
                  <Paper
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      border: '1px solid #ccc',
                      overflow: 'hidden',
                      transition: 'all 0.3s ease-in-out',
                      position: 'relative',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        transform: activeInfo.id === config.id ? 'translateX(-100%)' : 'translateX(0)',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: 'primary.main',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: '100%',
                          backgroundImage: 'url(/images/background_pattern.webp)',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          py: 3,
                        }}
                      >
                        <img src={config.icon} alt={config.label} style={{ width: 80, height: 80 }} />
                      </Box>
                      <Typography variant="h6" sx={{ mt: 1, fontWeight: 'bold' }}>
                        {config.label}
                      </Typography>
                      <Box sx={{ display: 'flex', mt: 1, mb: 2 }}>
                        <Box>
                          <Button variant="contained" sx={{ m: 1 }} onClick={() => handleShowInfo(config.id, 'maxDimensions')}>
                            Max Dimensions
                          </Button>
                          <Button variant="outlined" sx={{ m: 1 }} onClick={() => handleShowInfo(config.id, 'packingInstructions')}>
                            Packing Instructions
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#fff',
                        transform:
                          activeInfo.id === config.id &&
                            (activeInfo.infoType === 'maxDimensions' || activeInfo.infoType === 'packingInstructions')
                            ? 'translateX(0)'
                            : 'translateX(100%)',
                        opacity:
                          activeInfo.id === config.id &&
                            (activeInfo.infoType === 'maxDimensions' || activeInfo.infoType === 'packingInstructions')
                            ? 1
                            : 0,
                        transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
                        padding: 2,
                      }}
                    >
                      <Typography variant="h6" sx={{ mt: 1 }}>
                        {activeInfo.infoType === 'maxDimensions' ? 'Maximum Dimensions' : 'Packing Instructions'}
                      </Typography>
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        {activeInfo.infoType === 'maxDimensions'
                          ? config.maxDimensions
                          : config.packingInstructions}
                      </Typography>
                      <Button variant="contained" onClick={handleHideInfo} sx={{ mt: 2 }}>
                        Back
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Footer id="footer" />
    </Box>
  );
};

export default StorableItems;

