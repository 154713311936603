import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, Tooltip, Link, Skeleton, Button } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses,
} from '@mui/lab';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase'; // your Firestore init
import {
  CalendarToday,
  ShoppingCart,
  Inventory2,
  BeachAccess,
  CheckCircle,
  HelpOutline,
} from '@mui/icons-material';
import Storage from '@mui/icons-material/Storage';
import { useNavigate } from 'react-router-dom';
import WelcomePopup from '../components/WelcomePopup';
import NotificationsCarousel from '../components/NotificationsCarousel';
import { useAuth } from '../contexts/AuthContext';

const Dashboard = () => {
  const { userClaims, currentUser } = useAuth();
  const navigate = useNavigate();

  // State to hold computed university deadlines/dates
  const [uniDeadlines, setUniDeadlines] = useState({
    scheduleMoveIn: null,
    servicePaymentDue: null,
    suppliesPickup: null,
    addItems: null,
    moveOut: null,
    enjoySummerStart: null,
    enjoySummerEnd: null,
    moveIn: null,
  });

  // Existing states
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [storagePlan, setStoragePlan] = useState('None');
  const [moveOutAppointmentDetails, setMoveOutAppointmentDetails] = useState({});
  const [moveInAppointmentDetails, setMoveInAppointmentDetails] = useState({});
  const [loading, setLoading] = useState(true);

  // Helper function to get ordinal suffix for a day
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  // Helper function to format the day with ordinal suffix
  const formatDay = (date) => {
    const day = date.getDate();
    const suffix = getOrdinalSuffix(day);
    const month = date.toLocaleString('en-US', { month: 'long' });
    return `${month} ${day}${suffix}`;
  };

  // Fetch user data (appointments, storagePlan, etc.)
  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser) return;
      const userDocRef = doc(db, 'users', currentUser.uid);
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        const { universityId,
          moveInContainerLocationID,
          moveOutContainerLocationID,
          moveInAppointment,
          moveOutAppointment,
          hasSeenWelcomePopup,
          storagePlan } = userData;

        if (universityId && moveInContainerLocationID && moveInAppointment) {
          const inApptDocRef = doc(
            db,
            'universities',
            universityId,
            'containerLocations',
            moveInContainerLocationID,
            'appointments',
            moveInAppointment
          );
          const inApptSnap = await getDoc(inApptDocRef);
          if (inApptSnap.exists()) {
            setMoveInAppointmentDetails(inApptSnap.data());
          }
        } else {
          console.error('One or more required values for move in appointment are undefined.');
        }

        if (universityId && moveOutContainerLocationID && moveOutAppointment) {
          const outApptDocRef = doc(
            db,
            'universities',
            universityId,
            'containerLocations',
            moveOutContainerLocationID,
            'appointments',
            moveOutAppointment
          );
          const outApptSnap = await getDoc(outApptDocRef);
          if (outApptSnap.exists()) {
            setMoveOutAppointmentDetails(outApptSnap.data());
          }
        } else {
          console.error('One or more required values for move out appointment are undefined.');
        }

        setShowWelcomePopup(!hasSeenWelcomePopup);

        if (storagePlan) {
          const productDoc = await getDoc(doc(db, 'products', userData.storagePlan));
          if (productDoc.exists()) {
            setStoragePlan(productDoc.data().title);
          }
        }
      }
      // Once data is fetched, remove the skeleton
      setLoading(false);
    };

    fetchUserData();
  }, [currentUser]);

  // Fetch university data and compute deadlines/dates based on Firebase timestamps.
  useEffect(() => {
    const fetchUniversityData = async () => {
      if (!currentUser || !userClaims.universityId) return;
      const universityId = userClaims.universityId;
      const uniDocRef = doc(db, 'universities', universityId);
      const uniSnap = await getDoc(uniDocRef);
      if (uniSnap.exists()) {
        const uniData = uniSnap.data();
        const now = new Date();

        // Schedule Move In: compare early and late deadlines and choose the earlier that hasn’t passed.
        const earlyDeadline = uniData.moveInAppointmentEarlyDeadline?.toDate();
        const lateDeadline = uniData.moveInAppointmentLateDeadline?.toDate();
        let scheduleMoveIn = null;
        if (earlyDeadline && earlyDeadline > now) {
          scheduleMoveIn = earlyDeadline;
        } else if (lateDeadline && lateDeadline > now) {
          scheduleMoveIn = lateDeadline;
        }

        // Registration deadlines (used for both Service Payment Due and Select Storage Plan)
        const earlyReg = uniData.earlyRegistrationDeadline?.toDate();
        const lateReg = uniData.lateRegistrationDeadline?.toDate();

        let servicePaymentDue = null;
        if (earlyReg && earlyReg > now) {
          servicePaymentDue = earlyReg;
        } else if (lateReg && lateReg > now) {
          servicePaymentDue = lateReg;
        }

        // Supplies Pickup Date:
        const suppliesPickup = uniData.suppliesPickupDate?.toDate();

        // Add Items: one day before the user’s move out appointment date.
        let addItems = null;
        let moveOutDate = null;
        if (moveOutAppointmentDetails.start) {
          moveOutDate = moveOutAppointmentDetails.start.toDate
            ? moveOutAppointmentDetails.start.toDate()
            : new Date(moveOutAppointmentDetails.start);
          addItems = new Date(moveOutDate);
          addItems.setDate(addItems.getDate() - 1);
        }

        // Enjoy Summer: from the latest of moveOutDates to the earliest of moveInDates.
        const moveOutDates = uniData.moveOutDates || [];
        const moveInDates = uniData.moveInDates || [];
        const enjoySummerStart = moveOutDates.length
          ? new Date(Math.max(...moveOutDates.map(ts => ts.toDate().getTime())))
          : null;
        const enjoySummerEnd = moveInDates.length
          ? new Date(Math.min(...moveInDates.map(ts => ts.toDate().getTime())))
          : null;

        // Move In: from the user's move in appointment.
        let moveIn = null;
        if (moveInAppointmentDetails.start) {
          moveIn = moveInAppointmentDetails.start.toDate
            ? moveInAppointmentDetails.start.toDate()
            : new Date(moveInAppointmentDetails.start);
        }

        setUniDeadlines({
          scheduleMoveIn,
          servicePaymentDue,
          suppliesPickup,
          addItems,
          moveOut: moveOutDate,
          enjoySummerStart,
          enjoySummerEnd,
          moveIn,
        });
      }
    };

    fetchUniversityData();
  }, [currentUser, userClaims.universityId, moveOutAppointmentDetails, moveInAppointmentDetails]);

  // Prepare timeline steps and add each step's associated deadline (if any)
  // New step "Select Storage Plan" is added before "Service Payment Due".
  const timelineSteps = [
    {
      label: 'Select Storage Plan',
      icon: <Storage />,
      date: uniDeadlines.servicePaymentDue ? `by ${formatDay(uniDeadlines.servicePaymentDue)}` : 'TBD',
      tooltip: 'Select your storage plan',
      button: storagePlan === 'None' ? 'Select Plan' : 'Plan Selected',
      deadline: uniDeadlines.servicePaymentDue, // same as registration deadline
    },
    {
      label: 'Service Payment Due',
      icon: <CalendarToday />,
      date: uniDeadlines.servicePaymentDue
        ? `on ${formatDay(uniDeadlines.servicePaymentDue)}, 11:59pm`
        : 'TBD',
      tooltip: 'Make your service payment',
      button: 'Pay Now',
      deadline: uniDeadlines.servicePaymentDue,
    },
    {
      label: 'Supplies Pickup',
      icon: <ShoppingCart />,
      date: uniDeadlines.suppliesPickup
        ? `On ${formatDay(uniDeadlines.suppliesPickup)}`
        : 'TBD',
      tooltip: 'Pick up your supplies',
      button: 'Learn More',
      deadline: uniDeadlines.suppliesPickup,
    },
    {
      label: 'Add Items',
      icon: <Inventory2 />,
      date: uniDeadlines.addItems
        ? `By ${formatDay(uniDeadlines.addItems)}`
        : 'TBD',
      tooltip: 'Report your items',
      button: 'My Items',
      deadline: uniDeadlines.addItems,
    },
    {
      label: 'Move Out',
      icon: <Inventory2 />,
      date: uniDeadlines.moveOut
        ? `On ${formatDay(uniDeadlines.moveOut)}`
        : 'TBD',
      tooltip: 'Schedule your move out',
      button: 'View Appointment',
      deadline: uniDeadlines.moveOut,
    },
    {
      label: 'Enjoy Summer',
      icon: <BeachAccess />,
      date: uniDeadlines.enjoySummerStart && uniDeadlines.enjoySummerEnd
        ? `${formatDay(uniDeadlines.enjoySummerStart)} - ${formatDay(uniDeadlines.enjoySummerEnd)}`
        : 'TBD',
      tooltip: 'Enjoy your summer break',
      button: null,
      deadline: null,
    },
    {
      label: 'Schedule Move In',
      icon: <CalendarToday />,
      date: uniDeadlines.scheduleMoveIn
        ? `By ${formatDay(uniDeadlines.scheduleMoveIn)}, 11:59pm`
        : 'TBD',
      tooltip: 'Schedule your move in appointment',
      button: 'Schedule',
      deadline: uniDeadlines.scheduleMoveIn,
    },
    {
      label: 'Move In',
      icon: <CheckCircle />,
      date: uniDeadlines.moveIn
        ? `On ${formatDay(uniDeadlines.moveIn)}`
        : 'TBD',
      tooltip: 'Confirm your move in appointment',
      button: 'View Appointment',
      deadline: uniDeadlines.moveIn,
    },
  ];

  // Instead of: const now = new Date();
  const now = new Date();

  // Find the first timeline step that has a deadline in the future (upcoming deadline)
  // and skip "Select Storage Plan" if a storage plan has already been selected.
  const upcomingStepIndex = timelineSteps.findIndex(
    (step) =>
      step.deadline &&
      now < step.deadline &&
      !(step.label === 'Select Storage Plan' && storagePlan !== 'None')
  );

  // Handler for timeline button clicks
  const handleTimelineButtonClick = (label) => {
    switch (label) {
      case 'Select Storage Plan':
        navigate('plan');
        break;
      case 'Service Payment Due':
        navigate('payments');
        break;
      case 'Supplies Pickup':
        navigate('');
        break;
      case 'Add Items':
        navigate('my-items');
        break;
      case 'Move Out':
      case 'Move In':
        navigate('appointments');
        break;
      default:
        break;
    }
  };

  const handleClosePopup = async () => {
    setShowWelcomePopup(false);
    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        hasSeenWelcomePopup: true,
      });
    }
  };

  const firstName = userClaims.firstName || 'User';
  const profileInitial = firstName.charAt(0);
  const fullName = `${firstName} ${userClaims.lastName || ''}`.trim();
  const email = currentUser?.email || '';

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      {/* Welcome Popup */}
      <WelcomePopup
        name={firstName}
        open={showWelcomePopup}
        onClose={handleClosePopup}
      />

      <Typography variant="h4" gutterBottom>
        Welcome, {firstName}!
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" gutterBottom sx={{ color: 'text.secondary', textTransform: 'uppercase' }}>
            Account Details
          </Typography>
          <Paper sx={{ p: 2 }}>
            {loading ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton variant="circular" width={50} height={50} sx={{ mr: 2 }} />
                  <Box>
                    <Skeleton variant="text" width={120} height={30} />
                    <Skeleton variant="text" width={180} />
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="text" width="80%" />
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      backgroundColor: '#007bff',
                      color: 'white',
                      flexShrink: 0,
                      height: '50px',
                      width: '50px',
                      mr: 2,
                    }}
                  >
                    <Typography variant="h5" component="div" color="white">
                      {profileInitial}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">{fullName}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {email}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1">
                      <strong>Storage Plan:</strong> {storagePlan}
                    </Typography>
                    <Link href="dashboard/plan" variant="body2" sx={{ textAlign: 'right' }}>
                      Change Plan
                    </Link>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1">
                      <strong>Move Out:</strong> {moveOutAppointmentDetails.start
                        ? (moveOutAppointmentDetails.start.toDate
                          ? formatDay(moveOutAppointmentDetails.start.toDate())
                          : formatDay(new Date(moveOutAppointmentDetails.start)))
                        : 'N/A'}
                    </Typography>
                    <Link href="dashboard/appointments" variant="body2" sx={{ textAlign: 'right' }}>
                      Edit
                    </Link>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1">
                      <strong>Move In:</strong> {moveInAppointmentDetails.start
                        ? (moveInAppointmentDetails.start.toDate
                          ? formatDay(moveInAppointmentDetails.start.toDate())
                          : formatDay(new Date(moveInAppointmentDetails.start)))
                        : 'N/A'}
                    </Typography>
                    <Link href="dashboard/appointments" variant="body2" sx={{ textAlign: 'right' }}>
                      Edit
                    </Link>
                  </Box>
                </Box>
              </>
            )}
          </Paper>

          <Box sx={{ mt: 3 }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ color: 'text.secondary', textTransform: 'uppercase' }}
            >
              Notifications
            </Typography>
            <NotificationsCarousel loading={loading} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ color: 'text.secondary', textTransform: 'uppercase', mb: 2 }}
          >
            Service Timeline
          </Typography>
          <Paper sx={{ p: 2, pt: 1, height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {/* Vertical segmented progress bar */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {loading
                  ? Array.from(new Array(timelineSteps.length)).map((_, i) => (
                    <Skeleton
                      key={i}
                      variant="rectangular"
                      height={`${100 / timelineSteps.length}%`}
                      sx={{ mb: i < timelineSteps.length - 1 ? 2 : 0 }}
                    />
                  ))
                  : timelineSteps.map((step, i) => {
                    let isActive = false;
                    if (step.label === 'Enjoy Summer') {
                      if (uniDeadlines.enjoySummerStart && uniDeadlines.enjoySummerEnd) {
                        if (new Date() >= uniDeadlines.enjoySummerStart && new Date() <= uniDeadlines.enjoySummerEnd) {
                          isActive = true;
                        }
                      }
                    } else if (step.deadline) {
                      if (new Date() <= step.deadline && i === upcomingStepIndex) {
                        isActive = true;
                      }
                    }
                    return (
                      <Box
                        key={i}
                        sx={{
                          width: '4px',
                          height: `${100 / timelineSteps.length}%`,
                          bgcolor: isActive ? 'secondary.main' : 'grey.300',
                        }}
                      />
                    );
                  })}
              </Box>
              {/* Timeline steps */}
              <Box sx={{ ml: 2, width: '100%' }}>
                {loading ? (
                  Array.from(new Array(timelineSteps.length)).map((_, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        py: 2,
                        borderBottom: index !== timelineSteps.length - 1 ? '1px solid #eee' : 'none',
                      }}
                    >
                      <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
                      <Box sx={{ flexGrow: 1 }}>
                        <Skeleton variant="text" width="30%" />
                        <Skeleton variant="text" width="20%" />
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Skeleton variant="rectangular" width={80} height={30} />
                      </Box>
                    </Box>
                  ))
                ) : (
                  timelineSteps.map((step, index) => {
                    const now = new Date();
                    let isPast = false;
                    let isActive = false;
                    let isFuture = false;

                    if (step.label === 'Select Storage Plan' && storagePlan !== 'None') {
                      isPast = true;
                    } else if (step.label === 'Enjoy Summer') {
                      if (uniDeadlines.enjoySummerStart && uniDeadlines.enjoySummerEnd) {
                        if (now < uniDeadlines.enjoySummerStart) {
                          isFuture = true;
                        } else if (now > uniDeadlines.enjoySummerEnd) {
                          isPast = true;
                        } else {
                          isActive = true;
                        }
                      } else {
                        isFuture = true;
                      }
                    } else if (step.deadline) {
                      if (now > step.deadline) {
                        isPast = true;
                      } else if (now <= step.deadline && index === upcomingStepIndex) {
                        isActive = true;
                      } else {
                        isFuture = true;
                      }
                    } else {
                      isFuture = true;
                    }

                    let iconContainerStyles = {};
                    let iconElement = step.icon;
                    let titleStyles = {};
                    let buttonVariant = 'outlined';
                    let buttonProps = {};

                    if (isPast) {
                      iconContainerStyles = { backgroundColor: 'grey.100' };
                      iconElement = <CheckCircle sx={{ color: 'grey.600' }} />;
                      titleStyles = { color: 'grey.600', fontWeight: 'normal' };
                      buttonVariant = 'outlined';
                      buttonProps = { disabled: true };
                    } else if (isActive) {
                      iconContainerStyles = { backgroundColor: 'primary.main' };
                      iconElement = React.cloneElement(step.icon, {
                        sx: { color: 'common.white' },
                      });
                      titleStyles = { color: 'primary.main', fontWeight: 'bold' };
                      buttonVariant = 'contained';
                    } else {
                      iconContainerStyles = { backgroundColor: 'grey.300' };
                      iconElement = React.cloneElement(step.icon, {
                        sx: { color: 'grey.600' },
                      });
                      titleStyles = { color: 'grey.600', fontWeight: 'normal' };
                      buttonVariant = 'outlined';
                    }

                    return (
                      <Box
                        key={step.label}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          py: 2,
                          borderBottom: index !== timelineSteps.length - 1 ? '1px solid #eee' : 'none',
                        }}
                      >
                        {/* Icon in a circle */}
                        <Box
                          sx={{
                            ...iconContainerStyles,
                            borderRadius: '50%',
                            width: 40,
                            height: 40,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 2,
                          }}
                        >
                          {iconElement}
                        </Box>
                        {/* Step details */}
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body1" sx={titleStyles}>
                            {step.label}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {step.date}
                          </Typography>
                        </Box>
                        {/* Button and tooltip */}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {step.button && (
                            <>
                              <Button
                                variant={buttonVariant}
                                size="small"
                                onClick={() => {
                                  if (!isPast) {
                                    handleTimelineButtonClick(step.label);
                                  }
                                }}
                                sx={{ mr: 1 }}
                                {...buttonProps}
                              >
                                {step.button}
                              </Button>
                              <Tooltip title={step.tooltip}>
                                <HelpOutline sx={{ color: 'grey.500' }} />
                              </Tooltip>
                            </>
                          )}
                        </Box>
                      </Box>
                    );
                  })
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
